import { AxiosError } from 'axios';
import { t } from 'i18next';

// const NO_ERROR_URLS = [REPORTS_CONSTANTS.URLS.GET_AUDIT_REPORTS, '/login'];
const NO_ERROR_URLS = ['/login'];

const noErrorUrl = (error: AxiosError) => {
  const { url, baseURL } = {
    baseURL: error?.config?.baseURL,
    url: error?.request?.responseURL,
  };
  let newUrl = url.replace(baseURL, '');
  if (newUrl.indexOf('?') > 0) {
    newUrl = newUrl.substring(0, newUrl.indexOf('?'));
  }
  return NO_ERROR_URLS.includes(newUrl);
};
const handleApiError = (error: any) => {
  const { lang, status, errorMessage } = {
    lang: error?.config?.headers['Accept-Language'],
    status: error?.response?.status,
    errorMessage:
      error?.response?.data?.message || error?.response?.data?.detail,
  };
  if (noErrorUrl(error)) {
    return;
  }
};
export { handleApiError };
