import { UseMutationOptions, useMutation } from "@tanstack/react-query"
import { AxiosInstance, AxiosResponse } from "axios"
import { IPropsManualReservation, IResponseManualReservation } from "./types"
import { useApi } from "../../useApi"
import { ManualReservationAPI } from "../../../utils/constants"
import { Dispatch, SetStateAction, useContext } from "react"
import { ToastMessageContext } from "@context/toast-message-cotnext"
import { useTranslation } from "react-i18next"
async function postManualReservation(
	responseData: IPropsManualReservation,
	apiPrivate: AxiosInstance,
	showError: Dispatch<SetStateAction<string | undefined>>,
	language: string
): Promise<AxiosResponse<IResponseManualReservation>> {
	try {
		const response: AxiosResponse<IResponseManualReservation> =
			await apiPrivate.post(`${ManualReservationAPI}`, { ...responseData })

		return response
	} catch (error: any) {
		showError(
			error.response?.data?.errorMessages
				.map((item: any) =>
					language === "ar" ? item.messageAr : item.messageEn
				)
				.join(", ")
		)
		return error
	}
}

export function usePostManualReservation(
	options?:
		| Omit<
				UseMutationOptions<
					AxiosResponse<IResponseManualReservation>,
					unknown,
					unknown,
					AxiosResponse<IResponseManualReservation>
				>,
				"mutationFn"
		  >
		| undefined
) {
	const { showError, showSuccess } = useContext(ToastMessageContext)
	const {
		i18n: { language },
	} = useTranslation()
	const { apiPrivate } = useApi()
	const mutation = useMutation({
		mutationFn: (data: IPropsManualReservation) =>
			postManualReservation(data, apiPrivate, showError, language),
		...options,
	})
	return mutation
}
