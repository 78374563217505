import { MenuItem, Pagination, Select } from "@mui/material"
import { CustomPaginationWrapper } from "./styles"
import { Dispatch, SetStateAction, useEffect } from "react"
import { useTranslation } from "react-i18next"
export interface IListPerPagesNumber {
	label: string
	value: string
}
interface IProps {
	count: number
	setActivePage: Dispatch<SetStateAction<number>>
	perPage?: string
	listPerPagesNumber?: IListPerPagesNumber[]
	onPerpageChange?: Dispatch<SetStateAction<string>>
	activePage: number;
	totalRecords?: number
}
export default function CustomPagination({
	onPerpageChange,
	listPerPagesNumber,
	perPage,
	setActivePage,
	count,
	activePage,
	totalRecords
}: IProps) {
	const { t } = useTranslation()

	const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
		setActivePage(value)
	}

	return (
		<CustomPaginationWrapper>
			<Pagination count={count} defaultPage={activePage} page={activePage} onChange={handleChange} />
			{listPerPagesNumber && listPerPagesNumber?.length > 0 && (
				<div className='perPageSelect'>
					<p>{t("RESULTPERPAGE")}</p>

					<Select
						sx={{
							width: "65px",
							height: "35px",
							borderRadius: "8px",
							color: "rgba(142, 152, 161, 1)",
						}}
						value={perPage}
						label=''
						onChange={(v: any) => {
							onPerpageChange && onPerpageChange(v.target.value)
							setActivePage(1)
						}}
					>
						{listPerPagesNumber.map((item,index:number) => {
							return (
								<MenuItem value={item.value} key={item.value} 
								disabled={
									totalRecords?
									 parseInt(listPerPagesNumber?.[index-1]?.value)>totalRecords
									:false}>
									{item.label}
								</MenuItem>
							)
						})}
					</Select>
				</div>
			)}
		</CustomPaginationWrapper>
	)
}