import {
  AccountingCreateExpenseURL,
  AccountingCreateIncomeURL,
  AccountingDeleteIncomeURL,
  AccountingDeletexpenseURL,
  AccountingGetExpenseURL,
  AccountingGetIncomeURL,
  AccountingUpdateExpenseURL,
  AccountingUpdateIncomeURL,
  DashboardURL,
  getFinancialReportsURL,
  getPropertyFinancialReportURL,
} from '../utils/constants';
import { useApi } from './useApi';
import { IDashboard, IExpense, IIncome, IReport } from '@interfaces';
import { useMutation, useQuery } from '@tanstack/react-query';

const useAccounting = () => {
  const { apiPrivate } = useApi();

  const createIncome = async (body: IIncome) => {
    const { data } = await apiPrivate.post(AccountingCreateIncomeURL, body);
    return data;
  };

  const deleteIncome = async (id: number) => {
    const { data } = await apiPrivate.delete(`${AccountingDeleteIncomeURL}/${id}`);
    return data;
  };

  const updateIncome = async (id: string, body: IIncome) => {
    const { data } = await apiPrivate.put(`${AccountingUpdateIncomeURL}/${id}`, body);
    return data;
  };

  const getAllIncomeByPropertyId = async (id: string) => {
    const {
      data: { result },
    } = await apiPrivate.get<{ result: IIncome[] }>(`${AccountingGetIncomeURL}/${id}`);
    return result;
  };

  const getPropertyReports = async (id: string) => {
    const {
      data: { result },
    } = await apiPrivate.get<{ result: IReport }>(`${getPropertyFinancialReportURL}/${id}`);
    return result;
  };
  const getFinancialReports = async () => {
    const {
      data: { result },
    } = await apiPrivate.get<{ result: IReport }>(getFinancialReportsURL);
    return result;
  };
  const getDashboard = async () => {
    const {
      data: { result },
    } = await apiPrivate.get<{ result: IDashboard[] }>(DashboardURL);
    return result;
  };
  const createExpense = async (body: IExpense) => {
    const { data } = await apiPrivate.post(AccountingCreateExpenseURL, body);
    return data;
  };
  const deleteExpense = async (id: number) => {
    const { data } = await apiPrivate.delete(`${AccountingDeletexpenseURL}/${id}`);
    return data;
  };

  const updateExpense = async (id: string, body: IExpense) => {
    const { data } = await apiPrivate.put(`${AccountingUpdateExpenseURL}/${id}`, body);
    return data;
  };

  const getAllExpensesByPropertyId = async (id: string) => {
    const {
      data: { result },
    } = await apiPrivate.get<{ result: IExpense[] }>(`${AccountingGetExpenseURL}/${id}`);
    return result;
  };

  const createIncomeMutate = ({ onSuccess, onError }: { onSuccess?: () => void; onError?: () => void }) =>
    useMutation({
      mutationFn: (body: IIncome) => createIncome(body),
      onSuccess: (data) => onSuccess && onSuccess(),
      onError: (error) => onError && onError(),
    });

  const deleteIncomeMutate = ({ onSuccess, onError }: { onSuccess?: () => void; onError?: () => void }) =>
    useMutation({
      mutationFn: (id: number) => deleteIncome(id),
      onSuccess: (data) => onSuccess && onSuccess(),
      onError: (error) => onError && onError(),
    });
  const updateIncomeMutate = ({ onSuccess, onError }: { onSuccess?: () => void; onError?: () => void }) =>
    useMutation({
      mutationFn: (body: IIncome) => updateIncome(Number(body.revenueId).toString(), body),
      onSuccess: (data) => onSuccess && onSuccess(),
      onError: (error) => onError && onError(),
    });

  const createExpenseMutate = ({ onSuccess, onError }: { onSuccess?: () => void; onError?: () => void }) =>
    useMutation({
      mutationFn: (body: IExpense) => createExpense(body),
      onSuccess: (data) => onSuccess && onSuccess(),
      onError: (error) => onError && onError(),
    });
  const deleteExpenseMutate = ({ onSuccess, onError }: { onSuccess?: () => void; onError?: () => void }) =>
    useMutation({
      mutationFn: (id: number) => deleteExpense(id),
      onSuccess: (data) => onSuccess && onSuccess(),
      onError: (error) => onError && onError(),
    });
  const updateExpenseMutate = ({ onSuccess, onError }: { onSuccess?: () => void; onError?: () => void }) =>
    useMutation({
      mutationFn: (body: IExpense) => updateExpense(Number(body.transactionId).toString(), body),
      onSuccess: (data) => onSuccess && onSuccess(),
      onError: (error) => onError && onError(),
    });
  const allIncomeQuery = (id: string) =>
    useQuery({
      enabled: Boolean(id),
      queryKey: [AccountingGetIncomeURL, id],
      queryFn: ({ queryKey }) => getAllIncomeByPropertyId(queryKey[1]),
    });
  const propertyReportsQuery = (id: string) =>
    useQuery({
      enabled: Boolean(id),
      queryKey: [getPropertyFinancialReportURL, id],
      queryFn: ({ queryKey }) => getPropertyReports(queryKey[1]),
    });

  const allReportsQuery = () =>
    useQuery({
      queryKey: [getFinancialReportsURL],
      queryFn: getFinancialReports,
    });
  const allDashboardQuery = () =>
    useQuery({
      queryKey: [DashboardURL],
      queryFn: getDashboard,
    });

  const allExpenseQuery = (id: string) =>
    useQuery({
      enabled: Boolean(id),
      queryKey: [AccountingGetExpenseURL, id],
      queryFn: ({ queryKey }) => getAllExpensesByPropertyId(queryKey[1]),
    });

  return {
    createIncomeMutate,
    createExpenseMutate,
    updateExpenseMutate,
    updateIncomeMutate,
    allExpenseQuery,
    allIncomeQuery,
    deleteIncomeMutate,
    deleteExpenseMutate,
    allDashboardQuery,
    propertyReportsQuery,
    allReportsQuery,
  };
};

export { useAccounting };
