/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/rules-of-hooks */
import { useApi } from './useApi';
import {
  ListManagementURL,
  GathrenLoginURL,
  GathrenVerifyOtpURL,
  GathrenListingURL,
  getAirbnbListingURL,
  AirbnbCreateGroupAndPropertyURL,
  AirbnbChannelConnectionUrl,
  createPropertyProfileURL,
  ListManagementCardNumbersURL,
  DeActivateListingURL,
  CheckBookingConnectionURL,
  GetBookingMappingDetailsURL,
  SaveBookingChannelURL,
  saveAirbnbToBookingApiURL,
  SyncBookingPriceURL
} from '../utils/constants';
import { useMutation, useQuery } from '@tanstack/react-query';
import { IProperty, IPropertyProfile } from '@interfaces';
 
const useProperty = () => {
  const { apiPrivate } = useApi();
  const deActivateLisiting = async (body: any) => {
    let response;

    response = await apiPrivate.put(`${DeActivateListingURL}${body?.propertyId}`, body);

    const { result } = response.data || {};

    return result;
  };
  

  const getAllManagementList = async (pageSize=0,pageNumber=0) => {
    let url = ListManagementURL
    
    if(pageSize!==0){
      url = url + `?pageSize=${pageSize}&pageNumber=${pageNumber}`
    }
    const {
      data,
    } = await apiPrivate.get<any>(url); 
    if(pageSize!==0){
      return data
    }
    return data.result; 
  };

  const getPropertyById = async (id: string) => {
    const {
      data: { result },
    } = await apiPrivate.get<{ result: IProperty }>(`${ListManagementURL}/${id}`);
    return result;
  };
  const getCardsStatistics = async () => {
    const {
      data: { result },
    } = await apiPrivate.get<{ result: any }>(ListManagementCardNumbersURL);
    return result;
  };

  const gathrenLogin = async (body: any) => {
    let response;

    response = await apiPrivate.post(`${GathrenLoginURL}/?phone=${body?.phone}`, body);

    const { result } = response.data || {};

    return result;
  };

  const airbnbCreateGroupAndProperty = async () => {
    let response;
    response = await apiPrivate.post(`${AirbnbCreateGroupAndPropertyURL}`);
    const { result } = response.data || {};

    if (result?.data?.attributes?.url) {
      window.open(result?.data?.attributes?.url, '_self');
    } else {
      throw Error('Link Not found');
    }
    return result;
  };

  const AirbnbChannelConnection = async () => {
    let response;

    response = await apiPrivate.get(`${AirbnbChannelConnectionUrl}/?status=${true}`);

    const { result } = response.data || {};
    return result;
  };

  const addProperty = async (body: any) => {
    let response;

    response = await apiPrivate.post(`${ListManagementURL}`, body);

    const { result } = response.data || {};

    return result;
  };


  const addPropertyProfile = async (body: IPropertyProfile) => {
    let response;

    response = await apiPrivate.post(createPropertyProfileURL, body);

    const { result } = response.data || {};

    return result;
  };

  const updateProperty = async (body: any) => {
    let response;

    response = await apiPrivate.put(`${ListManagementURL}/${body?.propertyId}`, body); 

    const { result } = response.data || {};

    return result;
  };
  const addNewChannel = async (body: any) => {
    let response;

    response = await apiPrivate.put(`${ListManagementURL}/AddNewChannelToProperty/${body?.propertyId}`, body);

    const { result } = response.data || {};

    return result;
  };
  const updateAutoAccess = async (body: any) => {
    let response;

    response = await apiPrivate.put(
      `${ListManagementURL}/UpdateAutoAccess/${body?.propertyId}?AutoAccess=${body?.AutoAccess}`,
      body,
    );

    const { result } = response.data || {};

    return result;
  };

  const deleteProperty = async (propertyId: any) => {
    let response;

    response = await apiPrivate.delete(`${ListManagementURL}/${propertyId}`);

    const { result } = response.data || {};

    return result;
  };

  const gathrenVerifyOtp = async (body: any) => {
    let response;
    response = await apiPrivate.post(`${GathrenVerifyOtpURL}/?otp=${body?.otp}&phone=${body?.phone} `, body);

    const { result } = response.data || {};

    window.localStorage['gathernToken'] = result?.user?.accessToken;
    return result;
  };

  const gathrenListing = async () => {
    const result = await apiPrivate.get(`${GathrenListingURL}`);
    return result.data?.result;
  };

  const getAirbnbListing = async (channelId: string) => {
    let chnx = channelId == 'null'? null :channelId
    const result = await apiPrivate.get(getAirbnbListingURL, { params: { channelId:chnx } });
    return result.data?.result;
  };

  // Booking
  const CheckBookingConection = async (hotelId: string) => {
    let response;

    response = await apiPrivate.get(`${CheckBookingConnectionURL}/${hotelId}`);

    const { result } = response.data || {};

    return result;
  };
  const GetBookingMappingDetailsApi = async (hotelId='') => {
    let response;

    response = await apiPrivate.post(`${GetBookingMappingDetailsURL}${hotelId}&channel=BookingCom`);

    const { result } = response.data || {};

    return result;
  };
  const saveBookingChannelApi = async (body:any) => {
    let response;

    response = await apiPrivate.post(SaveBookingChannelURL,body);

    const { result } = response.data || {};

    return result;
  };
  
  const saveAirbnbToBookingApi = async (body:any) => {
    let response;

    response = await apiPrivate.post(saveAirbnbToBookingApiURL+`?hotelId=${body?.hotelId}&propertyId=${body?.propertyId}&channel=BookingCom`);

    const { result } = response.data || {};

    return result;
  };
  const SyncBookingPriceAPi = async (body:any) => {
    let response;

    response = await apiPrivate.post(SyncBookingPriceURL+`?id=${body?.propertyId}&listingId=${body?.propertyId}&channel=Booking`);

    const { result } = response.data || {};

    return result;
  };

  const checkBookingConnectionQuery =   ({ onSuccess, onError }: { onSuccess?: () => void; onError?: () => void }) =>
  useMutation({
    mutationFn: (b:string) => CheckBookingConection(b),
    onSuccess: (data) => onSuccess && onSuccess(),
    onError: (error) => onError && onError(),
  });
  const airbnbChannelConnectionMutate = ({ onSuccess, onError }: { onSuccess?: () => void; onError?: () => void }) =>
    useMutation({
      mutationFn: () => AirbnbChannelConnection(),
      onSuccess: (data) => onSuccess && onSuccess(),
      onError: (error) => onError && onError(),
    });

  const gathrenVerifyOtpMutate = ({ onSuccess, onError }: { onSuccess?: () => void; onError?: () => void }) =>
    useMutation({
      mutationFn: (body: any) => gathrenVerifyOtp(body),
      onSuccess: (data) => onSuccess && onSuccess(),
      onError: (error) => onError && onError(),
    });

  const gathrenLoginMutate = ({ onSuccess, onError }: { onSuccess?: () => void; onError?: () => void }) =>
    useMutation({
      mutationFn: (body: any) => gathrenLogin(body),
      onSuccess: (data) => onSuccess && onSuccess(),
      onError: (error) => onError && onError(),
    });

  const airbnbCreateGroupAndPropertyMutate = ({
    onSuccess,
    onError,
  }: {
    onSuccess?: () => void;
    onError?: () => void;
  }) =>
    useMutation({
      mutationFn: () => airbnbCreateGroupAndProperty(),
      onSuccess: (data) => onSuccess && onSuccess(),
      onError: (error) => onError && onError(),
    });

  const addPropertyMutate = ({ onSuccess, onError }: { onSuccess?: () => void; onError?: (e: any) => void }) =>
    useMutation({
      mutationFn: (body: any) => addProperty(body),
      onSuccess: (data) => onSuccess && onSuccess(),
      onError: (error) => onError && onError(error),
    });
  const addPropertyProfileMutate = ({ onSuccess, onError }: { onSuccess?: () => void; onError?: (e: any) => void }) =>
    useMutation({
      mutationFn: (body: IPropertyProfile) => addPropertyProfile(body),
      onSuccess: (data) => onSuccess && onSuccess(),
      onError: (error) => onError && onError(error),
    });

  const updatePropertyMutate = ({ onSuccess, onError }: { onSuccess?: () => void; onError?: () => void }) =>
    useMutation({
      mutationFn: (body: any) => updateProperty(body),
      onSuccess: (data) => onSuccess && onSuccess(),
      onError: (error) => onError && onError(),
    });
  const connectToNewChannelMutate = ({ onSuccess, onError }: { onSuccess?: () => void; onError?: () => void }) =>
    useMutation({
      mutationFn: (body: any) => addNewChannel(body),
      onSuccess: (data) => onSuccess && onSuccess(),
      onError: (error) => onError && onError(),
    });
  const updateAutoAccessMutate = ({ onSuccess, onError }: { onSuccess?: () => void; onError?: () => void }) =>
    useMutation({
      mutationFn: (body: any) => updateAutoAccess(body),
      onSuccess: (data) => onSuccess && onSuccess(),
      onError: (error) => onError && onError(),
    });

  const deletePropertyMutate = ({ onSuccess, onError }: { onSuccess?: () => void; onError?: () => void }) =>
    useMutation({
      mutationFn: (body: any) => deleteProperty(body),
      onSuccess: (data) => onSuccess && onSuccess(),
      onError: (error) => onError && onError(),
    });

  const getManagementListQuery = (pageSize=0,pageNumber=0) => useQuery({ queryKey: [ListManagementURL,pageSize,pageNumber], queryFn:()=> getAllManagementList(pageSize,pageNumber) });
  const gathrenListingQuery = () => useQuery({ queryKey: [GathrenListingURL], queryFn: gathrenListing });
  const getAirbnbListingQuery = (channelId: string) =>
    useQuery({
      queryKey: [getAirbnbListingURL, channelId],
      queryFn: ({ queryKey }) => getAirbnbListing(queryKey[1]),
      enabled: channelId ? channelId != 'null' : false,
    });

  const getPropertyByIdQuery = (id: string) =>
    useQuery({
      enabled: Boolean(id),
      queryKey: [ListManagementURL, id],
      queryFn: ({ queryKey }) => getPropertyById(queryKey[1]),
    });
  const cardStatisticsQuery = () =>
    useQuery({
      queryKey: [ListManagementCardNumbersURL],
      queryFn: getCardsStatistics,
    });
    const deActivateMutation = ({ onSuccess, onError }: { onSuccess?: () => void; onError?: () => void }) =>
    useMutation({
      mutationFn: (body:any) => deActivateLisiting(body),
      onSuccess: (data) => onSuccess && onSuccess(),
      onError: (error) => onError && onError(),
    });
    const GetBookingMappingDetailsMutation = ({ onSuccess, onError }: { onSuccess?: () => void; onError?: () => void }) =>
    useMutation({
      mutationFn: (body: any) => GetBookingMappingDetailsApi(body),
      onSuccess: (data) => onSuccess && onSuccess(),
      onError: (error) => onError && onError(),
    });
    const saveBookingChannelMutation = ({ onSuccess, onError }: { onSuccess?: () => void; onError?: () => void }) =>
    useMutation({
      mutationFn: (body: any) => saveBookingChannelApi(body),
      onSuccess: (data) => onSuccess && onSuccess(),
      onError: (error) => onError && onError(),
    });

    const connectAirbnbPropertyToBookingMutation = ({ onSuccess, onError }: { onSuccess?: () => void; onError?: (e:any) => void }) =>
      useMutation({
        mutationFn: (body: any) => saveAirbnbToBookingApi(body),
        onSuccess: (data) => onSuccess && onSuccess(),
        onError: (error:any) => onError && onError(error),
      });
    
      const syncBookingPriceMutation = ({ onSuccess, onError }: { onSuccess?: () => void; onError?: () => void }) =>
      useMutation({
        mutationFn: (body: any) => SyncBookingPriceAPi(body),
        onSuccess: (data) => onSuccess && onSuccess(),
        onError: (error) => onError && onError(),
      });
    

  return {
    getManagementListQuery,
    getPropertyByIdQuery,
    gathrenLoginMutate,
    gathrenVerifyOtpMutate,
    gathrenListingQuery,
    addPropertyMutate,
    deletePropertyMutate,
    updatePropertyMutate,
    getAirbnbListingQuery,
    cardStatisticsQuery,
    airbnbCreateGroupAndPropertyMutate,
    airbnbChannelConnectionMutate,
    updateAutoAccessMutate,
    connectToNewChannelMutate,
    addPropertyProfileMutate,
    deActivateMutation,
    checkBookingConnectionQuery,
    GetBookingMappingDetailsMutation,
    saveBookingChannelMutation,
    connectAirbnbPropertyToBookingMutation,
    syncBookingPriceMutation
  };
};

export { useProperty }; 