import { Box, Typography } from "@mui/material";
import Market from '@assets/svg/Sucessconnections.svg?react';
import { useTranslation } from "react-i18next";


export const SuccessLinking = ({onClose=()=>{}}) => {
    const { t } = useTranslation();
    return <Box sx={{ mx: "20%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
        <Market style={{ width: "74px", height: "74px", marginBottom: "30px" }} />
        <Typography sx={{ fontWeight: 700, fontSize: "24px", lineHeight: "28.8px", color: "#331860" }}>
            {t('PROPERTIES_MANAGEMENT.CONNECTED_SUCCESS_PROPERTY')}
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: "14px", lineHeight: "16.8px", color: "#625783", mt: 1, mb: 2 }}>
            {t('PROPERTIES_MANAGEMENT.READY_TO_MANAGEMENT_BOOKING')}
        </Typography>
        <Box onClick={onClose} sx={{ width: "200px", height: "41px", borderRadius: "8px", backgroundColor: "#5B3FFF", display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer", mt: 2 }}>
            <Typography sx={{ color: "#FFFFFF", fontWeight: 700, fontSize: "14px", lineHeight: "16.8px" }}>
                {t('PROPERTIES_MANAGEMENT.MANAGE_PROPERTIES')}
            </Typography>
        </Box>
    </Box>
}