import { UseQueryOptions, useQuery } from "@tanstack/react-query"
import { APIS_KEYS } from "../../apis-keys"
import { IPropsGetLockDetails, IResponseGetLockDetails } from "./types"
import { GetLockDetailsUrl } from "../../../utils/constants"
import type { AxiosInstance, AxiosResponse } from "axios"
import { useApi } from "../../useApi"

async function getLockDetails(
	apiPrivate: AxiosInstance,
	{ reservationId }: IPropsGetLockDetails
) {
	try {
		const response: AxiosResponse<IResponseGetLockDetails> =
			await apiPrivate.get(`${GetLockDetailsUrl}/${reservationId}`)
		return response.data
	} catch (error: any) {
		console.log(error)
		throw new Error("something went wrong")
	}
}

export function useGetLockDetails(
	data: IPropsGetLockDetails,
	options?:
		| Omit<
				UseQueryOptions<
					IResponseGetLockDetails,
					unknown,
					IResponseGetLockDetails
				>,
				"queryKey" | "queryFn"
		  >
		| undefined
) {
	const { apiPrivate } = useApi()
	return useQuery({
		queryKey: [APIS_KEYS.GETLOCKDETAILS, ...Object.values(data)],
		queryFn: () => getLockDetails(apiPrivate, data),
		...options,
	})
}
