import { FC } from 'react';
import { Box, Button } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { useTranslation } from 'react-i18next';

const LinkError: FC<{ channel: string; reset: (close: boolean) => void }> = ({ channel, reset }) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        flex: 1,
        pt: 4,
        pb: 2,
        gap: 2,
        display: 'flex',
        flexDirection: 'column',
        svg: {
          width: '80px',
          height: '80px',
          color: '#D70015',
        },
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        {<CancelIcon />}
        {t('ADD_PROPERTY.LINK_FAIL', { channel })}
      </Box>

      <Box sx={{ textAlign: 'center' }}>
        <Button size={'small'} variant={'contained'} onClick={() => reset(false)}>
          {t('ADD_PROPERTY.TRY_AGAIN')}
        </Button>
      </Box>
    </Box>
  );
};

export default LinkError;
