import { Grid } from "@mui/material"
import { PorpertesCardWrapper } from "./styles"
import { useFormContext } from "react-hook-form"
import { IForm } from "."
import { useCategoryMangment } from "@services"
import { useMemo } from "react"
interface IProps {
	searchInputWatch: string | undefined
}
export default function PorpertesCard({ searchInputWatch }: IProps) {
	const { formState, register } = useFormContext<IForm>()
	const { GetPropertiesDoNotHaveCategoryQuery } = useCategoryMangment()
	const { data: GetPropertiesDoNotHaveCategory } =
		GetPropertiesDoNotHaveCategoryQuery({
			pageNumber: "1",
			pageSize: "500",
			search: searchInputWatch,
		})

	const dataList = useMemo(() => {
		const data = GetPropertiesDoNotHaveCategory?.data?.result.map((item) => {
			return {
				image: item.propertyImage,
				name: item.propertyName,
				location: item.propertyLocation,
				id: item.propertyId,
			}
		})
		return data ?? []
	}, [GetPropertiesDoNotHaveCategory])
	return (
		<PorpertesCardWrapper>
			<Grid container>
				{dataList?.length > 0 &&
					dataList?.map((item) => {
						return (
							<Grid item xs={12} md={4} key={item.id}>
								<div className='containerCard'>
									<div className='wrapper'>
										<div className='checkbox'>
											<label className='checkbox-wrapper'>
												<input
													type='checkbox'
													className='checkbox-input'
													value={item.id}
													{...register("propertyId")}
												/>
												<span className='checkbox-tile'>
													<span className='checkbox-image'>
														{item.image && <img src={item.image} alt='test' />}
													</span>
													<span className='checkbox-label'>
														<span>{item.name}</span>
														<span>{item.location}</span>
													</span>
												</span>
											</label>
										</div>
									</div>
								</div>
							</Grid>
						)
					})}
			</Grid>
		</PorpertesCardWrapper>
	)
}
