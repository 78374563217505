/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useContext } from 'react';
import { Box, Modal,Slide,IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {userSettings } from '@services';
import { ToastMessageContext } from '@context';
import CloseIcon from '@mui/icons-material/Close';
import Join from '../Join'

type IModalSupscripe = {
    open:boolean;
    handleClose:()=>void;
    OnSubscriptionSelected:(pack:number)=>void;
}

const SupscriptionModal = ({ handleClose, open, OnSubscriptionSelected }:IModalSupscripe) => {
    const {
        t,
    } = useTranslation();

    const { showSuccess, showError } = useContext(ToastMessageContext);
    const { updateUserSettingMutate } = userSettings();  

    const { mutate: updateUser } = updateUserSettingMutate({
        onSuccess: () => {
            closeModal();
            showSuccess(t('SEETING.CHANGES_SAVED'));  
        },
        onError: () => {
            showError(t('ERRORS.SOMETHING_WENT_WRONG'));
        },
    });

    const closeModal = () => {
        handleClose();
    };

    return (
        <>
        <Modal
                open={open}
                onClose={closeModal}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
            >
                      <Slide direction="up" in={open} mountOnEnter unmountOnExit>

                <Box sx={{ bgcolor: true ? '#1E1655' : '#F5F3FFCC',marginTop:0,height:'100%',width:'94%',borderTopRightRadius:30,paddingX:1,borderTopLeftRadius:30,marginX:'auto',position:'relative',display:'flex',justifyContent:'center',alignItems:'center'}}>
                    <Box onClick={handleClose} sx={{cursor:'pointer',position:'absolute',left:10,top:10,zIndex:9999,fontSize:30,color:'white'}} >
                    <IconButton
                                onClick={() => {
                                    handleClose()
                                }}
                                edge='end'
                              >
                                <CloseIcon sx={{color:'white'}} />
                              </IconButton>
                    </Box>
                      <Join IsLanding={false} OPlanSelected={n=>OnSubscriptionSelected(Number(n))} />  

                </Box>
                </Slide>

            </Modal>
         
        </>
    );
};

export default SupscriptionModal;
