import { Box } from '@mui/material';
import { footerPadding, MenuItems, socialMediaItems } from '@utils';
import { FC } from 'react';
import { MenuLinkI } from '@interfaces';
import { useTranslation } from 'react-i18next';
import MsoolWhite from '@assets/svg/msoolWhite.svg?react';
const PublicFooter: FC<{
  handleClick: (l: MenuLinkI) => void;
}> = ({ handleClick }) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        ...footerPadding,
        width: '100%',
        background: '#0E0A29',
        color: 'white',
      }}
    >
      <Box sx={{ gap: 3, display: 'flex', flexWrap: 'wrap' }}>
        {MenuItems.map((item) => {
          return (
            <div key={item.name} className={'text-sm font-medium cursor-pointer'}>
              <Box onClick={(e) => handleClick(item)}>{t(item.name)}</Box>
            </div>
          );
        })}
      </Box>
      <Box sx={{ gap: 2, display: 'flex', flexWrap: 'wrap', mt: 4 }}>
        {socialMediaItems.map((s) => {
          return (
            <Box
              key={s.link}
              component={'a'}
              href={s.link}
              target={'_blank'}
              sx={{
                width: '38px',
                height: '24px',
                borderRadius: '20px',
                backgroundColor: '#5B3FFF',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                svg: {
                  width: '18px',
                  height: '18px',
                },
              }}
            >
              {s.icon}
            </Box>
          );
        })}
      </Box>
      <Box
        sx={{
          gap: 2,
          display: 'flex',
          alignItems: 'flex-end',
          flexDirection: 'column',
          mt: 4,
          svg: {
            width: '143px',
            height: '56px',
          },
        }}
      >
        <MsoolWhite />
        <div dir={'ltr'}>ⓒ2023 Masool. All rights reserved.</div>
      </Box>
    </Box>
  );
};

export default PublicFooter;
