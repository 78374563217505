import { atom } from "recoil"
import { ATOMS_KEYS } from "../atom-keys"
interface IPramsLockId {
	lockId: string
	passcode: string
}
export const PramsLockId = atom<IPramsLockId | undefined>({
	key: ATOMS_KEYS.PRAMSLOCKID,
	default: undefined,
})
