import { useDM } from "@services"
import PropertyInformation from "./propertyInformation"
import { SectionUserDetailsWrapper } from "./styles"
import UserInformation from "./userInformation"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { DMData, DMLiveFeedEventId } from "@store/index"
import { Dispatch, SetStateAction, useEffect } from "react"
import { DMDataReservationRequest } from "@store/DM/DMDataReservationRequest"
import Overlay from '../SectionChats/Overlay'


import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { Box, Typography } from "@mui/material"
interface IProps {
	alterationId?: string,
	isAltration: boolean,
	setIsPopupOpen: Dispatch<SetStateAction<boolean>>
	isOverlayOpen?: boolean;
	onCloseOverlay?: () => void
}

export const DmStatusMapper = (status = '') => {
	let reuslt = {
		en: '',
		ar: '',
		background: '',
		textColor: '',
		pointBg: '#FFE9A9'
	}
	if (status == 'new') {
		reuslt = {
			en: 'confirmed',
			ar: 'مؤكد',
			background: '#22C55E',
			textColor: 'white',
			pointBg: '#16AE26'

		}
	}
	if (status == 'cancelled') {
		reuslt = {
			en: 'cancelled',
			ar: 'ملغي',
			background: '#FFE0E3',
			textColor: '#D70015',
			pointBg: '#D70015'

		}
	}
	if (status == 'reservation_request') {
		reuslt = {
			en: 'pending',
			ar: 'معلق',
			background: '#FFF7E1',
			textColor: '#435363',
			pointBg: '#FFE9A9'

		}
	}
	if (status == 'modified') {
		reuslt = {
			en: 'modified',
			ar: 'معدل',
			background: '#FFF7E1',
			textColor: '#435363',
			pointBg: '#FFE9A9'

		}
	}

	return reuslt

}

export default function SectionUserDetails({ isOverlayOpen, onCloseOverlay = () => { }, setIsPopupOpen, isAltration, alterationId = '' }: IProps) {
	const setDMData = useSetRecoilState(DMData)
	const { GetPropertyDetailsQuery } = useDM()
	const DMLiveFeedEventIdValue = useRecoilValue(DMLiveFeedEventId)
	const DMDataReservationRequestValue = useRecoilValue(DMDataReservationRequest)

	const DMDataReservationRequestState = useSetRecoilState(
		DMDataReservationRequest
	)

	const { data, refetch, isRefetching, isLoading } = GetPropertyDetailsQuery(
		{
			eventId: isAltration ? alterationId : DMLiveFeedEventIdValue?.live_feed_event_id!,
		},
		{ enabled: isAltration ? alterationId !== undefined : DMLiveFeedEventIdValue?.live_feed_event_id !== undefined }
	)
	const hideAction = isRefetching || isLoading

	useEffect(() => {
		if (data?.data?.result) {
			setDMData({
				guest_name: `${data?.data?.result?.reservation.guest_first_name} ${data?.data?.result?.reservation.guest_last_name}`,
				checkin_date: dayjs(data?.data?.result.reservation.start_date).format(
					"YYYY-MM-DD"
				),
				currency: data?.data?.result?.reservation.host_currency,
				listing_name: data?.data?.result?.propertyName,
				property_id: data?.data?.result?.listingId,
				room_type_id: "",
				resolved: data?.data?.result?.resolved
			})
			DMDataReservationRequestState({

				channals: data.data.result.ota,
				customer: {
					name: data?.data?.result.customer.name,
					phone: data?.data?.result.customer.phone,
					surname: data?.data?.result.customer.phone,
				},
				reservation: {
					start_date: data?.data?.result.reservation.check_in_datetime,
					end_date: data?.data?.result.reservation.check_out_datetime,
					nightPrice:
						data?.data?.result.reservation.expected_payout_amount_accurate,
					totalPrice:
						data?.data?.result.reservation.listing_base_price_accurate,
					property_id: data?.data?.result?.reservationID,
					propertyName: data?.data?.result?.propertyName,
					time_zone: data?.data?.result?.reservation.time_zone,
					status_type: data?.data?.result?.status,
					listing_cancellation_payout_accurate: data?.data?.result?.reservation.listing_cancellation_payout_accurate,
					listing_cancellation_host_fee_accurate: data?.data?.result?.reservation?.listing_cancellation_host_fee_accurate,
					expected_payout_amount_before_taxes_accurate: data?.data?.result?.reservation?.expected_payout_amount_before_taxes_accurate,
					pass_through_tax_amount_paid_to_host_accurate: data?.data?.result?.reservation?.pass_through_tax_amount_paid_to_host_accurate,
				},
			})
		} else {
			DMDataReservationRequestState(undefined)
		}
	}, [data, DMLiveFeedEventIdValue])
	const { t,
		i18n: { language },

	} = useTranslation();


	return (
		<SectionUserDetailsWrapper>

			<Box sx={{
				display: 'flex', justifyContent: 'space-between',

				padding: ' 16px',
				borderBottom: '1px solid #0000001a',
				alignItems: 'center',

			}} >
				<p>{t('DM.Details')}</p>
				{DMDataReservationRequestValue?.reservation.status_type ? <Box sx={{
					display: 'flex',
					alignItems: 'center',
					backgroundColor: DmStatusMapper(DMDataReservationRequestValue?.reservation.status_type).background,
					 px: '10px',
					 height:'30px',

					   borderRadius: '50px'
				}} >

					<Box sx={{ 
						borderRadius: '5px',
						width: '9px',
						height: '9px',
						mx: 0.5,
						mt: -0.3,
						backgroundColor: DmStatusMapper(DMDataReservationRequestValue?.reservation.status_type).pointBg
					}}  >

					</Box>
					<Typography sx={{ color: DmStatusMapper(DMDataReservationRequestValue?.reservation.status_type).textColor }} >
						{DmStatusMapper(DMDataReservationRequestValue?.reservation.status_type)[language == "en" ? "en" : "ar"]}
					</Typography>
				</Box> : null}

				{/* <div className='containerStatus'> */}
				{/* <p className={"columnsContent pendding "}>
							<h6>{ DMDataReservationRequestValue?.reservation.status_type}</h6>
						
						</p>
					{isAltration ? <p className={"columnsContent pendding "}>
						<span></span>
						  {t('DM.UPDATED_RESERVATION')}
					</p> : DMDataReservationRequestValue?.reservation.status_type ===
					"pending" && (
						<p className={"columnsContent pendding "}>
							<span></span>
							{t('DM.PendingBookings')} 
						</p>
					)}
				</div> */}
			</Box>
			<div className='containerInfoData'>
				{DMLiveFeedEventIdValue?.live_feed_event_id && (
					<PropertyInformation
						isOverlayOpen={isOverlayOpen}
						onCloseOverlay={onCloseOverlay}
						onRefresh={() => refetch()} resolved={hideAction ? true : data?.data?.result?.resolved} alterationId={alterationId} isAltration={isAltration} setIsPopupOpen={setIsPopupOpen} />
				)}
			</div>
		</SectionUserDetailsWrapper>
	)
}
