import { FC, useContext, useState } from 'react';
import { Box, Button, IconButton, Modal, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';

import { IModalProps } from '@interfaces';
import { useLock } from '@services';
import { ToastMessageContext } from '@context';

const UpdateLockModal: FC<IModalProps> = ({ handleClose, open, data }) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 495,
    bgcolor: 'background.paper',
    color: '#261B6A',
    boxShadow: 24,
    p: 3,
    borderRadius: 5,
  };
  const styleShowAccessID = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 577,
    bgcolor: 'background.paper',
    color: '#261B6A',
    boxShadow: 24,
    p: 3,
    borderRadius: 5,
  };
  const styleShowTermsConditions = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1161,
    bgcolor: 'background.paper',
    color: '#261B6A',
    boxShadow: 24,
    p: 3,
    borderRadius: 5,
  };

  const {
    i18n: { language },
    t,
  } = useTranslation();
  const align = language === 'ar' ? 'right' : 'left';

  const [showAccessID, setShowAccessID] = useState(false);
  const [showTermsConditions, setShowTermsConditions] = useState(false);
  const { showSuccess, showError } = useContext(ToastMessageContext);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<any>({
    mode: 'onChange',
  });

  const { updateLockIdMutate } = useLock();

  const { mutate: updateLockId } = updateLockIdMutate({
    onSuccess: () => {
      showSuccess(t('LOCKS.UPDATE_SUCCESS'));
      closeModal();
    },
    onError: () => {
      showError(t('NEW_LOCKS.UPDATE_FAIL'));
    },
  });

  const onSubmit = (body: any) => {
    const payload: any = {
      id: data?.lockInfo?.lockIdForOperator,
      ...body,
    };
    updateLockId(payload);
  };

  const closeModal = () => {
    handleClose();
    reset({});
  };

  return (
    <>
      <Modal
        open={open}
        onClose={closeModal}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        {showAccessID ? (
          <Box sx={styleShowAccessID}>
            <div className='flex justify-between items-center mb-3'>
              <Typography id='modal-modal-title' className='text-[#27272A]'>
                {t('NEW_LOCKS.TITLE_ACCESS_ID')}
              </Typography>
            </div>
            <div className='mt-6 text-[#A1A1AA]'>
              <div className=''>{'1. قم بزيارة موقع الخدمة أو المنصة التي ترغب في الحصول على رمز ID منها.'}</div>
              <div className='pt-2'>
                {
                  '2. ابحث عن صفحة "الحساب" أو "الإعدادات" أو أي صفحة مشابهة تعرض المعلومات الشخصية الخاصة بك وإعدادات الحساب.'
                }
              </div>
              <div className='pt-2'>
                {
                  '3. في هذه الصفحة، قد تجد عنصرًا يدعى "معرّف الحساب" أو "رمز ID" أو ما شابه ذلك. قد يتم عرض الرمز مباشرة أو قد تكون هناك خيارات لإنشاء رمز ID جديد أو إعادة تعيين الرمز الحالي.'
                }
              </div>
              <div className='pt-2'>{'4. انقر على الخيار المناسب للحصول على الرمز ID الخاص بك.'}</div>
            </div>
            <div className='mt-6 flex items-center justify-center'>
              <Button
                className=''
                variant={'contained'}
                size={'large'}
                onClick={() => {
                  setShowAccessID(false);
                }}
              >
                {t('NEW_LOCKS.UNDERSTOOD')}
              </Button>
            </div>
          </Box>
        ) : showTermsConditions ? (
          <Box sx={styleShowTermsConditions}>
            <div className='flex justify-between items-center mb-3'>
              <Typography id='modal-modal-title' className='text-[#27272A]'>
                {t('NEW_LOCKS.TITLE_TERMS_CONDITIONS')}
              </Typography>
            </div>
            <div className='mt-6 text-[#A1A1AA]'>
              <div className=''>
                {
                  '١- الاستخدام الشخصي: يجب أن يكون استخدام الموقع للاستخدام الشخصي فقط، ولا يجوز استخدامه لأي غرض تجاري أو غير قانوني.'
                }
              </div>
              <div className='pt-2'>
                {
                  '٢- المحتوى: يجب أن يلتزم المستخدم بعدم نشر أو توزيع أو تحميل أو إرسال أي محتوى غير قانوني أو مسيء أو مهين أو ينتهك حقوق الملكية الفكرية للآخرين.'
                }
              </div>
              <div className='pt-2'>
                {
                  '٣- السلوك اللائق: يجب على المستخدم أن يلتزم بالسلوك اللائق وأخلاقيات استخدام الموقع، وعدم القيام بأي أعمال تشويشية أو ضارة تؤثر على سير عمل الموقع أو تعرقله.'
                }
              </div>
              <div className='pt-2'>
                {
                  '٤- الحماية والأمان: يجب على المستخدم أن يتحمل المسؤولية الكاملة عن حفظ معلومات حسابه وكلمة المرور الخاصة به، وعدم مشاركتها مع الآخرين. كما يجب على المستخدم .'
                }
              </div>
              <div className='pt-2'>{' ٥- الإبلاغ عن أي استخدام غير مصرح به لحسابه'}</div>
              <div className='pt-2'>
                {
                  '٦- روابط الجهات الخارجية: قد يحتوي الموقع على روابط لمواقع أو خدمات تابعة لجهات خارجية. يجب على المستخدم أن يكون على علم بأنه عند النقر على تلك الروابط فإنه يغادر الموقع، وتخضع الشروط والأحكام الخاصة بتلك الجهات الخارجية لتقدير المستخدم ومسؤوليته الخاصة.'
                }
              </div>
              <div className='pt-2'>
                {
                  '٧- التعديلات والتحديثات: يجوز لصاحب الموقع تعديل شروط وأحكام الاستخدام في أي وقت، وينبغي على المستخدم مراجعة هذه الشروط بشكل دوري للبقاء على اطلاع بأي تغييرات.'
                }
              </div>
              <div className='pt-2'>
                {
                  '٨- إنهاء الخدمة: يحق لصاحب الموقع إنهاء أو تعليق الخدمة في أي وقت دون إشعار مسبق، ولا يتحمل صاحب الموقع أي مسؤولية تجاه المستخدمين أو أي جهة ثالثة نتيجة لإنهاء الخدمة.'
                }
              </div>
            </div>
            <div className='mt-6 flex items-center justify-start'>
              <Button
                className=''
                variant={'contained'}
                size={'large'}
                onClick={() => {
                  setShowTermsConditions(false);
                }}
              >
                {t('NEW_LOCKS.UNDERSTOOD')}
              </Button>
            </div>
          </Box>
        ) : (
          <Box sx={style}>
            <div className='flex justify-between items-center mb-3'>
              <Typography id='modal-modal-title' className='text-[#27272A]'>
                {t('UPDATE_LOCKS.TITLE')}
              </Typography>

              <IconButton aria-label='close' onClick={closeModal} size='small'>
                <CloseIcon className='text-[#27272A] ' />
              </IconButton>
            </div>
            <form className='mt-10' onSubmit={handleSubmit(onSubmit)}>
              <>
                <div className=' text-[#5B3FFF]'>{data?.propertyname}</div>
                <Controller
                  name='updatedLockId'
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <>
                      <div className='text-[#261B6A] font-normal mt-4'>
                        <div>{t('NEW_LOCKS.LOCK_ID')}</div>
                        <div className='mt-4'>
                          <TextField
                            className='w-full rounded-3xl'
                            InputProps={{ sx: { borderRadius: 24 } }}
                            style={{ borderRadius: '24px !important', color: '#9CA3AF', borderColor: '#9CA3AF' }}
                            placeholder={`${t('NEW_LOCKS.LOCK_ID')}`}
                            variant='outlined'
                            onChange={onChange}
                          />
                        </div>
                      </div>
                    </>
                  )}
                />
                <div
                  className='mt-4 text-[#3F97FF] cursor-pointer'
                  onClick={() => {
                    setShowAccessID(true);
                  }}
                >
                  {t('NEW_LOCKS.ACCESS_ID')}
                </div>
                <div className='mt-6 mx-4'>
                  <Button className='w-full' variant={'contained'} size={'large'} type='submit'>
                    {t('NEW_LOCKS.LINK')}
                  </Button>
                </div>
                <div className='flex items-center gap-2 text-sm justify-center mt-2'>
                  <div className='text-[#A1A1AA]'>{t('NEW_LOCKS.CLICKING_LINK')}</div>
                  <div
                    className='text-[#3F97FF] cursor-pointer'
                    onClick={() => {
                      setShowTermsConditions(true);
                    }}
                  >
                    {t('NEW_LOCKS.RESPONSIBLE_TERMS_CONDITIONS')}
                  </div>
                </div>
              </>
            </form>
          </Box>
        )}
      </Modal>
    </>
  );
};

export default UpdateLockModal;
