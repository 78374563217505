import { Helmet } from 'react-helmet-async';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { FC, ReactNode } from 'react';
import { MsoolIcon } from '../../utils/image-consts';
import { IGuardProps } from '@interfaces';

/**
 * used for public pages to add helmet
 */
const PublicGuard: FC<IGuardProps> = ({ children, titleKey, descriptionKey }) => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>{t(titleKey)}</title>
        <meta charSet='utf-8' />
      </Helmet>
      <ErrorBoundary
        fallback={
          <div
            className={'flex-1  bg-white m-8 p-4 flex items-center justify-center rounded-xl font-bold text-primary'}
          >
            {t('ERRORS.SOMETHING_WENT_WRONG')}
          </div>
        }
      >
        {children}
      </ErrorBoundary>
    </>
  );
};
export default PublicGuard;
