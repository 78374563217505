import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import { BackgroundCompressed } from '../../utils/image-consts';
import LandingHeroSvg from '@assets/svg/landing-hero.svg?react';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

const LandingHero: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const lgUp = useMediaQuery(theme.breakpoints.up('md'));

  const registerScroll = () => {
    const el = document.getElementById('register');
    if (el) {
      el.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <Box sx={{ px: { xs: 0, md: 4, lg: 8 }, py: { xs: 4, md: 8 } }}>
      <Box
        sx={{
          borderRadius: 8,
          overflow: 'hidden',
          background: 'black',
          display: 'grid',
          gridTemplateColumns: { xs: 'repeat(1,1fr)', md: 'repeat(2,1fr)' },
        }}
      >
        <Box
          sx={{
            background: '#F9F7FF',
            wordBreak: 'break-word',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            p: 4,
            pt: { xs: 4, md: 8 },
            paddingInlineEnd: { xs: 4, md: 8 },
          }}
        >
          <Typography
            color={'primary'}
            sx={{ fontSize: { xs: '24px', sm: '32px', lg: '40px' } }}
            fontWeight={'600'}
            mb={1}
          >
            {t('HERO.TITLE')}
          </Typography>
          <Typography mb={6} color={'#8E98A1'}>
            {t('HERO.DESCRIPTION')}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              width: '100%',
              px: { xs: 0, xl: 4 },
              '.MuiButton-root': { borderWidth: '2px', fontWeight: 'bold', flex: 1 },
            }}
          >
            <Button size={lgUp ? 'large' : 'medium'} variant={'contained'} onClick={() =>false ? window.open("https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ0jfgD0WEizdTsurS3cNdfPFIuzm-3wqSogMkuzC9y-Dw99A8a7rYZF5OmwL8jdgfPeI2y1JydD", "_blank") : navigate('/register')}>
              {t('BOOK_DEMO')}
            </Button>
            <Button
              onClick={registerScroll}
              size={lgUp ? 'large' : 'medium'}
              sx={{ borderWidth: '2px' }}
              variant={'outlined'}
            >
              {t('HERO.SUBSCRIBE_NOW')}
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundImage: `url(${BackgroundCompressed})`,
            svg: {
              marginInlineStart: { xs: 0, md: -8 },
              width: { xs: '100%', md: 'calc(100% + 44px)' },
              height: 'auto',
            },
          }}
        >
          <LandingHeroSvg />
        </Box>
      </Box>
    </Box>
  );
};
export default LandingHero;
