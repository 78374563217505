
import { useApi } from './useApi';
import {
    getControlCenterUrl,
    updateControlCenterUrl,
    OnboardingGuideURL
} from '../utils/constants';
import { useMutation, useQuery } from '@tanstack/react-query';

const usecontrolCenter = () => {
    const { apiPrivate } = useApi();
    const updateAutomation = async (body: any) => {
        let result = await apiPrivate.post(`${updateControlCenterUrl}`, body);
        return result.data?.result;

    };
    const getOnboardingGuide = async (body: any) => {
        let result = await apiPrivate.get(`${OnboardingGuideURL}`);
        return result.data?.result;

    };
    const getControlCenter = async (body: any) => {
        let result = await apiPrivate.get(`${getControlCenterUrl}`);
        return result.data?.result;

    };
    const getControlCenterQuery = () => useQuery({ queryKey: [getControlCenterUrl], queryFn: getControlCenter });
    const getOnboardingGuideQuery = () => useQuery({ queryKey: [OnboardingGuideURL], queryFn: getOnboardingGuide });

    const updateAutomationMutation = ({ onSuccess, onError }: { onSuccess?: () => void; onError?: (e:any) => void }) =>
        useMutation({
            mutationFn: (body: any) => updateAutomation(body),
            onSuccess: (data) => {
                onSuccess && onSuccess()
            },
            onError: (error) => onError && onError(error),
        });
    return {
        updateAutomationMutation,
        getControlCenterQuery,
        getOnboardingGuideQuery 
    };
};

export { usecontrolCenter };
