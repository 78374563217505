/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useContext, useState } from 'react';
import { Box, Button, Chip, IconButton, MenuItem, Modal, Select, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';

import { IConnectNewLockModal } from '@interfaces';
import { useLock, useProperty } from '@services';
import { ToastMessageContext } from '@context';
import QRCode from "react-qr-code";
import useBreakPoinst from '@hooks/useBreakPoinst';
import TuyaBtan from '@assets/svg/TuyaBtan.svg?react'; 


const ConnectNewLockModal: FC<IConnectNewLockModal> = ({ handleClose, open }) => {
  const {isPhone}=useBreakPoinst()
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width:isPhone?'95%': 495,
    bgcolor: 'background.paper',
    color: '#261B6A',
    boxShadow: 24,
    p: 3,
    borderRadius: 5,
  };
  const styleShowAccessID = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 577,
    bgcolor: 'background.paper',
    color: '#261B6A',
    boxShadow: 24,
    p: 3,
    borderRadius: 5,
  };
  const styleShowTermsConditions = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1161,
    bgcolor: 'background.paper',
    color: '#261B6A',
    boxShadow: 24,
    p: 3,
    borderRadius: 5,
  };

  const {
    i18n: { language },
    t,
  } = useTranslation();
  const align = language === 'ar' ? 'right' : 'left';

  const [step, setStep] = useState(0);
  const [error, setError] = useState(false);
  const [operator, setOperator] = useState('');
  const [showAccessID, setShowAccessID] = useState(false);
  const [showTermsConditions, setShowTermsConditions] = useState(false);
  const [showAddOperator, setShowAddOperator] = useState(false);
  const { showSuccess, showError } = useContext(ToastMessageContext);
  const [showQr, setShowQr] = useState(false)

  const { getManagementListQuery } = useProperty();
  const { getAllOperatorListQuery, addLockMutate, requestNewOperatorMutate,geQrCodeQuery } = useLock();

  const { data: listOfOperator } = getAllOperatorListQuery();
  const {isLoading:isGettingQrCode,data:qrData}=geQrCodeQuery()

  const { data: listOfProperties, isLoading: listPropertiesLoading } = getManagementListQuery(); 

  const { mutate: addLock } = addLockMutate({
    onSuccess: () => {
      showSuccess(t('NEW_LOCKS.CREATED_SUCCESS'));
      closeModal();
    },
    onError: () => {
      showError(t('ERRORS.SOMETHING_WENT_WRONG'));
    },
  });

  const { mutate: requestNewOperator } = requestNewOperatorMutate({
    onSuccess: () => {
      showSuccess(t('NEW_LOCKS.REQUEST_NEW_OPERATOR_SUCCESS'));
      closeModal();
    },
    onError: () => {
      showError(t('ERRORS.SOMETHING_WENT_WRONG'));
    },
  });

  const { control, handleSubmit, watch, getValues, reset } = useForm<any>({
    mode: 'onChange',
  });

  const handleChange = (event: any) => {
    setOperator(event.target.value);
  };

  const watchOperator = watch('keyOperator');

  const onSubmit = (body: any) => {
    const payload: any = {
      ...body,
      lockId: parseInt(body?.lockId),
    };
    body.username && addLock(payload);
  };

  const handleStep = () => {
    if (getValues('propertyId') && getValues('keyOperator')) {
      if ( !showQr && step == 0 && getValues('keyOperator') == 2) {
        return setShowQr(true) 
      }
      setShowQr(false) 
      setStep(step === 0 ? 1 : 0);
      setError(false);
    } else {
      setError(true);
    }
  };

  const closeModal = () => {
    setError(false);
    handleClose();
    setStep(0);
    reset({});
    setShowAddOperator(false);
    setShowQr(false)
  };

  const onSubmitRequestNewOperator = () => {
    const payload: any = {
      operatorName: operator,
    };
    requestNewOperator(payload);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={closeModal}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        {showAccessID ? (
          <Box sx={styleShowAccessID}>
            <div className='flex justify-between items-center mb-3'>
              <Typography id='modal-modal-title' className='text-[#27272A]'>
                {t('NEW_LOCKS.TITLE_ACCESS_ID')}
              </Typography>
            </div>
            <div className='mt-6 text-[#A1A1AA]'>
              <div className=''>{'1. قم بزيارة موقع الخدمة أو المنصة التي ترغب في الحصول على رمز ID منها.'}</div>
              <div className='pt-2'>
                {
                  '2. ابحث عن صفحة "الحساب" أو "الإعدادات" أو أي صفحة مشابهة تعرض المعلومات الشخصية الخاصة بك وإعدادات الحساب.'
                }
              </div>
              <div className='pt-2'>
                {
                  '3. في هذه الصفحة، قد تجد عنصرًا يدعى "معرّف الحساب" أو "رمز ID" أو ما شابه ذلك. قد يتم عرض الرمز مباشرة أو قد تكون هناك خيارات لإنشاء رمز ID جديد أو إعادة تعيين الرمز الحالي.'
                }
              </div>
              <div className='pt-2'>{'4. انقر على الخيار المناسب للحصول على الرمز ID الخاص بك.'}</div>
            </div>
            <div className='mt-6 flex items-center justify-center'>
              <Button
                className=''
                variant={'contained'}
                size={'large'}
                onClick={() => {
                  setShowAccessID(false);
                }}
              >
                {t('NEW_LOCKS.UNDERSTOOD')}
              </Button>
            </div>
          </Box>
        ) : showTermsConditions ? (
          <Box sx={styleShowTermsConditions}>
            <div className='flex justify-between items-center mb-3'>
              <Typography id='modal-modal-title' className='text-[#27272A]'>
                {t('NEW_LOCKS.TITLE_TERMS_CONDITIONS')}
              </Typography>
            </div>
            <div className='mt-6 text-[#A1A1AA]'>
              <div className=''>
                {
                  '١- الاستخدام الشخصي: يجب أن يكون استخدام الموقع للاستخدام الشخصي فقط، ولا يجوز استخدامه لأي غرض تجاري أو غير قانوني.'
                }
              </div>
              <div className='pt-2'>
                {
                  '٢- المحتوى: يجب أن يلتزم المستخدم بعدم نشر أو توزيع أو تحميل أو إرسال أي محتوى غير قانوني أو مسيء أو مهين أو ينتهك حقوق الملكية الفكرية للآخرين.'
                }
              </div>
              <div className='pt-2'>
                {
                  '٣- السلوك اللائق: يجب على المستخدم أن يلتزم بالسلوك اللائق وأخلاقيات استخدام الموقع، وعدم القيام بأي أعمال تشويشية أو ضارة تؤثر على سير عمل الموقع أو تعرقله.'
                }
              </div>
              <div className='pt-2'>
                {
                  '٤- الحماية والأمان: يجب على المستخدم أن يتحمل المسؤولية الكاملة عن حفظ معلومات حسابه وكلمة المرور الخاصة به، وعدم مشاركتها مع الآخرين. كما يجب على المستخدم .'
                }
              </div>
              <div className='pt-2'>{' ٥- الإبلاغ عن أي استخدام غير مصرح به لحسابه'}</div>
              <div className='pt-2'>
                {
                  '٦- روابط الجهات الخارجية: قد يحتوي الموقع على روابط لمواقع أو خدمات تابعة لجهات خارجية. يجب على المستخدم أن يكون على علم بأنه عند النقر على تلك الروابط فإنه يغادر الموقع، وتخضع الشروط والأحكام الخاصة بتلك الجهات الخارجية لتقدير المستخدم ومسؤوليته الخاصة.'
                }
              </div>
              <div className='pt-2'>
                {
                  '٧- التعديلات والتحديثات: يجوز لصاحب الموقع تعديل شروط وأحكام الاستخدام في أي وقت، وينبغي على المستخدم مراجعة هذه الشروط بشكل دوري للبقاء على اطلاع بأي تغييرات.'
                }
              </div>
              <div className='pt-2'>
                {
                  '٨- إنهاء الخدمة: يحق لصاحب الموقع إنهاء أو تعليق الخدمة في أي وقت دون إشعار مسبق، ولا يتحمل صاحب الموقع أي مسؤولية تجاه المستخدمين أو أي جهة ثالثة نتيجة لإنهاء الخدمة.'
                }
              </div>
            </div>
            <div className='mt-6 flex items-center justify-start'>
              <Button
                className=''
                variant={'contained'}
                size={'large'}
                onClick={() => {
                  setShowTermsConditions(false);
                }}
              >
                {t('NEW_LOCKS.UNDERSTOOD')}
              </Button>
            </div>
          </Box>
        ) : showAddOperator ? (
          <Box sx={style}>
            <div className='flex justify-between items-center mb-3'>
              <Typography id='modal-modal-title' className='text-[#27272A]'>
                {t('NEW_LOCKS.ADD_NEW_OPERATOR')}
              </Typography>

              <IconButton aria-label='close' onClick={closeModal} size='small'>
                <CloseIcon className='text-[#27272A] ' />
              </IconButton>
            </div>

            <div className='text-[#261B6A] font-normal mt-4'>
              <div>{t('NEW_LOCKS.YOUR_LOCK_OPERATOR')}</div>
              <div className='mt-4'>
                <TextField
                  className='w-full rounded-3xl'
                  InputProps={{ sx: { borderRadius: 24 } }}
                  style={{ borderRadius: '24px !important', color: '#9CA3AF', borderColor: '#9CA3AF' }}
                  placeholder={t('NEW_LOCKS.YOUR_NAME_OPERATOR')}
                  variant='outlined'
                  onChange={handleChange}
                />
              </div>
              <div className='mt-6 mx-4'>
                <Button
                  className='w-full'
                  variant={'contained'}
                  size={'large'}
                  onClick={onSubmitRequestNewOperator}
                  disabled={operator === ''}
                >
                  {t('NEW_LOCKS.REQUEST')}
                </Button>
              </div>
            </div>
          </Box>
        ) : (
          <Box sx={style}>
            <div className='flex justify-between items-center mb-3'>
              <Typography id='modal-modal-title' className='text-[#27272A]'>
                {t('NEW_LOCKS.TITLE')}
              </Typography>

              <IconButton aria-label='close' onClick={closeModal} size='small'>
                <CloseIcon className='text-[#27272A] ' />
              </IconButton>
            </div>
            <form className='mt-10' onSubmit={handleSubmit(onSubmit)}> 
              {showQr ? <Box sx={{ display: 'flex', flexDirection: 'column', }} >
                <Box sx={{ color: 'rgba(67, 83, 99, 1)', fontSize:isPhone ? '13px': '17px', fontWeight: 300, lineHeight: '30px', mb: 3,textAlign:'center' }} >
                 {t('NEW_LOCKS.QR_TITLE')}
                </Box>
              <Box sx={{display:'flex',justifyContent:'center',alignItems:'center',width:'100%'}} >
              {/* <QRCode style={{ width: '170px', height: '170px', }} value={qrData} /> */}
              <TuyaBtan onClick={()=>{
                window.open(qrData, "_blank");
              }} style={{width:window.innerWidth,height:'45px',cursor:'pointer',objectFit:'contain'}} />
              </Box>
                <Box onClick={()=>{
                  handleStep()
                }}  sx={{ cursor:'pointer',color: 'rgba(91, 63, 255, 1)', fontSize: isPhone ? '15px': '17px', lineHeight: '24px', textAlign: 'center', mt: 4 }} >
                {t('NEW_LOCKS.QR_DETAILS')} 
                </Box>
                {/* <div className='mt-6 mx-4'>
                  <Button sx={{ width:isPhone?'100%': '400px', height: '50px' }} className='w-full' variant={'contained'} size={'large'} onClick={handleStep}>
                    {t('NEW_LOCKS.CONNECT')}
                  </Button>
                </div> */}
              </Box> : step === 0 ? (
                <>
                  {error && <div className='mb-4 text-[#EF4444]'>{t('ERRORS.ALL_REQUIRED')}</div>}
                  <Controller
                    name='propertyId'
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <>
                        <div className='text-[#261B6A] font-normal'>
                          <div>{t('NEW_LOCKS.SELECT_APARTMENT')}</div>
                          <div>
                            <Select
                              className='w-full mt-4 rounded-xl'
                              value={value}
                              defaultValue={0}
                              disabled={listPropertiesLoading}
                              onChange={onChange}
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    borderRadius: '24px',
                                    color: '#9CA3AF',
                                    borderColor: error ? '#EF4444' : '#9CA3AF',
                                  },
                                },
                              }}
                              style={{
                                borderRadius: '24px',
                                color: '#9CA3AF',
                                borderColor: error ? '#EF4444' : '#9CA3AF',
                              }}
                            >
                              <MenuItem value={0} style={{ color: '#9CA3AF', display: 'none' }}>
                                {t('NEW_LOCKS.APARTMENT')} 
                              </MenuItem>
                              {listOfProperties?.map((property:any) => (
                                <MenuItem
                                  key={property?.propertyId}
                                  value={property?.propertyId}
                                  style={{ color: '#9CA3AF' }}
                                >
                                  {property?.propertyName}
                                </MenuItem>
                              ))}
                            </Select>
                          </div>
                        </div>
                      </>
                    )}
                  />
                  <Controller
                    name='keyOperator'
                    control={control}
                    defaultValue={'Mountains keys'}
                    render={({ field: { onChange } }) => (
                      <>
                        <div className='text-[#261B6A] font-normal pt-4'>
                          <div>{t('NEW_LOCKS.LOCK_OPERATOR')}</div>
                          <div className='mt-4 flex gap-3 w-full'>
                            {listOfOperator?.map((keyOperator: any) => (
                              <Chip
                                key={keyOperator?.id}
                                label={keyOperator?.operatorName}
                                variant='outlined'
                                onClick={() => {
                                  onChange(keyOperator?.id);
                                }}
                                style={{
                                  color: watchOperator === keyOperator?.id ? '#ffffff' : '#000000',
                                  backgroundColor: watchOperator === keyOperator?.id ? '#5B3FFF' : '#ffffff',
                                }}
                              />
                            ))}
                          </div>
                        </div>
                      </>
                    )}
                  />
                  <div
                    className='mt-4 text-[#3F97FF] cursor-pointer'
                    onClick={() => {

                      setShowAddOperator(true);
                    }}
                  >
                    {t('NEW_LOCKS.APARTMENT_NOT_FIND')}
                  </div>
                  <div className='mt-6 mx-4'>
                    <Button className='w-full' variant={'contained'} size={'large'} onClick={handleStep}>
                      {t('NEW_LOCKS.NEXT')}
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <div className=' text-[#5B3FFF]'>
                    {watch('propertyId')
                      ? listOfProperties && listOfProperties?.length > 0 && listOfProperties
                        ? listOfProperties?.find((property:any) => property?.propertyId === watch('propertyId'))
                          ?.propertyName
                        : ''
                      : ''}
                  </div>
                  {watch('keyOperator') === 1 && (
                    <>
                      <Controller
                        name='username'
                        control={control}
                        render={({ field: { onChange } }) => (
                          <>
                            <div className='text-[#261B6A] font-normal mt-4'>
                              <div>{t('NEW_LOCKS.EMAIL_TTLOCK')}</div>
                              <div className='mt-4'>
                                <TextField
                                  className='w-full rounded-3xl'
                                  InputProps={{ sx: { borderRadius: 24 } }}
                                  style={{ borderRadius: '24px !important', color: '#9CA3AF', borderColor: '#9CA3AF' }}
                                  placeholder={`Example@msool.sa`}
                                  variant='outlined'
                                  onChange={onChange}
                                />
                              </div>
                            </div>
                          </>
                        )}
                      />
                      <Controller
                        name='password'
                        control={control}
                        render={({ field: { onChange } }) => (
                          <>
                            <div className='text-[#261B6A] font-normal mt-4'>
                              <div>{t('NEW_LOCKS.PASSWORD_TTLOCK')}</div>
                              <div className='mt-4'>
                                <TextField
                                  className='w-full rounded-3xl'
                                  type='password'
                                  InputProps={{ sx: { borderRadius: 24 } }}
                                  style={{ borderRadius: '24px !important', color: '#9CA3AF', borderColor: '#9CA3AF' }}
                                  placeholder={`*******************`}
                                  variant='outlined'
                                  onChange={onChange}
                                />
                              </div>
                            </div>
                          </>
                        )}
                      />
                    </>
                  )}
                  <Controller
                    name='lockId'
                    control={control}
                    render={({ field: { onChange } }) => (
                      <>
                        <div className='text-[#261B6A] font-normal mt-4'>
                          <div>{t('NEW_LOCKS.LOCK_ID')}</div>
                          <div className='mt-4'>
                            <TextField
                              className='w-full rounded-3xl'
                              InputProps={{ sx: { borderRadius: 24 } }}
                              style={{ borderRadius: '24px !important', color: '#9CA3AF', borderColor: '#9CA3AF' }}
                              placeholder={`${t('NEW_LOCKS.LOCK_ID')}`}
                              variant='outlined'
                              onChange={onChange}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  />
                  <div
                    className='mt-4 text-[#3F97FF] cursor-pointer'
                    onClick={() => {
                      setShowAccessID(true);
                    }}
                  >
                    {t('NEW_LOCKS.ACCESS_ID')}
                  </div>
                  <div className='mt-6 mx-4'>
                    <Button className='w-full' variant={'contained'} size={'large'} type='submit'>
                      {t('NEW_LOCKS.LINK')}
                    </Button>
                  </div>
                  <div className='flex items-center gap-2 text-sm justify-center mt-2'>
                    <div className='text-[#A1A1AA]'>{t('NEW_LOCKS.CLICKING_LINK')}</div>
                    <div
                      className='text-[#3F97FF] cursor-pointer'
                      onClick={() => {
                        setShowTermsConditions(true);
                      }}
                    >
                      {t('NEW_LOCKS.RESPONSIBLE_TERMS_CONDITIONS')}
                    </div>
                  </div>
                </>
              )}
            </form>
          </Box>
        )}
      </Modal>
    </>
  );
};

export default ConnectNewLockModal;
