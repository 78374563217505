import { useEffect, useState } from "react"
import { SwitchWrapper } from "./styles"
import { Controller, RegisterOptions, useFormContext } from "react-hook-form"
import { ErrorMessage } from "@hookform/error-message"
import { SwitchProps, Tooltip } from "@mui/material"
import { useTranslation } from "react-i18next"
import { Toggle } from "@components"
import { IoIosInformationCircleOutline } from "react-icons/io"

interface IProps extends SwitchProps {
	label?: string
	labelVisibility?: boolean
	name: string
	registerOptions?: RegisterOptions
	withOutErrorMessage?: boolean
	fontSize?: string
	TooltipTitle?: string
	placement?:
		| "top"
		| "bottom-end"
		| "bottom-start"
		| "bottom"
		| "left-end"
		| "left-start"
		| "left"
		| "right-end"
		| "right-start"
		| "right"
		| "top-end"
		| "top-start"
}

function CustomSwitch({
	name,
	label,
	labelVisibility = true,
	registerOptions,
	withOutErrorMessage = false,
	fontSize,
	TooltipTitle,
	placement = "top",
	...rest
}: IProps) {
	/* ------------------------------- Local State ------------------------------ */
	const [isRequired, setIsRequired] = useState(false)
	/* ---------------------------------- Hooks --------------------------------- */
	const { control, formState } = useFormContext()
	const { errors } = formState
	const {
		i18n: { language },
	} = useTranslation()
	/* ------------------------------ Derived State ----------------------------- */
	useEffect(() => {
		if (typeof registerOptions?.required === "object") {
			setIsRequired(registerOptions?.required?.value)
		}
		if (typeof registerOptions?.required === "boolean") {
			setIsRequired(registerOptions.required)
		}
	}, [registerOptions])
	return (
		<SwitchWrapper
			$islabelVisible={labelVisibility}
			$isError={errors[name] ? true : false}
			$language={language as "ar" | "en"}
			$fontSize={fontSize}
		>
			<label>
				{label && (
					<p>
						{label} {isRequired && <span>*</span>}
						{TooltipTitle && (
							<Tooltip title={TooltipTitle} placement={placement}>
								<div className='TooltipIconWrapper'>
									<IoIosInformationCircleOutline />
								</div>
							</Tooltip>
						)}
					</p>
				)}
				<Controller
					name={name}
					control={control}
					rules={registerOptions}
					render={({ field: { onChange, value } }) => (
						<Toggle {...rest} onChange={onChange} value={value} />
					)}
				/>
			</label>
			{!withOutErrorMessage && (
				<div className='ErrorMessageStyle'>
					<ErrorMessage
						errors={errors}
						name={name}
						render={({ message }) => <p>{message}</p>}
					/>
				</div>
			)}
		</SwitchWrapper>
	)
}

export default CustomSwitch
