import styled from "styled-components"

export const UploadImageWrapper = styled.div<{
	$disabled: boolean
	$isTheViewStyleCard: boolean
}>`
	margin-bottom: 40px;
	.containerAllElements {
		display: flex;
		justify-content: flex-start;
		align-items: ${(props) =>
			props.$isTheViewStyleCard ? "flex-start" : "center"};
		flex-direction: ${(props) =>
			props.$isTheViewStyleCard ? "column-reverse" : "column"};
		gap: 16px;
		.cardView {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: 16px;
			flex-wrap: wrap;
			.fileItem {
				width: 260px;
				border-radius: 16px;
				padding: 16px;
				background-color: #fff;
				height: 100%;
				overflow: hidden;
				box-shadow: 0px 4px 14px 0px #000;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				flex-direction: column;
				gap: 16px;
				> :nth-child(1) {
					order: 2;
					width: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					color: #e9eaeb;
				}
				> :nth-child(2) {
					order: 3;
					font-size: 16px;
					font-weight: 600;
					color: #000;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					width: 100%;
					text-align: center;
				}
				> :nth-child(3) {
					order: 1;
					width: 100%;
					display: flex;
					justify-content: flex-end;
					align-items: center;
					gap: 16px;
					div {
						display: flex;
						justify-content: center;
						align-items: center;
						width: 24px;
						height: 24px;
						color: blue;
						cursor: pointer !important;
					}
				}
			}
		}

		.containerFiles {
			display: flex;
			justify-content: flex-start;
			flex-direction: column;
			gap: 16px;
			width: 100%;
			.fileItem {
				border: 1px solid #9e77ed;
				padding: 12px;
				display: grid;
				grid-template-columns: 28px 1fr 48px;
				grid-template-rows: 100%;
				border-radius: 8px;
				align-items: center;
				gap: 16px;
			}
			div {
				display: flex;
				justify-content: center;
				align-items: center;
			}

			.iconFile {
				width: 28px;
				height: 28px;
				padding: 3px;
				border-radius: 50px;
				background-color: #f9f5ff;
				> div {
					border-radius: 50px;
					width: 100%;
					height: 100%;
					background-color: #f5ebff;
					padding: 3px;
				}
			}
			p {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				letter-spacing: 0em;
				font-size: 14px;
				font-weight: 500;
				line-height: 20px;
				color: #414651;
			}
			.containerControlbuttons {
				display: flex;
				justify-content: flex-end;
				align-items: center;
				gap: 8px;
				color: #717680;
				div {
					cursor: pointer !important;
				}
			}
		}

		.containerButtonAndLabel {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			.ContainerWrapper {
				width: ${(props) => (props.$isTheViewStyleCard ? "auto" : "100%")};
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 16px;
				border-radius: 24px;
				label {
					width: 100%;
					height: 100%;
					cursor: ${(props) => (props.$disabled ? "not-allowed" : "pointer")};

					.labelSection {
						width: 100%;
						height: 100%;
						display: flex;
						justify-content: center;
						align-items: center;
						flex-direction: column;
						gap: 16px;
						border: 1px solid
							${(props) => (props.$disabled ? "#000" : "#E9EAEB")};
						border-radius: 16px;
						padding: 10px 16px;
						background-color: white;
						div {
							display: flex;
							justify-content: center;
							align-items: center;
							&:first-child {
								background-color: #fafafa;
								padding: 5px;
								display: inline-block;
								width: 40px;
								height: 40px;
								border-radius: 50px;
								> div {
									width: 100%;
									height: 100%;
									padding: 5px;
									background-color: #f5f5f5;
									display: flex;
									justify-content: center;
									align-items: center;
									svg {
										width: 16px;
										height: 16px;
										color: ${(props) => (props.$disabled ? "#000" : "#535862")};
									}
								}
							}
							p {
								color: ${(props) => (props.$disabled ? "#000" : "#535862")};
								font-size: 14px;
								font-weight: 400;
								line-height: 20px;
								span {
									&:first-child {
										color: #6941c6;
										font-family: Tajawal;
										font-size: 14px;
										font-weight: 700;
										line-height: 20px;
									}
								}
							}
						}
					}
				}
			}
			.labelSection {
				font-size: 20px;
				font-weight: 500;
			}
		}
	}

	.ErrorMessageStyle {
		font-size: 13px;
		font-weight: 400;
		line-height: 22.4px;
		color: #ef4444;
		height: 22px;
		p {
			margin: 0;
		}
	}
`
