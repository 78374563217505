export const TermsAndConditions = [
    {
      id: '1',
      question: '1. التسجيل وإدارة البيانات:',
      answer: '',
      points:[
        ' 1.1 يحق لمدير العقار أو المالك (المشترك) فقط إدخال بيانات العقار على المنصة.',
        '1.2 يحق للمشترك قبول أو رفض ربط بيانات وحداته مع منصة مسؤول والمنصات ذات الصلة.',
        '1,3 بإشتراك مديري العقارات أو ملاك العقارات وربط حساباتهم مع التطبيقات المتاحة على منصة مسؤول، يعتبرون موافقين على عمليات الربط التقني والتزامن.',
        '1.4 تُستخدم بيانات الحجوزات من خلال عمليات الربط لتسهيل عرضها وإدارتها بشكل شامل ومركزي، وذلك لضمان تجربة مدير العقارات أو مالك العقارات تكون أكثر كفاءة وسهولة.'
      ]
    },
    {
      id: '2',
      question: '2. الاشتراكات والتغييرات:',
      answer: '',
      points:[
        '2.1 يترتب على إضافة اشتراك جديد تغيير طبقة الاشتراك للمستخدم (من أساسي إلى أساسي+ | من أساسي+ إلى متقدم | من',
        '2.2 يُحتسب الخصم على العقارات المضافة فقط وفقًا لنطاق كل باقة.',
        '2.3 يتم خصم عدد الأيام الغير مستخدمة من سعر الاشتراك حتى دورة الدفع القادمة.',
        '2.4 ابتداءً من دورة الدفع القادمة يُحتسب الخصم حسب عدد مجموع العقارات المضافة.'
      ]
    },
    {
      id: '3',
      question: '3. تقليل الباقة بإلغاء اشتراكات نشطة:',
      answer: '',
      points:[
        '3.1 في حال إلغاء اشتراك فعال، يترتب على ذلك تغيير طبقة الاشتراك للمستخدم.',
        '3.2 ابتداءً من دورة الدفع القادمة يتم حسم المبلغ على جميع العقارات المضافة حسب شروط كل باقة.'
      ]
    },
    {
      id: '4',
      question: '4. سياسة إلغاء الاشتراكات:',
      answer: '',
      points:[
        '4.1 يُرجى ملاحظة أن إلغاء الاشتراك لا يؤدي إلى إيقاف استخدام المميزات حتى نهاية الفترة المتبقية.',
        '4.2 يتم إيقاف الحسم من بطاقة العميل في دورات الدفع القادمة.',
        '4.3 في حال حذف العقار من إدارة العقارات يتم الغاء الاشتراك مباشرة وإيقاف الحسم.'
      ]
    },
    {
      id: '5',
      question: '5. سياسات الدفع:',
      answer: '',
      points:[
        '5.1 يتطلب من العميل إدخال معلومات البطاقة عند الاشتراك.',
        '5.2 مدة الفترة التجريبية لجميع الباقات هي 14 يومًا.',
        '5.3 يتم استحقاق الدفع في بداية كل دورة اشتراك.',
        '5.4 يُحسب الخصم عند إضافة اشتراك جديد بناءً على عدد الأيام الغير مستخدمة حتى الفاتورة القادمة.'
      ]
    },
    {
      id: '6',
      question: '6. سياسات الخصم:',
      answer: '',
      points:[
        '6.1 تتم مراعاة الخصم عند ترقية أو تقليل الباقة حسب عدد الاشتراكات النشطة.',
        '6.2 يُحدد الخصم بشكل محدد لكل باقة وفقًا للعقارات المضافة.'
      ]
    },
    {
      id: '7',
      question: '7. سياسات الخصوصية والأمان:',
      answer: '',
      points:[
        '7.1 نلتزم بحماية خصوصية معلومات المشتركين بأعلى مستويات الأمان.',
        '7.2 يُفترض أن يكون استخدام البيانات متسقًا مع سياسات الخصوصية المعتمدة.'
      ]
    },
    {
      id: '8',
      question: '8. الدعم الفني:',
      answer: '',
      points:[
        '8.1 يُقدم فريق دعم العملاء دعمًا فنيًا فعّالًا ويُشدد على رد سريع للاستفسارات والشكاوى.',
        '8.2 تُوضح السياسة الفنية لتحديثات الخدمة وتقديم الدعم.'
      ]
    },
    {
      id: '9',
      question: '9. سياسات التحديث والتغيير:',
      answer: '',
      points:[
        '9.1 يُعلم المستخدمون بأي تحديثات أو تغييرات قبل موعد التنفيذ.',
        '9.2 يُقدم دليل شامل لفهم التغييرات الجديدة.'
      ]
    },
    {
      id: '10',
      question: '10. قوانين الاستخدام والالتزام:',
      answer: '',
      points:[
        '10.1 يُلزم المشتركون بالالتزام بقوانين الاستخدام المحددة.',
        '10.2 يُعتبر استخدام الخدمة تلقائيًا موافقة على الشروط والأحكام.'
      ]
    },
    {
      id: '11',
      question: '11. تعديلات الأحكام والشروط:',
      answer: '',
      points:[
        '11.1 قد تُعدل الأحكام والشروط في أي وقت، ويُفترض من المستخدمين مراجعتها بشكل دوري.',
        '11.2 يتم الإعلان عن أي تعديلات قبل موعد التنفيذ.'
  
      ]
    },
    {
      id: '12',
      question: '12. انتهاء العقد:',
      answer: '',
      points:[
        '12.1 يمكن للمستخدمين إنهاء العقد وفقًا لسياسة الإلغاء.',
        '12.2 يتوقف حق الوصول والاستخدام بعد انتهاء العقد.'
      ]
    },
  
  ]