import { UseQueryOptions, useQuery } from "@tanstack/react-query"
import { APIS_KEYS } from "../../apis-keys"
import { IResponseGetListingManagement } from "./types"
import { ListManagementURL } from "../../../utils/constants"
import type { AxiosInstance, AxiosResponse } from "axios"
import { useApi } from "../../useApi"

async function getListingManagement(apiPrivate: AxiosInstance) {
	try {
		const response: AxiosResponse<IResponseGetListingManagement> =
			await apiPrivate.get(`${ListManagementURL}`)
		return response.data
	} catch (error: any) {
		console.log(error)
		throw new Error("something went wrong")
	}
}

export function useGetListingManagement(
	options?:
	| Omit<
	UseQueryOptions<
	IResponseGetListingManagement,
	unknown,
	IResponseGetListingManagement
	>,
	"queryKey" | "queryFn"
	>
	| undefined
) {
	const { apiPrivate } = useApi()
	return useQuery({
		queryKey: [APIS_KEYS.GETLISTINGMANAGEMENT],
		queryFn: () => getListingManagement(apiPrivate),
		...options,
	})
}
