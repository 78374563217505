import { Dispatch, SetStateAction } from "react"
import { PaginationPropertiesWrapper } from "./styles"
import { GrNext, GrPrevious } from "react-icons/gr"

interface IProps {
	lengthOfRecords: number
	resourcesPerPage: number
	setCurrentPage: Dispatch<SetStateAction<number>>
	currentPage: number
}
export default function PaginationProperties({
	lengthOfRecords,
	resourcesPerPage,
	currentPage,
	setCurrentPage,
}: IProps) {
	const totalPages = Math.ceil(lengthOfRecords / resourcesPerPage) // Total pages

	const handleNextPage = () => {
		if (currentPage < totalPages) {
			setCurrentPage(currentPage + 1)
		}
	}

	const handlePreviousPage = () => {
		if (currentPage > 1) {
			setCurrentPage(currentPage - 1)
		}
	}
	return (
		<PaginationPropertiesWrapper>
			<div>
				<button onClick={handlePreviousPage} disabled={currentPage === 1}>
					<GrNext />
				</button>
				<span>
					{currentPage} / {totalPages}
				</span>
				<button onClick={handleNextPage} disabled={currentPage === totalPages}>
					<GrPrevious />
				</button>
			</div>
		</PaginationPropertiesWrapper>
	)
}
