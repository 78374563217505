import styled from "styled-components"

export const CustomLoadingWrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100dvw;
	height: 100dvh;
	background-color: rgba(255, 255, 255, 0.7);
	z-index: 100000;
	display: flex;
	justify-content: center;
	align-items: center;
`
