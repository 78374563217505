import styled from "styled-components"

export const DetailsInfoWrapper = styled.div`
	.sectionInfoMessage {
		margin-top: 16px;
		padding-bottom: 24px;
		border-bottom: 1px solid #eaeafd;
		.iconSection {
			color: #5b3fff;
			font-size: 20px;
		}
		.titleSection {
			p {
				margin: 0;
				font-size: 13px;
				font-weight: 400;
				line-height: 15.6px;
				color: #435363;
			}
		}
	}
	.sectionTitle {
		margin-block: 16px;
		.header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			.iconSection {
				color: #5b3fff;
				font-size: 20px;
			}
			.containerStatus {
				background-color: #ffc2c8;
				border-radius: 50px;
				p {
					padding: 8px 12px;
					margin: 0;
					display: flex;
					justify-content: space-between;
					align-items: center;
					gap: 8px;
					span {
						display: inline-block;
						color: #d70015;
						&:first-child {
							background-color: #d70015;
							width: 10px;
							height: 10px;
							border-radius: 50px;
						}
					}
				}
			}
		}
		.titleSection {
			p {
				margin: 0;
				font-size: 13px;
				font-weight: 400;
				line-height: 15.6px;
				color: #435363;
			}
		}
	}
	.record {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 8px;
		p {
			font-size: 14px;
			font-weight: 400;
			line-height: 16.8px;
			margin: 0;
			color: #5b3fff;
			&:first-child {
				color: #0f172a;
			}
		}
		div {
			display: flex;
			justify-content: center;
			align-items: center;
			color: #5b3fff;
			cursor: pointer;
		}
	}
	.hint {
		font-size: 12px;
		font-weight: 400;
		line-height: 14.4px;
		color: #435363;
		margin: 0;
		margin-top: 8px;
	}
	.controlLock {
		margin-top: 16px;
		p {
			margin: 0;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: 8px;
			span {
				&:first-child {
					color: #0f172a;
					font-size: 14px;
					font-weight: 400;
					line-height: 16.8px;
				}
				&:last-child {
					display: flex;
					justify-content: center;
					align-items: center;
					color: #5b3fff;
					height: 20px;
				}
			}
		}
	}
	.otherInfo {
		padding-top: 16px;
		.header {
			color: #8e98a1;
			font-size: 12px;
			font-weight: 400;
			line-height: 14.4px;
			margin-bottom: 16px;
			p {
				margin: 0;
			}
		}
		.item {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: 18px;
			margin-bottom: 16px;
			.label {
				p {
					margin: 0;
					color: #0f172a;
					font-size: 12px;
					font-weight: 400;
					line-height: 14.4px;
				}
			}
			.status,
			.green,
			.gray {
				padding: 8px 12px;
				border-radius: 50px;
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 8px;
				span {
					&:first-child {
						width: 9px;
						height: 9px;
						border-radius: 50px;
						display: inline-block;
					}
					&:last-child {
						font-size: 14px;
						font-weight: 400;
						line-height: 16.8px;
					}
				}
			}

			.green {
				background-color: #f3fbf4;
				span {
					&:first-child {
						background-color: #16ae26;
					}
					&:last-child {
						color: #16ae26;
					}
				}
			}
			.gray {
				background-color: #f6f6f7;
				span {
					&:first-child {
						background-color: #999999;
					}
					&:last-child {
						color: #999999;
					}
				}
			}
		}
	}
`
