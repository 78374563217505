import React, { FC, MouseEvent, useMemo, useState } from 'react';
import { Box, Chip, Modal, Popover, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IMessage, ITemplate } from '@interfaces';
import { mobileBoxStyle, modalBoxStyle, modalContainerStyle } from '@utils';
import { useTheme } from '@mui/material/styles';
import {
  ChatBubbleOutline,
  ContentCopyOutlined,
  LocationOnOutlined,
  LoginOutlined,
  LogoutOutlined,
} from '@mui/icons-material';

const TemplateMobileView: FC<{
  data: ITemplate;
  welcome: IMessage[];
  handleClose: () => void;
  open: boolean;
}> = ({ data, welcome, open, handleClose }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | undefined>();

  const restItems = useMemo(() => {
    if (data?.property?.length && data?.property.length > 1) {
      return data.property?.slice(1, data.property.length);
    }
    return [];
  }, [data.property]);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(undefined);
  };

  return (
    <Modal
      open={open}
      sx={modalContainerStyle}
      onClose={handleClose}
      aria-labelledby='mobile-message-modal-title'
      aria-describedby='mobile-message-modal-description'
    >
      <Box sx={mobileBoxStyle}>
        <Box
          sx={{
            insetInline: 0,
            display: 'flex',
            justifyContent: 'center',
            top: 12,
            position: 'absolute',
          }}
        >
          <Box sx={{ borderRadius: 8, zIndex: 1, backgroundColor: 'black', width: '100px', height: '24px' }}></Box>
        </Box>
        <Box sx={{ px: 1, pt: 6, overflowY: 'auto', maxHeight: '100%' }}>
          <Typography fontWeight={'bold'}>
            {t('MESSAGING.HELLO')}{' '}
            <Typography sx={{ display: 'inline' }}>{t('MESSAGING.GUEST_NAME_PLACEHOLDER')}</Typography>
          </Typography>
          <Box sx={{ position: 'relative', mt: 2 }}>
            <Box
              sx={{
                position: 'absolute',
                top: '-12px',
                insetInlineStart: '-8px',
                padding: '2px',
                rotate: '-20deg',
              }}
            >
              <Box
                sx={{
                  color: 'white',
                  display: 'flex',
                  padding: '4px',
                  borderRadius: 1,
                  backgroundColor: theme.palette.primary.main,
                  border: '3px solid white',
                }}
              >
                <ChatBubbleOutline sx={{ rotate: '20deg', fontSize: '10px' }} />
              </Box>
            </Box>
            <Box
              sx={{
                border: '2px solid',
                fontSize: '12px',
                borderColor: theme.palette.primary.main,
                borderRadius: 2,
                p: 1,
                pt: 2,
              }}
              dangerouslySetInnerHTML={{
                __html:
                  welcome
                    ?.find((i) => i.id === data.welomeMessageId)
                    ?.content?.replaceAll('\\n', '<br/>')
                    .replaceAll('\\r', '') ?? '',
              }}
            />
          </Box>
          <Box sx={{ width: '100%', mt: 4, gap: 4, display: 'flex', flexDirection: 'column' }}>
            <Box>
              <Box sx={{ display: 'flex', gap: 1, mb: 1, alignItems: 'center' }}>
                <Typography sx={{ fontSize: '12px' }}>{t('MESSAGING.RESERVATION_INFO')}</Typography>
                <Box sx={{ backgroundColor: '#9D9D9D', height: '1px', flex: 1 }}></Box>
              </Box>
              <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', mb: 1 }}>
                <Box
                  sx={{
                    color: 'white',
                    display: 'flex',
                    padding: '4px',
                    height: 'max-content',
                    borderRadius: 1,
                    backgroundColor: theme.palette.success.main,
                  }}
                >
                  <LoginOutlined sx={{ fontSize: '10px' }} />
                </Box>
                <Typography
                  sx={{
                    width: '100%',
                    fontSize: '12px',
                  }}
                >
                  وقت تسجيل الدخول:11:00, 24 ديسمبر 2024
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', mb: 1 }}>
                <Box
                  sx={{
                    color: 'white',
                    display: 'flex',
                    padding: '4px',
                    height: 'max-content',
                    borderRadius: 1,
                    backgroundColor: theme.palette.error.main,
                  }}
                >
                  <LogoutOutlined sx={{ fontSize: '10px' }} />
                </Box>
                <Typography
                  sx={{
                    width: '100%',
                    fontSize: '12px',
                  }}
                >
                  وقت تسجيل الدخول:11:00, 25 ديسمبر 2024
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', mb: 1 }}>
                <Box
                  sx={{
                    color: 'white',
                    display: 'flex',
                    padding: '4px',
                    height: 'max-content',
                    borderRadius: 1,
                    backgroundColor: theme.palette.primary.main,
                  }}
                >
                  <LocationOnOutlined sx={{ fontSize: '10px' }} />
                </Box>
                <Typography
                  sx={{
                    width: '100%',
                    fontSize: '12px',
                  }}
                >
                  الرياض, حي الملقا 32230
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box sx={{ width: '100%', mt: 4, gap: 4, display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ mb: 1 }}>
              <Box sx={{ display: 'flex', gap: 1, mb: 1, alignItems: 'center' }}>
                <Typography sx={{ fontSize: '12px' }}>{t('MESSAGING.RULES_INSTRUCTIONS')}</Typography>
                <Box sx={{ backgroundColor: '#9D9D9D', height: '1px', flex: 1 }}></Box>
              </Box>
              {data?.ruleMessages?.map((c) => {
                const content = c.content;
                return (
                  <Typography
                    sx={{
                      mb: 1,
                      width: 'max-content',
                      backgroundColor: '#F6F6F6',
                      maxWidth: '100%',
                      borderInlineStart: '4px solid',
                      borderColor: theme.palette.primary.main,
                      borderRadius: 1,
                      paddingInline: 2,
                      py: '2px',
                      fontSize: '12px',
                    }}
                    key={c.id}
                  >
                    {content}
                  </Typography>
                );
              })}
            </Box>
          </Box>
          <Box sx={{ width: '100%', mt: 4, display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ marginBottom: 1 }}>
              <Box sx={{ display: 'flex', gap: 1, mb: 1, alignItems: 'center' }}>
                <Typography sx={{ fontSize: '12px' }}>{t('MESSAGING.LOCK_PIN')}</Typography>
                <Box sx={{ backgroundColor: '#9D9D9D', height: '1px', flex: 1 }}></Box>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Chip
                sx={{
                  flexShrink: 0,
                  alignItems: 'center',
                  cursor: 'pointer',
                  fontSize: '10px',
                  px: 2,

                  '&.MuiChip-root': {
                    paddingInline: 1,
                  },
                  '.MuiChip-icon': {
                    marginInline: '-4px 4px',
                  },

                  '.MuiChip-label': {
                    paddingTop: '2px',
                    paddingInline: 0,
                  },
                }}
                color={'primary'}
                variant={'filled'}
                icon={<ContentCopyOutlined sx={{ width: '10px' }} />}
                size={'small'}
                label={t('MESSAGING.COPY_PIN')}
              />
              <Box
                sx={{
                  flex: 1,
                  display: 'flex',
                  gap: 1,
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                  '.MuiBox-root': {
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: 1,
                    paddingTop: '10px',
                    px: 0.4,
                    backgroundColor: '#F0F0F0',
                    border: '1px solid #9d9d9d',
                    height: '24px',
                  },
                }}
              >
                <Box>*</Box>
                <Box>*</Box>
                <Box>*</Box>
                <Box>*</Box>
                <Box>*</Box>
                <Box>*</Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
export default TemplateMobileView;
