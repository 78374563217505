import { useTranslation } from "react-i18next"
import { PopupOverlayWrapper } from "./styles"
import { Grid } from "@mui/material"
import NavigateSection from "./components/navigateSection"
import ReservationDetails from "./components/reservationDetails"
import { useRecoilValue } from "recoil"
import { PopupOverlayNewCalender } from "@store/index"
import AdvancedSettings from "./components/advancedSetting"

export default function PopupOverlay() {
	const isPopupOverlayNewCalender = useRecoilValue(PopupOverlayNewCalender)

	const {
		i18n: { language },
	} = useTranslation()
	return (
		<PopupOverlayWrapper lang={language as "ar" | "en"}>
			<Grid container>
				<Grid item xs={12}>
					<NavigateSection />
				</Grid>
				{isPopupOverlayNewCalender?.isOpen &&
					isPopupOverlayNewCalender.openModule === "reservationDetails" && (
						<Grid item xs={12}>
							<ReservationDetails  />
						</Grid>
					)}
				{isPopupOverlayNewCalender?.isOpen &&
					isPopupOverlayNewCalender.openModule === "advancedSetting" && (
						<Grid item xs={12}>
							<AdvancedSettings />
						</Grid>
					)}
			</Grid>
		</PopupOverlayWrapper>
	)
}
