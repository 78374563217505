/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useContext,useState } from 'react';
import { Box, Button, IconButton, Modal, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import EyeIcon from '@assets/svg/eye-slash.svg?react';
import LockIcon from '@assets/svg/lock.svg?react';

import { IModalProps } from '@interfaces';
import { userSettings } from '@services';
import { ToastMessageContext } from '@context'; 
import useBreakPoinst from '@hooks/useBreakPoinst';

const UpdateNameModal: FC<IModalProps> = ({ handleClose, open, data }) => {
    const [showPass, setShowPass] = useState(false);
    const [showPassConfirm, setShowPassConfirm] = useState(false);

    const {isPhone} =  useBreakPoinst()
    const style = {
        position: 'absolute',
        top: '50%',
        left:isPhone ? '4%' : '50%',
        transform: isPhone ? 'translate(0, -60%)' : 'translate(-50%, -50%)',
        width:isPhone ? '90%' : 495,
        bgcolor: 'background.paper',
        color: '#261B6A',
        boxShadow: 24,
        p: 3, 
        borderRadius: 5,
    };

    const {
        t,
    } = useTranslation();

    const { showSuccess, showError } = useContext(ToastMessageContext);

    const { control, handleSubmit, reset, setValue,getValues } = useForm<any>({
        mode: 'onChange',
    });

    const { updateUserSettingMutate } = userSettings();

    const { mutate: updateUser } = updateUserSettingMutate({
        onSuccess: () => {
            closeModal();
            showSuccess(t('SEETING.CHANGES_SAVED'));  
        },
        onError: () => {
            showError(t('ERRORS.SOMETHING_WENT_WRONG'));
        },
    });

    const onSubmit = (body: any) => {
        const payload: any = {
            password: body?.password, 
        };

        updateUser(payload);
    };

    const closeModal = () => {
        handleClose();
        reset({});
    };
    return (
        <>
            <Modal
                open={open}
                onClose={closeModal}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
            >
                <Box sx={style}> 
                    <div className='flex justify-between items-center mb-3'>
                        <Typography sx={{fontSize:'24px', fontWeight:400}} id='modal-modal-title' className='text-[#435363]'>
                        {t('SEETING.CREATE_NEW_PASSWORD')}
                        </Typography>

                        <IconButton aria-label='close' onClick={closeModal} size='small'>
                            <CloseIcon className='text-[#27272A] ' />
                        </IconButton>
                    </div>
                    <form className='mt-10' onSubmit={handleSubmit(onSubmit)}>
                        <>
                            <Controller
                                name='password'
                                control={control}
                                rules={{
                                    required:{value:true,message:t("COMMON.REQUIRED")},pattern:{value:/^(?:(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*).{8,}$/,message:t("SEETING.PASSWORD_ERROR")}}}   
                                render={({ field: { value, onChange,ref },fieldState: { error }  }) => (
                                    <>
                                        <div className='text-[#261B6A] font-normal'>
                                            <Box sx={{fontWeight:'700',color:'#435363',fontSize:'20px',marginBottom:1}} > {t('SEETING.NEW_PASSWORD')}</Box>
                                            <div>
                                                <TextField
                                                    id='password'
                                                    type={showPass ? 'text' : 'password'}
                                                    error={Boolean(error?.message)}
                                                    helperText={t(error?.message ?? '')}
                                                    className='w-full rounded-3xl' 
                                                    value={value}
                                                    ref={ref}
                                                    InputProps={{ sx: { borderRadius: 24 },
                                                    startAdornment:(
                                                        <Box sx={{marginX:1}} >
                                                        <LockIcon  /> 
                                                        </Box>

                                                    ), 
                                                    endAdornment: (
                                                        <IconButton
                                                          aria-label='toggle password visibility'
                                                          onClick={() => setShowPass((show) => !show)}
                                                          edge='end'
                                                        >
                                                           <EyeIcon   />
                                                        </IconButton>
                                                      ),
                                                }}
                                                    style={{
                                                        borderRadius: '24px !important',
                                                        color: '#9CA3AF',
                                                        borderColor: '#9CA3AF',
                                                    
                                                    }}
                                                    placeholder={t('SEETING.PASSWORD')}
                                                    variant='outlined'
                                                    onChange={onChange}
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}
                            />
                            <Box sx={{height:20}} />
                            <Controller
                                name='secondpassword'
                                
                                control={control}
                                rules={{
                                    validate: (value) =>value ==getValues().password|| "The passwords do not match",
                                    required:{value:true,message:t("COMMON.REQUIRED")},pattern:{value:/^(?:(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*).{8,}$/,message:t("SEETING.PASSWORD_ERROR")}}}   
                                render={({ field: { value, onChange },fieldState: { error },  }) => (
                                    <>
                                        <div className='text-[#261B6A] font-normal'>
                                            <Box sx={{fontWeight:'700',color:'#435363',fontSize:'20px',marginBottom:1}} > {t('SEETING.CONFIRM_NEW_PASSWORD')}</Box>
                                            <div>
                                                <TextField
                                                    type={showPassConfirm ? 'text' : 'password'}
                                                    error={Boolean(error?.message)}
                                                    helperText={t(error?.message ?? '')}
                                                    className='w-full rounded-3xl' 
                                                    value={value}
                                                    InputProps={{ sx: { borderRadius: 24 },
                                                    startAdornment:(
                                                        <Box sx={{marginX:1}} >
                                                        <LockIcon  /> 
                                                        </Box>

                                                    ), 
                                                    endAdornment: (
                                                        <IconButton
                                                          aria-label='toggle password visibility'
                                                          onClick={() => setShowPassConfirm((show) => !show)}
                                                          edge='end'
                                                        >
                                                           <EyeIcon   />
                                                        </IconButton>
                                                      ),
                                                }}
                                                    style={{
                                                        borderRadius: '24px !important',
                                                        color: '#9CA3AF',
                                                        borderColor: '#9CA3AF',
                                                    
                                                    }}
                                                    placeholder={t('SEETING.PASSWORD')}
                                                    variant='outlined'
                                                    onChange={onChange}
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}
                            />
                            <Box mt={2} sx={{color:'#261B6A',fontWeight:300,fontSize:'16px'}} >
                                {t('SEETING.PASSWORD_HINT')}
                            </Box>
                            <div className='mt-6 mx-4'>
                                <Button className='w-full' variant={'contained'} size={'large'} type='submit'> 
                                {t('SEETING.SAVE_CHANGES')}
                                </Button>
                            </div>
                        </>
                    </form>
                </Box>
            </Modal>
        </>
    );
};

export default UpdateNameModal;
