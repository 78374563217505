// @ts-nocheck
import { useApi } from "./useApi";
import { getCalendarURL, getCalendaAibnbURL,sendWhatssAppMessageUrl,MsoolCalendarUrl } from "../utils/constants";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { IAirbnbDays, IAirbnbList, ICalendarData, IGatherinDates, IGatherinList } from "../interfaces";
interface IMsoolCalendarApiProps{
	page: string
	search?: string
}
const useCalendar = () => {
    const { apiPrivate } = useApi();
    const [isThereProperties, setIsThereProperties] = useState(false);
    // pure api without modification
    const GatherInApi = async () => {
      
       
    }

    const getAllCalendarGatherIn = async () => {
        const result = await apiPrivate.get(getCalendarURL);
        const reservations: ICalendarData[] = [];
        if (result.data.result.length != 0) {
            setIsThereProperties(true)
        } else {
            setIsThereProperties(isThereProperties)
        }
        result.data.result?.map((property: IGatherinList) => {
            property.dates?.map((date: IGatherinDates) => {
                if (date.checkIn != null && date.checkOut != null) {
                    reservations.push({
                         checkIn: date.checkIn,
                        checkOut: date.checkOut,
                        id: date.reservation_id,
                        propertyName: property.propertyName,
                        userName: date.user_name ?? '',
                        userMobile: date.clientNumber ?? '',
                        type: 'gatherin',
                        isAutoAccess:property?.isAutoAccess,
                        isMessageSend:property?.isMessageSend

                        
                    })
                }
            })
        })
        return result.data.result;
    }
    const sendWhatsAppMeessage = async (id) => {
        const result = await apiPrivate.get(sendWhatssAppMessageUrl+id);
        return result
    }
    const AirbnbApi = async () => {
      
    }
    const MsoolCalendarApi = async ({...rest}:IMsoolCalendarApiProps|null) => {
        const result = await apiPrivate.get(`${MsoolCalendarUrl}`, {
            params: rest,
        })
        return result.data.result
    }
    const getAllCalendarAibnb = async () => {

        const result = await apiPrivate.get(getCalendaAibnbURL);
        const reservations: any = [];

        if (result.data.result.length != 0) {
            setIsThereProperties(true)
        } else {
            setIsThereProperties(isThereProperties)
        }
    
        result.data.result?.map((property: any) => {
            reservations.push({
                propertyName: property?.propertyName,
                isMessageSend:property?.isMessageSend,

                type: 'airbnb',
                dates: property.calendarResponse.filter((x: any) => x.availability_type === 'reservation' && x.bookingInfo).map((cc:any) => {
                    return {
                        checkIn: cc.bookingInfo?.checkIn,
                        checkOut: cc.bookingInfo?.checkOut,
                        id: cc.bookingInfo?.reservationId,
                        userName: `${cc?.customerInfo?.name} ${cc?.customerInfo?.surname}`,
                        userMobile: cc?.customerInfo?.phone ?? '',
                        is_reserved : "1",
                        type: 'airbnb',
                        price:cc?.daily_price,
                        reservation_id: cc.bookingInfo?.reservationId,
                        user_name:`${cc?.customerInfo?.name} ${cc?.customerInfo?.surname}`,
                        isAutoAccess:property?.isAutoAccess,
                        propertyName: property?.propertyName,
                        isMessageSend:property?.isMessageSend


                    }
                })

 
            })

        })
        return reservations;
    }


   // const getCalendarGatherInListQuery = () => useQuery({ queryKey: [getCalendarURL], queryFn: getAllCalendarGatherIn });
    const getAllCalendarQuery = (data: IMsoolCalendarApiProps|null) => useQuery({ queryKey: [MsoolCalendarUrl,...Object.values(data)], queryFn:({})=> MsoolCalendarApi(data) });




    //const getCalendarAirbnbListQuery = () => useQuery({ queryKey: [getCalendaAibnbURL], queryFn: getAllCalendarAibnb });

    const gathernListing = () => useQuery({ queryKey: [getCalendarURL], queryFn: GatherInApi });
    const airbnbListing = () => useQuery({ queryKey: [], queryFn: AirbnbApi });
    const sendmessageQuery = (id: string) =>
    useQuery({
      enabled: false,
      queryKey: [sendWhatssAppMessageUrl, id],
      queryFn: ({ queryKey }) => sendWhatsAppMeessage(queryKey[1]),
      
      
    });


    return { getAllCalendarQuery, isThereProperties, gathernListing, airbnbListing,sendmessageQuery }

}

export { useCalendar }