import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import HeaderSection from "../headerSection"
import { Grid } from "@mui/material"
import CalendarAddIcon from "@assets/svg/CalendarAddIcon.svg?react"
import MoneysIcon from "@assets/svg/MoneysIcon.svg?react"
import UserOctagonIcon from "@assets/svg/UserOctagonIcon.svg?react"
import HomeIcon from "@assets/svg/HomeIcon.svg?react"
import { ReviewFormInputsWrapper } from "./styles"
import TextArea from "@Common/Textarea"
import { IFormPropertyInfoFormInputs } from "../PropertyInfoFormInputs"
import { IPricingFormInputs } from "../PricingFormInputs"
import { IGustInfoFormInputs } from "../GustInfoFormInputs"
import dayjs from "dayjs"
import { Dispatch, SetStateAction, useEffect } from "react"

export interface IReviewFormInputs {
	HostNote: string
}
interface IProps {
	getPropertyData: IFormPropertyInfoFormInputs | undefined
	getPricingData: IPricingFormInputs | undefined
	getGustData: IGustInfoFormInputs | undefined
	setReviewData: Dispatch<SetStateAction<IReviewFormInputs | undefined>>
}
export default function ReviewFormInputs({
	getPropertyData,
	getPricingData,
	getGustData,
	setReviewData,
}: IProps) {
	const { t } = useTranslation()
	const methods = useForm<IReviewFormInputs>()
	const { setValue, watch } = methods
	const HostNoteWatch = watch("HostNote")
	const onSubmit = (value: IReviewFormInputs) => {
		setReviewData(value)
	}

	useEffect(() => {
		if (HostNoteWatch) {
			setReviewData(HostNoteWatch as any)
		}
	}, [HostNoteWatch, setValue])
	return (
		<ReviewFormInputsWrapper>
			<HeaderSection
				label={t(
					"MANUALRESERVATION.REVIEWRESERVATIONINFORMATIONANDCONFIRMATION"
				)}
			/>
			<FormProvider {...methods}>
				<form onSubmit={methods.handleSubmit(onSubmit)}>
					<Grid container spacing={"24px"}>
						<Grid item xs={12} md={6}>
							<div className='containerCard'>
								<div className='containerIcon'>
									<UserOctagonIcon />
								</div>
								<p className='sectionInfo'>
									{t("MANUALRESERVATION.GUESTDATA")}
								</p>
								<div className='containerData'>
									<p>
										{getGustData?.firstName} {getGustData?.lastName}
									</p>
									<p>{getGustData?.guestPhoneNumber}</p>
								</div>
							</div>
						</Grid>

						<Grid item xs={12} md={6}>
							<div className='containerCard'>
								<div className='containerIcon'>
									<HomeIcon />
								</div>
								<p className='sectionInfo'>
									{t("MANUALRESERVATION.PROPERTIES")}
								</p>
								<div className='containerData'>
									<p>{getPropertyData?.propertyName}</p>
								</div>
							</div>
						</Grid>

						<Grid item xs={12} md={6}>
							<div className='containerCard'>
								<div className='containerIcon'>
									<MoneysIcon />
								</div>
								<p className='sectionInfo'>
									{t("MANUALRESERVATION.PRICINGDATA")}
								</p>
								<div className='containerData'>
									<p>
										{t("MANUALRESERVATION.NIGHTPRICE")} :
										<span>{getPricingData?.nightPrice} {t('GENERAL.SR')}</span>
									</p>
									<p>
										{t("MANUALRESERVATION.TOTALPRICE")} :
										<span>{getPricingData?.totalPrice} {t('GENERAL.SR')}</span>
									</p>
								</div>
							</div>
						</Grid>

						<Grid item xs={12} md={6}>
							<div className='containerCard'>
								<div className='containerIcon'>
									<CalendarAddIcon />
								</div>
								<p className='sectionInfo'>
									{t("MANUALRESERVATION.TIMEANDDATE")}
								</p>
								<div className='containerData'>
									<p>
										<p>{`${t('MANUALRESERVATION.startDate')} :`}</p>
										<p>
											{getPropertyData?.checkInTime} -{" "}
											{dayjs(getPropertyData?.checkIn).format("YYYY-MM-DD")}
										</p>
									</p>
									<p>
										<p>{`${t('MANUALRESERVATION.endDate')} :`}</p>
										<p>
											{getPropertyData?.checkOutTime} -{" "}
											{dayjs(getPropertyData?.checkOut).format("YYYY-MM-DD")}
										</p>
									</p>
								</div>
							</div>
						</Grid>
						<Grid item xs={12}>
							<div className='dividedLine'></div>
						</Grid>
						<Grid item xs={12}>
							<TextArea
								name='HostNote'
								label={t("MANUALRESERVATION.COMMENTS")}
								placeholder={t("MANUALRESERVATION.COMMENTS")}
							/>
						</Grid>
					</Grid>
				</form>
			</FormProvider>
		</ReviewFormInputsWrapper>
	)
}