import styled from "styled-components"

export const ActionViewWrapper = styled.div`
	.iconSection {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		color: #5b3fff;
		svg {
			width: 20px;
			height: 20px;
		}
	}
	.titleSection {
		margin-top: 8px;
		p {
			font-size: 14px;
			font-weight: 700;
			line-height: 16.8px;
			color: #435363;
			margin: 0;
		}
		.label {
			font-size: 13px;
			font-weight: 400;
			line-height: 15.6px;
			color: #435363;
			display: inline-block;
		}
	}
	.detailsSection {
		margin-top: 16px;
		.infoSectionpopupControle {
			position: relative;
			.controle {
				border: 1px solid #ff8a95;
				width: 15px;
				height: 15px;
				border-radius: 50px;
				background-color: #ff8a95;
				animation-name: animation;
				animation-duration: 1s;
				animation-iteration-count: infinite;
				cursor: pointer;
				@keyframes animation {
					0% {
						opacity: 1;
					}
					25% {
						opacity: 0.8;
					}
					50% {
						opacity: 0.4;
					}
					75% {
						opacity: 0.8;
					}
					100% {
						opacity: 1;
					}
				}
			}
			.PopupInfo {
				display: none;
			}
			&:hover {
				.PopupInfo {
					display: inline-block;
				}
			}
		}
	}
`
