import { atom } from "recoil"
import { ATOMS_KEYS } from "../atom-keys"
export interface IGitBookingInfoState {
	bookingInfo: BookingInfo
	eventFlag: "gathern" | "airbnb"
	continueToStart: boolean
	continueToEnd: boolean
	allWeek: boolean
	eventEndDateIsFirstDayOfWeek: boolean
	availability_type: string
}

export interface BookingInfo {
	date: string
	checkIn: string
	checkOut: string
	propertyName: string
	propertyId: number
	custom_price: string
	price: string
	is_reserved: string
	is_busy: string
	reservation_id: string
	user_name: string
	clientNumber: string
	type: any
	status: string
	flag: any
	guestName: string
	reservationId: string
	reservationNumber: string
	guestPhoneNumber: string
}

export const gitBookingInfo = atom<IGitBookingInfoState | undefined>({
	key: ATOMS_KEYS.GITBOOKINGINFO,
	default: undefined,
})
