/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/rules-of-hooks */
import { FC, useContext, useEffect, useState } from 'react';
import { Box, Button, IconButton, Modal, Radio, RadioGroup, TextField, Typography, Slide } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

import { IModalProps } from '@interfaces';
import { useLock } from '@services';
import { ToastMessageContext } from '@context';
import useBreakPoinst from '@hooks/useBreakPoinst';

const CreateEmergencyLockModal: FC<IModalProps> = ({ handleClose, open, data }) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 495,
    bgcolor: 'background.paper',
    color: '#261B6A',
    boxShadow: 24,
    p: 3,
    borderRadius: 5,
  };

  const {
    i18n: { language },
    t,
  } = useTranslation();
  const [step, setStep] = useState(0);

  const [lockId, setLockId] = useState('');

  const { showSuccess, showError } = useContext(ToastMessageContext);
  const {isPhone} = useBreakPoinst()

  const handleChange = (event: any) => {
    setLockId(event.target.value);
  };

  const closeModal = () => {
    handleClose();
    setStep(0);
    setLockId('');
  };

  const handleStep = (value: number) => {
    setStep(value);
  };
  const { setAutoTimeLockURLMutate, unlockMutate, getActivePassCodeQuery } = useLock();

  const {
    data: activePassCode,
    isLoading: activePassCodeLoading,
    isError: activePassCodeError,
  } = getActivePassCodeQuery(data?.lockId && data?.lockId);
  useEffect(()=>{
    setLockId(activePassCode?.passcode)
  },[activePassCode])
  const { mutate: unlock } = unlockMutate({
    onSuccess: () => {
      showSuccess(t('LOCKS.UNLOCK_SUCCESS'));
      closeModal();
    },
    onError: () => {
      showError(t('ERRORS.SOMETHING_WENT_WRONG'));
    },
  });

  const { mutate: setAutoTimeLock } = setAutoTimeLockURLMutate({
    onSuccess: () => {
      showSuccess(t('NEW_LOCKS.CREATED_SUCCESS'));
      closeModal();
    },
    onError: () => {
      showError(t('NEW_LOCKS.CREATED_FAIL'));
    },
  });

  const onSubmit = (body: any) => {
    if (step === 0) {
      const payload: any = {
        ...data,
      };

      unlock(payload);
    } else {
      const payload: any = {
        emrgencyPasscode: lockId,
        lockId: data?.lockId,
      };

      setAutoTimeLock(payload);
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={closeModal}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Slide direction="up" style={{
          position: 'absolute',
          top: '20%',
          left:isPhone? '10%' : '30%',
          transform: 'translate(-50%, -50%)',
          width:isPhone?'80%' : 495,
        }} in={open} mountOnEnter unmountOnExit>

          <Box sx={style}>
            <div className='flex justify-between items-center mb-3'>
              <Typography id='modal-modal-title' sx={{ color: '#27272A', fontSize: '20px', fontWeight: 700 }}>
                {t('LOCKS.CREATE_EMERGENCY_LOCK')}
              </Typography>

              <IconButton aria-label='close' onClick={closeModal} size='small'>
                <CloseIcon className='text-[#27272A] ' />
              </IconButton>
            </div>

            <div className='mt-10'>
              <>
                <Box sx={{ fontWeight: 700, fontSize: 20, color: '#5B3FFF' }} >
                 {data?.propertyname}
                </Box>
                <div className=' text-[#5B3FFF]'>{data?.i?.propertyname}</div>
                <RadioGroup
                  aria-labelledby='demo-error-radios'
                  name='quiz'
                  className='pt-4'
                  defaultValue={0}
                  value={step}
                >
                  <Box sx={{ display: 'flex' }} >
                    <Box
                      sx={{ borderRadius: 30, border: `1.4px solid ${step == 0 ? '#5B3FFF' : '#EAEDF1'} `, backgroundColor: step == 0 ? '#EEEBFF' : 'white' }}
                      className='flex items-center cursor-pointer'
                      onClick={() => {
                        handleStep(0);
                      }}
                    >
                      <Radio size='medium' value={0} />
                      <Typography sx={{ fontWeight: 400, color: step == 0 ? '#5A3FFE' : '#8E98A1' }}>{`${t('CREATE_EMERGENCY_LOCK.OPEN_DOOR_NOW')}`}</Typography>
                      <Box sx={{ width: '10px', display: 'flex' }} />

                    </Box>
                    <Box className='flex flex-1' />
                  </Box>
                  <Box sx={{ display: 'flex', mt: 1 }} >

                    <Box
                      sx={{ borderRadius: 30, border: `1.4px solid ${step == 1 ? '#5B3FFF' : '#EAEDF1'} `, backgroundColor: step == 1 ? '#EEEBFF' : 'white' }}
                      className='flex items-center cursor-pointer'
                      onClick={() => {
                        handleStep(1);
                      }}
                    >
                      <Radio value={1} />
                      <Typography sx={{ fontWeight: 400, color: step == 1 ? '#5A3FFE' : '#8E98A1' }} >{`${t('CREATE_EMERGENCY_LOCK.CREATE_MINUTE_LOCK')}`}</Typography>
                      <Box sx={{ width: '10px', display: 'flex' }} />
                    </Box>
                    <Box className='flex flex-1' />

                  </Box>
                </RadioGroup>
              </>
              {step === 0 ? (
                <></>
              ) : (
                <>
                {
                  true ? null : <div className='pt-4'>
                  <div className=''>
                    <TextField
                      className='w-full rounded-3xl'
                      InputProps={{ sx: { borderRadius: 24 } }}
                      style={{ borderRadius: '24px !important', color: '#9CA3AF', borderColor: '#9CA3AF' }}
                      placeholder={`${t('NEW_LOCKS.NO_LOCK')}`}
                      variant='outlined'
                      value={lockId}
                      onChange={handleChange} 
                    />
                  </div>
                  <div className='pt-4 text-[#3F97FF]'>{`${t('NEW_LOCKS.LOCK_IN_ACTIVATION')}`}</div>
                </div>
                }
                </> 
              )}

              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 3 }} >
                <Button sx={{ fontSize: '16px', fontWeight: 700, width: '232px', height: '44px' }} variant={'contained'} size={'large'} onClick={onSubmit}>
                  {t('LOCKS.ENABLE')}
                </Button>
              </Box>
            </div>
          </Box>
        </Slide>
      </Modal>
    </>
  );
};

export default CreateEmergencyLockModal;
