import styled from "styled-components"

export const GuestManagementWrapper = styled.div`
	position: relative;
	p {
		margin: 0;
	}
	.containerTable {
		width: 100%;
		.columnsContent {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			margin: 0;
		}
		.containerPopupAction {
			p {
				text-decoration: underline;
				cursor: pointer;
				color: #5b3fff;
			}
		}
	}
`
