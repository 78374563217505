import { Grid } from "@mui/material"
import { SectionTableWrapper } from "./styles"
import CustomTable from "@Common/CustomTable"
import { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import AirbnbIcon from "@assets/svg/airbnb.svg?react"
import GathernIcon from "@assets/svg/GathernIcon2.svg?react"
import BookingIcon from "@assets/svg/BookingIcon.svg?react"
import ChannelAgoda from "@assets/svg/ChannelAgoda.svg?react"
import MsoolIcon from "@assets/svg/msool-icon.svg?react"
import EyeLine from "@assets/svg/EyeLine.svg?react"
import TrashLine from "@assets/svg/TrashLine.svg?react"
import PenLine from "@assets/svg/PenLine.svg?react"
import FormInputSearch from "./formInputSearch"
import { CustomNoRowsOverlay } from "../CustomNoRowsOverlay"
import { useCategoryMangment } from "@services"
import PopupDeleteCategories from "../popupDeleteCategories"
import PopupViewInnerCategory from "../popupViewInnerCategory"
import PopupEditCategory from "../popupEditCategory"

export default function SectionTable() {
	const [getSearchCategryInputValue, setSearchCategryInputValue] =
		useState<string>()
	const [innerViewCategory, setInnerViewCategory] = useState(false)
	const [deleteCategories, setDeleteCategories] = useState(false)
	const [editCategory, setEditCategory] = useState(false)
	const [activePage, setActivePage] = useState(1)
	const [perPage, onPerpageChange] = useState("20")
	const { GetCategoriesQuery } = useCategoryMangment()
	const [recordData, setRecordData] = useState<{
		id: number
		categoryId: number
		categoryName: string
		propertyCounts: number
		channals: number[]
	}>()
	const { data: GetCategories } = GetCategoriesQuery({
		pageNumber: activePage.toString(),
		pageSize: perPage,
		search: getSearchCategryInputValue,
	})

	const {
		t,
		i18n: { language },
	} = useTranslation()

	const columns = [
		{
			field: "categoryName",
			headerName: t("CATEGORYMANAGER.CATEGORYNAME"),
			flex: 1,
			renderCell: (params: any) => (
				<p
					className='columnsContent'
					style={{ textAlign: `${language === "ar" ? "right" : "left"}` }}
				>
					{params.row.categoryName}
				</p>
			),
		},
		{
			field: "propertyCounts",
			headerName: t("CATEGORYMANAGER.NUM_OF_PROPERTY"),
			flex: 1,
			renderCell: (params: any) => (
				<p
					className='columnsContent'
					style={{ textAlign: `${language === "ar" ? "right" : "left"}` }}
				>
					{params.row.propertyCounts}
				</p>
			),
		},
		{
			field: "channals",
			headerName: t("CATEGORYMANAGER.CHANNELS"),
			flex: 1,
			renderCell: (params: any) => (
				<div
					className='columnsContent'
					style={{ textAlign: `${language === "ar" ? "right" : "left"}` }}
				>
					{params?.row?.channals?.map(
						(subItem: any) =>
							(subItem === 1 && <AirbnbIcon key={subItem} />) ||
							(subItem === 2 && <GathernIcon key={subItem} />) ||
							(subItem === 3 && <BookingIcon key={subItem} />) ||
							(subItem === 4 && <ChannelAgoda key={subItem} />) ||
							(subItem === 6 && <MsoolIcon key={subItem} />)
					)}
				</div>
			),
		},
		{
			field: "Actions",
			headerName: "",
			flex: 1,
			renderCell: (params: any) => (
				<p className='columnsActions'>
					<div
						onClick={() => {
							setInnerViewCategory(true)
							setRecordData(params.row)
						}}
					>
						<EyeLine />
					</div>
					<div
						onClick={() => {
							setDeleteCategories(true)
							setRecordData(params.row)
						}}
					>
						<TrashLine />
					</div>
					<div
						onClick={() => {
							setEditCategory(true)
							setRecordData(params.row)
						}}
					>
						<PenLine />
					</div>
				</p>
			),
		},
	]

	const rows = useMemo(() => {
		const data = GetCategories?.data.result.map((item) => {
			return {
				id: item?.categoryId,
				categoryId: item?.categoryId,
				categoryName: item?.categoryName,
				propertyCounts: item?.propertyCounts,
				channals: item?.connectedChannels.map((item) => item?.channelId),
			}
		})
		return data ?? []
	}, [GetCategories])

	return (
		<SectionTableWrapper>
			{deleteCategories && (
				<PopupDeleteCategories
					setDeleteCategories={setDeleteCategories}
					recordData={recordData}
				/>
			)}
			{innerViewCategory && (
				<PopupViewInnerCategory
					setInnerViewCategory={setInnerViewCategory}
					categoryId={recordData?.categoryId?.toString()!}
				/>
			)}

			{editCategory && (
				<PopupEditCategory
					setEditCategory={setEditCategory}
					recordData={recordData}
				/>
			)}
			<Grid container gap={"24px"}>
				<Grid item xs={6}>
					<FormInputSearch
						setSearchCategryInputValue={setSearchCategryInputValue}
					/>
				</Grid>
				<Grid item xs={12}>
					<CustomTable
						columns={columns}
						rows={rows ?? []}
						totalPages={
							GetCategories?.data?.totalRecords
								? Math.ceil(GetCategories?.data?.totalRecords / Number(perPage))
								: 1
						}
						totalRecords={GetCategories?.data?.totalRecords}
						setActivePage={setActivePage}
						activePage={activePage}
						onPerpageChange={onPerpageChange}
						perPage={perPage}
						CustomNoRowsOverlay={CustomNoRowsOverlay}
						listPerPagesNumber={[
							{
								label: "20",
								value: "20",
							},
							{
								label: "50",
								value: "50",
							},
							{
								label: "100",
								value: "100",
							},
						]}
					/>
				</Grid>
			</Grid>
		</SectionTableWrapper>
	)
}