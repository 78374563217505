/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useMemo, useState } from 'react';
import { Box, Button, Modal } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Stepper from '../Stepper'
import { IModalProps } from '@interfaces';
import useBreakPoinst from '@hooks/useBreakPoinst';
import OnboardingFirst from '@assets/svg/onboardingFirst.svg?react'; 
import OnboardingSecond from '@assets/svg/onboardingSecond.svg?react'; 
import Onboardingthird from '@assets/svg/onboardingthird.svg?react'; 

const UpdateNameModal: FC<IModalProps> = ({ handleClose, open }) => {
    const [step, setStep] = useState(0)
    const { isPhone } = useBreakPoinst() 
    const style = {
        position: 'absolute',
        top: '50%',
        left: isPhone ? '4%' : '50%',
        transform: isPhone ? 'translate(0, -70%)' : 'translate(-50%, -50%)',
        width: isPhone ? '90%' : 700,
        bgcolor: 'background.paper',
        color: '#261B6A',
        boxShadow: 24,
        p: 3,
        borderRadius: 5,
    }; 

    const {
        i18n: { language },
        t,
    } = useTranslation();
    const isRtl = language  === 'ar'
    const steps = useMemo(()=>[
        isRtl?   'قم بالضغط على الزر وتفعيله لكي تتمكن من إستخدام الميزة':'Press the button and activate it to be able to use the feature',
       isRtl ? 'قم بالذهاب الى شاشة التقويم من التبويب الجانبي للمنصة':'Go to the calendar screen from the side tab of the platform',
       isRtl ? 'إذا كانت الشقة متاحة، قم بالضغط على زر (إرسال رسالة) وذلك لتنبيه المستآجر بأن الشقة جاهزة للإستلام' : 'If the apartment is available, click the (Send Message) button to alert the tenant that the apartment is ready for receipt.'
    ],[isRtl]) 
    const STEPS = useMemo(()=>[
        {
            title:isRtl? 'تفعيل الزر':'Activate button',
            label:isRtl? '(الخطوة الأولى)':'(The first step)',
        },
        {
            title:isRtl? 'شاشة التقويم':'Calendar screen',
            label:isRtl? '(الخطوة الثانية)':'(The second step)'
        },
        {
            title:isRtl? 'إرسال الرسالة':'Send message',
            label:isRtl? '(الخطوة الثالثة)':'(The third step)'
        }, 
    ],[isRtl])

    const closeModal = () => {
        setStep(0)
        handleClose();
    };
    return (
        <>
            <Modal
                open={open}
                onClose={closeModal}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
            >
                <Box sx={style}>
               { step==0 &&     <OnboardingFirst style={{width:'100%',height: '202px',marginBottom: !isPhone? 0 : -30}} />}
               { step==1 &&     <OnboardingSecond  style={{width:'100%',height: '202px',marginBottom: !isPhone? 0 : -30}}  />}
               { step==2 &&     <Onboardingthird  style={{width:'100%',height: '202px',marginBottom: !isPhone? 0 : -30}}  />}

                    <Box textAlign={"center"} >
                        
                        <Stepper steps={STEPS} activeStep={step} /> 
                      {!isPhone &&   <Box mt={3} />}

                        <Box mt={2} sx={{ color: '#435363', fontWeight: 400, fontSize: '16px', textAlign: 'center',  paddingX: isPhone ?1 : 15, lineHeight: '28px' }} >
                            {
                                steps[step]
                            }
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }} className='mt-6 mx-4'>
                            <Button sx={{ width: '154px', height: '44px' }} variant={'contained'} size={'large'} onClick={() => step == 2 ? closeModal() : setStep(step + 1)}>
                                {t( step== 2? 'PROPERTIES_MANAGEMENT.DONE': 'PROPERTIES_MANAGEMENT.NEXT')} 
                            </Button>
                            <Box sx={{ marginX: 1 }} />

                            <Button sx={{ width: '154px', height: '44px' }} variant={'outlined'} size={'large'} onClick={() => step == 0 ? closeModal() : setStep(step - 1)}>
                            {t('PROPERTIES_MANAGEMENT.PASS')} 
                            </Button>

                        </Box>
                    </Box>
                </Box>
            </Modal>
        </>
    );
};

export default UpdateNameModal;
