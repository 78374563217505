import Input from "@Common/input"
import { Dispatch, SetStateAction, useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
interface IForm {
	searchCategryInput: string
}
interface IProps {
	setSearchCategryInputValue: Dispatch<SetStateAction<string | undefined>>
}
export default function FormInputSearch({
	setSearchCategryInputValue,
}: IProps) {
	const { t } = useTranslation()
	const methods = useForm<IForm>()
	const { watch } = methods
	const searchCategryInputWatch = watch("searchCategryInput")
	const onSubmit = async (value: any) => {
		console.log(value)
	}

	useEffect(() => {
		setSearchCategryInputValue(searchCategryInputWatch)
	}, [setSearchCategryInputValue, searchCategryInputWatch])
	return (
		<div>
			<FormProvider {...methods}>
				<form onSubmit={methods.handleSubmit(onSubmit)}>
					<Input
						type='search'
						name='searchCategryInput'
						placeholder={t("CATEGORYMANAGER.SEARCH")}
					/>
				</form>
			</FormProvider>
		</div>
	)
}
