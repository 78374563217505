import { Grid } from "@mui/material"
import { useFormContext } from "react-hook-form"
import { useCategoryMangment } from "@services"
import { useMemo } from "react"
import { PorpertesCardWrapper } from "../../../components/CreateCategoryManagement/StepTwo/styles"
import { IForm } from "."
interface IProps {
	categoryId: string
}
export default function PorpertesCard({ categoryId }: IProps) {
	const { register, setValue } = useFormContext<IForm>()
	const { GetPropertiesDoNotHaveCategoryQuery, GetCategoryByIdQuery } =
		useCategoryMangment()
	const { data: GetCategoryById } = GetCategoryByIdQuery(categoryId)
	const { data: GetPropertiesDoNotHaveCategory } =
		GetPropertiesDoNotHaveCategoryQuery({
			pageNumber: "1",
			pageSize: "500",
		})

	const myListedData = useMemo(() => {
		const data = GetCategoryById?.data?.result.flatMap((item) => {
			return item.properties.flatMap((subItem) => {
				return {
					image: subItem.propertyImage,
					name: subItem.propertyName,
					location: subItem.propertyLocation,
					id: subItem.propertyId,
				}
			})
		})
		const returnIds = data?.map((item) => item.id.toString())
		setValue("propertyId", returnIds ?? [])
		return data ?? []
	}, [GetCategoryById])

	const dataList = useMemo(() => {
		const data = GetPropertiesDoNotHaveCategory?.data?.result.map((item) => {
			return {
				image: item.propertyImage,
				name: item.propertyName,
				location: item.propertyLocation,
				id: item.propertyId,
			}
		})
		return data ?? []
	}, [GetPropertiesDoNotHaveCategory])

	return (
		<PorpertesCardWrapper>
			<Grid container>
				{myListedData?.length > 0 &&
					myListedData?.map((item) => {
						return (
							<Grid item xs={12} md={4} key={item.id}>
								<div className='containerCard'>
									<div className='wrapper'>
										<div className='checkbox'>
											<label className='checkbox-wrapper'>
												<input
													type='checkbox'
													className='checkbox-input'
													value={item.id}
													{...register("propertyId")}
												/>
												<span className='checkbox-tile'>
													<span className='checkbox-image'>
														{item.image && <img src={item.image} alt='test' />}
													</span>
													<span className='checkbox-label'>
														<span>{item.name}</span>
														<span>{item.location}</span>
													</span>
												</span>
											</label>
										</div>
									</div>
								</div>
							</Grid>
						)
					})}

				{dataList?.length > 0 &&
					dataList?.map((item) => {
						return (
							<Grid item xs={12} md={4} key={item.id}>
								<div className='containerCard'>
									<div className='wrapper'>
										<div className='checkbox'>
											<label className='checkbox-wrapper'>
												<input
													type='checkbox'
													className='checkbox-input'
													value={item.id}
													{...register("propertyId")}
												/>
												<span className='checkbox-tile'>
													<span className='checkbox-image'>
														{item.image && <img src={item.image} alt='test' />}
													</span>
													<span className='checkbox-label'>
														<span>{item.name}</span>
														<span>{item.location}</span>
													</span>
												</span>
											</label>
										</div>
									</div>
								</div>
							</Grid>
						)
					})}
			</Grid>
		</PorpertesCardWrapper>
	)
}
