import { UseQueryOptions, useQuery } from "@tanstack/react-query"
import { APIS_KEYS } from "../../apis-keys"
import { IResponseGetAirbnbCalendar } from "./types"
import { getCalendaAibnbURL } from "../../../utils/constants"
import type { AxiosResponse } from "axios"
import { useApi } from "../../useApi"

async function getAirbnbCalendar() {
	const { apiPrivate } = useApi()
	try {
		const response: AxiosResponse<IResponseGetAirbnbCalendar> =
			await apiPrivate.get(`${getCalendaAibnbURL}`)
		return response.data
	} catch (error: any) {
		console.log(error)
		throw new Error("something went wrong")
	}
}

export function useGetAirbnbCalendar(
	options?:
		| Omit<
				UseQueryOptions<
					IResponseGetAirbnbCalendar,
					unknown,
					IResponseGetAirbnbCalendar
				>,
				"queryKey" | "queryFn"
		  >
		| undefined
) {
	return useQuery({
		queryKey: [APIS_KEYS.GETAIRBNBCALENDAR],
		queryFn: () => getAirbnbCalendar(),
		...options,
	})
}
