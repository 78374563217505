import styled from "styled-components"

export const TextAreaWrapper = styled.div<{
	$islabelVisible: boolean
	$isError: boolean
	disabled: boolean | undefined
	$direction?: string
}>`
	label {
		display: flex;
		justify-content: flex-start;
		flex-direction: column;
		p {
			font-weight: 700;
			text-align: ${(props) => (props.$direction === "ltr" ? "end" : "start")};
			color: #435363;
			visibility: ${(props) => (props.$islabelVisible ? "visible" : "hidden")};
			font-size: 15px;
			margin: 0;
			margin-bottom: 4px;
			text-transform: capitalize;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			display: flex;
			flex-direction: ${(props) =>
				props.$direction === "ltr" ? "row-reverse" : "row"};
			span {
				color: #d70015;
				margin-inline: 4px;
			}
		}
		input,
		textarea {
			width: 100%;
			font-size: 15px;
			color: #000;
			background-color: #fff;
			padding: 7px 25px;
			border: 1px solid ${(props) => (props.$isError ? "#EF4444" : "#e5e7eb")};
			border-radius: 16px;
			outline: none;
			resize: none;
			&:focus {
				border: 1px solid ${(props) => (props.$isError ? "#EF4444" : "#e5e7eb")};
			}
			&::placeholder {
				color: #e8e9ed;
			}
		}
	}
	.ErrorMessageStyle {
		font-size: 13px;
    font-weight: 400;
    line-height: 22.4px;
    color: #ef4444;
    height: 22px;
		p {
			margin: 0;
		}
	}
`
