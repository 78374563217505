import { useTranslation } from "react-i18next"
import { PropertyInformationWrapper } from "./styles"
import HomeIcon from "@assets/svg/design-system/home.svg?react"
import AirbnbIcon from "@assets/svg/airbnb.svg?react"
import GathernIcon from "@assets/svg/GathernIcon2.svg?react"
import BookingIcon from "@assets/svg/BookingIcon.svg?react"
import ChannelAgoda from "@assets/svg/ChannelAgoda.svg?react"
import MsoolIcon from "@assets/svg/msool-icon.svg?react"
import { useRecoilValue } from "recoil"
import { DMDataReservationRequest } from "@store/DM/DMDataReservationRequest"
import { Dispatch, SetStateAction, useContext, useState } from "react"
import { useDM } from "@services"
import { ToastMessageContext } from "@context/toast-message-cotnext"
import { DMLiveFeedEventId } from "@store/index"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
import { ContentCopyOutlined } from "@mui/icons-material"
import { Box, Snackbar, Typography } from "@mui/material"
import CheckIcon from "@mui/icons-material/Check"
import { IoClose } from "react-icons/io5"
import Overlay from "../../SectionChats/Overlay"
import DmLeft from "@assets/svg/Dm-arrow-left.svg?react"
import DmCopy from "@assets/svg/Dm-copy.svg?react"
import DmGuest from "@assets/svg/Dm-user-octagon.svg?react"
import DmProperty from "@assets/svg/Dm-home-2.svg?react"
import DmCalendar from "@assets/svg/Dm-calendar-tick.svg?react"
import DmMoneys from "@assets/svg/Dm-moneys.svg?react"
import { DmStatusMapper } from ".."

// Extend dayjs with plugins
dayjs.extend(utc)
dayjs.extend(timezone)

interface IProps {
	setIsPopupOpen: Dispatch<SetStateAction<boolean>>
	isAltration: boolean
	alterationId?: string
	resolved?: boolean
	onRefresh?: () => void
	isOverlayOpen?: boolean
	onCloseOverlay: () => void
}
export const channals = {
	airbnb: <AirbnbIcon />,
	gathern: <GathernIcon />,
	booking: <BookingIcon />,
	agoda: <ChannelAgoda />,
	other: "Other",
	msool: <MsoolIcon />,
}
export default function PropertyInformation({
	isOverlayOpen,
	onCloseOverlay = () => { },
	onRefresh = () => { },
	setIsPopupOpen,
	isAltration = false,
	alterationId = "",
	resolved = false,
}: IProps) {
	/* ------------------------------- Local State ------------------------------ */

	const [isInstructionsOpen, setIsInstructionsOpen] = useState(false)
	const [snackOpen, setSnackOpen] = useState(false)
	const DMDataReservationRequestValue = useRecoilValue(DMDataReservationRequest)
	const DMLiveFeedEventIdValue = useRecoilValue(DMLiveFeedEventId)
	/* ---------------------------------- Hooks --------------------------------- */
	const {
		t,
		i18n: { language },
	} = useTranslation()
	const start_dateTimeZone =
		DMDataReservationRequestValue?.reservation.start_date.replace(/\[.*?\]/, "")
	const end_dateTimeZone =
		DMDataReservationRequestValue?.reservation.end_date.replace(/\[.*?\]/, "")
	const currantTimeZone = DMDataReservationRequestValue?.reservation.time_zone
	const start_date = dayjs
		.tz(start_dateTimeZone, currantTimeZone)
		.format("YYYY-MM-DD -- h:mm A")

	const end_date = dayjs
		.tz(end_dateTimeZone, currantTimeZone)
		.format("YYYY-MM-DD -- h:mm A")
	/* ------------------------------- API'S Call ------------------------------- */
	const { showError, showSuccess } = useContext(ToastMessageContext)
	const { HandleReservationRequestMutate, HandleAlternativeRequestMutate } =
		useDM()
	const { mutateAsync: mutateAlternative } = HandleAlternativeRequestMutate({
		onError: (e: any) => {
			try {
				const errObj = e?.response?.data?.errorMessages[0]
				const err = language === "ar" ? errObj?.messageAr : errObj?.messageEn
				showError(err || t("DM.errorOccurred"))
			} catch (e) {
				console.log(e)
			}
		},
		onSuccess: () => {
			showSuccess(t("MANUALRESERVATION.RESERVATIONHASBEENSUCCESSFULLYSAVED"))
			//	setIsPopupOpen(false)
			//	window.location.reload()
			onRefresh()
		},
	})
	const { mutateAsync: mutateAsyncHandleReservationRequestMutate } =
		HandleReservationRequestMutate({
			onError: (e: any) => {
				try {
					const errObj = e?.response?.data?.errorMessages[0]
					const err = language === "ar" ? errObj?.messageAr : errObj?.messageEn
					showError(err || t("DM.errorOccurred"))
				} catch (e) {
					console.log(e)
				}
			},
			onSuccess: () => {
				showSuccess(t("MANUALRESERVATION.RESERVATIONHASBEENSUCCESSFULLYSAVED"))
				//	setIsPopupOpen(false)
				//window.location.reload()
				onRefresh()
			},
		})

	const onSubmit = async () => {
		const data = {
			requestStatus: true,
			rejectReason: "0",
		}
		if (!DMLiveFeedEventIdValue?.live_feed_event_id) return
		await mutateAsyncHandleReservationRequestMutate({
			bookingId: DMLiveFeedEventIdValue?.live_feed_event_id,
			data: data,
		})
	}

	const handleCopyClick = (v = "") => {
		if (!DMDataReservationRequestValue?.reservation.property_id) return
		navigator.clipboard
			.writeText(v ?? DMDataReservationRequestValue?.reservation.property_id)
			.then(() => {
				// setSnackOpen(true)
				showSuccess(t("COMMON.RESERVATION_COPIED"))
			})
			.catch((err) => {
				console.error("Failed to copy: ", err)
			})
	}
	const action = (
		<Box sx={{ display: "flex", justifyContent: "space-between", paddingX: 5 }}>
			<CheckIcon sx={{ color: "white" }} />
			<Box width={30} />
			<Box sx={{ fontWeight: 900, mt: 0.5 }}>
				{t("COMMON.RESERVATION_COPIED")}
			</Box>
		</Box>
	)
	const currentChannle =
		DMDataReservationRequestValue?.channals?.toLowerCase() ?? "airbnb"
	const RenderDetails = ({ isOverlay = false }) => {
		return (
			<Box>
				{isOverlay && (
					<Box>
						<Box
							onClick={onCloseOverlay}
							sx={{
								cursor: "pointer",
								display: "flex",
								justifyContent: "flex-end",
							}}
						>
							<DmLeft />
						</Box>
						<Box
							sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}
						>
							<Typography
								sx={{ fontWeight: 700, fontSize: "18px", color: "#261B6A" }}
							>
								{t("DM.RESERVATION_DETAILS")}
							</Typography>
							{resolved ? null : (
								<Box
									sx={{
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
										mt: -3,
									}}
								>
									<Box
										onClick={() => {
											if (isAltration) {
												return mutateAlternative({
													requestId: alterationId,
													accept: "accept",
												})
											}
											onSubmit()
										}}
										sx={{
											cursor: "pointer",
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											backgroundColor: "#5B3FFF",
											borderRadius: "8px",
											pt: 2,
											px: 1,
										}}
									>
										<Typography
											sx={{
												color: "white",
												fontWeight: 700,
												fontSize: "10px",
												lineHeight: "12px",
												mt: 10,
											}}
										>
											{t("DM.CONFRIM")}
										</Typography>
									</Box>
									<Box width={10} />
									<Box
										onClick={() => setIsPopupOpen(true)}
										sx={{
											cursor: "pointer",
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											backgroundColor: "rgba(232, 91, 91, 0.09)",
											borderRadius: "8px",
											pt: 2,
											px: 1,
										}}
									>
										<Typography
											sx={{
												color: "#E85B5B",
												fontWeight: 700,
												fontSize: "10px",
												lineHeight: "12px",
												mt: 10,
											}}
										>
											{t("DM.REJECT")}
										</Typography>
									</Box>
								</Box>
							)}
						</Box>
						<div className='propertyInfo'>
							<Box
								sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}
							>
								<Box sx={{ display: "flex", mt: 2 }}>
									{
										channals[
										currentChannle as
										| "airbnb"
										| "gathern"
										| "booking"
										| "agoda"
										| "other"
										| "msool"
										]
									}
									<Box sx={{ mt: -0.5, mx: 0.4 }}>
										<Typography
											sx={{
												color: "rgba(67, 83, 99, 1)",
												fontWeight: 400,
												fontSize: "18px",
												mb: -10,
												mx: 1,
											}}
										>
											{currentChannle}
										</Typography>
									</Box>
								</Box>
								{DMDataReservationRequestValue?.reservation.status_type ? <Box sx={{
									display: 'flex',
									alignItems: 'center',
									justifyContent:'center',
									backgroundColor: DmStatusMapper(DMDataReservationRequestValue?.reservation.status_type).background,
									px: '20px',
									height:'30px',
									borderRadius: '50px'
								}} >

									<Box sx={{
										borderRadius: '5px',
										width: '9px',
										height: '9px',
										mx: 0.5,
										mt: -0.3,
										backgroundColor: DmStatusMapper(DMDataReservationRequestValue?.reservation.status_type).pointBg
									}}  >

									</Box>
									<Typography sx={{ 
										pt:2,
										color: DmStatusMapper(DMDataReservationRequestValue?.reservation.status_type).textColor }} >
										{DmStatusMapper(DMDataReservationRequestValue?.reservation.status_type)[language == "en" ? "en" : "ar"]}
									</Typography>
								</Box> : null}
							</Box>
						</div>

						<Separator />
						<Box sx={{ paddingX: "8px" }}>
							<SectionTitle title={t("DM.RESERVATION_INFO")} />
						</Box>
					</Box>
				)}

				<RenderRow
					label={t("DM.RESERVATION_NUMBER")}
					value={DMDataReservationRequestValue?.reservation.property_id}
					hasCopy
					onCopy={() =>
						handleCopyClick(
							DMDataReservationRequestValue?.reservation.property_id
						)
					}
				/>
				<RenderRow
					label={t("DM.CHANNLE_NUMBER")}
					value={DMDataReservationRequestValue?.reservation.property_id}
					hasCopy
					onCopy={() =>
						handleCopyClick(
							DMDataReservationRequestValue?.reservation.property_id
						)
					}
				/>
				<RenderRow
					label={t("DM.RESERVATTION_DATE")}
					value={dayjs
						.tz(start_dateTimeZone, currantTimeZone)
						.format("YYYY-MM-DD")}
					hasCopy={false}
					onCopy={() => handleCopyClick()}
				/>
				<Separator />
				<Box sx={{ px: "8px" }}>
					<Box sx={{ display: "flex", justifyContent: "flex-start" }}>
						<Typography
							sx={{
								color: "#8E98A1",
								fontWeight: 400,
								fontSize: "12px",
								lineHeight: "14.4px",
							}}
						>
							{t("DM.GUEST")}
						</Typography>
						<Box sx={{ width: 70 }} />
						<Typography
							sx={{
								color: "#8E98A1",
								fontWeight: 400,
								fontSize: "12px",
								lineHeight: "14.4px",
							}}
						>
							{t("DM.PROPERTY")}
						</Typography>
					</Box>
				</Box>
				<Box sx={{ px: "8px" }}>
					<Box sx={{ display: "flex", justifyContent: "flex-start" }}>
						<Box>
							<DmGuest />
							<Typography
								sx={{
									color: "#0F172A",
									fontWeight: 400,
									fontSize: "14px",
									lineHeight: "16.4px",
								}}
							>
								{DMDataReservationRequestValue?.customer?.name}
							</Typography>
						</Box>
						<Box sx={{ width: 60 }} />
						<Box>
							<DmProperty />
							<Typography
								sx={{
									color: "#0F172A",
									fontWeight: 400,
									fontSize: "14px",
									lineHeight: "16.4px",
								}}
							>
								{DMDataReservationRequestValue?.reservation?.propertyName ?? (
									<Box
										sx={{ borderTop: "1px solid black", width: 20, mt: 1 }}
									/>
								)}
							</Typography>
						</Box>
					</Box>
				</Box>
				<Separator />
				<DmCalendar style={{ marginRight: "8px", marginLeft: "8px" }} />
				<SectionTitle title='الوقت والتاريخ' />
				<DateTimeSection
					first={`${t("DM.LOGIN")}:  ${dayjs
						.tz(start_dateTimeZone, currantTimeZone)
						.format("YYYY-MM-DD")}`}
					second={dayjs
						.tz(start_dateTimeZone, currantTimeZone)
						.format("h:mm A")}
				/>
				<DateTimeSection
					first={`${t("DM.LOGOUT")}:  ${dayjs
						.tz(end_dateTimeZone, currantTimeZone)
						.format("YYYY-MM-DD")}`}
					second={dayjs.tz(end_dateTimeZone, currantTimeZone).format("h:mm A")}
				/>
				<Separator />
				<Box sx={{ px: "8px" }}>
					<DmMoneys style={{ marginRight: "8px", marginLeft: "8px" }} />
					<SectionTitle title={t("DM.PRICING")} />
					<RenderRow
						label={t("DM.TOTAL_PRICE")}
						value={`${DMDataReservationRequestValue?.reservation.totalPrice} ${t("GENERAL.SR")}`}
						hasCopy={false}
					/>

					<RenderRow
						label={t("DM.TOURISS_TAXES")}
						value={`${DMDataReservationRequestValue?.reservation.pass_through_tax_amount_paid_to_host_accurate} ${t("GENERAL.SR")}`}
						hasCopy={false}
					/>
					<RenderRow
						label={t("DM.CANCELATION_BACK")}
						value={`${DMDataReservationRequestValue?.reservation.listing_cancellation_host_fee_accurate} ${t("GENERAL.SR")}`}
						hasCopy={false}
					/>
					<RenderRow
						label={t("DM.CANCELL_FEES")}
						value={`${DMDataReservationRequestValue?.reservation.listing_cancellation_host_fee_accurate} ${t("GENERAL.SR")}`}
						hasCopy={false}
					/>
					<RenderRow
						label={t("DM.HOST_TAXES")}
						value={`${DMDataReservationRequestValue?.reservation.expected_payout_amount_before_taxes_accurate} ${t("GENERAL.SR")}`}
						hasCopy={false}
					/>
					<RenderRow
						label={t("DM.CLEAN_FESS")}
						value={`${0} ${t("GENERAL.SR")}`}
						hasCopy={false}
					/>
				</Box>
			</Box>
		)
	}
	return (
		<PropertyInformationWrapper>
			<Snackbar
				open={snackOpen}
				autoHideDuration={6000}
				onClose={() => {
					setSnackOpen(false)
				}}
				message=''
				action={action}
				anchorOrigin={{ vertical: "top", horizontal: "left" }}
				sx={{
					position: "absolute",
					backgroundColor: "#5A3FFE",
					borderRadius: 20,
				}}
				ContentProps={{
					sx: {
						background: "#5A3FFE",
						borderRadius: 20,
					},
				}}
			/>
			{isOverlayOpen ? (
				<Overlay>
					<RenderDetails isOverlay />
				</Overlay>
			) : null}

			<SectionTitle title={t("DM.RESERVATION_INFO")} />
			<RenderDetails />
			<div className='conatainerData'>
				{resolved ? null : isAltration ? (
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "space-between",
							mt: 2,
						}}
					>
						<Box
							onClick={() =>
								mutateAlternative({ requestId: alterationId, accept: "accept" })
							}
							sx={{
								cursor: "pointer",
								width: "150px",
								height: "40px",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								color: "white",
								fontWeight: 500,
								fontSize: "14px",
								lineHeight: "20px",
								backgroundColor: "#5B3FFF",
								borderRadius: "8px",
							}}
						>
							قبول الحجز
						</Box>
						<Box sx={{ width: 10 }} />
						<Box
							onClick={() =>
								mutateAlternative({
									requestId: alterationId,
									accept: "decline",
								})
							}
							sx={{
								cursor: "pointer",
								width: "150px",
								height: "40px",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								color: "#D70015",
								fontWeight: 500,
								fontSize: "14px",
								lineHeight: "20px",
								backgroundColor: "#FFE0E3",
								borderRadius: "8px",
							}}
						>
							{t("DM.REJECT")}
						</Box>
					</Box>
				) : (
					<>
						<div className='containerActions'>
							<button onClick={() => onSubmit()}>
								{t("DM.AcceptingBooking")}
							</button>
						</div>
						<div className='containerCancelReservetion'>
							<div
								className='ContainerLabel'
								onClick={() => setIsPopupOpen(true)}
							>
								<p>{t("DM.mess")}</p>
							</div>
						</div>
					</>
				)}
			</div>
		</PropertyInformationWrapper>
	)
}
const Separator = () => {
	return (
		<Box
			sx={{
				width: "100%",
				border: "0.7px solid #EAEAFD",
				mb: 3,
			}}
		/>
	)
}
const RenderRow = ({
	label = "",
	value = "",
	hasCopy = false,
	onCopy = () => { },
}) => {
	return (
		<Box sx={{ display: "flex", alignItems: "center", px: "8px" }}>
			<Typography
				sx={{
					color: "#0F172A",
					fontWeight: 400,
					fontSize: "14px",
					lineHeight: "16.8px",
				}}
			>
				{label}
			</Typography>
			<Typography
				sx={{
					color: "#5B3FFF",
					fontWeight: 400,
					fontSize: "14px",
					lineHeight: "16.8px",
					mx: 100,
				}}
			>
				{value}
			</Typography>
			{hasCopy && (
				<Box sx={{ mt: -1.4, cursor: "pointer" }} onClick={onCopy}>
					<DmCopy style={{ width: "16px" }} />
				</Box>
			)}
		</Box>
	)
}
const SectionTitle = ({ title = "" }) => {
	return (
		<Typography
			sx={{
				color: "#8E98A1",
				fontWeight: 400,
				fontSize: "12px",
				lineHeight: "14.4px",
				px: "8px",
			}}
		>
			{title}
		</Typography>
	)
}
const DateTimeSection = ({
	first = "الدخول: 04 مايو 2024",
	second = "2:00 م",
}) => {
	return (
		<Box sx={{ display: "flex", alignItems: "center", mb: 2, px: "8px" }}>
			<Box sx={{ height: 2, backgroundColor: "#5B3FFF" }} />
			<Typography
				sx={{
					color: "#0F172A",
					fontWeight: 500,
					fontSize: "12px",
					lineHeight: "14.4px",
				}}
			>
				{first}
			</Typography>
			<Box
				sx={{
					border: "1px solid #E5E7EB",
					borderRadius: "12px",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					height: "25px",
					mt: -2.5,
					mx: 1.5,
				}}
			>
				<Typography
					sx={{
						color: "#8E98A1",
						fontWeight: 500,
						fontSize: "12px",
						pt: 2.5,
						px: 1,
					}}
				>
					{second}
				</Typography>
			</Box>
		</Box>
	)
}
