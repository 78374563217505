import { PropsWithChildren } from "react"
import { WhiteCardWrapper } from "./styles"
interface IProps {
	height?: string
	padding?: string
}
export default function WhiteCard({
	children,
	height,
	padding,
}: PropsWithChildren<IProps>) {
	return (
		<WhiteCardWrapper $height={height} $padding={padding}>
			{children}
		</WhiteCardWrapper>
	)
}
