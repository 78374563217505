import { Dispatch, SetStateAction, useContext, useEffect } from "react"
import { PopupDeleteCategoriesWrapper } from "./styles"
import { IoClose } from "react-icons/io5"
import { FaRegTrashAlt } from "react-icons/fa"
import { useOwnerManagment } from "@services"

import { ToastMessageContext } from "@context/toast-message-cotnext"
import { useQueryClient } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"


interface IProps {
	onDeleteSuccess:()=>void
	setDeleteCategories: Dispatch<SetStateAction<boolean>>
	recordData:
		| {
				id: number
				
		  }
		| undefined
}
export default function PopupDeleteCategories({
	setDeleteCategories,
	recordData,
	onDeleteSuccess
	
}: IProps) {
	const {
		t,
	} = useTranslation();
	const { showSuccess,showError } = useContext(ToastMessageContext)
	const {DeleteOwnerMutate} =useOwnerManagment()
	const queryClient = useQueryClient()

	const { mutate,data,error} = DeleteOwnerMutate()
	useEffect(()=>{
		if(data?.data?.isSuccess){
			onDeleteSuccess()
		}
	},[data])
	useEffect(()=>{
		error &&showError(t('OwnerManagement.systemError'))
	},[error])

	const deleteCategoryFun = async () => {
		if (!recordData?.id) return
		 mutate(recordData?.id)
		showSuccess(data.data.result)
		setDeleteCategories(false)
		
	}
	return (
		<PopupDeleteCategoriesWrapper>
				
			
			<div className='popupContainer'>
				<div>
					<button onClick={() => setDeleteCategories(false)}>
						<IoClose />
					</button>
				</div>
				<div>
					<div>
						<FaRegTrashAlt />
					</div>
					<p>{t('OwnerManagement.askRemove')}</p>
				</div>
				<div>
					<button type='button' onClick={() => setDeleteCategories(false)}>
						{t('OwnerManagement.cancel')}
					</button>
					<button type='button' onClick={() => deleteCategoryFun()}>
						{t('OwnerManagement.delete')}
					</button>
				</div>
			</div>
		</PopupDeleteCategoriesWrapper>
	)
}