import {  useEffect, useMemo, useState } from 'react';
import { Box, Typography, Button,TextField } from '@mui/material';

import LinkingComponent from './LinkingComponent';
import LinkError from './LinkError'; 
import SmallBooking from '@assets/svg/smallBooking.svg?react';
import CloseSquare  from '@assets/svg/close-square.svg?react';
import { LoadingWrapper } from '@components';
import { useProperty } from '@services';
import { useTranslation } from 'react-i18next';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const RenderSuccess = ({onDone=()=>{}})=>{
  const {
    i18n: { language },
    t,
} = useTranslation();
  return <Box
  sx={{
    flex: 1,
    pt: 4,
    pb: 2,
    gap: 2,
    display: 'flex',
    flexDirection: 'column',
    svg: {
      width: '80px',
      height: '80px',
      color: '#16AE26',
    },
  }}
>
  <Box
    sx={{
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      gap: 2,
      justifyContent: 'center',
      flexDirection: 'column',
    }}
  >
    <CheckCircleIcon />
    {t('ADD_PROPERTY.LINK_SUCCESS', { channel:"BOOKING" })}
    <Box sx={{ textAlign: 'center' }}>
    <Button size={'small'} variant={'contained'} onClick={() =>onDone()}>
    {t('COMMON.NEXT')}
    </Button>
  </Box>
  </Box>
</Box>
}


const BookingPropertiesList = ({ hotelid = '', onNext = () => { },onDone=()=>{},onLinkSuccess=()=>{} }) => {
    const [checked, setChecked] = useState<number[]>([])
    const { GetBookingMappingDetailsMutation, saveBookingChannelMutation } = useProperty();
    const [pricing,setPricing]=useState<any>([])
    const {
        i18n: { language },
        t,
    } = useTranslation();
  
    const { mutate, isPending: isFetchingRooms, data: Rooms } = GetBookingMappingDetailsMutation({
      onError: () => { },
      onSuccess: () => { }
    })
    const { mutate: SaveChannel, isPending: isSaving, data, isError, reset } = saveBookingChannelMutation({
      onError: () => onNext(),
      onSuccess: () =>onNext()
    })
    useEffect(() => {
      mutate(hotelid)
    }, [])
    const body = useMemo(() => {
      let ratePlans: any = []
      let body = {
        "currency": 'GBP',
        "hotelId": hotelid
  
      }
      pricing.map((x: number,i:number) => {
        let curr = Rooms?.data?.rooms[i]
        ratePlans.push(
          {
            "roomTypeCode": Number(curr?.id), 
            "ratePlanCode": Number(curr?.rates[0]?.id),
            "occupancy": Number(curr?.rates[0]?.max_persons),
            "pricingType": curr?.rates[0]?.pricing,
            name: curr?.title,  
            "price": Number(x),
          }
        )
      }) 
      return { ...body, ratePlans }
    }, [Rooms,pricing])
    if (isError) {
      return <LinkError channel='Booking' reset={() => {
        reset()
        SaveChannel(body)
      }} />
  
    }
    if (isSaving) return <LinkingComponent channelIcon={<SmallBooking />} />
    if(data?.isSuccess || data?.response?.data ){
      return    <RenderSuccess onDone={onDone} />
    }
  
    return (
      <LoadingWrapper loading={isFetchingRooms || isSaving  } >
  
        <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column',height:'100%',minHeight:'80vh' }} >
          <Typography sx={{
            color: 'rgba(15, 23, 42, 1)',
            fontWeight: 400,
            fontSize: '20px',
            lineHeight: '24px',
            mt: 2
          }} >
         {t('BOOKING.ENTER_PRICE')}
          </Typography>
          <Typography sx={{
            color: 'rgba(142, 152, 161, 1)',
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '20px',
          }} >
         {t('BOOKING.TO_COMPLETE')}
          </Typography>
          <Typography sx={{
            color: 'rgba(51, 24, 96, 1))',
            fontWeight: 700,
            fontSize: '16px',
            lineHeight: '24px',
            mt:3,
            mb:1
          }} >
        {t('BOOKING.HOTEL_ID')}
          </Typography>
 
          <Box sx={{
            width:'319px',
            height:'44px',
            borderRadius:'4px',
            border:'1px solid rgba(229, 231, 235, 1)',
            display:'flex',
            justifyContent:'space-between',
            alignItems:'center',
            paddingX:'10px'
          }} >
            
          <Typography sx={{
            color:'rgba(142, 152, 161, 1)',
            fontWeight:500,
            fontSize:'16px',
            lineHeight:'24px'
          }} >
            {hotelid} 
          </Typography>
          <CloseSquare />
          </Box>
       
          <Box sx={{
          
            mt: 2,
            flex: 1,
          }} >
            {
              Rooms?.data?.rooms?.map((x: any, i: number) => {
                const isSelected = checked.includes(i)
                return (
                  <Box sx={{mt:i>0?2:0}} >
                    <Typography sx={{
                      color: 'rgba(51, 24, 96, 1)',
                      fontWeight: 700,
                      fontSize: '14px',
                      lineHeight: '17px',
                      mx:1,
                      mb:0.3
                    }} >
                      {x?.title}
                    </Typography>
                    <TextField
              sx={_inputStyle}
              onChange={e => {
                let newPrice = [...pricing]
                newPrice[i]=e.target.value 
                setPricing(newPrice)
                reset()
              }}
              type='number'
              value={pricing[i]??''}
              placeholder={t('COMMON.ENTERT_PRICING')}
           
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
                  </Box> 
                )
              })
            }
          </Box>
          {isFetchingRooms ? null : <Box sx={{ alignItems: 'center', }}>
            <Button size={'small'} variant={'contained'} onClick={() => {
              SaveChannel(body)
            }} disabled={pricing.length == 0 || pricing.length==1 && pricing[0]==0}>
                {t('BOOKING.COMPLETE_LINKING')}
            </Button>
          </Box>}
         
        </Box>
      </LoadingWrapper>
  
    )
  }
  export default BookingPropertiesList

  const _inputStyle = {
    border: ' 1px solid rgba(234, 237, 241, 1)',
    borderRadius: '24px',
    width: '337px',
    height: '47px',
    px: 2,
    pb: -2,
    '& .MuiInput-underline:after': {
      borderBottomColor: 'yellow',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white',
      },
      '&:hover fieldset': {
        borderColor: 'white',
      },
      '&.Mui-focused fieldset': {
        border: '0px',
      },
    },
  }