import { CustomButtonWrapper } from "./styles"
import { FaHandPointDown } from "react-icons/fa"

export interface IProps {
	type?: "button" | "submit" | "reset"
	label?: string
	fontSize?: string
	customPadding?: string
	withBG?: boolean
	withIcone?: boolean
	iconPosition?: "rtl" | "ltr"
	customIcon?: JSX.Element
	onClick?: () => void
	form?: string
	borderRadius?: string
	borderColor?: string
	disabled?: boolean
	customBG?: string
	customColor?: string
}
export default function CustomButton({
	type = "button",
	label,
	withBG = false,
	withIcone = false,
	customPadding,
	fontSize,
	customIcon,
	iconPosition,
	form,
	borderRadius,
	disabled,
	borderColor,
	customBG,
	customColor,
	...rest
}: IProps) {
	return (
		<CustomButtonWrapper
			type={type}
			$withBG={withBG}
			$customPadding={customPadding}
			$fontSize={fontSize}
			$iconPosition={iconPosition}
			$borderRadius={borderRadius}
			form={form}
			{...rest}
			disabled={disabled}
			$borderColor={borderColor}
			$customBG={customBG}
			$customColor={customColor}
		>
			{label && label}
			{withIcone && <span>{customIcon && customIcon}</span>}
		</CustomButtonWrapper>
	)
}
