import { Box } from '@mui/material';
const Indicators = ({ activeIndx = 0, length = 3 }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
      {Array.from({ length }).map((c, i) => (
        <Box sx={{ marginX: 0.2 }}>
          {activeIndx == i ? (
            <svg xmlns='http://www.w3.org/2000/svg' width='34' height='4' viewBox='0 0 34 4' fill='none'>
              <path d='M2 2L32 2' stroke='#130080' strokeWidth='3' strokeLinecap='round' />
            </svg>
          ) : (
            <svg xmlns='http://www.w3.org/2000/svg' width='12' height='4' viewBox='0 0 12 4' fill='none'>
              <path d='M2 2H10' stroke='#F4F4F5' strokeWidth='3' strokeLinecap='round' />
            </svg>
          )}
        </Box>
      ))}
    </Box>
  );
};

export default Indicators;
