export const FORM_REGEX_VALIDATORS = {
	email: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
	phone: /^(05)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/i,
	saudiNationalId: /^[1|2]{1}[0-9]{9}$/i,
	textOnly:
		/^[A-Za-z\u0600-\u065F\u066A-\u06EF\u06FA-\u06FF\u0600-\u065F\u066A-\u06EF\u06FA-\u06FF\s]+$/g,
	textArabicOnly: /^[\u0621-\u064A\u0660-\u0669 ]+$/g,
	numbersOnly: /^[0-9]*$/g,
	digitsDecimal: /^(0|[1-9]\d{0,8})(\.\d{0,9})?$/,
}
