import { FC } from 'react';
import { Box } from '@mui/material';

const StepProgress: FC<{ step: number; steps: number; height?: number }> = ({ steps, step, height = 4 }) => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: `repeat(${steps},1fr)`,
        height: `${height}px`,
        flexShrink: 0,
        gap: '4px',
        '.MuiBox-root': {
          width: '100%',
          height: '100%',
          borderRadius: '100px',
        },
      }}
    >
      {Array(steps)
        .fill('')
        .map((_, i) => {
          return <Box sx={{ background: step > i ? '#5B3FFF' : '#EAEDF1' }} key={i}></Box>;
        })}
    </Box>
  );
};
export default StepProgress;
