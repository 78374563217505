import React, { FC } from 'react';
import { IMessage, ITemplate } from '@interfaces';
import { Box, Modal, Typography } from '@mui/material';
import { modalBoxStyle, modalContainerStyle } from '@utils';
import { PreviewTemplate } from '@components';

const ViewMessageModal: FC<{
  open: boolean;
  data: ITemplate;
  welcome: IMessage[];
  handleClose: () => void;
}> = ({ open, welcome = [], handleClose, data }) => {
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        sx={modalContainerStyle}
        aria-labelledby='add-message-modal-title'
        aria-describedby='add-message-modal-description'
      >
        <Box sx={{ ...modalBoxStyle, height: '80vh' }}>
          <div className='flex justify-between items-center mb-3'>
            <Typography id='modal-modal-title' className='text-[#27272A]'>
              {data?.name}
            </Typography>
          </div>
          <PreviewTemplate data={data} welcome={welcome} />
        </Box>
      </Modal>
    </>
  );
};

export default ViewMessageModal;
