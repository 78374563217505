import CustomButton from "@Common/CustomButton"
import { SectionHeaderWrapper } from "./styles"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

export default function SectionHeader() {
	const { t } = useTranslation()
	const navigate = useNavigate()
	return (
		<SectionHeaderWrapper>
			<div>
				<p>{t('OwnerManagement.Title')}</p>
				<p>{t('OwnerManagement.subTitle')}</p>
			</div>
			<div>
				<CustomButton
					label={t('OwnerManagement.add')}
					withBG
					customPadding='8px 90px'
					onClick={() => navigate("/add-owner")}
				/>
			</div>
		</SectionHeaderWrapper>
	)
}