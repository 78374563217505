import styled from "styled-components"

export const PopupConfirmationWrapper = styled.div`
	width: 100dvw;
	height: 100dvh;
	background-color: rgba(0, 0, 0, 0.5);
	position: fixed;
	z-index: 9999;
	left: 0;
	top: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	.containerCard {
		background-color: #fff;
		border-radius: 16px;
		padding: 24px;
		box-shadow: 2px 4px 38px 0px #0000001a;
		p {
			margin: 0;
		}
		.header {
			color: #435363;
			font-size: 24px;
			font-weight: 400;
			line-height: 28.8px;
			margin-bottom: 24px;
		}
		.body {
			color: #8e98a1;
			font-size: 16px;
			font-weight: 400;
			line-height: 19.2px;
			margin-bottom: 24px;
		}
		.footer {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			gap: 24px;
			button {
				border: none;
				padding: 17px 20px;
				border-radius: 20px;
				font-size: 16px;
				font-weight: 700;
				line-height: 19.2px;
				color: #435363;
				cursor: pointer;
				&:first-child {
					background-color: #fff;
					border: 1px solid #8e98a1;
				}
				&:last-child {
					background-color: #d70015;
					color: #fff;
				}
			}
		}
	}
`
