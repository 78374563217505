import { useApi } from './useApi';
import { AllCategoriesURL, getMessagesURL } from '../utils/constants';
import { useQuery } from '@tanstack/react-query';
import { IMessage } from '@interfaces';

const useMessaging = () => {
  const { apiPrivate } = useApi();

  const getAllMessages = async (): Promise<IMessage[]> => {
    const { data } = await apiPrivate.get(getMessagesURL);
    return data;
  };

  const allMessagesQuery = () =>
    useQuery({
      queryKey: [getMessagesURL],
      queryFn: getAllMessages,
    });

  return { allMessagesQuery };
};

export { useMessaging };
