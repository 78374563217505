import styled from "styled-components"

export const StepTwoWrapper = styled.div`
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	form {
		width: 100%;
		.containerInfo {
			margin-bottom: 32px;
			p {
				margin: 0;
				&:first-child {
					font-size: 32px;
					font-weight: 700;
					line-height: 38.4px;
					color: #331860;
				}
			}
		}
		.containerSearchInput {
			margin-bottom: 32px;
		}
	}
`
export const PorpertesCardWrapper = styled.div`
	.containerCard {
		padding: 8px;
		.wrapper {
			.checkbox-input {
				clip: rect(0 0 0 0);
				clip-path: inset(100%);
				height: 1px;
				overflow: hidden;
				position: absolute;
				white-space: nowrap;
				width: 1px;
				&:checked + .checkbox-tile {
					border-color: #5b3fff;
				}
			}

			.checkbox-tile {
				padding: 8px;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				border-radius: 0.5rem;
				border: 2px solid #b5bfd9;
				background-color: #fff;
				box-shadow: 0 5px 10px rgba(#000, 0.1);
				transition: 0.15s ease;
				cursor: pointer;
				position: relative;
				.checkbox-image {
					transition: 0.375s ease;
					overflow: hidden;
					margin-bottom: 8px;
					height: 164px;
					img {
						border-radius: 8px;
						width: 100%;
						height: 100%;
					}
				}

				.checkbox-label {
					width: 100%;
					display: flex;
					justify-content: flex-start;
					align-items: flex-start;
					gap: 8px;
					flex-direction: column;
					span {
						width: 100%;
						&:first-child {
							font-size: 14px;
							font-weight: 700;
							line-height: 16.8px;
							color: #331860;
						}
						&:last-child {
							font-size: 12px;
							font-weight: 400;
							line-height: 14.4px;
							color: #625783;
						}
					}
				}
			}
		}
	}
`
