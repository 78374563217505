import HomeIcon from "@assets/svg/HomeIcon.svg?react"
import { useTranslation } from "react-i18next"
import { CustomDivider, HeaderSection, ReservationInfoWrapper } from "./styles"
import CustomSelect from "@Common/CustomSelect"
import { Grid } from "@mui/material"
import CustomDatePicker from "@Common/CustomDatePicker"
import Input from "@Common/input"

interface IProps {
	ReservationInfoData: {
		checkIn: string | undefined
		checkOut: string | undefined
		propertyName: string | undefined
		reservationId: string | undefined
	}
	dataList: {
		value: string
		label: string
	}[]
}
export default function ReservationInfo({
	ReservationInfoData,
	dataList,
}: IProps) {
	const { t } = useTranslation()

	return (
		<ReservationInfoWrapper>
			<div>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<HeaderSection>
							<HomeIcon />
							<p>{t("MANUALRESERVATION.PROPERTYINFORMATION")}</p>
						</HeaderSection>
					</Grid>
					<Grid item xs={12} md={6}>
						<CustomSelect
							data={dataList ?? []}
							label={t("COMMON.PROPERTY")}
							name='propertyId'
							registerOptions={{
								required: {
									value: true,
									message: t("COMMON.REQUIRED"),
								},
							}}
						/>
						<div className='reservationNum'>
							<p>
								<span>{t("NEW_LOCKS.RESERVATION_NUMBER")} :</span>{" "}
								<span>{ReservationInfoData.reservationId}</span>
							</p>
						</div>
					</Grid>
					<CustomDivider />
					<Grid item xs={12}>
						<HeaderSection>
							<HomeIcon />
							<p>{t("MANUALRESERVATION.TIMEANDDATEOFRESERVATION")}</p>
						</HeaderSection>
					</Grid>
					<Grid item xs={12} md={6}>
						<CustomDatePicker
							disablePast
							name='checkIn'
							label={t("MANUALRESERVATION.BOOKINGSTARTDATE")}
							format='YYYY-MM-DD'
							registerOptions={{
								required: {
									value: true,
									message: t(
										"MANUALRESERVATION.PLEASESELECTTHESTARTDATEOFTHERESERVATION"
									),
								},
							}}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<Input
							type='time'
							name='checkInTime'
							label={t("MANUALRESERVATION.BOOKINGSTARTTIME")}
							registerOptions={{
								required: {
									value: true,
									message: t(
										"MANUALRESERVATION.PLEASESELECTTHESTARTTIMEOFTHERESERVATION"
									),
								},
							}}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<CustomDatePicker
							disablePast
							name='checkOut'
							label={t("MANUALRESERVATION.BOOKINGENDDATE")}
							format='YYYY-MM-DD'
							registerOptions={{
								required: {
									value: true,
									message: t(
										"MANUALRESERVATION.PLEASESELECTTHEENDDATEOFTHERESERVATION"
									),
								},
							}}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<Input
							type='time'
							name='checkOutTime'
							label={t("MANUALRESERVATION.BOOKINGENDTIME")}
							registerOptions={{
								required: {
									value: true,
									message: t(
										"MANUALRESERVATION.PLEASESELECTTHEENDTIMEOFTHERESERVATION"
									),
								},
							}}
						/>
					</Grid>
				</Grid>
				<CustomDivider />{" "}
			</div>
		</ReservationInfoWrapper>
	)
}
