/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useContext, useEffect } from 'react';
import { Box, Button, Chip, IconButton, Modal, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import BookingLogo from '@assets/svg/BookingLogo.svg?react';

import { IModalProps } from '@interfaces';
import GATHREN from '@assets/svg/gathren.svg?react';

import ChannelAgoda from '@assets/svg/ChannelAgoda.svg?react';
import ChannelBooking from '@assets/svg/ChannelBooking.svg?react';
import ChannelGatherin from '@assets/svg/ChannelGatherin.svg?react';
import SoonFlag from '@assets/svg/soon.svg?react';

import { useProperty } from '@services';
import AIRBNB from '@assets/svg/airbnb.svg?react';
import { ToastMessageContext } from '@context';
import useBreakPoinst from '@hooks/useBreakPoinst';

const UpdatePropertiesModal: FC<IModalProps> = ({ handleClose, open, data, addNewChannel }) => {
  const { isPhone } = useBreakPoinst()
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isPhone ? '85%' : 495,
    bgcolor: 'background.paper',
    color: '#261B6A',
    boxShadow: 24,
    p: 3,
    borderRadius: 5,
  };
  const channelWrapperStyle = { height: 50, width: 200, position: 'relative', cursor: 'pointer' };
  const { airbnbUnitId, gathrenUnitId } = data

  const {
    i18n: { language },
    t,
  } = useTranslation();

  const { showSuccess, showError } = useContext(ToastMessageContext);

  const { control, handleSubmit, reset, setValue } = useForm<any>({
    mode: 'onChange',
  });

  const { updatePropertyMutate } = useProperty();

  const { mutate: updateProperty } = updatePropertyMutate({
    onSuccess: () => {
      closeModal();
      showSuccess(t('PROPERTIES_MANAGEMENT.UPDATE_SUCCESS'));
    },
    onError: () => {
      showError(t('ERRORS.SOMETHING_WENT_WRONG'));
    },
  });

  const onSubmit = (body: any) => {
    const payload: any = {
      ...body,
      propertyId: data?.propertyId,
    };

    updateProperty(payload);
  };

  const closeModal = () => {
    handleClose();
    reset({});
  };

  useEffect(() => {
    if (data) setValue('propertyName', data?.propertyName);
  }, [data]);

  return (
    <>
      <Modal
        open={open}
        onClose={closeModal}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <div className='flex justify-between items-center mb-3'>
            <Typography id='modal-modal-title' className='text-[#27272A]'>
              {t('PROPERTIES_MANAGEMENT.TITLE')}
            </Typography>

            <IconButton aria-label='close' onClick={closeModal} size='small'>
              <CloseIcon className='text-[#27272A] ' />
            </IconButton>
          </div>
          <form className={addNewChannel?'':'mt-10' }onSubmit={handleSubmit(onSubmit)}>
            <>
              <Controller
                name='propertyName'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <>
                    <div className='text-[#261B6A] font-normal'>
                      <Box  sx={{color:'#261B6A',fontSize:'20px',fontWeight:300}} >{t('PROPERTIES_MANAGEMENT.PROPERTY_NAME')}</Box>
                      <div>
                        <TextField
                          disabled={addNewChannel}
                          id='propertyName'
                          className='w-full rounded-3xl'
                          value={value}
                          InputProps={{ sx: { borderRadius: 24 } }}
                          style={{
                            borderRadius: '24px !important',
                            color: '#9CA3AF',
                            borderColor: '#9CA3AF',
                          }}
                          placeholder={`${t('PROPERTIES_MANAGEMENT.PROPERTY_NAME')}`}
                          variant='outlined'
                          onChange={onChange}
                        />
                      </div>
                    </div>
                  </>
                )}
              />
              {!addNewChannel && <div className='text-[#261B6A] font-normal mt-4'>
                <div>{t('PROPERTIES_MANAGEMENT.ACTIVATED_CHANNELS')}</div>
                <div className='mt-4'>
                  <div className='flex gap-2'>
                    <div>
                      {data?.gathrenUnitId && (
                        <Chip
                          avatar={<GATHREN />}
                          label={`${t('GATHERIN')}`}
                          variant='outlined'
                          style={{
                            color: '#FAFAFA',
                            backgroundColor: '#5A409B',
                            paddingInlineStart: '6px',
                          }}
                        />
                      )}
                    </div>
                    <div>
                      {data?.airbnbUnitId && (
                        <Chip
                          avatar={<AIRBNB />}
                          label={`${t('AIRBNB')}`}
                          variant='outlined'
                          style={{
                            color: '#FAFAFA',
                            backgroundColor: '#FF5A5F',
                            paddingInlineStart: '6px',
                          }}
                        />
                      )}
                    </div>
                    <div>
                      {data?.bookingUnitId && (
                        <Chip
                          avatar={<BookingLogo />}
                          label={`Booking`}
                          variant='outlined' 
                          style={{
                            color: 'white',
                            backgroundColor: 'rgb(1,59,149)',
                            paddingInlineStart: '6px',
                          }}
                        />
                      )}
                    </div>
                  
                  </div>
                </div>
              </div>}
              {addNewChannel && (
                <div className='text-[#261B6A] font-normal mt-4'>
                  <Box sx={{color:'#261B6A',fontSize:'20px',fontWeight:300}} >{t('PROPERTIES_MANAGEMENT.CHANNEL_AVAILABLE_FO_CONNECTION')}</Box>
                  <div className='mt-1'>
                    <Box className='flex'>
                      {!gathrenUnitId ? <Box sx={channelWrapperStyle}>
                        <ChannelGatherin style={{ height: 50, width: 200 }} />
                      </Box> : null}
                      <Box sx={channelWrapperStyle}>
                        <SoonFlag
                          style={{ position: 'absolute', height: 30, width: 100, zIndex: 1000, top: 2, right: -15 }}
                        />
                        <Box sx={channelWrapperStyle}>
                          <ChannelBooking style={{ width: '100%', height: '100%' }} />
                        </Box>
                      </Box>
                    </Box>
                    <Box sx={channelWrapperStyle}>
                      <SoonFlag
                        style={{ position: 'absolute', height: 34, width: 100, zIndex: 1000, top: 13, right: -15 }}
                      />
                      <ChannelAgoda style={{ height: 55, width: 200, marginTop: 10 }} />
                    </Box>
                  </div>
                </div>
              )}
              {addNewChannel && (
                <div className='mt-6  flex '>
                  <Button
                    sx={{ paddingY: 1.5 }}
                    className='w-full'
                    variant={'contained'}
                    size={'large'}
                    onClick={handleClose}
                  >
                    {t('PROPERTIES_MANAGEMENT.CONNECT')}
                  </Button>
                </div>
              )}
              {addNewChannel && <Box sx={{ color: '#A1A1AA', fontWeight: 300, fontSize: 14,marginTop:2,textAlign:'center' }} >
{t('PROPERTIES_MANAGEMENT.UPDATE_HINT')}
              </Box>}
              {!addNewChannel && (
                <div className='mt-6 mx-4 mt-2'>
                  <Button className='w-full' variant={'contained'} size={'large'} type='submit'>
                    {t('SAVE')}
                  </Button>
                </div>
              )}
            </>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default UpdatePropertiesModal;
