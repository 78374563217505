import { FC, useContext, useState } from 'react';
import { Box, Button, IconButton, Modal, Typography, Slide } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

import useBreakPoinst from '@hooks/useBreakPoinst';


type IModalProps = {
    handleClose: () => void;
    open: boolean;
    onDelete: () => void;

}

const DeleteLockModal = ({ handleClose, open, onDelete }:IModalProps) => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 495,
        bgcolor: 'background.paper',
        color: '#261B6A',
        boxShadow: 24,
        p: 3,
        borderRadius: 5,
    };

    const {
        i18n: { language },
        t
    } = useTranslation();

    const { isPhone } = useBreakPoinst()


    const closeModal = () => {
        handleClose();
    };
    return (
        <>
            <Modal
                open={open}
                onClose={closeModal}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
            >
                <Slide direction="up" style={{
                    position: 'absolute',
                    top: '20%',
                    left: isPhone ? '10%' : '30%',
                    transform: 'translate(-50%, -50%)',
                    width: isPhone ? '80%' : 495,
                }} in={open} mountOnEnter unmountOnExit>

                     <Box sx={style}>
                        <div className='flex justify-between items-center mb-3'>
                            <Typography id='modal-modal-title' sx={{ color: '#27272A', fontSize: '20px', fontWeight: 700 }}>
                              {t("LOCKS.DISABLE_LOCK")}
                            </Typography>

                            <IconButton aria-label='close' onClick={closeModal} size='small'>
                                <CloseIcon className='text-[#27272A] ' />
                            </IconButton>
                        </div>

                        <div className='mt-10'>
                            <>
                                <Box sx={{ fontWeight: 400, fontSize: 16, color: '#8E98A1', lineHeight: '24px' }} >
                                    {t('LOCKS.DISABLE_HINT')}
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 3 }} >
                                    <Button sx={{
                                        ':hover': {
                                            bgcolor: 'white', // theme.palette.primary.main
                                            color: '#435363',
                                            opacity: 1
                                        }, backgroundColor: 'white', color: '#435363', border: '1px solid #435363', fontSize: '16px', fontWeight: 700, width: '154px', height: '44px'
                                    }} variant={'contained'} size={'large'} onClick={closeModal}>
                                       {t("LOCKS.BACK")}
                                    </Button>
                                    <Box width={30} />
                                    <Button sx={{
                                        ':hover': {
                                            bgcolor: '#D70015', // theme.palette.primary.main
                                            color: 'white',
                                        }, fontSize: '16px', fontWeight: 700, width: '154px', height: '44px', backgroundColor: '#D70015'
                                    }} variant={'contained'} size={'large'} onClick={onDelete}>
                                       {t("LOCKS.DISABLE")}
                                    </Button>
                                </Box>
                            </>
                        </div>
                    </Box>
                </Slide>
            </Modal>
        </>
    );
};

export default DeleteLockModal;
