import styled from "styled-components"

export const PopupEditCategoryWrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1300;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	form {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		.popupContainer {
			width: 60%;
			background-color: #fff;
			border-radius: 16px;
			padding: 32px;
			display: grid;
			grid-template-columns: 1fr;
			grid-template-rows: auto 1fr auto;
			gap: 12px;
			> div {
				p {
					margin: 0;
				}
				&:nth-child(1) {
					display: flex;
					justify-content: flex-end;
					align-items: center;
					button {
						font-size: 24px;
						display: flex;
						justify-content: flex-end;
						align-items: center;
						background-color: transparent;
						border: none;
						cursor: pointer;
						color: #27272a;
					}
				}
				&:nth-child(2) {
					p {
						color: #261b6a;
						font-family: Tajawal;
						font-size: 34px;
						font-weight: 700;
						line-height: 40.8px;
						text-align: right;
					}
				}
				&:nth-child(3) {
					margin-top: 50px;
					border-bottom: 1px solid #eaeafd;
					padding-bottom: 32px;
					label {
						p {
							font-size: 18px;
							font-weight: 700;
							line-height: 21.6px;
							color: #261b6a;
							margin-top: 15px;
						}
					}
				}
				&:nth-child(4) {
					padding-top: 24px;
					gap: 16px;
					> div {
						display: flex;
						justify-content: space-between;
						align-items: center;
						button {
							background: #fafafb;
							border: none;
							padding: 12px 16px;
							border-radius: 50px;
							color: #5b3fff;
							font-size: 14px;
							font-weight: 700;
							line-height: 16.8px;
							cursor: pointer;
						}
					}
					p {
						font-size: 12px;
						font-weight: 400;
						line-height: 14.4px;
						color: #000000;
					}
				}
			}
		}
	}
`
