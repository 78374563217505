import styled from "styled-components"

export const ReviewFormInputsWrapper = styled.div`
	.containerCard {
		.containerIcon {
			width: 20px;
			height: 20px;
			margin-bottom: 8px;
			color: #5b3fff;
			svg {
				width: 100%;
				height: 100%;
			}
		}
		.sectionInfo {
			color: #8e98a1;
			font-size: 16px;
			font-weight: 400;
			line-height: 19.2px;
			margin: 0;
			margin-bottom: 6px;
		}
		.containerData {
			p {
				margin: 0;
				font-size: 18px;
				font-weight: 400;
				line-height: 21.6px;
				margin-bottom: 6px;
				color: #0f172a;
				span {
					color: #5b3fff;
				}
			}
		}
	}

  .dividedLine{
    border-bottom: 1px solid #EAEDF1;
  }
`
