import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export default () => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.only('sm'));
  const isPhone = useMediaQuery(theme.breakpoints.only('xs'));
  const smallLapTop = useMediaQuery(theme.breakpoints.only('md'));
  const desktop = useMediaQuery(theme.breakpoints.only('lg'));
  const largeScreen = useMediaQuery(theme.breakpoints.only('xl'));
  return {
    isPhone,
    desktop: desktop || smallLapTop,
    largeScreen,
    isTablet,
  }; 
};
