import { useApi } from "../useApi"
import {
	GuestManagementUrl,
	GuestManagementClassificationUrl,
} from "../../utils/constants"
import { useMutation, useQuery } from "@tanstack/react-query"
import {
	IGuestManagement,
	IGuestManagementClassification,
	IUpdateGuests,
} from "./types"

const useGuestManagement = () => {
	const { apiPrivate } = useApi()

	const Guests = async (params: {
		pageSize: string
		pageNumber: string
		searchQuery?: string
	}) => {
		const response: { data: IGuestManagement } = await apiPrivate.get(
			GuestManagementUrl,
			{
				params,
			}
		)
		return response
	}
	const GuestManagementClassification = async () => {
		const response: { data: IGuestManagementClassification } =
			await apiPrivate.get(GuestManagementClassificationUrl)
		return response
	}

	const GuestsQuery = (data: {
		pageSize: string
		pageNumber: string
		searchQuery?: string
	}) =>
		useQuery({
			queryKey: [GuestManagementUrl, data],
			queryFn: () => Guests(data),
		})

	const GuestManagementClassificationQuery = () =>
		useQuery({
			queryKey: [GuestManagementClassificationUrl],
			queryFn: GuestManagementClassification,
		})



	const updateGuests = async ({ ...rest }: IUpdateGuests) => {
		let response
		response = await apiPrivate.put(`${GuestManagementUrl}`, { ...rest })
		return response
	}

	const updateGuestsMutate = () =>
		useMutation({
			mutationFn: ({ ...rest }: IUpdateGuests) => updateGuests({ ...rest }),
		})

	return {
		GuestsQuery,
		GuestManagementClassificationQuery,
		updateGuestsMutate,
	}
}
export { useGuestManagement }
