import { useMemo } from "react";
import { ChatMessagesWrapper } from "./styles";
import dayjs from "dayjs";
import { Box, Typography } from "@mui/material";
import { t } from "i18next";
function dateDiffInDays(a: Date, b: Date) {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}
interface IProps {
  allChatMessages: any,
  alterationId?: string
  onShowReservationDetails?: () => void
  isResrvationRequet?: boolean
  reservationRequestNumber?: string
  onReservationRequestClick?: () => void
  onDetailsClick?: () => void;
  gusetName?: string;
  getALLChat: {
    message: string;
    sender: "guest" | "property" | "system";
    time: string;
    id: string;
  }[];
}
export default function ChatMessages({ allChatMessages, onDetailsClick = () => { }, gusetName = '', alterationId = '', onShowReservationDetails = () => { }, getALLChat, isResrvationRequet = false, reservationRequestNumber = '' }: IProps) {

  const RequestedData = useMemo(() => {
    const alterationRequestData = allChatMessages?.result.filter((c: any) => c.attributes.message == "alteration_requested")
    let alterationRequestDataOb: any = {}
    const reservationRequestData = allChatMessages?.result.filter((c: any) => c.attributes.message == "reservation_requested")
    let reservationRequestDataOb: any = {}
    alterationRequestData.map((c: any) => {
      // let currentIndx = allChatMessages?.result.findIndex((x: any) => x.id == c.id)
      alterationRequestDataOb[dayjs(c?.attributes?.inserted_at).format("YYYY-MM-DD")] = c

    })
    reservationRequestData.map((c: any) => {
      //  let currentIndx = allChatMessages?.result.findIndex((x: any) => x.id == c.id)
      reservationRequestDataOb[dayjs(c?.attributes?.inserted_at).format("YYYY-MM-DD")] = c

    })
    return {
      alterationRequestDataOb,
      reservationRequestDataOb
    }
  }, [allChatMessages])



  const messages = useMemo(
    () =>
      getALLChat.reduce((group: any, item) => {
        let date = dayjs(item.time).format("YYYY-MM-DD");

        if (!group[date]) {
          group[date] = [];
        }

        group[date].push(item);

        return group;
      }, {}),
    [getALLChat]
  );

  const lang = localStorage.getItem('i18nextLng');



  return (
    <ChatMessagesWrapper>

      {Object.keys(messages).map((date, index) => {
        const diffInDays = dateDiffInDays(new Date(date), new Date())
        const dayDiff = lang == "en" ? diffInDays < 10 ? `  ${diffInDays} ${t('DM.AGO')} ${t('DM.DAYS')}` : `${dayjs(date).locale(lang ?? 'ar').format('dddd')}, ${date}` : diffInDays < 10 ? ` ${t('DM.AGO')} ${diffInDays} ${t('DM.DAYS')}` : `${dayjs(date).locale(lang ?? 'ar').format('dddd')}, ${date}`
        return (
          <div>
            {RequestedData.reservationRequestDataOb[date] ? <Box sx={{ mt: 1.5 }} onClick={onDetailsClick}>

              <Typography sx={{ fontWeight: 700, textAlign: 'center', cursor: 'pointer', color: 'rgba(51, 65, 85, 1)' }}  >
                {t('DM.RESERVATION_REQUEST')}
              </Typography>
              <Typography sx={{ fontWeight: 400, textAlign: 'center', cursor: 'pointer', color: '#435363' }}  >
                {t('DM.NEW_RESERVATION_REQUEST')}<span style={{ color: 'rgba(90, 63, 254, 1)' }} >{` ${RequestedData.reservationRequestDataOb[dayjs(date).format("YYYY-MM-DD")]?.attributes?.meta?.ota_reservation_code} `}</span>
              </Typography>
            </Box> : null}
            {RequestedData.alterationRequestDataOb[date] ? <Box sx={{ mt: 1.5 }} onClick={onDetailsClick}>

              <Typography sx={{ fontWeight: 700, textAlign: 'center', cursor: 'pointer', color: 'rgba(51, 65, 85, 1)' }}  >
                {t('DM.ALTERATION_REQUEST')}
              </Typography>
              <Typography sx={{ fontWeight: 400, textAlign: 'center', cursor: 'pointer', color: '#435363' }}  >
                {t('DM.NEW_ALTERATION_REQUEST')}<span style={{ color: 'rgba(90, 63, 254, 1)' }} >{` ${RequestedData.alterationRequestDataOb[dayjs(date).format("YYYY-MM-DD")]?.attributes?.meta?.ota_reservation_code} `}</span>
              </Typography>
            </Box> : null}

            <Box
              sx={{
                textAlign: "center",
                color: "#00000045",
                fontSize: "12px",
                fontWeight: 700,
                marginTop: 1,
              }}
            >
              {dayDiff}
            </Box>
            <Box sx={{
              height: '0.6px',
              backgroundColor: ' #EAEAFD',
              mt: 1
            }} />
            {messages[date].map((item: any) => {
              return (
                <div key={item.id} className={`${item.sender}`}>
                  <Box component="circle" sx={{}} >

                    {item?.sender !== 'guest' ? <Avatar backgroundColor="rgb(19,0,128)" name={window.localStorage.getItem('userName')?.substring(0, 1)} /> : null}
                  </Box>

                  <div>
                    <p>{item.message}</p>
                  </div>
                  <Box component="circle" sx={{}} >

                    {item?.sender == 'guest' ? <Avatar name={gusetName?.substring(0, 1)} /> : null}
                  </Box>
                </div>
              );
            })}
          </div>
        );
      })}
    </ChatMessagesWrapper>
  );
}

const Avatar = ({ name = '', backgroundColor = 'black' }) => {
  return <Box sx={{
    display: 'flex',
    width: '32px',
    height: '32px',
    borderRadius: '50px',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor,
    mt: 1,
    mx: 0.7
  }} component="circle" >
    <Typography sx={{ fontWeight: 700, color: 'white', fontSize: '8px' }} >{name}</Typography>
  </Box>
}