import styled from "styled-components"

export const AdvancedSettingWrapper = styled.div`
	padding-top: 24px;
	p {
		margin: 0 !important;
	}
	.HeaderSection {
		display: flex;
		justify-content: space-between;
		align-items: center;
		.title {
			p {
				color: #261b6a;
				font-size: 18px;
				font-weight: 700;
				line-height: 21.6px;
			}
		}
		.containerButton {
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 16px;
		}
	}

	.containerTabSection {
		border-bottom: 1px solid #eaeafd;
		margin-bottom: 24px;
		button {
			background-color: transparent;
			border: none;
			font-size: 14px;
			font-weight: 400;
			line-height: 16.8px;
			color: #8e98a1;
			cursor: pointer;
			&.active {
				border-bottom: 1px solid #5b3fff;
				color: #5b3fff;
				font-weight: 700;
			}
		}
	}
`
