import { Box, Typography } from '@mui/material';
import React, { FC } from 'react';

const Badge: FC<{ color: string; bg: string; text: string; small?: boolean }> = ({
  color,
  bg,
  text,
  small = false,
}) => {
  return (
    <Box
      sx={{
        borderRadius: '20px',
        p: 0.5,
        paddingInlineStart: 4.5,
        paddingInlineEnd: 3,
        position: 'relative',
        background: bg,
        width: 'fit-content',
      }}
    >
      <Typography fontSize={small ? '12px' : '16px'} color={color}>
        {text}
      </Typography>
      <Box
        sx={{
          position: 'absolute',
          top: 6,
          borderRadius: '50%',
          insetInlineStart: 8,
          width: small ? 14 : 20,
          height: small ? 14 : 20,
          background: color,
        }}
      ></Box>
    </Box>
  );
};
export default Badge;
