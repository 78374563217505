import { FC, useEffect } from 'react';
import { useProperty } from '@services';
import { useTranslation } from 'react-i18next';
import LinkingComponent from './LinkingComponent';
import AirbnbIcon from '@assets/svg/airbnb-icon.svg?react';
import LinkError from './LinkError';

const AirBnbConnectStep: FC<{ onConnect: () => void; token?: string | null; reset: (close: boolean) => void }> = ({
  onConnect,
  token,
  reset,
}) => {
  const { getAirbnbListingQuery } = useProperty();
  const { t } = useTranslation();

  const { isSuccess, isError } = getAirbnbListingQuery(token!);

  useEffect(() => {
    if (token && isSuccess) {
      localStorage.setItem('channexChannelId', token);
      onConnect();
    }
  }, [isSuccess]);

  if (isError) {
    return <LinkError reset={reset} channel={t('AIRBNB')} />;
  }
  return <LinkingComponent channelIcon={<AirbnbIcon />} />;
};
export default AirBnbConnectStep;
