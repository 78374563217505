import { ErrorMessage } from "@hookform/error-message"
import {
	Checkbox,
	FormControl,
	ListItemText,
	MenuItem,
	OutlinedInput,
	Select,
	SelectChangeEvent,
} from "@mui/material"
import { useEffect, useState } from "react"
import { Controller, RegisterOptions, useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { CustomMultiSelectWrapper } from "./styles"
import { FaChevronDown } from "react-icons/fa6"

interface IProps {
	name: string
	registerOptions?: RegisterOptions
	data: { value: string; label: string; group?: string; id?: string }[]
	label?: string
	withOutErrorMessage?: boolean
	valueOfSelectAll?: string
	labelVisibility?: boolean
}

export default function CustomMultiSelect({
	name,
	registerOptions,
	data,
	label,
	withOutErrorMessage,
	valueOfSelectAll,
	labelVisibility = true,
}: IProps) {
	/* ------------------------------- Local State ------------------------------ */
	const [isRequired, setIsRequired] = useState(false)
	const [selectedData, setSelectedData] = useState<string[]>([])

	/* ---------------------------------- Hooks --------------------------------- */
	const { control, formState, setValue, getValues } = useFormContext()
	const { errors } = formState
	const {
		t,
		i18n: { language },
	} = useTranslation()

	/* ------------------------------- Functions ------------------------------- */
	const handleChange = (
		event: SelectChangeEvent<typeof selectedData>,
		onChange: (value: string[]) => void
	) => {
		const {
			target: { value },
		} = event

		let selectedValues = typeof value === "string" ? value.split(",") : value

		if (valueOfSelectAll && selectedValues.includes(valueOfSelectAll)) {
			if (selectedData.length === data.length) {
				setSelectedData([])
				onChange([])
			} else {
				const allValues = data.map((item) => item.value)
				setSelectedData(allValues)
				onChange(allValues)
			}
		} else {
			selectedValues = Array.from(new Set(selectedValues))
			setSelectedData(selectedValues)
			onChange(selectedValues)
		}
	}

	/* ------------------------------ Derived State ----------------------------- */
	useEffect(() => {
		if (typeof registerOptions?.required === "object") {
			setIsRequired(registerOptions.required.value)
		} else if (typeof registerOptions?.required === "boolean") {
			setIsRequired(registerOptions.required)
		}
		const initialValue = getValues(name) || []
		setSelectedData(initialValue)
	}, [registerOptions, getValues, name])

	useEffect(() => {
		const initialValue = getValues(name) || []
		setSelectedData(Array.isArray(initialValue) ? initialValue : [])
		setValue(name, initialValue) // Sync value with the form
	}, [getValues, name, setValue])

	return (
		<CustomMultiSelectWrapper
			$islabelVisible={labelVisibility}
			$isError={Boolean(errors[name])}
			$language={language as "ar" | "en"}
		>
			<label>
				{label && (
					<p>
						{label} {isRequired && <span>*</span>}
					</p>
				)}

				<Controller
					name={name}
					control={control}
					rules={registerOptions}
					render={({ field: { onChange, value } }) => (
						<FormControl sx={{ m: 1, width: 300 }}>
							<Select
								labelId='demo-multiple-checkbox-label'
								id='demo-multiple-checkbox'
								multiple
								IconComponent={FaChevronDown}
								value={Array.isArray(selectedData) ? selectedData : []}
								onChange={(event) => handleChange(event, onChange)}
								input={<OutlinedInput label='Tag' />}
								renderValue={(selected) => {
									if (!Array?.isArray(selected) || selected.length === 0) {
										return <em>Select options</em> // Placeholder if nothing is selected
									}
									return selected
										?.map(
											(val) =>
												data?.find((item) => item.value === val)?.label || val
										)
										?.join(", ")
								}}
							>
								{valueOfSelectAll && (
									<MenuItem value={valueOfSelectAll}>
										<Checkbox checked={selectedData.length === data.length} />
										<ListItemText primary={t("COMMON.All")} />
									</MenuItem>
								)}

								{data.map((item) => (
									<MenuItem key={item.value} value={item.value}>
										<Checkbox checked={selectedData.includes(item.value)} />
										<ListItemText primary={item.label} />
									</MenuItem>
								))}
							</Select>
						</FormControl>
					)}
				/>
			</label>

			{!withOutErrorMessage && (
				<div className='ErrorMessageStyle'>
					<ErrorMessage
						errors={errors}
						name={name}
						render={({ message }) => <p>{message}</p>}
					/>
				</div>
			)}
		</CustomMultiSelectWrapper>
	)
}
