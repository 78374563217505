import React, { Dispatch, FC, SetStateAction, useEffect } from 'react';
// @ts-ignore
import { AppBar, Box, Button, Drawer, Grid, Toolbar, useMediaQuery, useScrollTrigger } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { useTranslation } from 'react-i18next';
import { disabledFeatures, padding } from '@utils';
import { Logo } from '../../../utils/image-consts';
import { NavLinks } from '@components';
import { MenuLinkI } from '@interfaces';

const NavMenu: FC<{
  handleClick: (l: MenuLinkI) => void;
  mobileOpen: boolean;
  setMobileOpen: Dispatch<SetStateAction<boolean>>;
}> = ({ handleClick, mobileOpen, setMobileOpen }) => {
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const {
    i18n: { language },
  } = useTranslation();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  useEffect(() => {
    setMobileOpen(false);
  }, [pathname]);

  return (
    <AppBar
      sx={{
        ...padding,
        bgcolor: 'transparent',
        boxShadow: 'none',
        '&.MuiPaper-root': {
          borderRadius: '0',
        },
        position: 'static',
      }}
    >
      <div
        style={{
          zIndex: 1,
          display: 'flex',
          gap: '3rem',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Box
          component='img'
          src={Logo}
          width='143px'
          height='56px'
          onClick={() => navigate('/')}
          sx={{ my: 2, objectFit: 'contain', cursor: 'pointer' }}
        />
        {mdUp && (
          <div style={{ width: '100%' }}>
            <NavLinks handleClick={handleClick} />
          </div>
        )}
        {mdDown && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginInlineStart: 'auto',
            }}
          >
            <Button
              color='inherit'
              aria-label='open drawer'
              sx={{ m: 0, p: 0, minWidth: 'fit-content' }}
              onClick={handleDrawerToggle}
            >
              <MenuIcon color={'primary'} fontSize={'large'} />
            </Button>
          </div>
        )}
      </div>
      {mdDown && (
        <Drawer
          variant='temporary'
          anchor={language === 'ar' ? 'left' : 'right'}
          open={mobileOpen}
          onClose={handleDrawerToggle}
        >
          <NavLinks handleClick={handleClick} />
        </Drawer>
      )}
    </AppBar>
  );
};
export default NavMenu;
