import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import HeaderSection from "../headerSection"
import { Grid } from "@mui/material"
import Input from "@Common/input"
import { FORM_REGEX_VALIDATORS } from "@utils"
import { Dispatch, SetStateAction, useEffect } from "react"
import { IFormPropertyInfoFormInputs } from "../PropertyInfoFormInputs"
import dayjs from "dayjs"

export interface IPricingFormInputs {
	totalPrice: string
	nightPrice?: string
}
interface IProps {
	setIsActiveStep: Dispatch<SetStateAction<number>>
	isActiveStep: number
	getPricingData: IPricingFormInputs | undefined
	setPricingData: Dispatch<SetStateAction<IPricingFormInputs | undefined>>
	getPropertyData: IFormPropertyInfoFormInputs | undefined
}
export default function PricingFormInputs({
	setIsActiveStep,
	isActiveStep,
	getPricingData,
	setPricingData,
	getPropertyData,
}: IProps) {
	const { t } = useTranslation()
	const methods = useForm<IPricingFormInputs>()
	const { setValue, watch } = methods
	const watchNightPrice = watch("nightPrice")
	const onSubmit = (value: IPricingFormInputs) => {
		setPricingData(value)
		setIsActiveStep(isActiveStep + 1)
	}

	useEffect(() => {
		if (getPricingData) {
			setValue("totalPrice", getPricingData.totalPrice)
		}
	}, [getPricingData, setValue])

	useEffect(() => {
		const totalDays = dayjs(getPropertyData?.checkOut)?.diff(
			dayjs(getPropertyData?.checkIn).format("YYYY-MM-DD"),
			"days"
		)
		const thePrice =
			Number(watchNightPrice) * totalDays > 0
				? Number(watchNightPrice) * totalDays
				: 0
		setValue("totalPrice", thePrice as any)
	}, [getPropertyData, dayjs, setValue, watchNightPrice])
	return (
		<div>
			<HeaderSection label={t("MANUALRESERVATION.PRICINGDATA")} />
			<FormProvider {...methods}>
				<form
					onSubmit={methods.handleSubmit(onSubmit)}
					id='FormManualReservation'
				>
					<Grid container spacing={"24px"}>
						<Grid item xs={12}>
							<Input
								name='nightPrice'
								label={t("MANUALRESERVATION.NIGHTPRICE")}
								placeholder={t("MANUALRESERVATION.NIGHTPRICE")}
								registerOptions={{
									required: {
										value: true,
										message: t("MANUALRESERVATION.PLEASEENTERTHENIGHTRATE"),
									},
									pattern: {
										value: FORM_REGEX_VALIDATORS.digitsDecimal,
										message: t(
											"MANUALRESERVATION.ONLYNUMBERSAREALLOWEDINTHISFIELD"
										),
									},
								}}
							/>
						</Grid>
						<Grid item xs={12}>
							<Input
								name='totalPrice'
								label={t("MANUALRESERVATION.TOTALPRICEOFRESERVATION")}
								placeholder={t("MANUALRESERVATION.TOTALPRICEOFRESERVATION")}
								readonly
								registerOptions={{
									required: {
										value: true,
										message: t("MANUALRESERVATION.PLEASEENTERTHETOTALPRICE"),
									},
									pattern: {
										value: FORM_REGEX_VALIDATORS.digitsDecimal,
										message: t(
											"MANUALRESERVATION.ONLYNUMBERSAREALLOWEDINTHISFIELD"
										),
									},
								}}
							/>
						</Grid>
					</Grid>
				</form>
			</FormProvider>
		</div>
	)
}
