import { useApi } from "../useApi"
import {
	CreateCategoryUrl,
	DeleteCategoryUrl,
	GetCategoryDetailsByIdUrl,
	GetListingManagementCategoriesUrl,
	GetPropertiesDoNotHaveCategoryUrl,
	UpdateCategoryUrl,
} from "../../utils/constants"
import { useMutation, useQuery } from "@tanstack/react-query"
import {
	IGetCategories,
	IPostCreateCategories,
	IGetPropertiesDoNotHaveCategory,
	IDeleteCategory,
	IPostCreateCategoriesResponse,
	IGetCategoryById,
	IUpdateCategory,
	UpdateCategoryResponse,
} from "./types"

const useCategoryMangment = () => {
	const { apiPrivate } = useApi()
	/* ------------------------------ getCategories ----------------------------- */
	const getCategories = async (params: {
		pageSize: string
		pageNumber: string
		search?: string
	}) => {
		const response: { data: IGetCategories } = await apiPrivate.get(
			GetListingManagementCategoriesUrl,
			{
				params,
			}
		)
		return response
	}

	const GetCategoriesQuery = (data: {
		pageSize: string
		pageNumber: string
		search?: string
	}) =>
		useQuery({
			queryKey: [GetListingManagementCategoriesUrl, data],
			queryFn: () => getCategories(data),
		})
	/* ------------------------- GetCategoryDetailsById ------------------------- */
	const getCategoryById = async (categoryId: string) => {
		const response: { data: IGetCategoryById } = await apiPrivate.get(
			`${GetCategoryDetailsByIdUrl}/${categoryId}`
		)

		return response
	}

	const GetCategoryByIdQuery = (categoryId: string) =>
		useQuery({
			queryKey: [GetCategoryDetailsByIdUrl, categoryId],
			queryFn: () => getCategoryById(categoryId),
		})

	/* --------------------- GetPropertiesDoNotHaveCategory --------------------- */

	const getPropertiesDoNotHaveCategory = async (params: {
		pageSize: string
		pageNumber: string
		search?: string
	}) => {
		const response: { data: IGetPropertiesDoNotHaveCategory } =
			await apiPrivate.get(GetPropertiesDoNotHaveCategoryUrl, {
				params,
			})
		return response
	}

	const GetPropertiesDoNotHaveCategoryQuery = (data: {
		pageSize: string
		pageNumber: string
		search?: string
	}) =>
		useQuery({
			queryKey: [GetPropertiesDoNotHaveCategoryUrl, data],
			queryFn: () => getPropertiesDoNotHaveCategory(data),
		})
	/* -------------------------- postCreateCategories -------------------------- */
	const postCreateCategories = async (body: IPostCreateCategories) => {
		const data: IPostCreateCategoriesResponse = await apiPrivate.post(
			CreateCategoryUrl,
			body
		)
		return data
	}

	const postCreateCategoriesMutate = ({
		onError,
	}: {
		onError?: (error: any) => void
	}) =>
		useMutation({
			mutationFn: (body: any) => postCreateCategories(body),
			onError: (error) => onError && onError(error),
		})
	/* -------------------------- putCreateCategories -------------------------- */
	const UpdateCategory = async (body: IUpdateCategory) => {
		const data: UpdateCategoryResponse = await apiPrivate.put(
			`${UpdateCategoryUrl}/${body.categoryId}`,
			body
		)
		return data
	}

	const UpdateCategoryMutate = () =>
		useMutation({
			mutationFn: (body: any) => UpdateCategory(body),
		})

	/* ----------------------------- Delete category ---------------------------- */
	const deleteCategory = async (id: number) => {
		const data: IDeleteCategory = await apiPrivate.delete(
			`${DeleteCategoryUrl}/${id}`
		)
		return data
	}
	const deleteCategoryMutate = () =>
		useMutation({
			mutationFn: (id: number) => deleteCategory(id),
		})

	return {
		GetCategoriesQuery,
		postCreateCategoriesMutate,
		GetPropertiesDoNotHaveCategoryQuery,
		deleteCategoryMutate,
		GetCategoryByIdQuery,
		UpdateCategoryMutate,
	}
}
export { useCategoryMangment }
