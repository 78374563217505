import styled from "styled-components"

export const ReservationManagementWrapper = styled.div`
	.containerTable {
		width: 100%;
		.columnsContent {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			margin: 0;
		}
		.containerStatus {
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			.accept,
			.reject,
			.pendding,
			.known {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				margin: 0;
				width: auto;
				height: 25px;
				border-radius: 30px;
				padding-inline: 10px;
				gap: 5px;
				span {
					width: 5px;
					height: 5px;
					border-radius: 50px;
				}
			}
			.accept {
				background-color: #f3fbf4;
				color: #16ae26;
				span {
					background-color: #16ae26;
				}
			}
			.reject {
				background-color: #ffe0e3;
				color: #d70015;
				span {
					background-color: #d70015;
				}
			}
			.pendding {
				background-color: #fff2d2;
				color: #fdbf21;
				span {
					background-color: #fdbf21;
				}
			}
			.known {
				background-color: #e9e9e9;
				color: #435363;
				span {
					background-color: #435363;
				}
			}
		}
	}
`
