import CustomButton from "@Common/CustomButton"
import { CancelReservationWrapper } from "./styles"
import { useTranslation } from "react-i18next"
import { Grid, Modal } from "@mui/material"
import { Dispatch, SetStateAction, useContext } from "react"
import { APIS_KEYS, useDeleteManualReservation } from "@services"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { PopupOverlayNewCalender, reservationDetails } from "@store/index"
import { useQueryClient } from "@tanstack/react-query"
import { ToastMessageContext } from "@context/toast-message-cotnext"
interface IProps {
	isOpen: boolean
	setIsOpen: Dispatch<SetStateAction<boolean>>
}
export default function CancelReservationPopup({ isOpen, setIsOpen }: IProps) {
	const { t } = useTranslation()
	const { mutateAsync: mutateAsyncDeleteManualReservation } =
		useDeleteManualReservation()
	const reservationDetailsValue = useRecoilValue(reservationDetails)
	const setPopupOverlayNewCalender = useSetRecoilState(PopupOverlayNewCalender)
	const queryClient = useQueryClient()
	const { showSuccess } = useContext(ToastMessageContext)

	const cancelBooking = async () => {
		if (!reservationDetailsValue?.id) return
		const response = await mutateAsyncDeleteManualReservation({
			id: reservationDetailsValue?.id,
		})
		if (response.status === 200) {
			setIsOpen(false)
			setPopupOverlayNewCalender(undefined)
			queryClient.invalidateQueries({ queryKey: [APIS_KEYS.GETCALENDERDATA] })
			showSuccess(t("COMMON.THE_RESERVATION_HAS_BEEN_CANCELLED"))
		}
	}
	return (
		<div>
			<Modal
				open={isOpen}
				onClose={() => setIsOpen(false)}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
				<CancelReservationWrapper>
					<Grid container justifyContent='center' alignItems='center'>
						<Grid item xs={12} md={6}>
							<div className='containerPopup'>
								<h1>{t("COMMON.CONFIRM_CANCEL_BOOKING")}</h1>
								<p>
									{t(
										"COMMON.ARE_YOU_SURE_YOU_WANT_TO_CANCEL_THIS_GUEST_RESERVATION"
									)}
								</p>
								<div className='containerFooter'>
									<CustomButton
										label={t("COMMON.CONFIRM_CANCEL_BOOKING")}
										withBG
										borderRadius='8px'
										customPadding='18px'
										onClick={() => cancelBooking()}
									/>
									<CustomButton
										label={t("COMMON.No_back")}
										borderRadius='8px'
										customPadding='18px'
										borderColor='#5B3FFF'
										customColor='#5B3FFF'
										onClick={() => setIsOpen(false)}
									/>
								</div>
							</div>
						</Grid>
					</Grid>
				</CancelReservationWrapper>
			</Modal>
		</div>
	)
}
