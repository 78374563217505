import styled from "styled-components"

export const SectionChatsWrapper = styled.div`
	border: 1px solid #0000001a;
	border-radius: 16px;
	display: grid;
	grid-template-rows: auto 1fr auto;
	grid-template-columns: 1fr;
	height: 100%;
	margin-inline: 4px;
	.containerHeader {
		padding: 16px;
		border-bottom: 1px solid #0000001a;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 8px;
		div {
			&:first-child {
				width: 35px;
				height: 35px;
			}
			&:last-child {
				p {
					margin: 0;
					&:first-child {
						font-size: 16px;
						font-weight: 400;
						line-height: 20px;
						color: #000000;
					}
					&:last-child {
						font-size: 14px;
						font-weight: 400;
						line-height: 16px;
						color: #00000066;
					}
				}
			}
		}
	}
	.containerBody {
		padding: 16px;
		overflow-y: scroll;
		height: 100%;
		.noMessages {
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;
			p {
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				z-index: 1;
				span {
					&:first-child {
						color: #121926;
						font-size: 16px;
						font-weight: 800;
						line-height: 24px;
					}
					&:last-child {
						color: #475467;
						font-size: 14px;
						font-weight: 500;
						line-height: 20px;
					}
				}
			}
			.containerIconEmpty {
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				position: absolute;
				svg {
					width: 100%;
					height: 100%;
				}
			}
		}
	}
	.containerInputData {
		form {
			width: 100%;
			padding: 16px;
			border-top: 1px solid #0000001a;
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 8px;
			textarea {
				width: 100%;
				resize: none;
				max-height: 14px;
				border: none;
				border: none;
				border-style: none;
				border-color: Transparent;
				overflow: auto;
				outline: none;


			}
			// textarea:focus{
			// 	line-height: 14px;
			// 	border: none;
			// 	border-style: none;
			// 	border-color: Transparent;
			// 	overflow: auto;
			// 	outline: none;

			//   }
			button {
				background-color: transparent;
				border: none;
				width: 20px;
				height: 20px;
				cursor: pointer;
				svg {
					transform: rotate(180deg);
					width: 20px;
					height: 20px;
					color: #5b3fff;
				}
				&.disabledButton {
					svg {
						color: #8e98a1;
					}
				}
			}
		}
	}
`
