import styled from "styled-components"

export const CustomSearchInputWrapper = styled.div<{
	lang: "ar" | "en"
}>`
	width: 100%;
	height: 100%;
	form {
		width: 100%;
		height: 100%;
		padding: 8px;
		background-color: #f9f9f9 !important;
		.inputcontainer {
			position: relative;
			display: flex;
			justify-content: flex-end;
			align-items: center;
			.search {
				position: absolute;
				top: 0;
				right: ${(props) => (props.lang === "ar" ? "0" : "auto")};
				left: ${(props) => (props.lang === "ar" ? "auto" : "0")};
				height: 100%;
				width: 16px;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 20px;
				color: #6b7280;
			}
			input {
				width: calc(100% - 16px);
				outline: none;
				border: 1px solid #f9f9f9;
				background-color: transparent;
			}
		}
	}
`
