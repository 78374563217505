import styled from "styled-components"

export const CancelReservationWrapper = styled.div`
	width: 100dvw;
	height: 100dvh;
	display: flex;
	justify-content: center;
	align-items: center;
	.containerPopup {
		box-shadow: 2px 4px 38px 0px #0000001a;
		background-color: #fff;
		padding: 32px;
		border-radius: 16px;
		h1,
		p {
			margin: 0;
			padding: 0;
		}
		h1 {
			color: #27272a;
			font-size: 20px;
			font-weight: 700;
			line-height: 24px;
			margin-bottom: 32px;
		}
		p {
			color: #3f3f46;
			font-size: 20px;
			font-weight: 400;
			line-height: 24px;
			margin-bottom: 32px;
		}
		.containerFooter {
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 8px;
		}
	}
`
