import { useTranslation } from 'react-i18next';
import { Button, Chip, Popover, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';

import { useAccounting, useCommon } from '@services';
import { FC, useContext, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';
import { IExpense } from '@interfaces';
import { LoadingWrapper, CreateNewRow, UpdateExpenseRow } from '@components';
import { QueryObserverResult, RefetchOptions, useIsMutating } from '@tanstack/react-query';
import { DeleteOutline, MoreVert } from '@mui/icons-material';
import { sarFormat } from '@utils';
import { ToastMessageContext } from '@context';

const ExpenseTable: FC<{
  refetch: (options?: RefetchOptions | undefined) => Promise<QueryObserverResult<IExpense[], Error>>;
  expenses?: IExpense[];
  isLoading?: boolean;
}> = ({ refetch, expenses = [], isLoading = false }) => {
  const {
    i18n: { language },
    t,
  } = useTranslation();
  const { id } = useParams();

  const align = language === 'ar' ? 'right' : 'left';
  const [updatingCell, setUpdatingCell] = useState<number | undefined>();
  const [creating, setCreating] = useState<boolean>(false);
  const mutating = useIsMutating();

  const [deleteEl, setDeleteEl] = useState<HTMLElement | undefined>();
  const { allCategoriesQuery } = useCommon();

  const { data: categories } = allCategoriesQuery();
  const [params, setParams] = useSearchParams();
  const { showSuccess, showError } = useContext(ToastMessageContext);

  const { createExpenseMutate, updateExpenseMutate, deleteExpenseMutate } = useAccounting();

  const { mutate: deleteExpense } = deleteExpenseMutate({
    onSuccess: () => {
      refetch();
      showSuccess(t('FINANCE.EXPENSE.DELETE_SUCCESS'));
    },
    onError: () => {
      showError(t('FINANCE.EXPENSE.DELETE_FAIL'));
    },
  });
  const { mutate: createExpense } = createExpenseMutate({
    onSuccess: () => {
      refetch();
      setCreating(false);
      showSuccess(t('FINANCE.EXPENSE.CREATED_SUCCESS'));
    },
    onError: () => {
      setUpdatingCell(undefined);
      setCreating(false);
      showError(t('FINANCE.EXPENSE.CREATED_FAIL'));
    },
  });
  const { mutate: updateExpense } = updateExpenseMutate({
    onSuccess: () => {
      refetch();
      setUpdatingCell(undefined);
      setCreating(false);
      showSuccess(t('FINANCE.EXPENSE.UPDATED_SUCCESS'));
    },
    onError: () => {
      setUpdatingCell(undefined);
      setCreating(false);
      showError(t('FINANCE.EXPENSE.UPDATED_FAIL'));
    },
  });
  const onUpdate = (data: IExpense) => {
    const { transactionCreateDate, transactionId, transactionName, amount, categoryId, propertyId } = data;
    updateExpense({
      ownerId: Number(window.localStorage['userId']),
      transactionCreateDate: transactionCreateDate.set('hour', 12),
      transactionName,
      transactionId,
      amount,
      categoryId,
      propertyId,
    });
  };

  const onCreate = ({ transactionCreateDate, transactionName, amount, categoryId }: IExpense) => {
    createExpense({
      ownerId: Number(window.localStorage['userId']),
      // transactionId: 0,
      transactionCreateDate: transactionCreateDate.set('hour', 12),
      transactionName,
      amount,
      categoryId,
      propertyId: +id!,
    });
  };

  const onCancel = () => {
    setCreating(false);
  };
  const customFilter = (i: IExpense) => {
    let show = true;
    if (params?.get('category')) {
      show = i?.category?.id === Number(params.get('category'));
    }
    if (params.get('from') || params.get('to')) {
      const from: Dayjs = dayjs(params.get('from') ?? '2000-01-01')
        .set('hour', 0)
        .set('minute', 0)
        .set('second', 0);
      const to: Dayjs = (params.get('to') ? dayjs(params.get('to')) : dayjs())
        .set('hour', 23)
        .set('minute', 59)
        .set('second', 59);
      const date = dayjs(i.transactionCreateDate);
      show = show && from.isBefore(date) && to.isAfter(date);
    }
    return show;
  };
  const handleEdit = (id?: number) => {
    if (updatingCell === id) {
      setUpdatingCell(undefined);
    } else {
      setCreating(false);
      setUpdatingCell(id);
    }
  };

  return (
    <LoadingWrapper loading={mutating > 0 || isLoading}>
      <Typography fontSize={'14px'} mt={2} marginInlineStart={2} color={'#A1A1AA'}>
        {t('FINANCE.HINT')}
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align={align}></TableCell>
            <TableCell align={align}> {t('FINANCE.EXPENSE.NAME')}</TableCell>
            <TableCell align={align}>{t('FINANCE.EXPENSE.CLASSIFICATION')}</TableCell>
            <TableCell align={align}> {t('FINANCE.EXPENSE.VALUE')}</TableCell>
            <TableCell align={align}> {t('FINANCE.EXPENSE.DATE')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <CreateNewRow
            span={5}
            align={align}
            onAdd={() => {
              setCreating(true);
              setUpdatingCell(undefined);
            }}
          />
          {creating && <UpdateExpenseRow onSubmit={onCreate} onCancel={onCancel} categories={categories} />}
          {expenses?.map((i) => {
            if (updatingCell === i.transactionId) {
              return (
                <UpdateExpenseRow
                  key={i.transactionId}
                  data={i}
                  onCancel={() => setUpdatingCell(undefined)}
                  onSubmit={(data) =>
                    onUpdate({
                      ...data,
                      propertyId: i.propertyId,
                      transactionId: i.transactionId,
                    })
                  }
                  categories={categories}
                />
              );
            }
            return (
              <TableRow key={`${i.transactionId}${updatingCell}`}>
                <TableCell
                  align={align}
                  sx={{ p: 0, width: 0 }}
                  id={`delete-icon-${i.transactionId}`}
                  onClick={(e) => setDeleteEl(e.currentTarget)}
                >
                  <MoreVert
                    sx={{
                      color: '#888',
                      cursor: 'pointer',
                      ':hover': {
                        color: '#5B3FFF',
                      },
                      ':active': {
                        color: '#D32F2F',
                      },
                    }}
                  />
                </TableCell>
                <TableCell
                  onClick={() => {
                    handleEdit(i.transactionId);
                  }}
                  align={align}
                >
                  {i.transactionName}
                </TableCell>
                <TableCell
                  onClick={() => {
                    handleEdit(i.transactionId);
                  }}
                  align={align}
                >
                  <Chip color={'primary'} sx={{ backgroundColor: '#1D4ED8' }} label={i.category?.name} />
                </TableCell>
                <TableCell
                  onClick={() => {
                    handleEdit(i.transactionId);
                  }}
                  align={align}
                >
                  {sarFormat(i.amount ?? 0)}
                </TableCell>
                <TableCell
                  onClick={() => {
                    handleEdit(i.transactionId);
                  }}
                  align={align}
                >
                  {i?.transactionCreateDate && dayjs(i.transactionCreateDate).format('YYYY-MM-DD')}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <Popover
        id={'delete-expense'}
        open={Boolean(deleteEl)}
        anchorEl={deleteEl}
        onClose={() => setDeleteEl(undefined)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Button
          sx={{ minWidth: '200px', gap: 3, py: 0.5, px: 2, m: 1, display: 'flex', justifyContent: 'space-between' }}
          endIcon={<DeleteOutline />}
          color={'error'}
          onClick={() => {
            const id = Number(deleteEl?.id?.replace('delete-icon-', ''));
            setDeleteEl(undefined);
            if (!isNaN(id)) {
              deleteExpense(id);
            }
          }}
        >
          {t('FINANCE.EXPENSE.DELETE')}
        </Button>
      </Popover>
    </LoadingWrapper>
  );
};
export default ExpenseTable;
