import styled from "styled-components"

export const AvailabilityTabWrapper = styled.div<{ language: "ar" | "en" }>`
	.containerDate,
	.containerRadio {
		border-bottom: 1px solid #eaeafd;
		margin-bottom: 24px;
		> label {
			font-size: 12px;
			font-weight: 400;
			line-height: 14.4px;
			color: #000;
		}
		.dateInputs {
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 10px;
			> div {
				width: 100%;
			}
		}
	}

	.containerRadio {
		margin-bottom: 24px;
		.containerRadioButtons {
			margin-bottom: 24px;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: 16px;
			.Radio {
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 8px;
				label {
					font-size: 14px;
					color: #435363;
				}
			}
		}
	}
	.containerMinDays {
		> label {
			font-size: 12px;
			font-weight: 400;
			line-height: 14.4px;
			color: #000;
		}
	}
`
