/* eslint-disable @typescript-eslint/no-explicit-any */
// @ts-nocheck
import { FC, useContext } from 'react';
import { Box, Button, Chip, IconButton, Modal, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';

import { IModalProps } from '@interfaces';
import { useProperty } from '@services';

import GATHREN from '@assets/svg/gathren.svg?react';
import AIRBNB from '@assets/svg/airbnb.svg?react';
import { ToastMessageContext } from '@context'; 
import CircleBooking from '@assets/svg/BookingActive.svg?react';
// import CircleBooking from '@assets/public/images/CircleBooking.webp?url';  

const DeletePropertiesModal: FC<IModalProps> = ({ handleClose, open, data, showAskMessage }) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 495,
    bgcolor: 'background.paper',
    color: '#261B6A',
    boxShadow: 24,
    p: 3,
    borderRadius: 5,
  };

  const {
    i18n: { language },
    t,
  } = useTranslation();
  const align = language === 'ar' ? 'right' : 'left';

  const { showSuccess, showError } = useContext(ToastMessageContext);

  const { control, handleSubmit, reset } = useForm<any>({
    mode: 'onChange',
  });

  reset(data);

  const onSubmit = (body: any) => {
  };

  const closeModal = () => {
    handleClose();
    reset({});
  };

  const delete1 = () => {
    showAskMessage();
    // deleteProperty(data?.propertyId);
    handleClose();
    reset({});
  };

  const { deletePropertyMutate } = useProperty();

  const { mutate: deleteProperty } = deletePropertyMutate({
    onSuccess: () => {
      closeModal();
      showSuccess(t('PROPERTIES_MANAGEMENT.DELETED_SUCCESS'));
    },
    onError: () => {
      showError(t('ERRORS.SOMETHING_WENT_WRONG'));
    },
  });

  return (
    <>
      <Modal
        open={open}
        onClose={closeModal}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <div className='flex justify-between items-center mb-3'>
            <Typography id='modal-modal-title' className='text-[#27272A]'>
              {t('PROPERTIES_MANAGEMENT.TITLE')}
            </Typography>

            <IconButton aria-label='close' onClick={closeModal} size='small'>
              <CloseIcon className='text-[#27272A] ' />
            </IconButton>
          </div>
          <form className='mt-10' onSubmit={handleSubmit(onSubmit)}>
            <>
              <Controller
                name='propertyName'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <>
                    <div className='text-[#261B6A] font-normal'>
                      <div>{t('PROPERTIES_MANAGEMENT.PROPERTY_NAME')}</div>
                      <div>
                        <TextField
                          id='propertyName'
                          className='w-full rounded-3xl'
                          value={value}
                          InputProps={{ sx: { borderRadius: 24 } }}
                          style={{
                            borderRadius: '24px !important',
                            color: '#9CA3AF',
                            borderColor: '#9CA3AF',
                          }}
                          disabled
                          placeholder={`${t('PROPERTIES_MANAGEMENT.PROPERTY_NAME')}`}
                          variant='outlined'
                          onChange={onChange}
                        />
                      </div>
                    </div>
                  </>
                )}
              />
              <div className='text-[#261B6A] font-normal mt-4'>
                <div>{t('PROPERTIES_MANAGEMENT.ACTIVATED_CHANNELS')}</div>
                <div className='mt-4'>
                  <div className='flex gap-2'>
                    <div>
                      {data?.gathrenUnitId && (
                        <Chip
                          avatar={<GATHREN />}
                          label={`${t('GATHERIN')}`}
                          variant='outlined'
                          style={{
                            color: '#FAFAFA',
                            backgroundColor: '#5A409B',
                            paddingInlineStart: '6px',
                          }}
                        />
                      )}
                    </div>
                    <div>
                      {data?.airbnbUnitId && (
                        <Chip
                          avatar={<AIRBNB />}
                          label={`${t('AIRBNB')}`}
                          variant='outlined'
                          style={{
                            color: '#FAFAFA',
                            backgroundColor: '#FF5A5F',
                            paddingInlineStart: '6px',
                          }}
                        />
                      )}
                       {data?.bookingUnitId && (
                        <Chip
                          avatar={<CircleBooking />} 
                          label={'Booking'}
                          variant='outlined'
                          style={{
                            color: 'white',
                            backgroundColor: '#013680', 
                            paddingInlineStart: '6px',
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className='mt-6 mx-4'>
                <Button
                  className='w-full bg-black'
                  variant={'contained'}
                  disabled={data?.propertyId ? false : true}
                  size={'large'}
                  onClick={delete1}
                  style={{ backgroundColor: '#EF4444' }}
                >
                  {t('DELETE')}
                </Button>
              </div>
            </>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default DeletePropertiesModal;