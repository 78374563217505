const Logo = '/images/Logo.webp';
const MsoolIcon = '/images/msool-icon.jpg';
const Background = '/images/Background.webp';
const PublicBg = '/images/heropage.webp';
const registerNow = '/images/register_now.webp';
const BackgroundLocks = '/images/BackgroundLocks.webp';
const MessagingBg = '/images/MessagingBg.png';
const BackgroundP = '/images/BackgroundP.webp';
const BackgroundCompressed = '/images/BackgroundCompressed.webp';
const InActiveBg = '/images/InActiveModal.webp'; 
const SubscribeNow = '/images/subscribebanner.webp'; 
const LeepBackground = '/images/leepBackk.webp'


export {
  InActiveBg,
  Logo,
  MsoolIcon,
  BackgroundLocks,
  PublicBg,
  registerNow,
  Background,
  BackgroundP,
  MessagingBg,
  BackgroundCompressed,
  SubscribeNow,
  LeepBackground
};
