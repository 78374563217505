import styled from "styled-components"

export const PopupConfirmationWrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100dvw;
	height: 100dvh;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 9999;
	display: flex;
	justify-content: center;
	align-items: center;
	.PopupConfirmationCard {
		width: 40%;
		background-color: #fff;
		border-radius: 8px;
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 1fr 1fr 1fr;
		gap: 8px;
    padding: 24px;
		.header {
			p {
				color: #435363;
				margin: 0;
				font-size: 18px;
				font-weight: 700;
				line-height: 21.6px;
			}
		}
		.body {
			p {
				margin: 0;
				font-size: 16px;
				font-weight: 400;
				line-height: 19.2px;
				color: #435363;
			}
		}
		.footer {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			gap: 16px;
		}
	}
`
