import { useTranslation } from "react-i18next"
import { PopupInfoWrapper } from "./styles"
import IconAutoAccess from "@assets/svg/IconAutoAccess.svg?react"
export default function PopupInfo() {
	const { t } = useTranslation()
	return (
		<PopupInfoWrapper className="PopupInfo">
			<div className="containerIcon">
				<IconAutoAccess />
			</div>
			<div>
				<p className='AUTO_ACCESS'>{t("PROPERTIES_MANAGEMENT.AUTO_ACCESS")}</p>
			</div>
			<div>
				<p className='MODAL_TITLE'>{t("PROPERTIES_MANAGEMENT.MODAL_TITLE")}</p>
			</div>
		</PopupInfoWrapper>
	)
}
