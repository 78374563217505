import { useEffect, useState } from "react"
import { SelectWrapper } from "./styles"
import { Controller, RegisterOptions, useFormContext } from "react-hook-form"
import { ErrorMessage } from "@hookform/error-message"
import { FaChevronDown } from "react-icons/fa6"
import {
	BaseSelectProps,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
} from "@mui/material"
import { useTranslation } from "react-i18next"

interface IProps extends BaseSelectProps {
	label?: string
	labelVisibility?: boolean
	name: string
	registerOptions?: RegisterOptions
	data: { value: string; label: string; group?: string; id?: string }[]
	withOutErrorMessage?: boolean
	placeholder?: string
	value?:string
}

function CustomSelect({
	name,
	label,
	labelVisibility = true,
	registerOptions,
	data = [],
	withOutErrorMessage = false,
	placeholder,
	value,
	...rest
}: IProps) {
	/* ------------------------------- Local State ------------------------------ */
	const [isRequired, setIsRequired] = useState(false)
	/* ---------------------------------- Hooks --------------------------------- */
	const { control, formState } = useFormContext()
	const { errors } = formState
	const {
		i18n: { language },
	} = useTranslation()
	/* ------------------------------ Derived State ----------------------------- */
	useEffect(() => {
		if (typeof registerOptions?.required === "object") {
			setIsRequired(registerOptions?.required?.value)
		}
		if (typeof registerOptions?.required === "boolean") {
			setIsRequired(registerOptions.required)
		}
	}, [registerOptions])
	return (
		<SelectWrapper
			$islabelVisible={labelVisibility}
			$isError={errors[name] ?true : false}
			$language={language as "ar" | "en"}
		>
			<label>
				{label && (
					<p>
						{label} {isRequired && <span>*</span>}
					</p>
				)}
				<Controller
					name={name}
					control={control}
					rules={registerOptions}
					render={({ field: { onChange, value:local } }) => (
						<FormControl fullWidth>
							{placeholder && (
								<InputLabel id='select-placeholder-label'>
									{placeholder}
								</InputLabel>
							)}
							<Select
								labelId='select-placeholder-label'
								{...rest}
								value={local||String(value)}
								onChange={onChange}
								IconComponent={FaChevronDown}
							>
								{data.map((item) => {
									return (
										<MenuItem value={item.value} key={item.value}>
											{item.label}
										</MenuItem>
									)
								})}
							</Select>
						</FormControl>
					)}
				/>
			</label>
			{!withOutErrorMessage && (
				<div className='ErrorMessageStyle'>
					<ErrorMessage
						errors={errors}
						name={name}
						render={({ message }) => <p>{message}</p>}
					/>
				</div>
			)}
		</SelectWrapper>
	)
}

export default CustomSelect
