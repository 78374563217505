import { Box, IconButton, Modal, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';


type IModalProps ={
    handleClose:()=>void;
    open:boolean;
    isActive:boolean;
    onSupscripe:()=>void;
}

const ActivateModal = ({ handleClose, open, isActive,onSupscripe }:IModalProps) => {
    const style = {
        position: 'absolute',
        top: '58%',
        left: '39%',
        transform: 'translate(-50%, -50%)',
        width: 506,
        bgcolor: '#FFFFFF',
        color: '#261B6A',
        boxShadow: 24,
        p: 3,
        borderRadius: 5,
    
    };

    const {
        t,
    } = useTranslation();
    const closeModal = () => {
        handleClose();
    };
    return (
        <>
            <Modal
                open={open}
                onClose={closeModal}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
            >
                <Box sx={style}>
                    <div className='flex justify-between items-center mb-3'>
                        <Box sx={{ display: 'flex' }} >
                            <Typography sx={{color:'#435363',fontSize:'14px',fontWeight:400}} id='modal-modal-title' className='text-[#27272A]'>
                                {t('PROPERTIES_MANAGEMENT.CURRENT_PROPERYT_STATUS')}
                            </Typography>
                            <Box sx={{ color: isActive ? '#16AE26' : '#858585', backgroundColor: isActive ? '#F3FBF4' : '#F6F6F7', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 10, height: '25px', width: '92px', textAlign: 'center',marginX:1 }} >
                                <Box sx={{ height: 9, width: 9, borderRadius: 4.5, backgroundColor: isActive ? '#16AE26' : '#999999', marginX: 0.4, fontSize: '14px', fontWeight: 400 }} />
                                {!isActive ? t('PROPERTIES_MANAGEMENT.IN_ACTIVE') : t('PROPERTIES_MANAGEMENT.ACTIVE')}
                            </Box>
                        </Box>
                        <IconButton aria-label='close' onClick={closeModal} size='small'>
                            <CloseIcon className='text-[#27272A] ' />
                        </IconButton>
                    </div>
                    <Box sx={{color:'#435363',fontSize:'20px',fontWeight:400}} className='text-[#261B6A] font-normal mt-4'>
                    { t('PROPERTIES_MANAGEMENT.ACTIVATE_MODAL_TITLE')}
                    </Box>
                    <Box onClick={onSupscripe} sx={{backgroundColor:'#5A3FFE',color:'#FFFFFF',fontSize:'16px',fontWeight:700,textAlign:'center',borderRadius:'20px',padding:'12px',width:'30%',cursor:'pointer'}} mt={3} >
                   { t('PROPERTIES_MANAGEMENT.SUBSCRIBE_NOW')}
                    </Box>
                </Box>
            </Modal>
        </>
    );
};

export default ActivateModal;
