import styled from "styled-components"

export const PricingTabWrapper = styled.div<{ language: "ar" | "en" }>`
	position: relative;
	.containerDate,
	.containerPricingManagement {
		border-bottom: 1px solid #eaeafd;
		padding-bottom: 24px;
		margin-bottom: 24px;
		> label {
			font-size: 12px;
			font-weight: 400;
			line-height: 14.4px;
			color: #000;
		}
		.dateInputs {
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 10px;
			> div {
				width: 100%;
			}
		}
		.numberOfNights {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: 10px;
			border-right: ${(props) =>
				props.language === "ar" ? "2px solid #5b3fff" : "none"};
			border-left: ${(props) =>
				props.language === "en" ? "2px solid #5b3fff" : "none"};
			padding-right: ${(props) => (props.language === "ar" ? "5px" : "none")};
			padding-left: ${(props) => (props.language === "en" ? "5px" : "none")};
			font-size: 12px;
			font-weight: 500;
			line-height: 14.4px;
			margin-top: 12px;
			span {
				padding: 5px 14px;
				color: #8e98a1;
				border: 1px solid #e5e7eb;
				border-radius: 12px;
				font-size: 12px;
				font-weight: 500;
				line-height: 24px;
			}
		}
	}

	.byProperty {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-direction: column;

		> div {
			width: 100%;
		}
	}

	.rateContainer {
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		flex-direction: column;
		gap: 18px;
		.containerLogoAndLabel {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: 8px;
			.containerIcon {
				width: 20px;
				height: 20px;
				svg {
					width: 100%;
					height: 100%;
				}
			}
			p.label {
				margin: 0;
				font-weight: 700;
				color: #435363;
				font-size: 15px;
				text-transform: capitalize;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
		.containerInputs {
			width: 100%;
			.containerinput {
				display: flex;
				justify-content: space-between;
				align-items: center;
				p.label {
					margin: 0;
					font-weight: 700;
					color: #435363;
					font-size: 15px;
					text-transform: capitalize;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}
		}
	}
`
