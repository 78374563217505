import { CircularProgress } from "@mui/material"
import { CustomLoadingWrapper } from "./styles"

export default function CustomLoading() {
	return (
		<CustomLoadingWrapper>
			<div>
				<CircularProgress />
			</div>
		</CustomLoadingWrapper>
	)
}
