import { Dispatch, SetStateAction } from "react"
import { PopupViewInnerCategoryWrapper } from "./styles"
import { useCategoryMangment } from "@services"
import { IoClose } from "react-icons/io5"
import { Grid } from "@mui/material"
import { useTranslation } from "react-i18next"

interface IProps {
	setInnerViewCategory: Dispatch<SetStateAction<boolean>>
	categoryId: string
}
export default function PopupViewInnerCategory({
	setInnerViewCategory,
	categoryId,
}: IProps) {
	const { GetCategoryByIdQuery } = useCategoryMangment()
	const { data: GetCategoryById } = GetCategoryByIdQuery(categoryId)
	const {t}=useTranslation();
	return (
		<PopupViewInnerCategoryWrapper>
			<div className='popupContainer'>
				<div>
					<button onClick={() => setInnerViewCategory(false)}>
						<IoClose />
					</button>
				</div>

				<div>
					<p>{GetCategoryById?.data?.result[0]?.categoryName}</p>
				</div>
				<div>
					<p>{t("CATEGORYMANAGER.CATEGORYNAME")}</p>
					<p>{GetCategoryById?.data?.result[0]?.categoryName}</p>
				</div>
				<div>
					<p>{t("CATEGORYMANAGER.NUM_OF_PROPERTY")}</p>
					<div>
						<Grid container>
							{GetCategoryById?.data?.result.map((item) => {
								return item.properties.map((subItem) => {
									return (
										<Grid item xs={12} md={4} key={subItem.propertyId}>
											<div className='propertyCard'>
												<div className='containerData'>
													<div className='containerImage'>
														<img src={subItem.propertyImage} alt='subItem' />
													</div>
													<div className='containerInfo'>
														<p>{subItem.propertyName}</p>
														<p>{subItem.propertyLocation}</p>
													</div>
												</div>
											</div>
										</Grid>
									)
								})
							})}
						</Grid>
					</div>
				</div>
			</div>
		</PopupViewInnerCategoryWrapper>
	)
}