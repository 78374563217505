import { useTranslation } from "react-i18next"
import { SectionMessagesWrapper } from "./styles"
import { useDM } from "@services"
import { Dispatch, Fragment, SetStateAction } from "react"
import AirbnbIcon from "@assets/svg/airbnb.svg?react"
import GathernIcon from "@assets/svg/GathernIcon2.svg?react"
import BookingIcon from "@assets/svg/BookingIcon.svg?react"
import ChannelAgoda from "@assets/svg/ChannelAgoda.svg?react"
import MsoolIcon from "@assets/svg/msool-icon.svg?react"
import dayjs from "dayjs"
import { Typography } from "@mui/material"

interface IProps {
	setSelectedMessageId: Dispatch<SetStateAction<string | undefined>>
	selectedMessageId: string | undefined
}
export default function SectionMessages({
	setSelectedMessageId,
	selectedMessageId,
}: IProps) {
	const channals = {
		airbnb: <AirbnbIcon />,
		gathern: <GathernIcon />,
		bookingcom: <BookingIcon />,
		agoda: <ChannelAgoda />,
		msool: <MsoolIcon />,
	}
	const { GetAllMessageThreadsQuery } = useDM()
	const { data: AllMessageThreads } = GetAllMessageThreadsQuery() 
	const { t } = useTranslation()

	return (
		<SectionMessagesWrapper>
			<p>{t("DM.MESSAGES")}</p>
			<div className='containerList'>
				{AllMessageThreads?.data.result.length === 0 ||
				!AllMessageThreads?.data.result ? (
					<div className='containerNoData'>
						<p className='noData'>{t("DM.NOMESSAGES")}</p>
					</div>
				) : (
					<Fragment>
						{AllMessageThreads.data.result.map((item) => { 
							const isActive = item.id === selectedMessageId
							return (
								<div
									className={`${isActive ? "active" : "containerCardMessageInfo"}`}
									key={item.id}
									onClick={() => setSelectedMessageId(item.id)}
								>
									<div className='containerIcon'>
										<div>
											{
												channals[
													item.attributes.provider.toLowerCase() as
														| "airbnb"
														| "gathern"
														| "bookingcom"
														| "agoda"
														| "msool"
												]
											}
										</div>
									</div>
									<div className='containerDataInfo'>
										<Typography sx={{
											color:'#000000',
											fontWeight:700,
											opacity:isActive?1:0.7,
											lineHeight:'20px',
											fontSize:'14px'
										}} >{item.attributes.title}</Typography>
											<Typography sx={{
											color:'#000000',
											fontWeight:400,
											lineHeight:'16px',
											fontSize:'12px',
											opacity:0.4
										}} >{item?.attributes?.last_message?.message}</Typography> 
								 	</div>
									<div className='containerTime'>
										<p>{dayjs(item?.attributes?.inserted_at).format("HH:MM")}</p>
									</div>
								</div>
							)
						})}
					</Fragment>
				)}
			</div>
		</SectionMessagesWrapper>
	)
}