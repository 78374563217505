import { useTranslation } from "react-i18next"
import { DetailsInfoWrapper } from "./styles"
import { ContentCopyOutlined } from "@mui/icons-material"
import {
	Dispatch,
	Fragment,
	SetStateAction,
	useContext,
	useEffect,
	useState,
} from "react"
import { ToastMessageContext } from "@context/toast-message-cotnext"
import { PiDotsThreeOutlineVerticalLight } from "react-icons/pi"
import { CiLock } from "react-icons/ci"
import { MdOutlineMessage } from "react-icons/md"
import { Menu, MenuItem } from "@mui/material"
import { useGetLockDetails } from "@services"
import { PramsLockId, reservationDetails } from "@store/index"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { LockDetailsStore } from "@store/NewCalenderPage/LockDetailsStore"
interface IProps {
	setIsPopupDisableLock: Dispatch<SetStateAction<boolean>>
}
export default function DetailsInfo({ setIsPopupDisableLock }: IProps) {
	const { t } = useTranslation()
	const pramsLockIdState = useSetRecoilState(PramsLockId)
	const reservationDetailsValue = useRecoilValue(reservationDetails)
	const setLockDetailsStoreValue = useSetRecoilState(LockDetailsStore)

	const { data: dataLockDetails } = useGetLockDetails(
		{
			reservationId: reservationDetailsValue?.reservationId!,
		},
		{
			enabled: reservationDetailsValue?.reservationId !== undefined,
		}
	)
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
	const open = Boolean(anchorEl)
	const handleClick = (event: any) => {
		setAnchorEl(event.currentTarget)
	}
	const handleClose = () => {
		setAnchorEl(null)
	}
	const { showSuccess } = useContext(ToastMessageContext)

	const handleCopyClick = () => {
		if (!dataLockDetails?.result?.lockDetails?.lockCode) return
		navigator.clipboard
			.writeText(dataLockDetails?.result?.lockDetails?.lockCode)
			.then(() => {
				showSuccess(t("COMMON.LOCK_COPIED"))
			})
			.catch((err) => {
				console.error("Failed to copy: ", err)
			})
	}
	useEffect(() => {
		if (
			dataLockDetails?.statusCode === 200 &&
			dataLockDetails?.result?.lockDetails?.lockCode &&
			dataLockDetails?.result?.lockDetails?.lockId
		) {
			setLockDetailsStoreValue({
				passcode: dataLockDetails?.result?.lockDetails?.lockCode,
				lockId: dataLockDetails?.result?.lockDetails?.lockId,
			})
		}
	}, [dataLockDetails])
	return (
		<DetailsInfoWrapper>
			{reservationDetailsValue?.isMessageSend && (
				<div className='sectionInfoMessage'>
					<div className='iconSection'>
						<MdOutlineMessage />
					</div>
					<div className='titleSection'>
						<p>
							{t(
								"COMMON.a_welcome_message_with_the_lock_code_has_been_sent_to_the_guest_automatically_the_guest_will_be_able_to_access_the_lock_12_hours_before_check_in_time"
							)}
						</p>
					</div>
				</div>
			)}

			<div className='sectionTitle'>
				<div className='header'>
					<div className='iconSection'>
						<CiLock />
					</div>
					{dataLockDetails?.result?.lockDetails?.isLockDisabled && (
						<div className='containerStatus'>
							<p>
								<span></span>
								<span>{t("NEW_LOCKS.LOCK_DISABLED")}</span>
							</p>
						</div>
					)}
				</div>
				<div className='titleSection'>
					<p>{t("NEW_LOCKS.LOCK_DETAILS")}</p>
				</div>
			</div>
			<div className='record'>
				<p>{t("LOCKS.LOCK_CODE")} : </p>
				<p>{dataLockDetails?.result?.lockDetails?.lockCode}</p>
				<div className='handleCopyClick' onClick={() => handleCopyClick()}>
					{dataLockDetails?.result?.lockDetails?.lockCode && (
						<ContentCopyOutlined sx={{ width: "16px" }} />
					)}
				</div>
			</div>
			<p className='hint'>
				{dataLockDetails?.result?.lockDetails?.message
					? dataLockDetails?.result?.lockDetails?.message
					: t("COMMON.Must_be_available_only_12_hours_before_login_time")}
			</p>

			{reservationDetailsValue?.isMessageSend && (
				<Fragment>
					<div className='controlLock'>
						<p>
							<span>{t("NEW_LOCKS.CONTROL_LOCK")}</span>
							<div>
								{!dataLockDetails?.result?.lockDetails?.isLockDisabled && (
									<div
										id='basic-button'
										aria-controls={open ? "basic-menu" : undefined}
										aria-haspopup='true'
										aria-expanded={open ? "true" : undefined}
										onClick={handleClick}
									>
										<span>
											<PiDotsThreeOutlineVerticalLight />
										</span>
									</div>
								)}
							</div>
						</p>
						<Menu
							id='basic-menu'
							anchorEl={anchorEl}
							open={open}
							onClose={handleClose}
							MenuListProps={{
								"aria-labelledby": "basic-button",
							}}
						>
							<MenuItem
								onClick={() => {
									if (!dataLockDetails?.result?.lockDetails?.lockId) return
									pramsLockIdState({
										lockId: dataLockDetails?.result?.lockDetails?.lockId,
										passcode: "",
									})
									setIsPopupDisableLock(true)
								}}
							>
								{t("LOCKS.DISABLE_LOCK")}
							</MenuItem>
						</Menu>
					</div>
					<div className='otherInfo'>
						<p className='header'>
							<p>{t("NEW_LOCKS.ANOTHER_INFORMATION")}</p>
						</p>
						<div className='item'>
							<div className='label'>
								<p>{t("COMMON.battery_status")}</p>
							</div>
							<div className='status green'>
								<span></span>
								<span>
									{" "}
									{dataLockDetails?.result?.lockDetails?.batteryLevel}
								</span>
							</div>
						</div>
						<div className='item'>
							<div className='label'>
								<p>{t("COMMON.Door_status_now")}</p>
							</div>
							<div className='status gray'>
								<span> </span>
								<span>
									{dataLockDetails?.result?.lockDetails?.isDoorOpen
										? t("LOCKS.OPEN")
										: t("LOCKS.CLOSED")}
								</span>
							</div>
						</div>
					</div>
				</Fragment>
			)}
		</DetailsInfoWrapper>
	)
}
