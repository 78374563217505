import styled from "styled-components"

export const ChatMessagesWrapper = styled.div`
	height: 100%;
	.guest {
		padding: 5px;
		display: flex;
		justify-content: flex-end;
		> div {
			
			padding: 16px 20px;
			border-radius: 16px 16px 16px 4px;
			background-color: #E5ECF6;
			font-size: 12px;
			font-weight: 500;
			line-height: 20px;
			color: #435363;
			text-align: left;
			p {
				color:#435363;
				font-weight:500,
				fon-size:12px;
				padding:0;
				margin:0;
				white-space: normal;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}
	.property {
		padding: 2px;
		display: flex;
		justify-content: flex-start;

		> div {
			background-color: #EEEBFF;
			padding-top:10px;
			padding-bottom:10px;
			padding-right:20px;
			padding-left:20px;
			font-size: 12px;
			font-weight: 500;
			line-height: 20px;
			color: #435363;
border-radius: 16px 16px 4px 16px;
			text-align: right;
			p {

				color:#435363;
				font-weight:500,
				fon-size:12px;
				padding:0;
				margin:0;
				white-space: normal;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}
`
