import { SectionChatsWrapper } from "./styles"
import { RiSendPlane2Line } from "react-icons/ri"
import Emptystate from "@assets/svg/Emptystate.svg?react"
import { useDM } from "@services"
import { useEffect, useMemo, useState } from "react"
import AirbnbIcon from "@assets/svg/airbnb.svg?react"
import GathernIcon from "@assets/svg/GathernIcon2.svg?react"
import BookingIcon from "@assets/svg/BookingIcon.svg?react"
import ChannelAgoda from "@assets/svg/ChannelAgoda.svg?react"
import MsoolIcon from "@assets/svg/msool-icon.svg?react"
import ChatMessages from "./ChatMessages"
import { useRecoilState, useSetRecoilState } from "recoil"
import { DMData, DMLiveFeedEventId } from "@store/index"
import { useForm, useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Box, Typography } from "@mui/material"
import Overlay from './Overlay'

interface IProps {
	onShowReservationDetails: () => void
	selectedMessageId: string | undefined,
	setAlterationId: (v: string) => void
	isAltration?: boolean
	alterationId?: string
	onDetailsClick?:()=>void
}
interface IForm {
	textareaMessage: string
}
export default function SectionChats({ onDetailsClick=()=>{},isAltration = false, alterationId = '', selectedMessageId, onShowReservationDetails = () => { }, setAlterationId = () => { } }: IProps) {
	const { t } = useTranslation();
	const [DMDataValue, setDMData] = useRecoilState(DMData)
	const [ota_reservation_code, setOta_reservationCode] = useState('')

	const [DMLiveFeedEventIdValue, setDMLiveFeedEventId] =
		useRecoilState(DMLiveFeedEventId)
	const { GetAllMessageThreadsQuery } = useDM()
	const { data: AllMessageThreads } = GetAllMessageThreadsQuery()
	console.log(selectedMessageId, 'mum', AllMessageThreads?.data?.result?.filter(x => x.id == selectedMessageId))
	const selectedThread = AllMessageThreads?.data?.result?.filter(x => x.id == selectedMessageId)

	const channals = {
		airbnb: <AirbnbIcon />,
		gathern: <GathernIcon />,
		booking: <BookingIcon />,
		agoda: <ChannelAgoda />,
		msool: <MsoolIcon />,
	}
	const { useGetMessagesQuery, GetMessageThreadsByIdQuery, SendMessageMutate } =
		useDM()
	const { mutateAsync: SendMessageMutateAsync } = SendMessageMutate()
	const { data: messageThreadsById, refetch: refetchMessageThreadsById } =
		GetMessageThreadsByIdQuery(
			{
				messageThreadId: selectedMessageId!,
			},
			{ enabled: selectedMessageId !== undefined }
		)

	const { data: allChatMessages, refetch: refetchAllChatMessages } =
		useGetMessagesQuery(
			{
				messageThreadId: selectedMessageId!,
				orderBy: "asc",
			},
			{ enabled: selectedMessageId !== undefined }
		)
	
	const getALLChat = useMemo(() => {
		const chatData = allChatMessages?.result?.map((item) => {
			return {
				message: item?.attributes?.message,
				sender: item?.attributes?.sender as "guest" | "property" | "system",
				time: item?.attributes?.inserted_at,
				id: item?.id,
				meta: item?.attributes?.meta,
			}
		})
		const filteredData = chatData?.filter((item) => item.sender !== "system")

		const systemData =
			chatData?.filter((item) => item.sender === "system") ?? []
		const reservation_requested =
			systemData?.filter((item) => item.message === "reservation_requested") ??
			[]
		const alteratn_requioested = systemData?.filter((item) => item.message === "alteration_requested") ??
			[]
		if (alteratn_requioested.length) {
			setAlterationId(alteratn_requioested[alteratn_requioested.length - 1]?.meta?.live_feed_event_id)
		} else {
			setAlterationId('')
		}
		if (reservation_requested?.length > 0) {
			setOta_reservationCode(reservation_requested[0]?.meta?.ota_reservation_code)
		} else {
			setOta_reservationCode('')
		}
		setDMLiveFeedEventId({
			live_feed_event_id:
				reservation_requested?.length > 0
					? systemData?.[0].meta.live_feed_event_id
					: undefined,
		})

		if (systemData?.length > 0) {
			setDMData({
				checkin_date: systemData?.[0]?.meta?.booking_details?.checkin_date,
				currency: systemData?.[0]?.meta?.booking_details?.currency,
				guest_name: systemData?.[0]?.meta?.booking_details?.guest_name,
				listing_name: systemData?.[0]?.meta?.booking_details?.listing_name,
				property_id: systemData?.[0]?.meta?.booking_details?.property_id,
				room_type_id: systemData?.[0]?.meta?.booking_details?.room_type_id,
			})
		} else {
			setDMData(undefined)
		}
		return filteredData ?? []
	}, [allChatMessages])

	const { handleSubmit, register, reset, watch } = useForm<IForm>()
	const textareaMessageWatch = watch("textareaMessage")

	const onSubmit = async (values: IForm) => {
		const response = await SendMessageMutateAsync({
			messageThreadId: selectedMessageId,
			data: {
				message: {
					message: values.textareaMessage,
				},
			},
		})
		if (response?.status === 200) {
			reset()
			refetchMessageThreadsById()
			refetchAllChatMessages()
		}
	}

	useEffect(() => {
	}, [textareaMessageWatch])
	return (
		<SectionChatsWrapper>
			<div className='containerHeader'>
				<div>
					{messageThreadsById?.data.result.attributes.provider &&
						channals[
						messageThreadsById?.data.result.attributes.provider.toLocaleLowerCase() as
						| "airbnb"
						| "gathern"
						| "booking"
						| "agoda"
						| "msool"
						]}
				</div>
			<Box sx={{mr:-2}} >
			<Typography sx={{fontWeight:400,fontSize:'16px',lineHeight:'20px',color:'#000000'}} >{messageThreadsById?.data.result.attributes.title}</Typography>
					<p>{DMDataValue?.listing_name??'----'}</p>
			
			</Box>
					
			</div>
			<div className='containerBody'>
				{getALLChat.length === 0 && (
					<div className='noMessages'>
						<div className='containerIconEmpty'>
							<Emptystate />
						</div>
						<p>
							<span>{t('DM.mess11')}</span>
							<span>{t('DM.mess12')}</span>
						</p>
					</div>
				)}
				{getALLChat.length !== 0 && <ChatMessages
				allChatMessages={allChatMessages}
				gusetName={ selectedThread?.length ? selectedThread[0]?.attributes?.title:''}
					alterationId={alterationId}
					onDetailsClick={onDetailsClick}
					onShowReservationDetails={onShowReservationDetails}
					isResrvationRequet={ota_reservation_code?.length > 0}
					reservationRequestNumber={ota_reservation_code}
					getALLChat={getALLChat} />}
			</div>
			<div className='containerInputData'>
				{getALLChat.length !== 0 && (
					<form onSubmit={handleSubmit(onSubmit)}>
						<textarea
							placeholder={t('DM.mess13')}
							{...register("textareaMessage", { required: true })}
						></textarea>
						<button
							className={
								textareaMessageWatch === "" ||
									textareaMessageWatch === undefined
									? "disabledButton"
									: ""
							}
						>
							<RiSendPlane2Line />
						</button>
					</form>
				)}
			</div>
		</SectionChatsWrapper>
	)
}