/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/rules-of-hooks */
import { useApi } from "./useApi"
import { ManualReservationAPI } from "../utils/constants"
import { useMutation } from "@tanstack/react-query"

const useManualReservation = () => {
	const { apiPrivate } = useApi()
	const ManualReservation = async (body: any) => {
		let response
		response = await apiPrivate.post(ManualReservationAPI, body)
		return response
	}

	
	const ManualReservationMutate = ({
		onSuccess,
		onError,
	}: {
		onSuccess?: () => void
		onError?: () => void
	}) =>
		useMutation({
			mutationFn: (body: any) => ManualReservation(body),
			onSuccess: (data) => onSuccess && onSuccess(),
			onError: (error) => onError && onError(),
		})

	return {
		ManualReservationMutate,
	}
}

export { useManualReservation }
