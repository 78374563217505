import styled from "styled-components"

export const PopupDeleteCategoriesWrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1300;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	.popupContainer {
		width: 60%;
		background-color: #fff;
		border-radius: 16px;
		padding: 32px;
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: auto 1fr auto;
		gap: 12px;
		div {
			&:nth-child(1) {
				display: flex;
				justify-content: flex-end;
				align-items: center;
				button {
					font-size: 24px;
					display: flex;
					justify-content: flex-end;
					align-items: center;
					background-color: transparent;
					border: none;
					cursor: pointer;
					color: #27272a;
				}
			}
			&:nth-child(2) {
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				gap: 12px;
				div {
					width: 48px;
					height: 48px;
					border-radius: 4px;
					background-color: #e85b5b17;
					color: #e85b5b;
					display: flex;
					justify-content: center;
					align-items: center;
				}
				p {
					margin: 0;
					color: #261b6a;
					font-size: 20px;
					font-weight: 700;
					line-height: 22px;
				}
			}
			&:nth-child(3) {
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 16px;
				margin-top: 38px;
				button {
					padding: 12px 23px;
					border-radius: 50px;
					border: none;
					cursor: pointer;
					font-size: 14px;
					font-weight: 400;
					line-height: 16.8px;
					&:nth-child(1) {
						background-color: #fafafb;
						color: #5b3fff;
					}
					&:nth-child(2) {
						background-color: #e85b5b17;
						color: #e85b5b;
					}
				}
			}
		}
	}
`
