import { UseMutationOptions, useMutation } from "@tanstack/react-query"
import { AxiosInstance, AxiosResponse } from "axios"
import {
	IPropsDeleteDisablePasscode,
	IResponseDeleteDisablePasscode,
} from "./types"
import { useApi } from "@services"
import { DisablePassCode } from "../../../utils/constants"

async function DeleteDisablePasscode(
	responseData: IPropsDeleteDisablePasscode,
	apiPrivate: AxiosInstance
) {
	try {
		const response: AxiosResponse<IResponseDeleteDisablePasscode> =
			await apiPrivate.delete(
				`${DisablePassCode}?passcode=${responseData.passcode}&lockId=${responseData.lockId}`
			)
		return response
	} catch (error: any) {
		return error.response
	}
}

export function useDeleteDisablePasscode(
	options?:
		| Omit<
				UseMutationOptions<
					AxiosResponse<IResponseDeleteDisablePasscode>,
					unknown,
					unknown,
					AxiosResponse<IResponseDeleteDisablePasscode>
				>,
				"mutationFn"
		  >
		| undefined
) {
	const { apiPrivate } = useApi()
	const mutation = useMutation({
		mutationFn: (data: IPropsDeleteDisablePasscode) =>
			DeleteDisablePasscode(data, apiPrivate),
		...options,
	})
	return mutation
}
