import { PopupDeletePropertiesWrapper } from "./styles";
import { IoClose } from "react-icons/io5";
import { FaRegTrashAlt } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { ToastMessageContext } from "@context";
import { useProperty } from "@services";

interface IProps {
  close: () => void;
  data: { propertyId?: string };
}
export default function PopupDeleteProperty({ close, data }: IProps) {
  const { t } = useTranslation();
  const { showSuccess, showError } = useContext(ToastMessageContext);

  const { deletePropertyMutate } = useProperty();

  const submitDelete = () => {
    deleteProperty(data?.propertyId);
    close();
  };

  const { mutate: deleteProperty } = deletePropertyMutate({
    onSuccess: () => {
      showSuccess(t("PROPERTIES_MANAGEMENT.DELETED_SUCCESS"));
    },
    onError: () => {
      showError(t("ERRORS.SOMETHING_WENT_WRONG"));
    },
  });

  return (
    <PopupDeletePropertiesWrapper>
      <div className="popupContainer">
        <div>
          <button onClick={close}>
            <IoClose />
          </button>
        </div>
        <div>
          <div>
            <FaRegTrashAlt />
          </div>
          <p>{t("PROPERTIES_MANAGEMENT.AreDeleteProperty")}</p>
        </div>
        <div>
          <button type="button" onClick={close}>
            {t("PROPERTIES_MANAGEMENT.cancel")}
          </button>
          <button type="button" onClick={submitDelete}>
            {t("PROPERTIES_MANAGEMENT.delete")}
          </button>
        </div>
      </div>
    </PopupDeletePropertiesWrapper>
  );
}