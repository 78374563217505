import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';

interface IStep{
  label:string;
  title:string;
}
interface IHorizontalNonLinearStepperProps{
  steps:IStep[];
  activeStep:number
}

export default function HorizontalNonLinearStepper({ steps = [], activeStep = 0 }:IHorizontalNonLinearStepperProps) {
return (
    <Box sx={{ width: '100%', px: 2 }}>
      <Box sx={{ paddingX: 4 }} >
        <Stepper
          sx={{
            "& .MuiStepConnector-line": {
              borderTopWidth: "1px",
              borderColor: '#8E98A1'
            },
            "& .MuiStepConnector-root.Mui-active .MuiStepConnector-line": {
              borderTopWidth: "1px",
              borderColor: '#5B3FFF',
            },
            "& .MuiStepConnector-root.Mui-completed .MuiStepConnector-line": {
              borderTopWidth: "1px",
              borderColor: '#5B3FFF',
            },
          }}
          activeStep={activeStep}>
          {steps.map(({ label, title }, index) => (
            <Step key={label}  completed={activeStep > index }>
              <Box sx={{ width: 20, height: 20, borderRadius: 10, borderColor: activeStep>= index? '#5B3FFF':'#8E98A1', borderWidth: 1, padding: '1px', borderStyle: 'solid', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }} >
                {
                  activeStep >= index ? <Box sx={{ backgroundColor: '#5B3FFF', height: 14, width: 14, borderRadius: 7, marginTop: '1px', marginRight: '1px' }} />
                    : null
                }
              </Box>

            </Step>
          ))}
        </Stepper>
      </Box>
      <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', px: 1 }} >
        {steps.map(({ label, title }, i) => {
          return <Box sx={{ opacity: activeStep >= i ? 1 : 0.5 }} >
            <Box sx={{ fontSize: '14px', fontWeight: 400, color: '#435363' }} >
              {label}
            </Box>
            <Box sx={{ fontSize: '16px', fontWeight: 700, color: '#5B3FFF' }} >
              {title}
            </Box>
          </Box>
        })}
      </Box>

    </Box>
  );
}