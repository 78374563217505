import { atom } from "recoil"
import { ATOMS_KEYS } from "../atom-keys"
interface IDMData {
	channals: string
	customer: {
		name: string
		phone: string
		surname: string
	}
	reservation: {
		start_date: string
		end_date: string
		nightPrice: string
		totalPrice: string
		property_id: string
		propertyName: string
		time_zone: string
		status_type: string
		listing_cancellation_host_fee_accurate:string
		expected_payout_amount_before_taxes_accurate:string,
		pass_through_tax_amount_paid_to_host_accurate:string
		listing_cancellation_payout_accurate:string
	}
}
export const DMDataReservationRequest = atom<IDMData | undefined>({
	key: ATOMS_KEYS.DMDATARESERVATIONREQUEST,
	default: undefined,
})
