import { FC } from 'react';
import { Box, CircularProgress, CircularProgressProps, Typography } from '@mui/material';

const ProgressGauge: FC<CircularProgressProps & { value: number }> = ({ value, ...props }) => {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant='determinate' {...props} value={value} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant='caption'
          fontSize={'24px'}
          fontWeight={'bold'}
          component='div'
        >{`%${Math.round(value)}`}</Typography>
      </Box>
    </Box>
  );
};
export default ProgressGauge;
