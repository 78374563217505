import { FC, useEffect, useState } from 'react';
import { Box, Modal, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IModalProps } from '@interfaces';

import { modalBoxStyle, modalContainerStyle } from '@utils';
import { Conditional, If, StepProgress } from '@components';
import StepOne from './StepOne';
import GathernProperties from './GathernProperties';
import AirBnbProperties from './AirBnbProperties';
import GathernNewLink from './GathernNewLink';
import AirBnbRedirectStep from './AirBnbRedirectStep';
import AirBnbConnectStep from './AirBnbConnectStep';
import { BackgroundCompressed } from '../../utils/image-consts';
import UnBlockingSteps from './UnBlockingSteps'
import BookingHotelId from './BookingHotelId'
import BookingOnboarding from './BookingOnboarding'
import BookingPropertiesList from './BookingPropertiesList'


const AddPropertyModal: FC<any> = ({ handleClose, open, data,property }) => {
  const { t } = useTranslation();
  const [step, setStep] = useState(1);
  const [selectedChannel, setSelectedChannel] = useState<string | undefined>();
  const searchParams = new URLSearchParams(location.search);

  const resetModal = (close: boolean) => {
    if (close) {
      handleClose();
    }
    setStep(1);
    setSelectedChannel(undefined);
  };



  const tokenExists = () => {
    let token;
    if (selectedChannel == 'gathern') {
      token = localStorage.getItem('gathernToken');
    } else if (selectedChannel == 'airbnb') {
      token = localStorage.getItem('channexChannelId');
    }
    return token && token != 'null';
  };
  const handleFirstNext = () => {
    // if (selectedChannel == 'booking') {
    //   return setStep(6)
    // }
    if (tokenExists()) {
      setStep(4);
    } else {
      setStep(2);
    }
  };
  return (
    <Modal
      open={open} 
      onClose={() => resetModal(true)}
      sx={modalContainerStyle}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box
        sx={{
          ...modalBoxStyle,
          width: { xs: '800px', lg: '1000px' },
          height: '90vh',
          display: { xs: 'flex', lg: 'grid' },
          gridTemplateColumns: { lg: 'repeat(11,1fr)' },
          p: 0,
        }}
      >
        <Box
          sx={{
            gridColumn: { lg: 'span 5 / span 5' },
            height: { xs: 'fit-content', lg: 'auto' },
            flexShrink: 0,
            px: 2,
            py: 4,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: 1,
            bgcolor: 'primary.main',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundImage: `url(${BackgroundCompressed})`,
          }}
        >
          <Typography color={'white'} fontSize={{ xs: '24px', lg: '32px' }}>
            {t('ADD_PROPERTY.TITLE')}
          </Typography>
          <Typography color={'white'} fontSize={{ xs: '14px', lg: '16px' }}>
            {t('ADD_PROPERTY.DESCRIPTION')}
          </Typography>
        </Box>
        <Box
          sx={{
            p: 2,
            gridColumn: { lg: 'span 6 / span 6' },
            flexGrow: 1,
            height: 'inherit',
            overflowY: 'auto',
            display: 'flex',
            gap: 2,
            flexDirection: 'column',
            '::-webkit-scrollbar': {
              width: '10px',
              background: '#F0F0F0',
            },
            '::-webkit-scrollbar-thumb': {
              background: '#5B3FFF',
              borderRadius: '5px',
            },

          }}
        >

          <StepProgress step={step} steps={ selectedChannel == 'booking'?4: 5} />
          <Conditional> 
            <If isTrue={step == 1}>
              <StepOne property={property} setChannel={setSelectedChannel} channel={selectedChannel} onLink={handleFirstNext} />
            </If>
            <If isTrue={selectedChannel == 'gathern' && [2, 3].includes(step)}>
              <GathernNewLink onSuccess={() => setStep(4)} step={step} setStep={setStep} /> 
            </If>
            <If isTrue={step == 4 && selectedChannel == 'gathern'}>
              <GathernProperties onNext={() => setStep(5)} reset={resetModal} />
            </If>
            <If isTrue={step == 2 && selectedChannel == 'airbnb'}>
              <AirBnbRedirectStep reset={resetModal} />
            </If>
            <If isTrue={step >= 2 && selectedChannel == 'booking'}> 
              <RenderBookingSteps property={property} onDone={()=> resetModal(true)} onNext={() => setStep(step + 1)} step={step} />
            </If>
            <If isTrue={step == 3 && selectedChannel == 'airbnb'}> 
              <AirBnbConnectStep
                reset={resetModal}
                token={searchParams.get('channel_id')}
                onConnect={() => setStep(4)}
              />
            </If>
            <If isTrue={step == 4 && selectedChannel == 'airbnb'}>
              <AirBnbProperties onNext={() => setStep(5)} reset={resetModal} channelId={localStorage.getItem('channexChannelId') ?? ''} />
            </If>



            <If isTrue={step == 5}>
              <UnBlockingSteps
                channel={selectedChannel == 'airbnb' ? 'Airbnb' : 'GatherIn'}
                onClose={() => {
                  resetModal(true)
                }} />
            </If>
      
          </Conditional>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddPropertyModal;


const RenderBookingSteps = ({ step = 2, onNext = () => { },onDone=()=>{},property }:any) => {
  const [hotelid, setHotelId] = useState('')
  if (step == 2) return <BookingOnboarding onNext={onNext} />
  if (step >= 3) return <BookingHotelId  onDone={onDone} property={property} onSUccessHotel={(n: any) => setHotelId(n)} onNext={onNext} />  
  if (step >3) return <BookingPropertiesList hotelid={hotelid} onNext={onNext} onDone={onDone}  /> 
 
}


 