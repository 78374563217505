import styled from "styled-components"

export const PopupReservationsListWrapper = styled.div`
	position: absolute;
	width: 30%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: #fff;
	box-shadow: 2px 2px 5px #ccc;
	z-index: 99;
	border-radius: 24px;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: auto 1fr;
	gap: 16px;
	overflow: hidden;
	padding-block: 24px;
	.containerHeader {
		padding-inline: 20px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		color: #435363;
		font-size: 24px;
		font-weight: 500;
		line-height: 28.8px;
		div {
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
		}
	}
	.containerAllData {
		padding-inline: 20px;
		overflow-x: scroll;
		.containerCard {
			margin-bottom: 20px;
			> div {
				&:first-child {
					display: flex;
					justify-content: flex-start;
					align-items: flex-start;
					gap: 8px;
					margin-bottom: 8px;
					> div {
						&:first-child {
							width: 20px;
							height: 20px;
							border: 1px solid #ccc;
							background-color: #ccc;
							overflow: hidden;
							border-radius: 50px;
							display: flex;
							justify-content: center;
							align-items: center;
							svg {
								width: 20px;
								height: 20px;
							}
						}
						&:last-child {
							p {
								font-size: 14px;
								font-weight: 700;
								color: #435363;
							}
						}
					}
				}

				&:last-child {
					background-color: #fff;
					padding: 14px 10px;
					border-radius: 8px;
					box-shadow: 2px 4px 20px 0px #0000000d;
					display: flex;
					justify-content: flex-start;
					align-items: center;
					gap: 24px;
					div {
						/* &:first-child {
							border: 1px solid #ccc;
							background-color: #ccc;
							width: 30%;
							height: 80px;
							border-radius: 8px;
							img {
								width: 100%;
								height: 100%;
								object-fit: contain;
							}
						} */
						&:last-child {
							p {
								margin-bottom: 4px;
								display: flex;
								justify-content: flex-start;
								align-items: center;
								gap: 8px;
								span {
									&:first-child {
										color: #0f172a;
										font-size: 12px;
										font-weight: 500;
										line-height: 14.4px;
									}
									&:last-child {
										color: #435363;
										font-size: 12px;
										font-weight: 400;
										line-height: 14.4px;
									}
								}
								&:first-child {
									span {
										&:first-child {
											color: #5b3fff;
										}
										&:last-child {
											color: #435363;
											font-size: 12px;
											font-weight: 700;
											line-height: 14.4px;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
	@media only screen and (max-width: 1024px) {
		width: 50%;
	}
`
