import styled from "styled-components"

export const EditReservationWrapper = styled.div`
	width: 100dvw;
	height: 100dvh;
	display: flex;
	justify-content: center;
	align-items: center;
	.containerPopup {
		box-shadow: 2px 4px 38px 0px #0000001a;
		background-color: #fff;
		padding: 32px;
		border-radius: 16px;
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: auto 1fr auto;
		gap: 23px;
		h1 {
			margin: 0;
			padding: 0;
			color: #261b6a;
			font-size: 20px;
			font-weight: 700;
			line-height: 24px;
		}
		form {
			.containerBody {
				.containerTabButtons {
					display: flex;
					justify-content: flex-start;
					align-items: center;
					gap: 4px;
					margin-bottom: 23px;
					.item {
						p {
							margin: 0;
							padding: 0;
						}
						cursor: pointer;
						padding: 8px 12px;
						border-radius: 8px;
						font-size: 14px;
						font-weight: 700;
						line-height: 20px;
						color: #8e98a1;
						&.active {
							background-color: #eeebff;
							color: #364152;
						}
					}
				}
			}
			.containerFooter {
				display: flex;
				justify-content: space-between;
				align-items: center;
				gap: 8px;
			}
		}
	}
`
