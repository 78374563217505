import styled from "styled-components"

export const PopupConfirmationSendMessegWrapper = styled.div`
	border: 1px solid #000;
	position: fixed;
	width: 100dvw;
	height: 100dvh;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	.containerPopup {
		background-color: #fff;
		box-shadow: 2px 4px 38px 0px #0000001a;
		padding: 32px;
		border-radius: 16px;
		div {
			&:first-child {
				display: flex;
				justify-content: space-between;
				align-items: center;
				color: #27272a;
				font-size: 20px;
				font-weight: 700;
				line-height: 24px;
				margin-bottom: 32px;
				.containerClose {
					cursor: pointer;
				}
			}
			&:nth-child(2) {
				margin-bottom: 32px;
				p {
					font-size: 20px;
					font-weight: 400;
					line-height: 24px;
					color: #3f3f46;
				}
			}
		}
		p {
			margin: 0;
		}
	}
`
