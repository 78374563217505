import { Dispatch, SetStateAction, useContext, useEffect } from "react"
import { PopupEditCategoryWrapper } from "./styles"
import { useCategoryMangment } from "@services"
import { IoClose } from "react-icons/io5"
import PorpertesCard from "./porpertesCard"
import { FormProvider, useForm } from "react-hook-form"
import Input from "@Common/input"
import { useTranslation } from "react-i18next"
import { ToastMessageContext } from "@context/toast-message-cotnext"
import { useQueryClient } from "@tanstack/react-query"
import { GetListingManagementCategoriesUrl } from "../../../utils/constants"

interface IProps {
	setEditCategory: Dispatch<SetStateAction<boolean>>
	recordData:
		| {
				id: number
				categoryId: number
				categoryName: string
				propertyCounts: number
				channals: number[]
		  }
		| undefined
}
export interface IForm {
	categoryId: string
	categoryName: string
	propertyId: string[]
}
export default function PopupEditCategory({
	setEditCategory,
	recordData,
}: IProps) {
	const { showSuccess } = useContext(ToastMessageContext)

	const { t } = useTranslation()
	const { GetCategoryByIdQuery, UpdateCategoryMutate } = useCategoryMangment()
	const { mutateAsync: UpdateCategory } = UpdateCategoryMutate()
	const { data: GetCategoryById } = GetCategoryByIdQuery(
		recordData?.categoryId.toString()!
	)
	const methods = useForm<IForm>()
	const { setValue } = methods
	const queryClient = useQueryClient()
	const onSubmit = async (value: IForm) => {
		const data = {
			...value,
			categoryId: recordData?.categoryId.toString()!,
			propertyId: !Array.isArray(value?.propertyId)
				? [value.propertyId]
				: value.propertyId,
		}
		const response = await UpdateCategory(data)
		console.log("UpdateCategory", response)
		if (response?.data.statusCode >= 200 && response?.data.statusCode < 300) {
			// showSuccess(response?.data?.result)
			showSuccess(t('CATEGORYMANAGER.updateSuccess'))
			setEditCategory(false)
			queryClient.invalidateQueries({
				queryKey: [GetListingManagementCategoriesUrl],
			})
		}
	}
	useEffect(() => {
		if (recordData?.categoryName) {
			setValue("categoryName", recordData?.categoryName)
		}
	}, [setValue, recordData])
	return (
		<PopupEditCategoryWrapper>
			<FormProvider {...methods}>
				<form onSubmit={methods.handleSubmit(onSubmit)} id='formAddCategory'>
					<div className='popupContainer'>
						<div>
							<button type='button' onClick={() => setEditCategory(false)}>
								<IoClose />
							</button>
						</div>
						<div>
							<p>{GetCategoryById?.data?.result[0]?.categoryName}</p>
						</div>
						<div>
							<Input
								label={t("CATEGORYMANAGER.CATEGORYNAME")}
								placeholder={t("CATEGORYMANAGER.CATEGORYNAME")}
								name='categoryName'
								registerOptions={{
									required: {
										value: true,
										message: t("COMMON.REQUIRED"),
									},
								}}
							/>
						</div>
						<div>
							<div>
								<p>{t("CATEGORYMANAGER.NUM_OF_PROPERTY")}</p>
								<div>
									<button type='submit'>{t("CATEGORYMANAGER.Save")}</button>
								</div>
							</div>
							<div>
								<PorpertesCard
									categoryId={recordData?.categoryId?.toString()!}
								/>
							</div>
						</div>
					</div>
				</form>
			</FormProvider>
		</PopupEditCategoryWrapper>
	)
}