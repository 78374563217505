import React, { FC, ReactNode, Suspense } from 'react';
import { Background, Logo } from '../../utils/image-consts';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const AuthLayout: FC<{ children: ReactNode; logo?: boolean }> = ({ children, logo = false }) => {
  const navigate = useNavigate();
  return (
    <Suspense fallback={<></>}>
      <Box
        sx={{
          background: `url(${Background}) no-repeat center center fixed`,
          backgroundSize: 'cover',
          minHeight: '100vh',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          p: 2,
          py: 6,
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            maxWidth: '100%',
            backgroundColor: '#ffffff',
            p: 4,
            borderRadius: 5,
          }}
        >
          {logo && (
            <Box
              component='img'
              src={Logo}
              width='143px'
              height='56px'
              onClick={() => navigate('/')}
              sx={{ mb: 2, objectFit: 'contain', cursor: 'pointer' }}
            />
          )}
          {children}
        </Box>
      </Box>
    </Suspense>
  );
};
export default AuthLayout;
