import styled from "styled-components"

export const CustomDayCellContentWrapper = styled.div<{
	$isToday: boolean
	$isPast: boolean
}>`
	height: 100%;
	width: 100%;
	/* background-color: ${(props) => (props.$isPast ? "#F6F6F6" : "#fff")}; */
	.days {
		background-color: ${(props) =>
			props.$isToday ? "#5B3FFF" : "transparent"};
		color: ${(props) => (props.$isToday ? "#fff" : "#969696")};
		font-size: 21px;
		font-weight: 500;
		border-radius: 50px;
		width: 25px;
		height: 25px;
		display: flex;
		justify-content: center;
	}

	.dataDisabel {
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		background-color: #f6f6f6;
		width: 100%;
		height: 100%;
		flex-direction: column;
		z-index: -1;
		/* &:hover {
			cursor: not-allowed;
		} */
		> div {
			width: 100%;
			height: 100%;
			color: #6b7280;
			font-size: 20px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
`
