import { useTranslation } from 'react-i18next';
import { modalBoxStyle, modalContainerStyle } from '@utils';
import { Box, Button, Modal, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import InActiveIcon from '@assets/svg/inactive-modal-icon.svg?react';
import { InActiveBg } from '../../utils/image-consts';
import useBreakPoinst from '@hooks/useBreakPoinst';
const mode = import.meta.env.VITE_MODE ??   'not-found';

const SupscriptModal = ({open=false,onClose=()=>{},onSupscribe=()=>{}}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {isPhone}=useBreakPoinst()
  return (
    <Modal
      open={open}
      sx={{ ...modalContainerStyle }} 
      aria-labelledby='add-message-modal-title'
      aria-describedby='add-message-modal-description'
    
    >
      <Box sx={{ ...modalBoxStyle, p: 0, width: '800px', maxWidth: '80vw', height:isPhone?'93vh': '500px', maxHeight:isPhone?'93vh':  '80vh' }}>
        <Box
          sx={{
            bgcolor: (theme) => theme.palette.primary.main,
            width: '100%',
            flex:isPhone?0.5: 1,
            p: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundImage: `url(${InActiveBg})`,
            backgroundSize: 'cover',
          }}
        >
          <Box
            sx={{
              flex: 1,
              aspectRatio: 1,
              svg: {
                width: '100%',
                height: '100%',
              },
            }}
          >
            <InActiveIcon />
          </Box>
        </Box>
        <Box
          sx={{
            p: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          //  flex: 1,
          }}
        >
          <Typography textAlign={'center'} sx={{
            color:'rgba(91, 63, 255, 1)',
            fontWeight:700,
            fontSize:isPhone?'13px':'24px',
            lineHeight:isPhone?undefined:'29px'
          }}>
           
            {t('trial.MODAL_TITLE')}
             </Typography>
             <Typography sx={{
                color:'rgba(67, 83, 99, 1)',
                fontWeight:500,
                fontSize:isPhone?'13px':'24px',
                lineHeight:isPhone?undefined:'29px',
                my:1,
                textAlign:'center',
                width:'70%'
             }} >
              {t('trial.MODAL_SUB_TITLE')}
             </Typography>
             
          <Button size={'large'} sx={{mt:1,color:'rgba(255, 255, 255, 1)',fontWeight:700}} variant={'contained'} onClick={() => {
            onSupscribe()
          }}>
            {t('SUBSCRIBE_NOW')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
export default SupscriptModal;
