import styled from "styled-components"

export const PopupInfoWrapper = styled.div`
	position: absolute;
	bottom: 40px;
	right: 39px;
	transform: translateX(50%);
	width: 290px;
	overflow: hidden;
	background-color: #ffffff;
	box-shadow: 2px 4px 38px 0px #0000001a;
	border-radius: 16px;
	padding: 24px;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 200px auto auto;
	justify-content: center;
	align-items: center;
	.containerIcon {
		width: 100%;
		height: 100%;
		svg {
			width: 100%;
			height: 100%;
		}
	}
	p {
		text-align: center;
		text-wrap: auto;
		color: #435363;
		line-height: 19.2px;
		font-size: 16px;
		direction: rtl;
		&.AUTO_ACCESS {
			font-weight: 700;
		}
		&.MODAL_TITLE {
			font-weight: 400;
		}
	}
`
