import { useApi } from "../useApi"
import {
	AirbnbHandleReservationRequestUrl,
	Channex_GetLiveEventByIdUrl,
	Channex_GetMessagesUrl,
	Channex_GetMessageThreadsByIdUrl,
	Channex_MessageThread_SendMessagetUrl,
	GetAllMessageThreadsUrl,
	RejectReasonUrl,
	AlternativeRequestUrl
} from "../../utils/constants"
import { useMutation, useQuery, UseQueryOptions } from "@tanstack/react-query"
import {
	IGetAllMessageThreads,
	IGetMessagesProps,
	IGetMessagesResponse,
	IGetMessageThreadsById,
	IGetMessageThreadsByIdProps,
	IGetPropertyDetails,
	IGetPropertyDetailsProps,
	IGetRejectReasonProps,
	IGetRejectReasonResponse,
	IHandleReservationRequestProps,
	ISendMessageProps,
} from "./types"

const useDM = () => {
	const { apiPrivate } = useApi()
	/* ---------------------------- Get All messages ---------------------------- */
	const GetAllMessageThreads = async () => {
		const response: IGetAllMessageThreads = await apiPrivate.get(
			GetAllMessageThreadsUrl
		)
		return response
	}

	const GetAllMessageThreadsQuery = () =>
		useQuery({
			queryKey: [GetAllMessageThreadsUrl],
			queryFn: () => GetAllMessageThreads(),
		})

	/* ------------------------ Get Message Threads By Id ----------------------- */
	const GetMessageThreadsById = async ({
		messageThreadId,
	}: IGetMessageThreadsByIdProps) => {
		const response: IGetMessageThreadsById = await apiPrivate.get(
			Channex_GetMessageThreadsByIdUrl,
			{
				params: {
					messageThreadId: messageThreadId,
				},
			}
		)
		return response
	}

	const GetMessageThreadsByIdQuery = (
		data: IGetMessageThreadsByIdProps,
		options?: Omit<
			UseQueryOptions<IGetMessageThreadsById, unknown, IGetMessageThreadsById>,
			"queryKey" | "queryFn"
		>
	) =>
		useQuery({
			queryKey: [Channex_GetMessageThreadsByIdUrl, ...Object.values(data)],
			queryFn: () => GetMessageThreadsById(data),
			...options,
		})

	/* ---------------------------- property details ---------------------------- */
	const GetPropertyDetails = async ({ eventId }: IGetPropertyDetailsProps) => {
		const response: IGetPropertyDetails = await apiPrivate.get(
			`${Channex_GetLiveEventByIdUrl}/${eventId}`
		)
		return response
	}

	const GetPropertyDetailsQuery = (
		data: IGetPropertyDetailsProps,
		options?: Omit<
			UseQueryOptions<IGetPropertyDetails, unknown, IGetPropertyDetails>,
			"queryKey" | "queryFn"
		>
	) =>
		useQuery({
			queryKey: [Channex_GetLiveEventByIdUrl, ...Object.values(data)],
			queryFn: () => GetPropertyDetails(data),
			...options,
		})

	/* ------------------------------ Get Messages ------------------------------ */
	async function GetMessages({ messageThreadId, orderBy }: IGetMessagesProps) {
		try {
			const response = await apiPrivate.get<IGetMessagesResponse>(
				`${Channex_GetMessagesUrl}?messageThreadById=${messageThreadId}&orderBy=order[inserted_at]=${orderBy}`
			)
			return response.data
		} catch (error: any) {
			console.log(error)
			throw new Error(error)
		}
	}

	function useGetMessagesQuery(
		data: IGetMessagesProps,
		options?: Omit<
			UseQueryOptions<IGetMessagesResponse, unknown, IGetMessagesResponse>,
			"queryKey" | "queryFn"
		>
	) {
		return useQuery({
			queryKey: [Channex_GetMessagesUrl, ...Object.values(data)],
			queryFn: () => GetMessages(data),
			...options,
		})
	}
	/* ---------------------------- get Reject Reason --------------------------- */
	async function GetRejectReason({ lang }: IGetRejectReasonProps) {
		try {
			const response = await apiPrivate.get<IGetRejectReasonResponse>(
				`${RejectReasonUrl}?lang=${lang}`
			)
			return response.data
		} catch (error: any) {
			console.log(error)
			throw new Error(error)
		}
	}

	function useGetRejectReasonQuery(
		data: IGetRejectReasonProps,
		options?: Omit<
			UseQueryOptions<IGetRejectReasonResponse, unknown, IGetRejectReasonResponse>,
			'queryKey' | 'queryFn'
		>
	) {
		return useQuery({
			queryKey: [RejectReasonUrl, ...Object.values(data)],
			queryFn: () => GetRejectReason(data),
			...options,
		})
	}
	/* ---------------------------- post send message --------------------------- */
	const SendMessage = async ({ messageThreadId, data }: ISendMessageProps) => {
		const response = await apiPrivate.post(
			`${Channex_MessageThread_SendMessagetUrl}?messageThreadId=${messageThreadId}`,
			data
		)
		return response
	}

	const SendMessageMutate = () =>
		useMutation({
			mutationFn: (data: any) => SendMessage(data),
		})

	/* ----------------------- Handle Reservation Request ----------------------- */
	const HandleReservationRequest = async ({
		bookingId,
		data,
	}: IHandleReservationRequestProps) => {
		const response = await apiPrivate.post(
			`${AirbnbHandleReservationRequestUrl}?bookingId=${bookingId}`,
			data
		)
		return response
	}

	const HandleReservationRequestMutate = ({
		onSuccess,
		onError,
	}: {
		onSuccess?: () => void
		onError?: (e: any) => void
	}) =>
		useMutation({
			mutationFn: (data: IHandleReservationRequestProps) =>
				HandleReservationRequest(data),
			onSuccess: (dataSuccess: any) => onSuccess && onSuccess(),
			onError: (error: any) => onError && onError(error),
		})
/* ----------------------- Handle Alterbative Request ----------------------- */
const HandleAlternativeRequest = async ({
	requestId,
	accept,
}: {requestId:string,accept:string}) => {
	const response = await apiPrivate.post(
		`${AlternativeRequestUrl}/${requestId}?accept=${accept}`
	)
	return response
}
const HandleAlternativeRequestMutate = ({
	onSuccess,
	onError,
}: {
	onSuccess?: () => void
	onError?: (e: any) => void
}) =>
	useMutation({
		mutationFn: (data: any) =>
		HandleAlternativeRequest(data),
		onSuccess: (dataSuccess: any) => onSuccess && onSuccess(),
		onError: (error: any) => onError && onError(error),
	})
	return {
		GetAllMessageThreadsQuery,
		GetMessageThreadsByIdQuery,
		useGetMessagesQuery,
		SendMessageMutate,
		GetPropertyDetailsQuery,
		useGetRejectReasonQuery,
		HandleReservationRequestMutate,
		HandleAlternativeRequestMutate
	}
}
export { useDM }
