// @ts-nocheck

import { FC, useLayoutEffect } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { ICategoryChartData } from '@interfaces';
const HorizontalBarChart: FC<{ id?: string; data?: ICategoryChartData[] }> = ({ id = 'horzBar', data }) => {
  useLayoutEffect(() => {
    const root = am5.Root.new(id);
    root.setThemes([am5themes_Animated.new(root)]);
    root._logo.dispose();

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: 'none',
        wheelY: 'none',
        paddingLeft: 0,
      }),
    );

    // We don't want zoom-out button to appear while animating, so we hide it
    chart.zoomOutButton.set('forceHidden', true);

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    const yRenderer = am5xy.AxisRendererY.new(root, {
      minGridDistance: 30,
      minorGridEnabled: true,
    });

    yRenderer.grid.template.set('location', 1);

    const yAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(root, {
        maxDeviation: 0,
        categoryField: 'category',
        renderer: yRenderer,
        tooltip: am5.Tooltip.new(root, { themeTags: ['axis'] }),
      }),
    );

    const xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 0,
        min: 0,
        numberFormatter: am5.NumberFormatter.new(root, {
          numberFormat: '#,###a',
        }),
        extraMax: 0.1,
        renderer: am5xy.AxisRendererX.new(root, {
          strokeOpacity: 0.1,
          minGridDistance: 80,
        }),
      }),
    );

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    const series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: 'Series 1',
        xAxis: xAxis,
        yAxis: yAxis,
        fill: am5.color(0x5b3fff),
        valueXField: 'value',
        categoryYField: 'category',
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: 'left',
          labelText: '{valueX}',
        }),
      }),
    );

    // Rounded corners for columns
    series.columns.template.setAll({
      cornerRadiusTR: 5,
      cornerRadiusBR: 5,
      strokeOpacity: 0,
    });

    // Set data

    yAxis.data.setAll(data);
    series.data.setAll(data);
    sortCategoryAxis();

    // Get series item by category
    function getSeriesItem(category) {
      for (let i = 0; i < series.dataItems.length; i++) {
        const dataItem = series.dataItems[i];
        if (dataItem.get('categoryY') == category) {
          return dataItem;
        }
      }
    }

    chart.set(
      'cursor',
      am5xy.XYCursor.new(root, {
        behavior: 'none',
        xAxis: xAxis,
        yAxis: yAxis,
      }),
    );

    // Axis sorting
    function sortCategoryAxis() {
      // Sort by value
      series.dataItems.sort(function (x, y) {
        return x.get('valueX') - y.get('valueX'); // descending
        //return y.get("valueY") - x.get("valueX"); // ascending
      });

      // Go through each axis item
      am5.array.each(yAxis.dataItems, function (dataItem) {
        // get corresponding series item
        const seriesDataItem = getSeriesItem(dataItem.get('category'));

        if (seriesDataItem) {
          // get index of series data item
          const index = series.dataItems.indexOf(seriesDataItem);
          // calculate delta position
          const deltaPosition = (index - dataItem.get('index', 0)) / series.dataItems.length;
          // set index to be the same as series data item index
          dataItem.set('index', index);
          // set deltaPosition instanlty
          dataItem.set('deltaPosition', -deltaPosition);
          // animate delta position to 0
          dataItem.animate({
            key: 'deltaPosition',
            to: 0,
            duration: 1000,
            easing: am5.ease.out(am5.ease.cubic),
          });
        }
      });

      // Sort axis items by index.
      // This changes the order instantly, but as deltaPosition is set,
      // they keep in the same places and then animate to true positions.
      yAxis.dataItems.sort(function (x, y) {
        return x.get('index') - y.get('index');
      });
    }

    // update data with random values each 1.5 sec
    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    series.appear(1000);
    chart.appear(1000, 100);
  }, []);
  return <div dir={'ltr'} id={id} style={{ width: '100%', height: '100%' }}></div>;
};
export default HorizontalBarChart;
