import styled from "styled-components"

export const PopupOverlayWrapper = styled.div<{ lang: "ar" | "en" }>`
	position: fixed;
	left: ${(props) => (props.lang === "ar" ? 0 : "auto")};
	right: ${(props) => (props.lang === "ar" ? "auto" : 0)};
	top: 0;
	width: 40%;
	background-color: #fff;
	height: 100%;
	z-index: 999;
	box-shadow: 2px 4px 20px 0px #00000033;
	padding: 24px;
	overflow-y: scroll;
	.NavigateSection {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		button {
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 29px;
			background-color: transparent;
			border: none;
			cursor: pointer;
			color: #435363;
		}
	}
`
