import styled from "styled-components"

export const ManualReservationWrapper = styled.div`
	.containerTitleSection {
		margin-bottom: 50px;
		p {
			margin: 0;
			color: #435363;
			font-size: 24px;
			font-weight: 400;
			line-height: 28.8px;
		}
	}
	.containerFooterButtons {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 36px;
		button {
			width: 154px;
		}
		div {
			&:last-child {
				display: flex;
				justify-content: flex-end;
				align-items: center;
				gap: 24px;
			}
		}
	}
	.containerForms {
		display: grid;
		grid-template-columns: auto 1fr;
		grid-template-rows: 100%;
		> div {
			&:last-child {
				padding-top: 42px;
				padding: 40px;
			}
		}
	}
`
