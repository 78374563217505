import { FC, useEffect, useMemo } from 'react';
import { useProperty } from '@services';
import PropertiesList from './PropertiesList';
import { useTranslation } from 'react-i18next';
import AirbnbIcon from '@assets/svg/airbnb-icon.svg?react';
import { useIsMutating } from '@tanstack/react-query';

const AirBnbProperties: FC<{ reset: (close: boolean) => void; channelId: string, onNext: () => void }> = ({ reset, channelId, onNext }) => {
  const { getAirbnbListingQuery, addPropertyMutate } = useProperty();

  const { t } = useTranslation();
  const { data, isLoading, isError: errorFetching } = getAirbnbListingQuery(channelId);
  const mutating = useIsMutating();
  const { mutate: addProperty, isError, isSuccess } = addPropertyMutate({});

  const onLink = (properties: any[]) => {
    const payload: any = properties?.map((property) => ({
      propertyLocation: property?.city,
      propertyId: 0,
      propertyName: property?.title,
      propertyIsDeteled: false,
      propertyCreateDate: null,
      gathrenUnitId: '',
      airbnbUnitId: `${property?.id}`,
      autoAccess: false,
      status: true,
      templateId: null,
      propertyImage: property?.image,
    }));
    addProperty(payload);
  };
  const list = useMemo(() => {
    return data?.response?.data?.listing_id_dictionary?.values ?? [];
  }, [data]);
  return (
    <>
      <PropertiesList
        loading={isLoading}
        channel={t('AIRBNB')}
        mutate={onLink}
        channelIcon={<AirbnbIcon />}
        propertyList={list}
        success={isSuccess}
        error={isError || errorFetching}
        reset={reset}
        mutating={mutating > 0}
        onNext={onNext}
      />
    </>
  );
};
export default AirBnbProperties;
