import React, { FC } from 'react';
import classNames from 'classnames';
import { Radio, Typography } from '@mui/material';

//wrap it with flex
const RadioItem: FC<{ value: string | number; itemValue?: string | number; text: string; small?: boolean }> = ({
  itemValue,
  value,
  text,
  small,
}) => {
  return (
    <div
      className={classNames(
        `flex gap-2 items-center cursor-pointer p-1 rounded-full border border-solid w-fit`,
        itemValue == value ? ' border-primary bg-[#EEEBFF]' : 'border-[#EAEDF1]',
        small ? '[&_.PrivateSwitchBase-input]:size-4 [&_.MuiButtonBase-root]:p-0 pe-2' : 'pe-4',
      )}
    >
      <Radio value={value} size={small ? 'small' : 'medium'} />
      <Typography color={itemValue == value ? 'primary' : '#8E98A1'}>{text}</Typography>
    </div>
  );
};
export default RadioItem;
