import { useTranslation } from "react-i18next"
import { ReservationDetailsWrapper } from "./styles"
import AirbnbIcon from "@assets/svg/airbnb.svg?react"
import GathernIcon from "@assets/svg/GathernIcon2.svg?react"
import BookingIcon from "@assets/svg/BookingIcon.svg?react"
import ChannelAgoda from "@assets/svg/ChannelAgoda.svg?react"
import MsoolIcon from "@assets/svg/msool-icon.svg?react"
import MoneysIcon from "@assets/svg/MoneysIcon.svg?react"
import IconMessage from "@assets/svg/IconMessage.svg?react"
import CalendarTick from "@assets/svg/calendarTick.svg?react"
import HomeIcon from "@assets/svg/HomeIcon.svg?react"
import UserOctagon from "@assets/svg/userOctagon.svg?react"
import { useRecoilValue } from "recoil"
import { loaderState, reservationDetails } from "@store/index"
import dayjs from "dayjs"
import { useContext, useState } from "react"
import { ToastMessageContext } from "@context/toast-message-cotnext"
import { ContentCopyOutlined } from "@mui/icons-material"
import DetailsInfo from "./detailsInfo"
import PopupConfirmationSendMesseg from "./popupConfirmationSendMesseg"
import ActionView from "./actionView"
import PopupEnableSmartEntryFeature from "./popupEnableSmartEntryFeature"
import PopupDisableLock from "./popupDisableLock"
import CustomLoading from "../../../../../CustomLoading"
import CustomButton from "@Common/CustomButton"
import CancelReservationPopup from "./cancelReservation"
import EditReservationPopup from "./editReservation"

interface IChannals {
	Airbnb: JSX.Element
	GatherIn: JSX.Element
	Booking: JSX.Element
	Agoda: JSX.Element
	Msool: JSX.Element
}
const channals: IChannals = {
	Airbnb: <AirbnbIcon />,
	GatherIn: <GathernIcon />,
	Booking: <BookingIcon />,
	Agoda: <ChannelAgoda />,
	Msool: <MsoolIcon />,
}

export default function ReservationDetails() {
	const [
		isPopupConfirmationSendMessegOpen,
		setIsPopupConfirmationSendMessegOpen,
	] = useState(false)
	const [isOpenCancelReservationPopup, setIsOpenCancelReservationPopup] =
		useState(false)
	const [isOpenEditReservationPopup, setIsOpenEditReservationPopup] =
		useState(false)
	const isloaderState = useRecoilValue(loaderState)

	const [isPopupEnableSmartEntryFeature, setIsPopupEnableSmartEntryFeature] =
		useState(false)
	const [isPopupDisableLock, setIsPopupDisableLock] = useState(false)
	const reservationDetailsValue = useRecoilValue(reservationDetails)
	const { t } = useTranslation()
	const { showSuccess } = useContext(ToastMessageContext)
	const checkOut = dayjs(reservationDetailsValue?.checkOut).format("YYYY-MM-DD")
	const isBeforeDate = dayjs().isBefore(checkOut, "month")
	const styleStatus = () => {
		const status = reservationDetailsValue?.bookingStatus
		if (status === "مؤكد" || status === "new") {
			return "green"
		}
		if (status === "cancelled" || status === "ملغي") {
			return "gray"
		}
		return "yellow"
	}

	const handleCopyClick = () => {
		if (!reservationDetailsValue?.reservationId) return
		navigator.clipboard
			.writeText(reservationDetailsValue?.reservationId)
			.then(() => {
				showSuccess(t("COMMON.RESERVATION_COPIED"))
			})
			.catch((err) => {
				console.error("Failed to copy: ", err)
			})
	}

	return (
		<ReservationDetailsWrapper status={styleStatus()}>
			{isloaderState && <CustomLoading />}
			<CancelReservationPopup
				isOpen={isOpenCancelReservationPopup}
				setIsOpen={setIsOpenCancelReservationPopup}
			/>
			<EditReservationPopup
				isOpen={isOpenEditReservationPopup}
				setIsOpen={setIsOpenEditReservationPopup}
			/>
			{isPopupConfirmationSendMessegOpen && (
				<PopupConfirmationSendMesseg
					setIsPopupConfirmationSendMessegOpen={
						setIsPopupConfirmationSendMessegOpen
					}
				/>
			)}
			{isPopupEnableSmartEntryFeature && (
				<PopupEnableSmartEntryFeature
					setIsPopupEnableSmartEntryFeature={setIsPopupEnableSmartEntryFeature}
				/>
			)}

			{isPopupDisableLock && (
				<PopupDisableLock setIsPopupDisableLock={setIsPopupDisableLock} />
			)}
			<div className='containerHeader'>
				<p>{t("CALENDAR.RESERVATIONDETAILS")}</p>
				{reservationDetailsValue?.bookingStatus !== "cancelled" &&
					reservationDetailsValue?.bookingStatus !== "ملغي" &&
					reservationDetailsValue?.channelName.toLocaleLowerCase() ===
						"msool" && (
						<div className='containerButtons'>
							<CustomButton
								label={t("COMMON.BOOKING_UPDATED")}
								withBG
								customPadding='12px 22px'
								borderRadius='8px'
								onClick={() => setIsOpenEditReservationPopup(true)}
							/>
							<CustomButton
								label={t("MANUALRESERVATION.CANCELLATIONOFRESERVATION")}
								customBG='#fdf0f0'
								customColor='#E85B5B'
								borderColor='#fdf0f0'
								customPadding='12px 22px'
								borderRadius='8px'
								onClick={() => setIsOpenCancelReservationPopup(true)}
							/>
						</div>
					)}
			</div>
			<div className='channalAndStatus'>
				<div className='channal'>
					<div>
						{reservationDetailsValue?.channelName &&
							channals[reservationDetailsValue?.channelName]}
					</div>
					<p>{reservationDetailsValue?.channelName}</p>
				</div>
				{reservationDetailsValue?.bookingStatus && (
					<div className='status'>
						<span></span>
						<span>{reservationDetailsValue?.bookingStatus}</span>
					</div>
				)}
			</div>
			<div className='infoSection'>
				<div>
					<div className='iconSection'>
						<UserOctagon />
					</div>
					<div className='titleSection'>
						<p>{t("Reservation.GuestName")}</p>
					</div>
					<div className='detailsSection'>
						<p>{reservationDetailsValue?.guestName}</p>
						<p className='guestPhoneNumber'>
							<span>{reservationDetailsValue?.guestPhoneNumber}</span>
						</p>
					</div>
				</div>
				<div>
					<div className='iconSection'>
						<HomeIcon />
					</div>
					<div className='titleSection'>
						<p>{t("COMMON.PROPERTY")}</p>
					</div>
					<div className='detailsSection'>
						<p>{reservationDetailsValue?.propertyName}</p>
						<p>
							<span>{t("Reservation.BookingNum")} : </span>
							<span>{reservationDetailsValue?.reservationId}</span>
							<div
								className='handleCopyClick'
								onClick={() => handleCopyClick()}
							>
								<ContentCopyOutlined sx={{ width: "16px" }} />
							</div>
						</p>
					</div>
				</div>
			</div>

			<div className='dateAndTimeSection'>
				<div className='iconSection'>
					<CalendarTick />
				</div>
				<div className='titleSection'>
					<p>{t("MANUALRESERVATION.TIMEANDDATE")}</p>
				</div>
				<div className='dateAndTime'>
					<div className='cotainer'>
						<p>
							{t("CALENDAR.CHECK_IN")}:{" "}
							{reservationDetailsValue?.checkIn &&
								dayjs(reservationDetailsValue?.checkIn).format("YYYY-MM-DD")}
						</p>
						<div className='time'>
							{reservationDetailsValue?.checkOut &&
								dayjs(reservationDetailsValue?.checkIn).format("HH-MM A")}
						</div>
					</div>
				</div>
				<div className='dateAndTime'>
					<div className='cotainer'>
						<p>
							{t("CALENDAR.CHECK_OUT")}:{" "}
							{reservationDetailsValue?.checkIn &&
								dayjs(reservationDetailsValue?.checkOut).format("YYYY-MM-DD")}
						</p>
						<div className='time'>
							{reservationDetailsValue?.checkOut &&
								dayjs(reservationDetailsValue?.checkOut).format("HH-MM A")}
						</div>
					</div>
				</div>
			</div>

			<div className='pricingSection'>
				<div className='iconSection'>
					<MoneysIcon />
				</div>
				<div className='titleSection'>
					<p>{t("COMMON.PRICING")}</p>
				</div>
				<div className='detailsSection'>
					<p>
						<span>{t("MANUALRESERVATION.TOTALPRICE")} : </span>
						<span>
							{reservationDetailsValue?.totalPrice} {t("GENERAL.SR")}
						</span>
					</p>
				</div>
			</div>

			<div className='smartAccessSection'>
				{!reservationDetailsValue?.isAbleToSendMessage && (
					<div className='containerInfoMessage'>
						<IconMessage />
						<p>
							{t(
								"PROPERTIES_MANAGEMENT.12_HOURS_BEFORE_CHECK_IN_TIME_YOU_WILL_BE_ABLE_TO_SEND_CHECK_IN_INSTRUCTIONS_TO_THE_GUEST"
							)}
						</p>
					</div>
				)}
				{!reservationDetailsValue?.isMessageSend && (
					<ActionView
						setIsPopupConfirmationSendMessegOpen={
							setIsPopupConfirmationSendMessegOpen
						}
						setIsPopupEnableSmartEntryFeature={
							setIsPopupEnableSmartEntryFeature
						}
					/>
				)}

				{reservationDetailsValue?.isMessageSend && (
					<DetailsInfo setIsPopupDisableLock={setIsPopupDisableLock} />
				)}
			</div>
		</ReservationDetailsWrapper>
	)
}
