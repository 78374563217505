import { amiriFont } from "../utils/callFont";
import autoTable, { RowInput } from "jspdf-autotable";
import { useMemo, useTransition } from "react";
import { useTranslation } from "react-i18next";
import jsPDF from "jspdf";
import { useLocation, useSearchParams } from "react-router-dom";

const useExportTable = () => {
  const {
    i18n: { language },
  } = useTranslation();

  const exportTable: (
    head: string[],
    body: string[][],
    name: string,
  ) => void = (head, body, name) => {
    const doc = new jsPDF();

    doc.addFileToVFS("Amiri-Regular-normal.ttf", amiriFont);
    doc.addFont("Amiri-Regular-normal.ttf", "Amiri", "normal");

    if (language == "ar") {
      head = head.reverse();
      body = body.map((i) => i.reverse());
    }
    autoTable(doc, {
      head: [head],
      theme: "grid",
      styles: {
        font: "Amiri",
        halign: language === "ar" ? "right" : "left",
        fontStyle: "normal",
      },
      headStyles: {
        fillColor: "#1b416a",
        textColor: "#FFF",
        font: "Amiri",
        fontStyle: "normal",
      },
      bodyStyles: {
        overflow: "linebreak",
        minCellWidth: 140 / head?.length,
      },
      body: body as RowInput[],
    });

    doc.save(`${name}.pdf`);
  };

  return { exportTable };
};
export { useExportTable };
