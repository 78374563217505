import { GuestMangmentHeaderWrapper } from "./styles"
import { useTranslation } from "react-i18next"

export default function GuestMangmentHeader() {
	const { t } = useTranslation()
	return (
		<GuestMangmentHeaderWrapper>
			<div>
				<p>{t("GuestManagement.title")}</p>
				<span>{t("GuestManagement.subTitle")}</span>
			</div>
			<div></div>
		</GuestMangmentHeaderWrapper>
	)
}