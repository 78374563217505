import { useTranslation } from "react-i18next"
import { PopupEnableSmartEntryFeatureWrapper } from "./styles"
import IconAutoAccess from "@assets/svg/IconAutoAccess.svg?react"
import CustomButton from "@Common/CustomButton"
import { Dispatch, SetStateAction } from "react"
import { useNavigate } from "react-router-dom"

interface IProps {
	setIsPopupEnableSmartEntryFeature: Dispatch<SetStateAction<boolean>>
}
export default function PopupEnableSmartEntryFeature({
	setIsPopupEnableSmartEntryFeature,
}: IProps) {
	const { t } = useTranslation()
	const navigate = useNavigate()
	return (
		<PopupEnableSmartEntryFeatureWrapper className='PopupInfo'>
			<div>
				<div className='containerIcon'>
					<IconAutoAccess />
				</div>
				<div>
					<p className='AUTO_ACCESS'>
						{t("PROPERTIES_MANAGEMENT.AUTO_ACCESS")}
					</p>
				</div>
				<div>
					<p className='MODAL_TITLE'>
						{t("PROPERTIES_MANAGEMENT.MODAL_TITLE")}
					</p>
					<p className='MODAL_TITLE'>
						{t("COMMON.to_activate_please_go_to_the_locks_page_and_add_a_lock")}
					</p>
				</div>
				<div className='containerFooter'>
					<CustomButton
						label={t("COMMON.enable_smart_entry_feature")}
						withBG
						borderRadius='8px'
						onClick={() => navigate("/control-center")}
					/>
					<CustomButton
						label={t("COMMON.activate_later")}
						borderRadius='8px'
						onClick={() => setIsPopupEnableSmartEntryFeature(false)}
					/>
				</div>
			</div>
		</PopupEnableSmartEntryFeatureWrapper>
	)
}
