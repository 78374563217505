import { useApi } from "./useApi"
import { ReservationsUrl, ReservationCardUrl } from "../utils/constants"
import { useQuery } from "@tanstack/react-query"
interface IReservationCard {
	totalReservationsCount: number
	manualReservationsCount: number
	automaticReservationsCount: number
	pendingReservationsCount: number
	cancelledReservationsCount: number
}
interface IReservations {
	statusCode: number
	isSuccess: boolean
	errorMessages: any[]
	totalRecords: number
	result: {
		reservationDTOs: ReservationDto[]
	}
}

interface ReservationDto {
	id: number
	reservationId: string
	propertyId: number
	propertyName: string
	checkIn: string
	checkOut: string
	guestName: string
	guestPhoneNumber: string
	platFormSRC: number
	channelName: string
	reservationDate: string
	propertyLocation: string
	numberOfNights: number
	totalPrice: number
	reservationNumber?: string
	bookingStatus: string
	createOn: string
	modifiedOn: string
	isMessageSend: boolean
	ownerId: number
}

const useReservations = () => {
	const { apiPrivate } = useApi()

	const ReservationCard = async () => {
		const {
			data: { result },
		} = await apiPrivate.get<{ result: IReservationCard }>(ReservationCardUrl)
		return result
	}
	const Reservations = async (params: {
		pageSize: string
		pageNumber: string
	}) => {
		const response: { data: IReservations } = await apiPrivate.get(
			ReservationsUrl,
			{
				params,
			}
		)
		return response
	}

	const ReservationCardQuery = () =>
		useQuery({
			queryKey: [ReservationCardUrl],
			queryFn: ReservationCard,
		})

	const ReservationsQuery = (data: { pageSize: string; pageNumber: string }) =>
		useQuery({
			queryKey: [ReservationsUrl, data],
			queryFn: () => Reservations(data),
		})

	return {
		ReservationCardQuery,
		ReservationsQuery,
	}
}
export { useReservations }
