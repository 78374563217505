import { Breadcrumbs } from "@mui/material"
import { ReactNode, useMemo } from "react"
import { BreadcrumbsWrapper } from "./styles"
import { useTranslation } from "react-i18next"
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore"
import NavigateNextIcon from "@mui/icons-material/NavigateNext"

interface IProps {
	currantLink: string
	Links: {
		label: string
		onClick: (() => void) | undefined
	}[]

	separator?: ReactNode
}
export default function CustomBreadcrumbs({
	currantLink,
	Links,
	separator,
}: IProps) {
	const {
		i18n: { language },
	} = useTranslation()
	const breadcrumbs = useMemo(() => {
		if (!Links) return []
		const breadcrumbsData = Links.map((item, index) => {
			return (
				<div key={index} onClick={item.onClick} className='prevLink'>
					{item.label}
				</div>
			)
		})
		breadcrumbsData.push(
			<div key={breadcrumbsData.length + 1} className='currantLink'>
				{currantLink}
			</div>
		)
		return breadcrumbsData
	}, [Links])

	return (
		<BreadcrumbsWrapper>
			<Breadcrumbs
				separator={
					separator ? (
						separator
					) : language === "ar" ? (
						<NavigateBeforeIcon fontSize='small' />
					) : (
						<NavigateNextIcon fontSize='small' />
					)
				}
				aria-label='breadcrumb'
			>
				{breadcrumbs}
			</Breadcrumbs>
		</BreadcrumbsWrapper>
	)
}
