import styled from "styled-components"

export const ContainerCardWrapper = styled.div`
	display: flex;
	justify-content: flex-start;
	margin-bottom: 16px;
	gap: 16px;
	flex-wrap: wrap;
	.containerCard {
		background-color: #fff;
		border-radius: 24px;
		padding: 12px;
		display: flex;
		justify-content: flex-start;
		flex-direction: column;
		gap: 16px;
		flex: 1;
		> div {
			&:first-child {
				display: flex;
				justify-content: space-between;
				align-items: center;
				p {
					color: #435363;
					font-size: 14px;
					font-weight: 400;
					line-height: 16.8px;
					margin: 0;
				}
				div {
					display: flex;
					justify-content: center;
					align-items: center;
					color: #1c274c66;
				}
			}
			&:last-child {
				p {
					color: #5b3fff;
					font-size: 32px;
					font-weight: 700;
					line-height: 38.4px;
					margin: 0;
				}
			}
		}
	}
`
