import CustomButton from "@Common/CustomButton"
import { EditReservationWrapper } from "./styles"
import { useTranslation } from "react-i18next"
import { Grid, Modal } from "@mui/material"
import {
	Dispatch,
	SetStateAction,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react"
import ReservationInfo from "./components/reservationInfo"
import PriceData from "./components/priceData"
import GuestData from "./components/guestData"
import {
	APIS_KEYS,
	useGetListingManagement,
	useGetManualReservation,
	usePutManualReservation,
} from "@services"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { PopupOverlayNewCalender, reservationDetails } from "@store/index"
import { FormProvider, useForm } from "react-hook-form"
import { ToastMessageContext } from "@context/toast-message-cotnext"
import { useQueryClient } from "@tanstack/react-query"
import dayjs from "dayjs"
interface IProps {
	isOpen: boolean
	setIsOpen: Dispatch<SetStateAction<boolean>>
}
interface IForm {
	checkIn: string
	checkOut: string
	propertyId: string
	checkInTime: string
	checkOutTime: string
	guestName: string
	guestPhoneNumber: string
	nightPrice: number
	totalPrice: number
}
export default function EditReservationPopup({ isOpen, setIsOpen }: IProps) {
	/* ------------------------------- Local State ------------------------------ */
	const [isActive, setIsActive] = useState<1 | 2 | 3>(1)
	const reservationDetailsValue = useRecoilValue(reservationDetails)
	const {
		t,
		i18n: { language },
	} = useTranslation()
	const tabButtons = [
		{
			id: 1,
			label: t("MANUALRESERVATION.RESERVATIONINFORMATION"),
			idForm: "ReservationInfoID",
		},
		{
			id: 2,
			label: t("MANUALRESERVATION.PRICINGDATA"),
			idForm: "PriceDataID",
		},
		{
			id: 3,
			label: t("MANUALRESERVATION.GUESTDATA"),
			idForm: "GuestDataID",
		},
	]
	/* ---------------------------------- API'S --------------------------------- */
	const { data: dataGetManualReservation } = useGetManualReservation(
		{
			id: reservationDetailsValue?.id!,
		},
		{ enabled: reservationDetailsValue?.id !== undefined }
	)
	const { data: getListingManagement } = useGetListingManagement()
	const { mutateAsync: mutateAsyncPutManualReservation } =
		usePutManualReservation()
	/* ---------------------------------- Hooks --------------------------------- */
	const setPopupOverlayNewCalender = useSetRecoilState(PopupOverlayNewCalender)
	const queryClient = useQueryClient()
	const { showSuccess, showError } = useContext(ToastMessageContext)
	const methods = useForm<IForm>()
	const { trigger, setValue } = methods

	const dataOfSections = useMemo(() => {
		const data = {
			ReservationInfoData: {
				checkIn: dataGetManualReservation?.result.checkIn,
				checkOut: dataGetManualReservation?.result.checkOut,
				propertyName: dataGetManualReservation?.result.propertyName,
				reservationId: dataGetManualReservation?.result.reservationId,
			},
			valueOfPriceData: {
				totalPrice: Number(dataGetManualReservation?.result.totalPrice),
				numberOfNights: dataGetManualReservation?.result.numberOfNights,
				checkIn: dataGetManualReservation?.result.checkIn,
				checkOut: dataGetManualReservation?.result.checkOut,
			},
			valueOfGuestData: {
				guestName: dataGetManualReservation?.result?.guestName,
				guestPhoneNumber: dataGetManualReservation?.result.guestPhoneNumber,
			},
		}
		return data
	}, [dataGetManualReservation])

	const dataList = useMemo(() => {
		const data = getListingManagement?.result.map((item) => {
			return {
				value: item.propertyId.toString(),
				label: item.propertyName,
			}
		})

		return data ?? []
	}, [getListingManagement])
	/* -------------------------------- Functions ------------------------------- */
	const switchTab = async (stepId: 1 | 2 | 3) => {
		const isHaveError = await trigger([
			"checkIn",
			"checkOut",
			"propertyId",
			"checkInTime",
			"checkOutTime",
			"guestName",
			"guestPhoneNumber",
			"nightPrice",
		])
		if (isHaveError) {
			setIsActive(stepId as 1 | 2 | 3)
		}
	}
	const onSubmit = async (value: IForm) => {
		const nameParts = value.guestName.trim().split(" ")
		const firstName = nameParts[0]
		const lastName = nameParts.slice(1).join(" ")

		const customGuestName = {
			firstName: firstName,
			lastName: lastName,
		}
		const { guestName, nightPrice, ...bookingWithoutGuestName } = value
		const mergedData = {
			...bookingWithoutGuestName,
			...customGuestName,
			checkIn: dayjs(value.checkIn).format("YYYY-MM-DD"),
			checkOut: dayjs(value.checkOut).format("YYYY-MM-DD"),
			id: reservationDetailsValue?.id!,
			propertyName: dataOfSections.ReservationInfoData.propertyName,
		}
		const response = await mutateAsyncPutManualReservation({
			id: reservationDetailsValue?.id!,
			data: mergedData,
		})
		if (response.status >= 200 && response.status < 300) {
			setIsOpen(false)
			setPopupOverlayNewCalender(undefined)
			queryClient.invalidateQueries({ queryKey: [APIS_KEYS.GETCALENDERDATA] })
			showSuccess(t("COMMON.THE_RESERVATION_HAS_BEEN_MODIFIED"))
		}
		if (response.status === 400) {
			if (response?.data?.errors) {
				const merged: any = Object?.values(response?.data?.errors)?.reduce(
					(accumulator: any, currentValue) => accumulator.concat(currentValue),
					[]
				)
				merged.map((item: any) => showError(item))
			}

			response?.data?.errorMessages &&
				response?.data?.errorMessages.map((item: any) =>
					showError(language === "ar" ? item.messageAr : item.messageEn)
				)
		}
		if (response.status === 500) {
			response?.data?.errorMessages.map((item: any) =>
				showError(language === "ar" ? item.messageAr : item.messageEn)
			)
		}
	}
	/* -------------------------------- useEffect ------------------------------- */

	useEffect(() => {
		if (dataOfSections.ReservationInfoData) {
			setValue("checkIn", dataOfSections.ReservationInfoData.checkIn ?? "")
			setValue("checkOut", dataOfSections.ReservationInfoData.checkOut ?? "")
		}

		if (
			dataOfSections.ReservationInfoData.propertyName &&
			dataList.length > 0
		) {
			const listFilterd = dataList.filter(
				(item) => item.label === dataOfSections.ReservationInfoData.propertyName
			)
			setValue("propertyId" ,listFilterd.length &&  listFilterd[0]?.value ?listFilterd[0]?.value :'')
		}
	}, [dataOfSections.ReservationInfoData])

	useEffect(() => {
		if (dataOfSections.valueOfPriceData) {
			setValue(
				"nightPrice",
				Number(
					(dataOfSections.valueOfPriceData.totalPrice ?? 0) /
						(dataOfSections.valueOfPriceData.numberOfNights ?? 0)
				)
			)
			setValue("totalPrice", dataOfSections.valueOfPriceData.totalPrice ?? 0)
		}
	}, [dataOfSections.valueOfPriceData])

	useEffect(() => {
		if (dataOfSections.valueOfGuestData) {
			setValue("guestName", dataOfSections.valueOfGuestData?.guestName ?? "")
			setValue(
				"guestPhoneNumber",
				dataOfSections.valueOfGuestData?.guestPhoneNumber ?? ""
			)
		}
	}, [dataOfSections.valueOfGuestData])
	return (
		<div>
			<Modal
				open={isOpen}
				onClose={() => setIsOpen(false)}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
				<EditReservationWrapper>
					<Grid container justifyContent='center' alignItems='center'>
						<Grid item xs={12} md={8}>
							<div className='containerPopup'>
								<h1>{t("COMMON.BOOKING_UPDATED")}</h1>
								<FormProvider {...methods}>
									<form onSubmit={methods.handleSubmit(onSubmit)}>
										<div className='containerBody'>
											<Grid container>
												<Grid item xs={12} md={7}>
													<div className='containerTabButtons'>
														{tabButtons.map((item) => {
															return (
																<div
																	key={item.id}
																	className={`${item.id === isActive ? "item active" : "item"}`}
																	onClick={() =>
																		switchTab(item.id as 1 | 2 | 3)
																	}
																>
																	<p>{item.label}</p>
																</div>
															)
														})}
													</div>
												</Grid>
												<Grid item xs={12}>
													{isActive === 1 && (
														<ReservationInfo
															dataList={dataList}
															ReservationInfoData={
																dataOfSections?.ReservationInfoData
															}
														/>
													)}
													{isActive === 2 && (
														<PriceData
															valueOfPriceData={
																dataOfSections?.valueOfPriceData
															}
														/>
													)}
													{isActive === 3 && <GuestData />}
												</Grid>
											</Grid>
										</div>
										<div>
											<Grid container>
												<Grid item xs={12} md={7}>
													<div className='containerFooter'>
														<CustomButton
															label={t("COMMON.SAVE_EDIT")}
															withBG
															borderRadius='8px'
															customPadding='18px'
															type='submit'
														/>
														<CustomButton
															label={t("COMMON.CANCEL_EDIT")}
															borderRadius='8px'
															customPadding='18px'
															borderColor='#5B3FFF'
															customColor='#5B3FFF'
															onClick={() => setIsOpen(false)}
														/>
													</div>
												</Grid>
											</Grid>
										</div>
									</form>
								</FormProvider>
							</div>
						</Grid>
					</Grid>
				</EditReservationWrapper>
			</Modal>
		</div>
	)
}
