import styled from "styled-components"

export const SelectClassficationWrapper = styled.div`
	text-align: right;
	.Success {
		background-color: #e4fce6;
	}
	.normal {
		background-color: #e8e8e8;
	}
	.Danger {
		background-color: #ffe0e3;
	}
`
