import { useTranslation } from "react-i18next"
import { ManualReservationWrapper } from "./styles"
import { useNavigate } from "react-router-dom"

export default function ManualReservation() {
	const { t } = useTranslation()
	const navigate = useNavigate()
	return (
		<ManualReservationWrapper>
			<button onClick={() => navigate("/manual-reservation")}>
				{t("MANUALRESERVATION.MANUALRESERVATION")}
			</button>
		</ManualReservationWrapper>
	)
}
