import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';
import { minusPadding } from '@utils';
import AVOID_CONFLICTS from '@assets/svg/AVOID_CONFLICTS.svg?react';
import SMART_LOCK from '@assets/svg/SMART_LOCK.svg?react';
import FINANCE_1 from '@assets/svg/FINANCE_1.svg?react';
import FINANCE_2 from '@assets/svg/FINANCE_2.svg?react';

const featureIcons: any = {
  AVOID_CONFLICTS: <AVOID_CONFLICTS />,
  SMART_LOCK: <SMART_LOCK />,
  FINANCE_1: <FINANCE_1 />,
  FINANCE_2: <FINANCE_2 />,
};
const featuresList = ['AVOID_CONFLICTS', 'SMART_LOCK', 'FINANCE_1', 'FINANCE_2'];

const Features: FC = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  return (
    <Box
      id={'features'}
      sx={{
        ...minusPadding,
        py: 4,
        mt: -2,
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
        alignItems: 'center',
        position: 'relative',
        width: '100vw',
        backgroundImage: `linear-gradient(180deg, #F6F4FF 0%, #FFFFFF 100%)`,
      }}
    >
      <Typography
        mb={2}
        mt={4}
        fontSize={{ xs: '24px', lg: '32px' }}
        textAlign={'center'}
        fontWeight={'bold'}
        color={'primary'}
      >
        {t('FEATURES.TITLE')}
      </Typography>
      <Box
        sx={{
          display: 'grid',
          width: '100%',
          maxWidth: '600px',
          pt: 4,
          pb: 8,
          gap: 3,
          justifyContent: 'center',
          gridTemplateColumns: { xs: 'repeat(1,1fr)', sm: 'repeat(2,1fr)' },
        }}
      >
        {featuresList?.map((f, i) => {
          const isEven = i % 2 == 0;
          return (
            <Box
              key={f}
              sx={{
                p: 3,
                boxShadow: '0px 00px 10px #5B3FFF40',
                background: 'radial-gradient(at 20% 100%,   #5B3FFF40,#FFFFFF, #FFFFFF)',
                borderRadius: 4,
                display: 'flex',
                minHeight: 'fit-content',
                flexDirection: 'column',
                mt: { sm: isEven ? -4 : 0 },
                mb: { sm: isEven ? 4 : 0 },
              }}
            >
              <Typography color={'#8E98A1'} mb={2}>
                {t(`FEATURES.${f}.TITLE`)}
              </Typography>
              <Typography mb={2}>{t(`FEATURES.${f}.DESCRIPTION`)}</Typography>
              <Box
                sx={{
                  marginTop: 'auto',
                  width: '100%',
                  svg: { width: '100%', height: 'auto' },
                }}
              >
                {featureIcons[f]}
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
export default Features;
