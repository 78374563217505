import styled from "styled-components"

export const InputWrapper = styled.div<{
	$islabelVisible: boolean
	$isError: boolean
	disabled: boolean | undefined
	$direction?: string
}>`
	label {
		display: flex;
		justify-content: flex-start;
		flex-direction: column;
		p {
			font-weight: 700;
			text-align: ${(props) => (props.$direction === "ltr" ? "end" : "start")};
			color: #435363;
			visibility: ${(props) => (props.$islabelVisible ? "visible" : "hidden")};
			font-size: 15px;
			margin: 0;
			margin-bottom: 4px;
			text-transform: capitalize;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			display: flex;
			flex-direction: ${(props) =>
				props.$direction === "ltr" ? "row-reverse" : "row"};
			span {
				color: #d70015;
				margin-inline: 4px;
			}
		}
		input,
		textarea {
			width: 100%;
			font-size: 15px;
			color: #261b6a;
			background-color: #fff;
			padding: 11px;
			border: 1px solid ${(props) => (props.$isError ? "#EF4444" : "#E5E7EB")};
			border-radius: 12px;
			outline: none;
			resize: none;
			font-weight: 500;
			line-height: 24px;
			&:focus {
				border: 1px solid ${(props) => (props.$isError ? "#EF4444" : "#E5E7EB")};
			}
			&::placeholder {
				color: #e5e7eb;
			}
			/* &:disabled, */
			// &:read-only {
			// 	background-color: #E5E7EB;
			// 	border: 1px solid #E5E7EB;
			// 	color: #000;
			// 	opacity: 0.6;
			// 	cursor: not-allowed;
			// }
		}

		.inputcontainer {
			position: relative;
			.search {
				position: absolute;
				top: 0;
				right: 8px;
				height: 100%;
				width: 16px;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 20px;
				color: #6b7280;
				&:lang(ar) {
					right: auto;
					left: 8px;
				}
			}
			.clock {
				position: absolute;
				top: 0;
				left: 8px;
				height: 100%;
				width: 16px;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 20px;
				color: #6b7280;
				&:lang(ar) {
					left: auto;
					right: 8px;
				}
			}
		}
	}
	.ErrorMessageStyle {
		font-size: 13px;
		font-weight: 400;
		line-height: 22.4px;
		color: #ef4444;
		height: 22px;
		p {
			margin: 0;
		}
	}
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Firefox */
	input[type="number"] {
		-moz-appearance: textfield;
	}
`
