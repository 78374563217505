import { DataGridProps, NoRowsOverlayPropsOverrides } from "@mui/x-data-grid"
import { DataGrid, GridRowsProp, GridColDef } from "@mui/x-data-grid"
import { CustomTableWrapper } from "./styles"
import React, {
	Dispatch,
	HTMLAttributes,
	JSXElementConstructor,
	SetStateAction,
	useEffect,
	useState,
} from "react"
import CustomPagination, { IListPerPagesNumber } from "./CustomPagination"
import { Box, SxProps, Theme } from "@mui/material"

interface IProps extends DataGridProps {
	hasPages?:boolean
	rows: GridRowsProp
	columns: GridColDef[]
	disableColumnMenu?: boolean
	disableColumnFilter?: boolean
	disableColumnResize?: boolean
	disableColumnSorting?: boolean
	disableRowSelectionOnClick?: boolean
	listPerPagesNumber?: IListPerPagesNumber[]
	HeaderTheTable?: string
	onPerpageChange?: Dispatch<SetStateAction<string>>
	perPage?: string
	totalPages: number
	setActivePage: Dispatch<SetStateAction<number>>
	activePage: number
	suffix?:any
	CustomNoRowsOverlay?:
		| JSXElementConstructor<
				HTMLAttributes<HTMLDivElement> & {
					sx?: SxProps<Theme>
				} & NoRowsOverlayPropsOverrides
		  >
		| undefined,
	totalRecords?:number;
}

export default function CustomTable({
	hasPages=true,
	disableColumnFilter = true,
	disableColumnResize = true,
	disableColumnSorting = true,
	disableRowSelectionOnClick = true,
	disableColumnMenu = true,
	rows,
	listPerPagesNumber = [],
	HeaderTheTable,
	totalPages,
	onPerpageChange,
	perPage,
	setActivePage,
	activePage,
	CustomNoRowsOverlay,
	suffix=()=>{},
	totalRecords,
	...rest
}: IProps) {
	const [windowSize, setWindowSize] = useState(window.innerWidth)
	useEffect(() => {
		const handleResize = () => {
			setWindowSize(window.innerWidth)
		}
		window.addEventListener("resize", handleResize)
		return () => {
			window.removeEventListener("resize", handleResize)
		}
	}, [totalPages])
	return (
		<CustomTableWrapper>
			{HeaderTheTable && (
				<Box sx={{display:'flex',justifyContent:'space-between',alignItems:'center'}} className='HeaderTheTable'>
					<p>{HeaderTheTable}</p>
					{suffix}
				</Box>
			)}

			<DataGrid
				{...rest}
				rows={rows}
				disableColumnMenu={disableColumnMenu}
				disableColumnFilter={disableColumnFilter}
				disableColumnResize={disableColumnResize}
				disableColumnSorting={disableColumnSorting}
				disableRowSelectionOnClick={disableRowSelectionOnClick}
				hideFooterPagination
				key={windowSize}
				autoHeight
				slots={{
					noRowsOverlay: CustomNoRowsOverlay,
				}}
			/>
			{hasPages?<CustomPagination
				count={totalPages}
				setActivePage={setActivePage}
				listPerPagesNumber={listPerPagesNumber}
				onPerpageChange={onPerpageChange}
				perPage={perPage}
				activePage={activePage}
				totalRecords={totalRecords}
			/>:null}
		</CustomTableWrapper>
	)
}