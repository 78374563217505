import { useApi } from './useApi';
import { RequestPasswordOtpURL,VerfiyForgetOtpURL,ForgetPasswordChangeURL } from '../utils/constants';
import { useQuery, useMutation } from '@tanstack/react-query';

const useForgetPassword = () => {
  const { apiPrivate } = useApi(); 

  const RequestOtpRequest = async (body:any) => {
    const { data } = await apiPrivate.post(RequestPasswordOtpURL,body);
    return data;
  };
  const VefiyOtpRequest = async (body:any) => {
    const { data } = await apiPrivate.post(VerfiyForgetOtpURL,body);
    return data;
  };
  const changePasswordRequest = async (body:any) => {
    const { data } = await apiPrivate.post(ForgetPasswordChangeURL,body);
    return data;
  };

  const RequestOptMutation = ({ onSuccess, onError }: { onSuccess?: () => void; onError?: () => void }) =>
  useMutation({
    mutationFn: (body: any) => RequestOtpRequest(body),
    onSuccess: (data) => onSuccess && onSuccess(),
    onError: (error) => onError && onError(),
  });
  const VrifyPinMutation = ({ onSuccess, onError }: { onSuccess?: () => void; onError?: () => void }) =>
  useMutation({
    mutationFn: (body: any) => VefiyOtpRequest(body),
    onSuccess: (data) => onSuccess && onSuccess(),
    onError: (error) => onError && onError(),
  });
  const changePasswordMutation = ({ onSuccess, onError }: { onSuccess?: () => void; onError?: () => void }) =>
  useMutation({
    mutationFn: (body: any) => changePasswordRequest(body),
    onSuccess: (data) => onSuccess && onSuccess(),
    onError: (error) => onError && onError(),
  });

  return { RequestOptMutation,VrifyPinMutation,changePasswordMutation };
};

export { useForgetPassword };
