import { ActionViewWrapper } from "./styles"
import { CiLock } from "react-icons/ci"
import CustomButton from "@Common/CustomButton"
import PopupInfo from "../popupInfo"
import { Dispatch, SetStateAction } from "react"
import { useTranslation } from "react-i18next"
import { useRecoilValue } from "recoil"
import { reservationDetails } from "@store/index"
interface IProps {
	setIsPopupConfirmationSendMessegOpen: Dispatch<SetStateAction<boolean>>
	setIsPopupEnableSmartEntryFeature: Dispatch<SetStateAction<boolean>>
}
export default function ActionView({
	setIsPopupConfirmationSendMessegOpen,
	setIsPopupEnableSmartEntryFeature,
}: IProps) {
	const { t } = useTranslation()
	const reservationDetailsValue = useRecoilValue(reservationDetails)

	return (
		<ActionViewWrapper>
			<div className='iconSection'>
				<CiLock />
			</div>
			<div className='titleSection'>
				<p>{t("PROPERTIES_MANAGEMENT.SLIDER_FIRST_TITLE")}</p>
				<label className='label'>
					{reservationDetailsValue?.isAutoAccess &&
					reservationDetailsValue?.isAbleToSendMessage
						? t(
								"COMMON.you_can_now_send_the_access_code_to_your_guest_with_a_welcome_message"
							)
						: t("COMMON.you_have_not_activated_the_Smart_Login_feature_yet")}
				</label>
			</div>
			<div className='detailsSection'>
				<CustomButton
					onClick={() =>
						reservationDetailsValue?.isAutoAccess &&
						reservationDetailsValue?.isAbleToSendMessage
							? setIsPopupConfirmationSendMessegOpen(true)
							: setIsPopupEnableSmartEntryFeature(true)
					}
					withIcone
					iconPosition='ltr'
					customIcon={
						reservationDetailsValue?.isAutoAccess &&
						reservationDetailsValue?.isAbleToSendMessage ? (
							<div className='infoSectionpopupControle'>
								<div className='controle'></div>
								<PopupInfo />
							</div>
						) : undefined
					}
					label={
						reservationDetailsValue?.isAutoAccess &&
						reservationDetailsValue?.isAbleToSendMessage
							? t("COMMON.send_now")
							: t("COMMON.enable_smart_entry_feature")
					}
					borderRadius='8px'
					borderColor='#5B3FFF'
					customColor='#5B3FFF'
				/>
			</div>
		</ActionViewWrapper>
	)
}
