import { UseQueryOptions, useQuery } from "@tanstack/react-query"
import { APIS_KEYS } from "../../apis-keys"
import { IPropsGetCalenderData, IResponseGetCalenderData } from "./types"
import type { AxiosInstance, AxiosResponse } from "axios"
import { useApi } from "../../useApi"
import { MsoolCalendarUrl } from "../../../utils/constants"

async function getCalenderData({ ...rest }: IPropsGetCalenderData, apiPrivate:AxiosInstance) {
	try {
		const response: AxiosResponse<IResponseGetCalenderData> =
			await apiPrivate.get(`${MsoolCalendarUrl}`, {
				params: rest,
			})
		return response.data
	} catch (error: any) {
		console.log(error)
		throw new Error("something went wrong")
	}
}

export function useGetCalenderData(
	data: IPropsGetCalenderData,
	options?:
		| Omit<
				UseQueryOptions<
					IResponseGetCalenderData,
					unknown,
					IResponseGetCalenderData
				>,
				"queryKey" | "queryFn"
		  >
		| undefined
) {
	const { apiPrivate } = useApi()
	return useQuery({
		queryKey: [APIS_KEYS.GETCALENDERDATA, ...Object.values(data)],
		queryFn: () => getCalenderData(data, apiPrivate),
		...options,
	})
}
