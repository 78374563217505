import { useApi } from "./useApi"
import {
	Airbnb_InstantBookURL,
	airBnbMinStayURL,
	airBnbPriceURL,
	airBnbUpdateAvailabilityURL,
	InstantBookSettingsURL,
	AllPlatformPriceURL,
	GatherinAvailabilityURL,
	AirbnbUpdateAvailabilityRulesURL,
	UpdatePricingDiscountForAllChannelsURL,
	AllChannelsAvailabilityURL,
	UpdateCHannelsResterictionURL
} from "../utils/constants"
import { useMutation, useQuery } from "@tanstack/react-query" 

interface ISettings {
	listing_id: string
	date_from?: string
	date_to?: string
	rate: string
	availability?: number
	min_stay: number
}
const useAdvanceSettings = () => {
	const { apiPrivate } = useApi()

	const AirbnbUpdateAvailabilityRules = async (body: any) => {
		let url = body?.propertyName
			? AirbnbUpdateAvailabilityRulesURL + `?propertyName=${body?.propertyName}`
			: airBnbUpdateAvailabilityURL
		const { data } = await apiPrivate.post(url, body)
		return data
	}
	const UpdateAvailabilityInChannelsFn = async (body: any) => {
		const { data } = await apiPrivate.post(
			AllChannelsAvailabilityURL,
			body
		)
		return data
	}
	const UpdatePricingDiscountForAllChannels = async (body: ISettings) => {
		const { data } = await apiPrivate.post(
			UpdatePricingDiscountForAllChannelsURL,
			body
		)
		return data
	}
	const airBnbPrice = async (body: ISettings) => {
		const { data } = await apiPrivate.post(airBnbPriceURL, body)
		return data
	}
	const allPlatformPrice = async (body: any) => {
		const { data } = await apiPrivate.post(AllPlatformPriceURL, body)
		return data
	}

	const airBnbMinStay = async (body: ISettings) => {
		const { data } = await apiPrivate.post(airBnbMinStayURL, body)
		return data
	}

	const airBnbUpdateAvailability = async (body: any) => {
		let url = body?.propertyName
			? airBnbUpdateAvailabilityURL + `Rules?propertyName=${body?.propertyName}`
			: airBnbUpdateAvailabilityURL
		const { data } = await apiPrivate.post(url, body)
		return data
	}
	const gatehrInAvailability = async (body: ISettings) => {
		const { data } = await apiPrivate.post(GatherinAvailabilityURL, body)
		return data
	}

	const getInstantBookingSettings = async () => {
		const {
			data: { result },
		} = await apiPrivate.get(InstantBookSettingsURL)
		return result
	}
	const airBnbInstantBooking = async (
		propertyName: string,
		instantBookSetting: string
	) => {
		const { data } = await apiPrivate.get(Airbnb_InstantBookURL, {
			params: { propertyName, instantBookSetting },
		})
		return data
	}
	const UpdaetChannexRestrictions = async (body: any) => {
		const { data } = await apiPrivate.post(UpdateCHannelsResterictionURL, body)
		return data
	}
	const UpdaetChannexRestrictionsMutation = ({
		onSuccess,
		onError,
	}: {
		onSuccess?: () => void
		onError?: () => void
	}) =>
		useMutation({
			mutationFn: (body: any) => UpdaetChannexRestrictions(body),
			onSuccess: (data) => onSuccess && onSuccess(),
			onError: (error) => onError && onError(),
		})
	const updateAllChannelsPrice = ({
		onSuccess,
		onError,
	}: {
		onSuccess?: () => void
		onError?: () => void
	}) =>
		useMutation({
			mutationFn: (body: any) => allPlatformPrice(body),
			onSuccess: (data) => onSuccess && onSuccess(),
			onError: (error) => onError && onError(),
		})
	const AirbnbUpdateAvailabilityRulesMutate = ({
		onSuccess,
		onError,
	}: {
		onSuccess?: () => void
		onError?: () => void
	}) =>
		useMutation({
			mutationFn: (body: any) => AirbnbUpdateAvailabilityRules(body),
			onSuccess: (data) => onSuccess && onSuccess(),
			onError: (error) => onError && onError(),
		})
	const UpdatePricingDiscountForAllChannelsMutate = ({
		onSuccess,
		onError,
	}: {
		onSuccess?: () => void
		onError?: () => void
	}) =>
		useMutation({
			mutationFn: (body: any) => UpdatePricingDiscountForAllChannels(body),
			onSuccess: (data) => onSuccess && onSuccess(),
			onError: (error) => onError && onError(),
		})
	const airBnbPriceMutate = ({
		onSuccess,
		onError,
	}: {
		onSuccess?: () => void
		onError?: () => void
	}) =>
		useMutation({
			mutationFn: (body: ISettings) => airBnbPrice(body),
			onSuccess: (data) => onSuccess && onSuccess(),
			onError: (error) => onError && onError(),
		})

	const airBnbMinStayMutate = ({
		onSuccess,
		onError,
	}: {
		onSuccess?: () => void
		onError?: () => void
	}) =>
		useMutation({
			mutationFn: (body: ISettings) => airBnbMinStay(body),
			onSuccess: (data) => onSuccess && onSuccess(),
			onError: (error) => onError && onError(),
		})

	const airBnbUpdateAvailabilityMutate = ({
		onSuccess,
		onError,
	}: {
		onSuccess?: () => void
		onError?: () => void
	}) =>
		useMutation({
			mutationFn: (body: any) => airBnbUpdateAvailability(body),
			onSuccess: (data) => onSuccess && onSuccess(),
			onError: (error) => onError && onError(),
		})
		const AllChannleseAvailabilityMutate = ({
			onSuccess,
			onError,
		}: {
			onSuccess?: () => void
			onError?: () => void
		}) =>
			useMutation({
				mutationFn: (body: any) => UpdateAvailabilityInChannelsFn(body),
				onSuccess: (data) => onSuccess && onSuccess(),
				onError: (error) => onError && onError(),
			})

		
	const gatherInAvailabilityMutate = ({
		onSuccess,
		onError,
	}: {
		onSuccess?: () => void
		onError?: () => void
	}) =>
		useMutation({
			mutationFn: (body: ISettings) => gatehrInAvailability(body),
			onSuccess: (data) => onSuccess && onSuccess(),
			onError: (error) => onError && onError(),
		})

	const instantBookingSettingsQuery = () =>
		useQuery({
			queryKey: [InstantBookSettingsURL],
			queryFn: getInstantBookingSettings,
		})
	const iarBnbInstantBookingMutate = ({
		onSuccess,
		onError,
	}: {
		onSuccess?: () => void
		onError?: () => void
	}) =>
		useMutation({
			mutationFn: ({
				propertyName,
				instantBookSetting,
			}: {
				propertyName: string
				instantBookSetting: string
			}) => airBnbInstantBooking(propertyName, instantBookSetting),
			onSuccess: (data) => onSuccess && onSuccess(),
			onError: (error) => onError && onError(),
		})

	return {
		airBnbPriceMutate,
		AirbnbUpdateAvailabilityRulesMutate,
		UpdatePricingDiscountForAllChannelsMutate,
		airBnbMinStayMutate,
		airBnbUpdateAvailabilityMutate,
		iarBnbInstantBookingMutate,
		instantBookingSettingsQuery,
		updateAllChannelsPrice,
		gatherInAvailabilityMutate,
		AllChannleseAvailabilityMutate,
		UpdaetChannexRestrictionsMutation
	}
}
export { useAdvanceSettings }
