import { PopupOverlayNewCalender } from "@store/index"
import { useTranslation } from "react-i18next"
import { IoIosArrowRoundBack, IoIosArrowRoundForward } from "react-icons/io"
import { useSetRecoilState } from "recoil"

export default function NavigateSection() {
	const {
		i18n: { language },
	} = useTranslation()
	const setPopupOverlayNewCalender = useSetRecoilState(PopupOverlayNewCalender)
	return (
		<div className='NavigateSection'>
			<button type='button' onClick={() => setPopupOverlayNewCalender(undefined)}>
				{language === "en" ? (
					<IoIosArrowRoundForward />
				) : (
					<IoIosArrowRoundBack />
				)}
			</button>
		</div>
	)
}
