import { UseMutationOptions, useMutation } from "@tanstack/react-query"
import { AxiosInstance } from "axios"
import { IPropsPutManualReservation } from "./types"
import { useApi } from "@services"
import { ManualReservationAPI } from "../../../utils/constants"

async function putManualReservation(
	{ id, data }: IPropsPutManualReservation,
	apiPrivate: AxiosInstance
) {
	try {
		const response: any = await apiPrivate.put(
			`${ManualReservationAPI}/${id}`,
			data
		)
		return response
	} catch (error: any) {
		return error.response
	}
}

export function usePutManualReservation(
	options?:
		| Omit<UseMutationOptions<any, unknown, unknown, any>, "mutationFn">
		| undefined
) {
	const { apiPrivate } = useApi()
	const mutation = useMutation({
		mutationFn: (data: IPropsPutManualReservation) =>
			putManualReservation(data, apiPrivate),
		...options,
	})
	return mutation
}
