import { UseMutationOptions, useMutation } from "@tanstack/react-query"
import { AxiosInstance, AxiosResponse } from "axios"
import {
	IPropsDeleteManualReservation,
	IResponseDeleteManualReservation,
} from "./types"
import { useApi } from "@services"
import { ManualReservationAPI } from "../../../utils/constants"

async function DeleteManualReservation(
	{ id }: IPropsDeleteManualReservation,
	apiPrivate: AxiosInstance
) {
	try {
		const response: AxiosResponse<IResponseDeleteManualReservation> =
			await apiPrivate.delete(`${ManualReservationAPI}/${id}`)
		return response
	} catch (error: any) {
		return error.response
	}
}

export function useDeleteManualReservation(
	options?:
		| Omit<
				UseMutationOptions<
					AxiosResponse<IResponseDeleteManualReservation>,
					unknown,
					unknown,
					AxiosResponse<IResponseDeleteManualReservation>
				>,
				"mutationFn"
		  >
		| undefined
) {
	const { apiPrivate } = useApi()
	const mutation = useMutation({
		mutationFn: (data: IPropsDeleteManualReservation) =>
			DeleteManualReservation(data, apiPrivate),
		...options,
	})
	return mutation
}
