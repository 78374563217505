import styled from "styled-components"

export const TimeLineWrapper = styled.div<{ $language: "ar" | "en" }>`
	.TimeLineContainer {
		div {
			&:first-child {
				.containerIcon {
					&::after {
						content: "";
						position: absolute;
						left: 50%;
						top: -32px;
						bottom: 54px;
						width: 2px;
						background-color: #8e98a1;
					}
					&::before {
						content: "";
						position: absolute;
						left: 50%;
						top: 55px;
						bottom: -32px;
						width: 2px;
						background-color: #8e98a1;
					}
				}
			}
		}
		.TimeLineItme {
			display: grid;
			grid-template-columns: 1fr auto;
			grid-template-rows: 100%;
			align-items: center;
			gap: 24px;
			margin-block: 32px;
			.containerIcon {
				position: relative;
				border: 1px solid #8e98a1;
				color: #8e98a1;
				width: 56px;
				height: 56px;
				border-radius: 50px;
				display: flex;
				justify-content: center;
				align-items: center;
				svg {
					width: 24px;
					height: 24px;
				}
				&.active {
					border: 1px solid #5b3fff;
					color: #5b3fff;
				}
				&.done {
					background-color: #5b3fff;
					color: #fff;
				}
				&::before {
					content: "";
					position: absolute;
					left: 50%;
					top: 55px;
					bottom: -32px;
					width: 2px;
					background-color: #8e98a1;
				}
			}
			.conatainerData {
				display: flex;
				justify-content: flex-start;
				align-items: flex-end;
				flex-direction: column;
				width: 100%;
				span {
					font-size: clamp(18px, 5vw, 22px);
					font-weight: 400;
					line-height: 26.4px;
					&:first-child {
						color: #0f172a;
					}
					&:last-child {
						color: #435363;
						font-size: clamp(14px, 5vw, 18px);
						line-height: 21.6px;
					}
				}
				&.active {
					span {
						font-size: clamp(18px, 5vw, 22px);
						font-weight: 400;
						line-height: 26.4px;
						&:first-child {
							color: #5b3fff;
						}
						&:last-child {
							color: rgba(59, 42, 167, 0.5);
							font-size: clamp(14px, 5vw, 18px);
							line-height: 21.6px;
						}
					}
				}
			}
		}
	}
`
