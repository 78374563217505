import { Box, Button, Card, Typography } from '@mui/material';
import React, { FC } from 'react';
import { CheckCircleOutline } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

interface Props {
  disabled?: boolean;
  description?: string;
  price: number;
  planName: string;
  discount?: number;
  oldPrice?: number;
  best?: boolean;
  onClick: () => void;
  features: string[];
  noPadding?: boolean;
}
export const JoinCard: FC<Props> = ({
  disabled,
  best,
  oldPrice,
  price,
  discount,
  planName,
  features,
  onClick,
  noPadding,
  description,
}) => {
  const { t } = useTranslation();
  return (
    <Box sx={{ p: noPadding ? 0 : 3 }}>
      <Box
        sx={{
          p: 3,
          flex: 1,
          boxShadow: '0px 00px 10px #5B3FFF40',
          background: 'radial-gradient(at 20% 100%,   #5B3FFF40,#FFFFFF, #FFFFFF)',
          borderRadius: 4,
          height: '440px',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          px: 2,
          pb: 3,
          pt: 6,
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            insetInline: 0,
            px: 6,
            top: -20,
            display: 'flex',
            justifyContent: 'center',
            '.MuiBox-root': {
              py: 1,
              color: 'white',
              fontWeight: 'bold',
              textAlign: 'center',
              borderRadius: '20px',
              width: '100%',

              background: 'linear-gradient(90deg, #226FA6 -0.44%, #523DEA 104.87%)',
              boxShadow: '2px 8px 16px 0px rgba(38, 27, 106, 0.24)',
            },
          }}
        >
          <Box>{description}</Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            '.MuiBox-root': {
              px: 1.5,
              fontSize: '12px',
              borderRadius: '20px',
            },
          }}
        >
          <Box
            sx={{
              color: 'black',
              fontWeight: 'bold',
              background: '#EEEBFF',
            }}
          >
            {planName}
          </Box>
          {Boolean(discount && discount > 0) && (
            <Box
              sx={{
                color: 'white',
                fontWeight: 'bold',
                background: '#FFAA00',
              }}
            >
              {discount}%
            </Box>
          )}
        </Box>
        <Typography fontSize={'24px'} fontWeight={'bold'}>
          {t('SUBSCRIPTION_PRICE', { price: price })}
        </Typography>
        <Typography mb={4} fontSize={'16px'} color={'#3B2AA7'}>
          {t('FOR_EACH_PROPERTY')}
        </Typography>
        {features?.map((f) => (
          <Typography sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }} key={f}>
            <CheckCircleOutline color={'success'} />
            {f}
          </Typography>
        ))}
        <Box sx={{ marginTop: 'auto', display: 'flex', justifyContent: 'center', px: 5 }}>
          <Button onClick={onClick} disabled={disabled} size={'large'} fullWidth variant={'contained'}>
            {t('SUBSCRIBE_NOW')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
