import styled from "styled-components"

export const SectionHeaderWrapper = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	div {
		p {
			margin: 0;
			&:first-child {
				font-size: 20px;
				font-weight: 700;
				line-height: 24px;
				color: #130080;
			}
			&:last-child {
				font-size: 16px;
				font-weight: 400;
				line-height: 19.2px;
				color: #adbac7;
			}
		}
	}
`
