
import { useApi } from './useApi';
import {
    UserApi,
} from '../utils/constants';
import { useMutation } from '@tanstack/react-query';

const userSettings = () => {
    const { apiPrivate } = useApi();
    const updateUserSettings = async (body: any) => {
        let response;
        response = await apiPrivate.put(`${UserApi}`, {
            id:  Number(window.localStorage['userId']),
            fullName: body?.fullName ?? window.localStorage['userName'],
            email: body?.email || '',
            password: body?.password ?? '',
            ConfirmPassword:body?.password ?? '',
        });
        const { result } = response.data || {};
      if(result == 'success'){
        if( body?.fullName){
            window.localStorage['userName'] =body?.fullName 
        }
        if(body?.email){
            window.localStorage['email'] = body?.email 
        }
       
      }

        return result;
    };

    const updateUserSettingMutate = ({ onSuccess, onError }: { onSuccess?: () => void; onError?: () => void }) =>
        useMutation({
            mutationFn: (body: any) => updateUserSettings(body),
            onSuccess: (data) => {
                onSuccess && onSuccess()
            },
            onError: (error) => onError && onError(),
        });
    return {
        updateUserSettingMutate,
    };
};

export { userSettings };
