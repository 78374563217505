import { createContext, Dispatch, FC, ReactNode, SetStateAction, useState } from 'react';
import { Alert, Box, Snackbar } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';


import ToastClose from '@assets/svg/ToastClose.svg?react';
import ToastError from '@assets/svg/ToastError.svg?react';
import ToastSuccess from '@assets/svg/ToastSuccess.svg?react';


interface ContextProps {
  showError: Dispatch<SetStateAction<string | undefined>>;
  showSuccess: Dispatch<SetStateAction<string | undefined>>;
  showWarning: Dispatch<SetStateAction<string | undefined>>;
}
const ToastMessageContext = createContext<ContextProps>({
  showError: (string) => { },
  showSuccess: (string) => { },
  showWarning: (string) => { },
});

const ToastMessageProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [successMessage, setSuccessMessage] = useState<string | undefined>();
  const [warningMessage, setWarningMessage] = useState<string | undefined>();
  const lang = localStorage.getItem('i18nextLng') || 'ar'
  const isAr = lang == 'ar'
  const dir = lang == 'ar'? 'marginLeft':'marginRight'
  const sx = {
    background: 'white',
    borderRadius: 20,
    top: window.innerHeight - 130,
    // paddingLeft:200,
    // right:window.innerWidth/2,
    position: 'absolute',
    [dir]: 105
  }
  const action = (
    <Box sx={{ display: 'flex',flexDirection:isAr?'row':'row-reverse' ,justifyContent: 'space-between', backgroundColor: ' #FFFFFF', alignItems: 'center', minWidth:String(successMessage)?.length>60||String(errorMessage)?.length>60?'700px': '485px', p: 0.5 }} >
      <Box sx={{ display: 'flex', alignItems: 'center',flexDirection:isAr?'row':'row-reverse' }} >
        {successMessage ? <ToastSuccess style={{ width: '34px', height: '34px' }} /> : <ToastError style={{ width: '34px', height: '34px' }} />}
        <Box sx={{ fontWeight: 400, mt: 0.5, color: ' #000000', fontSize: '16px', mx: 0.5 }} >
      {errorMessage?errorMessage:successMessage}
        </Box>
      </Box>

      <Box sx={{ mt: 1, cursor: 'pointer' }} onClick={()=>{
        setSuccessMessage(undefined)
        setErrorMessage(undefined)
      }} >
        <ToastClose />
      </Box>

    </Box>

  );
  return (
    <ToastMessageContext.Provider
      value={{
        showError: setErrorMessage,
        showSuccess: setSuccessMessage,
        showWarning: setWarningMessage,
      }}
    >
      {children}
      {errorMessage && (
        <Snackbar
          action={action}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          message=""
          sx={{ position: "absolute", borderRadius: 20 }}
          ContentProps={{
            sx
          }}
          autoHideDuration={5000}
          open={Boolean(errorMessage)}
          onClose={() => setErrorMessage(undefined)}
         

        />

      )}
      {successMessage && (
        <Snackbar
          open={Boolean(successMessage)}
          onClose={() => setSuccessMessage(undefined)}
          action={action}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          message=""
          sx={{ position: "absolute", borderRadius: 20 }}
          ContentProps={{
            sx
          }}
          autoHideDuration={5000}
        

       />
         
      
      )}
      {warningMessage && (
        <Snackbar
          open={Boolean(warningMessage)}
          autoHideDuration={4000}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          onClose={() => setWarningMessage(undefined)}
        >
          <Alert
            onClose={() => setWarningMessage(undefined)}
            severity='warning'
            sx={{ width: '400px', maxWidth: '80%' }}
          >
            {warningMessage}
          </Alert>
        </Snackbar>
      )}
    </ToastMessageContext.Provider>
  );
};

export { ToastMessageContext, ToastMessageProvider };
