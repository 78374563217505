import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import { Logo } from '../../utils/image-consts';

const ErrorPage: FC<{ code: number }> = ({ code }) => {
  const { t } = useTranslation();

  return (
    <div className={'flex gap-2 flex-col flex-1 items-center justify-center'}>
      <Box component='img' src={Logo} width='100px' sx={{ marginBottom: 8 }} />

      <div className={'text-2xl text-primary'}>{t('ERRORS.ERROR_CODE', { code })}</div>
      <div className={'text-bold'}>{t(`ERRORS.ERROR_${code}_MESSAGE`)}</div>
      <Link to={'/'}>
        <Button variant={'contained'} sx={{ mt: 4 }}>
          {t('ROUTER.HOME')}
        </Button>
      </Link>
    </div>
  );
};

export default ErrorPage;
