import { UseQueryOptions, useQuery } from "@tanstack/react-query"
import { APIS_KEYS } from "../../apis-keys"
import {
	IPropsGetManualReservation,
	IResponseGetManualReservation,
} from "./types"
import type { AxiosInstance, AxiosResponse } from "axios"
import { useApi } from "../../useApi"
import { ManualReservationAPI } from "../../../utils/constants"

async function getManualReservation(
	{ id }: IPropsGetManualReservation,
	apiPrivate: AxiosInstance
) {
	try {
		const response: AxiosResponse<IResponseGetManualReservation> =
			await apiPrivate.get(`${ManualReservationAPI}/${id}`)
		return response.data
	} catch (error: any) {
		console.log(error)
		throw new Error("something went wrong")
	}
}

export function useGetManualReservation(
	data: IPropsGetManualReservation,
	options?:
		| Omit<
				UseQueryOptions<
					IResponseGetManualReservation,
					unknown,
					IResponseGetManualReservation
				>,
				"queryKey" | "queryFn"
		  >
		| undefined
) {
	const { apiPrivate } = useApi()
	return useQuery({
		queryKey: [APIS_KEYS.GETMANUALRESERVATION, ...Object.values(data)],
		queryFn: () => getManualReservation(data, apiPrivate),
		...options,
	})
}
