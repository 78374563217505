import styled from "styled-components"

export const ControlsMonthlyViewWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 30px;
	margin-top: 40px;
	> p {
		margin: 0;
		color: #435363;
		font-size: 24px;
		font-weight: 700;
		letter-spacing: -0.04em;
	}
`
