import styled from "styled-components"

export const CustomMultiSelectWrapper = styled.div<{
	$islabelVisible?: boolean
	$direction?: string
	$isError: boolean
	$language: "ar" | "en"
}>`
	label {
		display: flex;
		justify-content: flex-start;
		flex-direction: column;
		p {
			font-weight: 700;
			text-align: ${(props) => (props.$direction === "ltr" ? "end" : "start")};
			color: #435363;
			visibility: ${(props) => (props.$islabelVisible ? "visible" : "hidden")};
			font-size: 15px;
			margin: 0;
			margin-bottom: 4px;
			text-transform: capitalize;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			display: flex;
			flex-direction: ${(props) =>
				props.$direction === "ltr" ? "row-reverse" : "row"};
			span {
				color: #d70015;
				margin-inline: 4px;
			}
		}
		label {
			&.MuiFormLabel-root {
				right: ${(props) => (props.$language === "ar" ? "0" : "auto")};
				left: ${(props) => (props.$language === "ar" ? "auto" : "0")};
				transform: ${(props) =>
					props.$language === "ar"
						? "translate(-10px, -50%)"
						: "translate(10px, -50%)"};
				color: #e5e7eb;
				font-size: 15px;
				top: 50%;
			}
			&.Mui-focused {
				display: none;
			}
		}
		> div {
			width: 100%;
			margin: 0;
		}
		.MuiInputBase-root {
			.MuiSelect-select {
				padding: 0;
				font-size: 15px;
				background-color: #fff;
				outline: none;
				border: 1px solid #000;
				font-size: 15px;
				color: ${(props) => (props.$isError ? "#EF4444" : "#261B6A")};
				background-color: #fff;
				padding: 11px;
				border: 1px solid ${(props) => (props.$isError ? "#EF4444" : "#E5E7EB")};
				border-radius: 12px;
				outline: none;
				font-weight: 500;
				line-height: 24px;
				&:focus {
					border: 1px solid
						${(props) => (props.$isError ? "#EF4444" : "#E5E7EB")};
				}
			}
			svg {
				right: ${(props) => (props.$language === "ar" ? "auto" : "7px")};
				left: ${(props) => (props.$language === "ar" ? "7px" : "auto")};
				color: #435363;
			}
			fieldset {
				border: none;
			}
		}
	}

	.ErrorMessageStyle {
		font-size: 13px;
		font-weight: 400;
		line-height: 22.4px;
		color: #ef4444;
		height: 22px;
		p {
			margin: 0;
		}
	}
`
