import { Button, Chip, Popover, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DeleteOutline, MoreVert } from '@mui/icons-material';
import { FC, useContext, useState } from 'react';
import { CreateNewRow, LoadingWrapper, UpdateIncomeRow } from '@components';
import { useAccounting, useCommon } from '@services';
import { useParams, useSearchParams } from 'react-router-dom';
import { IIncome } from '@interfaces';
import { useIsMutating } from '@tanstack/react-query';
import dayjs, { Dayjs } from 'dayjs';
import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query/build/modern';
import { sarFormat } from '@utils';
import { ToastMessageContext } from '@context';

const IncomeTable: FC<{
  refetch: (options?: RefetchOptions | undefined) => Promise<QueryObserverResult<IIncome[], Error>>;
  incomes?: IIncome[];
  isLoading?: boolean;
}> = ({ refetch, incomes = [], isLoading = false }) => {
  const {
    i18n: { language },
    t,
  } = useTranslation();

  const [creating, setCreating] = useState<boolean>(false);
  const [updatingCell, setUpdatingCell] = useState<number | undefined>();
  const align = language === 'ar' ? 'right' : 'left';
  const { id } = useParams();
  const mutating = useIsMutating();
  const [deleteEl, setDeleteEl] = useState<HTMLElement | undefined>();
  const { showSuccess, showError } = useContext(ToastMessageContext);

  const [params, setParams] = useSearchParams();

  const { allChannelsQuery } = useCommon();

  const { data: channels } = allChannelsQuery();

  const { createIncomeMutate, updateIncomeMutate, deleteIncomeMutate } = useAccounting();

  const { mutate: createIncome } = createIncomeMutate({
    onSuccess: () => {
      refetch();
      setCreating(false);
      showSuccess(t('FINANCE.INCOME.CREATED_SUCCESS'));
    },
    onError: () => {
      setUpdatingCell(undefined);
      setCreating(false);
      showError(t('FINANCE.INCOME.CREATED_FAIL'));
    },
  });
  const { mutate: deleteIncome } = deleteIncomeMutate({
    onSuccess: () => {
      refetch();
      showSuccess(t('FINANCE.INCOME.DELETE_SUCCESS'));
    },
    onError: () => {
      showError(t('FINANCE.INCOME.DELETE_FAIL'));
    },
  });
  const { mutate: updateIncome } = updateIncomeMutate({
    onSuccess: () => {
      refetch();
      setUpdatingCell(undefined);
      setCreating(false);
      showSuccess(t('FINANCE.INCOME.UPDATED_SUCCESS'));
    },
    onError: () => {
      setUpdatingCell(undefined);
      setCreating(false);
      showError(t('FINANCE.INCOME.UPDATED_FAIL'));
    },
  });

  const onCreate = ({
    numberOfNights,
    reservationAmount,
    channelId,
    isThereCompensation,
    compensationAmount,
  }: IIncome) => {
    createIncome({
      numberOfNights,
      reservationAmount,
      channelId,
      isThereCompensation,
      compensationAmount: isThereCompensation ? compensationAmount : undefined,
      propertyId: +id!,
    });
  };

  const onCancel = () => {
    setCreating(false);
  };

  const onUpdate = (data: IIncome) => {
    const {
      numberOfNights,
      reservationAmount,
      channelId,
      revenueId,
      propertyId,
      isThereCompensation,
      compensationAmount,
    } = data;
    updateIncome({
      numberOfNights,
      reservationAmount,
      channelId,
      revenueId,
      isThereCompensation,
      compensationAmount: isThereCompensation ? compensationAmount : undefined,
      propertyId,
    });
  };

  const customFilter = (i: IIncome) => {
    let show = true;
    if (params?.get('channel')) {
      show = i.channelId === Number(params.get('channel'));
    }
    if (params.get('from') || params.get('to')) {
      const from: Dayjs = dayjs(params.get('from') ?? '2000-01-01')
        .set('hour', 0)
        .set('minute', 0)
        .set('second', 0);
      const to: Dayjs = (params.get('to') ? dayjs(params.get('to')) : dayjs())
        .set('hour', 23)
        .set('minute', 59)
        .set('second', 59);
      const date = dayjs(i.revenueCreateDate);
      show = show && from.isBefore(date) && to.isAfter(date);
    }
    return show;
  };

  const handleEdit = (id?: number) => {
    if (updatingCell === id) {
      setUpdatingCell(undefined);
    } else {
      setCreating(false);
      setUpdatingCell(id);
    }
  };

  return (
    <LoadingWrapper loading={mutating > 0 || isLoading}>
      <Typography fontSize={'14px'} mt={2} marginInlineStart={2} color={'#A1A1AA'}>
        {t('FINANCE.HINT')}
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align={align}></TableCell>
            <TableCell align={align}>{t('FINANCE.INCOME.RESERVATION_NUMBER')}</TableCell>
            <TableCell align={align}> {t('FINANCE.INCOME.NIGHTS')}</TableCell>
            <TableCell align={align}> {t('FINANCE.INCOME.RESOURCE')}</TableCell>
            <TableCell align={align}>{t('FINANCE.INCOME.COMMISSION')}</TableCell>
            <TableCell align={align}> {t('FINANCE.INCOME.TITLE')}</TableCell>
            <TableCell align={align}>{t('FINANCE.INCOME.NET_INCOME')}</TableCell>
            <TableCell align={align}> {t('FINANCE.INCOME.IS_COMPENSATION')}</TableCell>
            <TableCell align={align}> {t('FINANCE.INCOME.COMPENSATION_AMOUNT')}</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          <CreateNewRow
            span={9}
            align={align}
            onAdd={() => {
              setCreating(true);
              setUpdatingCell(undefined);
            }}
          />
          {creating && <UpdateIncomeRow onSubmit={onCreate} onCancel={onCancel} channels={channels} />}
          {incomes?.map((i) => {
            if (updatingCell && updatingCell === i.revenueId) {
              return (
                <UpdateIncomeRow
                  key={`${i.revenueId}-editing-${updatingCell}`}
                  data={i}
                  onCancel={() => setUpdatingCell(undefined)}
                  onSubmit={(data) =>
                    onUpdate({
                      ...data,
                      propertyId: i.propertyId,
                      revenueId: i.revenueId,
                    })
                  }
                  channels={channels}
                />
              );
            } else {
              return (
                <TableRow key={`${i.revenueId}${updatingCell}`}>
                  <TableCell
                    align={align}
                    sx={{ p: 0, width: 0 }}
                    id={`delete-icon-${i.revenueId}`}
                    onClick={(e) => setDeleteEl(e.currentTarget)}
                  >
                    <MoreVert
                      sx={{
                        color: '#888',
                        cursor: 'pointer',
                        ':hover': {
                          color: '#5B3FFF',
                        },
                        ':active': {
                          color: '#D32F2F',
                        },
                      }}
                    />
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      handleEdit(i.revenueId);
                    }}
                    align={align}
                  >
                    {i.reservationNumber}
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      handleEdit(i.revenueId);
                    }}
                    align={align}
                  >
                    {t('FINANCE.INCOME.NIGHTS_DAYS', {
                      count: i.numberOfNights,
                    })}
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      handleEdit(i.revenueId);
                    }}
                    align={align}
                  >
                    <Chip
                      color={'primary'}
                      sx={{ backgroundColor: i.channels?.color }}
                      label={i.channels?.channelName}
                    />
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      handleEdit(i.revenueId);
                    }}
                    align={align}
                  >
                    {i.channels?.commission}%
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      handleEdit(i.revenueId);
                    }}
                    align={align}
                  >
                    {sarFormat(i.reservationAmount ?? 0)}
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      handleEdit(i.revenueId);
                    }}
                    align={align}
                  >
                    <Typography fontSize={'14px'} sx={{ color: '#2F7C31' }}>
                      {sarFormat(i.netRevenue ?? 0)}
                    </Typography>
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      handleEdit(i.revenueId);
                    }}
                    align={align}
                  >
                    {i.isThereCompensation ? t('GENERAL.YES') : t('GENERAL.NO')}
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      handleEdit(i.revenueId);
                    }}
                    align={align}
                  >
                    {i.isThereCompensation && sarFormat(i.compensationAmount ?? 0)}
                  </TableCell>
                </TableRow>
              );
            }
          })}
        </TableBody>
      </Table>
      <Popover
        id={'delete-income'}
        open={Boolean(deleteEl)}
        anchorEl={deleteEl}
        onClose={() => setDeleteEl(undefined)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Button
          sx={{
            minWidth: '200px',
            gap: 3,
            py: 0.5,
            px: 2,
            m: 1,
            display: 'flex',
            justifyContent: 'space-between',
          }}
          endIcon={<DeleteOutline />}
          color={'error'}
          onClick={() => {
            const id = Number(deleteEl?.id?.replace('delete-icon-', ''));
            setDeleteEl(undefined);
            if (!isNaN(id)) {
              deleteIncome(id);
            }
          }}
        >
          {t('FINANCE.INCOME.DELETE')}
        </Button>
      </Popover>
    </LoadingWrapper>
  );
};
export default IncomeTable;
