import { ColCellContentArg } from "@fullcalendar/resource"
import { CustomViewPropertiesWrapper } from "./styles"
import { Tooltip } from "@mui/material"
interface IProps {
	arg: ColCellContentArg
}
export default function CustomViewProperties({ arg }: IProps) {
	return (
		<Tooltip title={arg?.resource?.extendedProps?.title} placement='top' arrow followCursor>
			<CustomViewPropertiesWrapper>
				<div className='containerImage'>
					{arg?.resource?.extendedProps?.image ? (
						<img src={arg?.resource?.extendedProps?.image} alt='image' />
					) : (
						""
					)}
				</div>
				<div className='containerData'>
					<p>{arg?.resource?.extendedProps?.title?.substr(0, 10) + "\u2026"}</p>
					<p>{arg?.resource?.extendedProps?.id}</p>
				</div>
			</CustomViewPropertiesWrapper>
		</Tooltip>
	)
}
