import React, { Dispatch, FC, SetStateAction } from 'react';
import { IMessage } from '@interfaces';
import { Box, Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const SelectInstructions: FC<{
  messages: IMessage[];
  selected: number[];
  setSelected: Dispatch<SetStateAction<number[]>>;
}> = ({ setSelected, messages, selected }) => {
  const { t } = useTranslation();
  return (
    <Box sx={{ pt: 3, px: 1, overflowY: 'auto', maxHeight: '100%' }}>
      <Typography mb={2} color={'primary'}>
        {t('MESSAGING.CHOOSE_INSTRUCTIONS')}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          // '.MuiFormGroup-root': { gap: 2 },
          '.MuiFormControlLabel-root': {
            marginInline: 0,
          },
          flexDirection: 'column',
          gap: 1,
        }}
      >
        <FormGroup>
          {messages.map((c, index) => {
            const content = c.title ?? c.content;
            return (
              <FormControlLabel
                key={c.id}
                control={
                  <Checkbox
                    value={c.id}
                    checked={selected?.includes(c.id)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelected((prev) => {
                          prev.push(c.id);
                          return [...prev, c.id];
                        });
                      } else {
                        setSelected((prev) => {
                          return prev.filter((p) => p !== c.id);
                        });
                      }
                    }}
                  />
                }
                label={content}
              />
            );
          })}
        </FormGroup>
      </Box>
    </Box>
  );
};
export { SelectInstructions };
