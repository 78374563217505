import { createTemplateURL, deleteTemplateURL, getUserTemplatesURL, updateTemplateURL } from '../utils/constants';
import { useApi } from './useApi';
import { useMutation, UseMutationResult, useQuery } from '@tanstack/react-query';
import { IIncome, IProperty, ITemplate } from '@interfaces';

const useTemplate = () => {
  const { apiPrivate } = useApi();

  const createTemplate = async (body:{id: string, dataRes: FormData}) => {
    const {dataRes}=body
    const { data } = await apiPrivate.post(createTemplateURL, dataRes);
    return data;
  };
  const updateTemplate = async (body:{id: string, dataRes: FormData}) => {
    const {id,dataRes}=body
    const { data } = await apiPrivate.put(`${updateTemplateURL}/${id}`, dataRes);
    return data;
  };

  const deleteTemplate = async (id: number) => {
    const { data } = await apiPrivate.delete(`${deleteTemplateURL}/${id}`);
    return data;
  };

  const getTemplates = async () => {
    const { data } = await apiPrivate.get<any>(getUserTemplatesURL);
    return data?.result
    ;
  };

  const createTemplateMutate = ({
    onSuccess,
    onError,
  }: {
    onSuccess?: () => void;
    onError?: () => void;
  }): UseMutationResult<void, Error, {id: string, dataRes: FormData}, unknown> =>
    useMutation({
      mutationFn: (body: {id: string, dataRes: FormData}) => createTemplate(body),
      onSuccess: (data) => onSuccess && onSuccess(),
      onError: (error) => onError && onError(),
    });
  const updateTemplateMutate = ({
    onSuccess,
    onError,
  }: {
    onSuccess?: () => void;
    onError?: () => void;
  }): UseMutationResult<void, Error, {id: string, dataRes: FormData}, unknown> =>
    useMutation({
      mutationFn: (body: {id: string, dataRes: FormData}) => updateTemplate(body),
      onSuccess: (data) => onSuccess && onSuccess(),
      onError: (error) => onError && onError(),
    });
  const deleteTemplateMutate = ({
    onSuccess,
    onError,
  }: {
    onSuccess?: () => void;
    onError?: () => void;
  }): UseMutationResult<void, Error, number, unknown> =>
    useMutation({
      mutationFn: (id: number) => deleteTemplate(id),
      onSuccess: (data) => onSuccess && onSuccess(),
      onError: (error) => onError && onError(),
    });

  const getTemplatesQuery = () =>
    useQuery({
      queryKey: [getUserTemplatesURL],
      queryFn: ({ queryKey }) => getTemplates(),
    });

  return { createTemplateMutate, getTemplatesQuery, deleteTemplateMutate, updateTemplateMutate };
};
export { useTemplate };
