/**
 * "If the condition is true, return the item, otherwise return null."
 * @param {boolean} condition - boolean
 * @param {Type} item - The item to be added to the object.
 * @returns A function that takes a
 * condition and an item and returns the item if
 * the condition is true otherwise null.
 */
export function conditionalInObject<Type>(
  condition: boolean,
  item: Type
): Type | null {
  return condition ? item : null;
}

/**
 * If the item is not undefined, return the item.
 * @param {Type | undefined} item - Type | undefined
 * @returns A function that takes a generic type and returns a boolean.
 */
export const isNotUndefined = <Type>(item: Type | undefined): item is Type => {
  return !!item;
};
