import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import HeaderSection from "../headerSection"
import { Grid } from "@mui/material"
import Input from "@Common/input"
import CustomSwitch from "@Common/CustomSwitch"
import { Dispatch, SetStateAction, useEffect } from "react"
import { FORM_REGEX_VALIDATORS } from "@utils"

export interface IGustInfoFormInputs {
	isAutoAccess: boolean
	firstName: string
	lastName: string
	guestPhoneNumber: string
}
interface IProps {
	setIsActiveStep: Dispatch<SetStateAction<number>>
	isActiveStep: number
	getGustData: IGustInfoFormInputs | undefined
	setGustData: Dispatch<SetStateAction<IGustInfoFormInputs | undefined>>
}
export default function GustInfoFormInputs({
	setIsActiveStep,
	isActiveStep,
	getGustData,
	setGustData,
}: IProps) {
	const { t } = useTranslation()
	const methods = useForm<IGustInfoFormInputs>()
	const { setValue } = methods
	const onSubmit = (value: IGustInfoFormInputs) => {
		setGustData(value)
		setIsActiveStep(isActiveStep + 1)
	}

	useEffect(() => {
		if (getGustData) {
			setValue("firstName", getGustData?.firstName)
			setValue("lastName", getGustData?.lastName)
			setValue("guestPhoneNumber", getGustData?.guestPhoneNumber)
		}
	}, [])
	return (
		<div>
			<HeaderSection label={t("MANUALRESERVATION.GUESTDATA")} />
			<FormProvider {...methods}>
				<form
					onSubmit={methods.handleSubmit(onSubmit)}
					id='FormManualReservation'
				>
					<Grid container spacing={"24px"}>
						<Grid item xs={6}>
							<Input
								name='firstName'
								label={t("MANUALRESERVATION.FIRSTNameOfTheGuest")}
								placeholder={t("MANUALRESERVATION.FIRSTNameOfTheGuest")}
								registerOptions={{
									required: {
										value: true,
										message: t(
											"MANUALRESERVATION.PLEASEENTERTHEGUESTSFIRSTNAME"
										),
									},
									pattern: {
										value: FORM_REGEX_VALIDATORS.textOnly,
										message: t("MANUALRESERVATION.ACCEPTTEXTONLY"),
									},
								}}
							/>
						</Grid>
						<Grid item xs={6}>
							<Input
								name='lastName'
								label={t("MANUALRESERVATION.LASTNameOfTheGuest")}
								placeholder={t("MANUALRESERVATION.LASTNameOfTheGuest")}
								registerOptions={{
									required: {
										value: true,
										message: t(
											"MANUALRESERVATION.PLEASEENTERTHEGUESTSLASTNAME"
										),
									},
									pattern: {
										value: FORM_REGEX_VALIDATORS.textOnly,
										message: t("MANUALRESERVATION.ACCEPTTEXTONLY"),
									},
								}}
							/>
						</Grid>
						<Grid item xs={12}>
							<Input
								name='guestPhoneNumber'
								label={t("MANUALRESERVATION.GUESTMOBILENUMBER")}
								placeholder={t("MANUALRESERVATION.GUESTMOBILENUMBER")}
								registerOptions={{
									required: {
										value: true,
										message: t(
											"MANUALRESERVATION.PLEASEENTERTHEGUESTSMOBILENUMBER"
										),
									},
									pattern: {
										value: FORM_REGEX_VALIDATORS.phone,
										message: t(
											"MANUALRESERVATION.PLEASEENTERTHEGUESTSMOBILENUMBERCORRECTLY"
										),
									},
								}}
							/>
						</Grid>
						<Grid item xs={6}>
							<CustomSwitch
								name='isAutoAccess'
								label={t("MANUALRESERVATION.SMARTACCESS")}
								fontSize='18px'
								TooltipTitle={t("MANUALRESERVATION.SMARTACCESSDESC")}
								defaultChecked={getGustData?.isAutoAccess}
							/>
						</Grid>
					</Grid>
				</form>
			</FormProvider>
		</div>
	)
}
