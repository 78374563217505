import { SxProps } from '@mui/material';

const padding: SxProps = {
  px: {
    xs: 4,
    md: 6,
  },
};
const minusPadding: SxProps = {
  mx: {
    xs: -4,
    md: -6,
  },
  px: {
    xs: 4,
    md: 6,
  },
};

const footerPadding: SxProps = {
  px: {
    xs: 4,
    md: 6,
  },
  py: 3,
};

const mobileRTL: SxProps = {
  direction: 'ltr',
  '.MuiInputBase-input': {
    textAlign: 'end',
  },
};

const modalContainerStyle: SxProps = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  p: 2,
  flexDirection: 'column',
};
const modalBoxStyle: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  width: 495,
  maxWidth: '100%',
  bgcolor: 'background.paper',
  color: '#261B6A',
  boxShadow: 24,
  overflow: 'hidden',
  p: 3,
  borderRadius: 5,
  outline:'none'
};

const mobileBoxStyle: SxProps = {
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  height: '70vh',
  aspectRatio: 1 / 2,
  maxWidth: '100%',
  border: '10px solid black',
  bgcolor: 'background.paper',
  color: '#261B6A',
  boxShadow: 24,
  overflow: 'hidden',
  p: 3,
  borderRadius: 12,
};
export { padding, minusPadding, mobileRTL, footerPadding, modalContainerStyle, modalBoxStyle, mobileBoxStyle };
