import { useTranslation } from "react-i18next"
import { TimeLineWrapper } from "./styles"
import { FaCheck } from "react-icons/fa6"
interface IProps {
	listData: {
		label: string
		describtion: string
		icon: JSX.Element
		isActive: boolean
		isDone: boolean
	}[]
}
export default function CustomTimeLine({ listData }: IProps) {
	const {
		i18n: { language },
	} = useTranslation()

	return (
		<TimeLineWrapper $language={language as "ar" | "en"}>
			<div className='TimeLineContainer'>
				{listData.map((item, index) => {
					return (
						<div className='TimeLineItme' key={index}>
							<div
								className={`conatainerData ${item.isActive ? "active" : ""}`}
							>
								<span>{item.label}</span>
								<span>{item.describtion}</span>
							</div>
							<div
								className={`containerIcon ${item.isActive ? "active" : ""} ${item.isDone ? "done" : ""} `}
							>
								{item.isDone ? <FaCheck /> : item?.icon}
							</div>
						</div>
					)
				})}
			</div>
		</TimeLineWrapper>
	)
}
