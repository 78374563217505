// @ts-nocheck
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { FC, useLayoutEffect } from 'react';
import { ICategoryChartData } from '@interfaces';

const BarChart: FC<{ id?: string; data?: ICategoryChartData[] }> = ({ id = 'barChart', data = [] }) => {
  useLayoutEffect(() => {
    const root = am5.Root.new(id);

    root.setThemes([am5themes_Animated.new(root)]);
    root._logo.dispose();

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        focusable: true,
        panX: true,
        panY: false,
        wheelX: 'panX',
        wheelY: 'none',
        paddingLeft: 0,
        paddingRight: 0,
      }),
    );

    chart.rtl = true;

    // Craete Y-axis
    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 0,
        numberFormatter: am5.NumberFormatter.new(root, { numberFormat: '#.p' }),
        renderer: am5xy.AxisRendererY.new(root, {}),
      }),
    );

    // Create X-Axis
    const xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        maxDeviation: 0.49,
        renderer: am5xy.AxisRendererX.new(root, {}),
        categoryField: 'category',
      }),
    );
    xAxis.data.setAll(data);

    const series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: 'Series',
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'value',
        categoryXField: 'category',
        interpolationDuration: 2000,
        interpolationEasing: am5.ease.inOut(am5.ease.elastic),
      }),
    );
    series.columns.template.setAll({
      cornerRadiusTL: 15,
      cornerRadiusTR: 15,
      maxWidth: 15,
      strokeOpacity: 0,
      fill: am5.color(0x5b3fff),
    });

    series.data.setAll(data);
    chart.appear(1000, 50);
    return () => {
      root.dispose();
    };
  }, [data]);

  return <div dir={'ltr'} id={id} style={{ width: '100%', height: '100%' }}></div>;
};
export default BarChart;
