import { DayCellContentArg } from "@fullcalendar/core"
import { CustomDayCellContentWrapper } from "./styles"
import { useMemo } from "react"
import { SlLock } from "react-icons/sl"
import dayjs from "dayjs"

interface IProps {
	info: DayCellContentArg
	disabledDates: string[]
}

export default function CustomDayCellContent({ info, disabledDates }: IProps) {
	const date = info.date.getDate() // Get the day of the month (1-31)
	const today = new Date() // Get today's date
	const cellDate = dayjs(info.date) // Convert the cell's date to Day.js
	const startOftoday = dayjs().startOf("day")

	const isToday =
		date === today.getDate() &&
		info.date.getMonth() === today.getMonth() &&
		info.date.getFullYear() === today.getFullYear()

	const isPast = cellDate.isBefore(startOftoday) ? true : false

	/* ---------------------------------- Hooks --------------------------------- */
	const dataDisabel = useMemo(() => {
		if (disabledDates.length === 0 || disabledDates === undefined) return
		const dateStr = info.date.toISOString().split("T")[0]
		return disabledDates.includes(dateStr)
	}, [info, disabledDates])
	return (
		<CustomDayCellContentWrapper $isToday={isToday} $isPast={isPast}>
			<span className='days'>{date}</span>
			{dataDisabel && (
				<div className='dataDisabel'>
					<div>
						<SlLock />
					</div>
				</div>
			)}
		</CustomDayCellContentWrapper>
	)
}
