import styled from "styled-components"

export const PopupEnableSmartEntryFeatureWrapper = styled.div`
	position: fixed;
	top: 0;
	right: 0;
	width: 100dvw;
	height: 100dvh;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9;
	> div {
		width: 50%;
		background-color: #ffffff;
		box-shadow: 2px 4px 38px 0px #0000001a;
		border-radius: 16px;
		padding: 24px;
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 200px auto auto auto;
		justify-content: center;
		align-items: center;
		z-index: 999;
		.containerIcon {
			width: 100%;
			height: 100%;
			svg {
				width: 100%;
				height: 100%;
			}
		}
		p {
			text-align: center;
			text-wrap: auto;
			color: #435363;
			line-height: 19.2px;
			font-size: 16px;
			direction: rtl;
			&.AUTO_ACCESS {
				font-weight: 700;
			}
			&.MODAL_TITLE {
				font-weight: 400;
			}
		}
		.containerFooter {
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 16px;
		}
	}
`
