// @ts-ignore
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, IconButton } from '@mui/material';

const Language = () => {
  const {
    t,
    i18n: { language, changeLanguage },
  } = useTranslation();

  return (
    <>
      <div>
        <Button
          color={'primary'}
          sx={{
            fontSize: '12px',
            fontWeight: 'bold',
            minWidth: '32px',
            width: '32px',
            height: '32px',
            p: 0,
          }}
          variant={'contained'}
          onClick={() => changeLanguage(language === 'en' ? 'ar' : 'en')}
        >
          {language === 'en' ? 'ع' : 'En'}
        </Button>
      </div>
    </>
  );
};
export default Language;
