import { MenuItem, Select } from "@mui/material"
import { switchReservationsState } from "@store/index"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { IoIosArrowDown } from "react-icons/io"
import { useSetRecoilState } from "recoil"

export default function SwitchReservations() {
	const setSwitchReservationsState = useSetRecoilState(switchReservationsState)
	const { t } = useTranslation()
	const data = useMemo(() => {
		const list = [
			{
				value: "all",
				label: t("COMMON.All"),
			},
			{
				value: "reservation",
				label: t("COMMON.RESERVATIONS"),
			},
			{
				value: "reservation_request",
				label: t("COMMON.RESERVATION_REQUESTS"),
			},
		]

		return list ?? []
	}, [])
	return (
		<div className='containerCustomSelectOption'>
			<Select
				defaultValue={"all"}
				onChange={(event) => {
					setSwitchReservationsState(event.target.value)
				}}
				IconComponent={IoIosArrowDown}
			>
				{data.map((item, index) => {
					return (
						<MenuItem value={item.value} key={index}>
							{item.label}
						</MenuItem>
					)
				})}
			</Select>
		</div>
	)
}
