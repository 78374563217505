import { atom } from "recoil"
import { ATOMS_KEYS } from "../atom-keys"
interface IProps {
	passcode: string
	lockId: string
}
export const LockDetailsStore = atom<IProps>({
	key: ATOMS_KEYS.LOCKDETAILSSTORE,
	default: undefined,
})
