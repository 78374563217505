
import UnBlockingSuccess from '@assets/svg/UnBlockingSuccess.svg?react';
import UnBlockingCalander from '@assets/svg/UnBlockingCalander.svg?react';
import UnBlockingPercentage from '@assets/svg/UnBlockingPercentage.svg?react';
import { useTranslation } from 'react-i18next'

import { Box, Typography } from '@mui/material';
import { useState } from 'react';


const UnblockBtn = ({ title = '', onClick = () => { } }) => {
    return (
        <Box
            onClick={onClick}
            sx={{
                cursor: 'pointer',
                backgroundColor: 'rgba(91, 63, 255, 1)',
                width: '134px',
                height: '34px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'white',
                textAlign: 'center',
                borderRadius: '43px',
                fontSize:'13px'
            }} >
            {title}
        </Box>
    )
}

const Features = (isRtl = true) => {
    return [
        isRtl ? 'اعدادات الاتاحة' : 'Availability settings',
        isRtl ? 'التسعير' : 'Pricing',
        isRtl ? 'التقويم' : 'Calendar'
    ]
}
const RenderFeaturesStep = ({channel='',onClick=()=>{}}) => {
    const { t,i18n:{language} } = useTranslation()
    const isRtl = language == 'ar'

    return (
        <Box sx={{display:'flex',flexDirection:'column',alignItems:'center'}} >
            <UnBlockingCalander style={{ width: '74px', height: '74px' }} />
            <Typography sx={{
                color: 'rgba(67, 83, 99, 1)',
                fontWeight: 400,
                fontSize: '16px',
                lineHeight: '22px',
                textAlign: 'center',
                my: 3,

            }} >
                {`${t('UN_BLOCKING.TITLE',{channel})} ${channel}`}
            </Typography>
            <Box sx={{
                backgroundColor: 'rgba(245, 227, 172, 0.7)',
                width: '100%',
                paddingX: 2,
                paddingY: 1,
                borderRadius: '4px'
            }} >
                <Typography sx={{
                    fontWeight: 700,
                    fontSize: '14px',
                    lineHeight: '17px',
                    color: 'rgba(67, 83, 99, 1)'
                }} >
                    {t('UN_BLOCKING.DESIRE')}
                </Typography>
                {
                    Array.from({ length: 3 }).map((_, i: number) => {
                        return (
                            <Typography sx={{
                                fontWeight: 350,
                                fontSize: '14px',
                                lineHeight: '17px',
                                color: 'rgba(67, 83, 99, 1)'
                            }} >
                                <Box sx={{
                                    fontSize: '20px',
                                    fontWeight: 600,
                                }} component="span" >.</Box>
                                <Box component="span" sx={{ mx: 1 }} >
                                    {Features(isRtl)[i]}
                                </Box>
                            </Typography>
                        )
                    })
                }
                <Typography sx={{
                    color: 'rgba(67, 83, 99, 1)',
                    fontWeight: 700,
                    fontSize: '14px',
                    lineHeight: '17px',
                    textAlign: 'center',
                    mt: 1
                }} >
                    {t('UN_BLOCKING.ONLY_FROM_MSOOL')}
                </Typography>

            </Box>
            <Box sx={{ mt: 2 }} />
            <UnblockBtn onClick={onClick} title={t('UN_BLOCKING.UN_BLOCK')} />
            <Box sx={{ mt: 5 }} />
            <UnBlockingPercentage style={{
                width: '159px',
                height: '8px',

            }} />
        </Box>
    )
}
const RenderFinalStep = ({channel='',onClick=()=>{}}) => {
    const { t } = useTranslation()
    return (
        <Box sx={{display:'flex',flexDirection:'column',alignItems:'center'}} >
            <UnBlockingSuccess style={{ width: '74px', height: '74px' }} />
            <Typography sx={{
                fontWeight:700,
                fontSize:'20px',
                lineHeight:'20px',
                color:'rgba(67, 83, 99, 1)',
                textAlign:'center',
                mt:2,
                mb:1
            }} >
           {t('UN_BLOCKING.CHANGE_SETTINGS')}<Box component="span" sx={{
                color:'rgba(91, 63, 255, 1)'
            }} >
            {t('COMMON.MSOOL')}
                </Box> {t('UN_BLOCKING.TO_AVOID')}
            </Typography>
            
            <UnblockBtn onClick={onClick} title={t('COMMON.PROPERTY_MANGEMENT')} />
           
            <Box sx={{ mt: 20 }} />
            <UnBlockingPercentage style={{
                width: '159px',
                height: '8px',

            }} />
        </Box>
    )
}
const UnBlockingSteps = ({onClose=()=>{},channel=''}) => {
    const { t } = useTranslation()
    const [Done, setDone] = useState(false)

    return (
        <Box sx={{
            mt:Done ? '30%': '13%',
            width: '80%',
            marginRight: 'auto',
            marginLeft: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
        }} >
            {Done ? <RenderFinalStep  onClick={()=>onClose()} /> : <RenderFeaturesStep channel={channel} onClick={()=>setDone(true)} />}
        </Box>
    )
}

export default UnBlockingSteps 