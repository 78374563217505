import { FormProvider, useForm } from "react-hook-form"
import { StepOneWrapper } from "./styles"
import Input from "@Common/input"
import { useTranslation } from "react-i18next"
import { Container } from "@mui/material"
import { Dispatch, SetStateAction, useEffect } from "react"
interface IForm {
	categoryName: string
}
interface IProps {
	setCategoryName: Dispatch<SetStateAction<string | undefined>>
	setActiveStepIs: Dispatch<SetStateAction<2 | 1>>
	categoryName: string | undefined
}
export default function StepOne({
	setCategoryName,
	setActiveStepIs,
	categoryName,
}: IProps) {
	const { t } = useTranslation()
	const methods = useForm<IForm>()
	const { setValue } = methods
	const onSubmit = async (value: IForm) => {
		setCategoryName(value.categoryName)
		setActiveStepIs(2)
	}
	useEffect(() => {
		if (categoryName) {
			setValue("categoryName", categoryName)
		}
	}, [categoryName, setValue])
	return (
		<StepOneWrapper>
			<Container maxWidth='sm'>
				<FormProvider {...methods}>
					<form onSubmit={methods.handleSubmit(onSubmit)} id='formAddCategory'>
						<div className='containerInfo'>
							<p>{t('CATEGORYMANAGER.EnterCatName')}</p>
							<p>{t('CATEGORYMANAGER.NamingProperties')}</p>
						</div>
						<div>
							<Input
								label={t("CATEGORYMANAGER.CATEGORYNAME")}
								placeholder={t("CATEGORYMANAGER.CATEGORYNAME")}
								name='categoryName'
								registerOptions={{
									required: {
										value: true,
										message: t("COMMON.REQUIRED"),
									},
								}}
							/>
						</div>
					</form>
				</FormProvider>
			</Container>
		</StepOneWrapper>
	)
}