import styled from "styled-components"

export const StepOneWrapper = styled.div`
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	form {
		width: 100%;
		.containerInfo {
			margin-bottom: 32px;
			p {
				margin: 0;
				&:first-child {
					font-size: 32px;
					font-weight: 700;
					line-height: 38.4px;
					color: #331860;
				}
				&:last-child {
					font-size: 18px;
					font-weight: 400;
					line-height: 21.6px;
					color: #625783;
				}
			}
		}
	}
`
