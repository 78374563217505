import { IoInformationCircleOutline } from "react-icons/io5"
import { ContainerCardWrapper } from "./styles"
import { useTranslation } from "react-i18next"
interface IProps {
	data: IData
}
interface IData {
	blacklistedGuests: number | undefined
	repeatedGuests: number | undefined
	totalGuests: number | undefined
}
export default function CardsInfo({ data }: IProps) {
	const {t}=useTranslation();
	const dataList = [
		{
			title: t('GuestManagement.NumGuests'),
			count: data?.totalGuests,
			info: "",
		},
		{
			title: t('GuestManagement.RepeatedGuests'),
			count: data?.repeatedGuests,
			info: "",
		},
		{
			title: t('GuestManagement.BlockedGuests'),
			count: data?.blacklistedGuests,
			info: "",
		},
	]

	return (
		<ContainerCardWrapper>
			{dataList.map((item) => {
				return (
					<div className='containerCard'>
						<div>
							<p>{item.title}</p>
							<div>
								<IoInformationCircleOutline />
							</div>
						</div>
						<div>
							<p>{item.count}</p>
						</div>
					</div>
				)
			})}
		</ContainerCardWrapper>
	)
}