import { UseMutationOptions, useMutation } from "@tanstack/react-query"
import { AxiosInstance, AxiosResponse } from "axios"
import { IPropsAllPlatformPrice, IResponseAllPlatformPrice } from "./types"
import { useApi } from "../../useApi"
import { AllPlatformPriceURL } from "../../../utils/constants"

async function postAllPlatformPrice(
	responseData: IPropsAllPlatformPrice,
	apiPrivate: AxiosInstance
) {
	try {
		const response: AxiosResponse<IResponseAllPlatformPrice> =
			await apiPrivate.post(`${AllPlatformPriceURL}`, { ...responseData })

		return response
	} catch (error: any) {
		return error.response
	}
}

export function usePostAllPlatformPrice(
	options?:
		| Omit<
				UseMutationOptions<
					AxiosResponse<IResponseAllPlatformPrice>,
					unknown,
					unknown,
					AxiosResponse<IResponseAllPlatformPrice>
				>,
				"mutationFn"
		  >
		| undefined
) {
	const { apiPrivate } = useApi()
	const mutation = useMutation({
		mutationFn: (data: IPropsAllPlatformPrice) =>
			postAllPlatformPrice(data, apiPrivate),
		...options,
	})
	return mutation
}
