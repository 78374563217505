import styled from "styled-components"

export const SwitchWrapper = styled.div<{
	$islabelVisible: boolean
	$direction?: string
	$isError: boolean
	$language: "ar" | "en"
	$fontSize?: string
}>`
	label {
		display: flex;
		justify-content: space-between;
		flex-direction: row;
		p {
			font-weight: 700;
			text-align: ${(props) => (props.$direction === "ltr" ? "end" : "start")};
			color: #435363;
			visibility: ${(props) => (props.$islabelVisible ? "visible" : "hidden")};
			font-size: ${(props) => (props.$fontSize ? props.$fontSize : "20px")};
			margin: 0;
			margin-bottom: 4px;
			text-transform: capitalize;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			display: flex;
			align-items: center;
			flex-direction: ${(props) =>
				props.$direction === "ltr" ? "row-reverse" : "row"};
			span {
				color: #d70015;
				margin-inline: 4px;
			}
			.TooltipIconWrapper {
				display: flex;
				justify-content: center;
				align-items: center;
				padding-inline: 4px;
			}
		}
	}
	.ErrorMessageStyle {
		font-size: 13px;
		font-weight: 400;
		line-height: 22.4px;
		color: #ef4444;
		p {
			margin: 0;
		}
	}
`
