import styled from "styled-components"

export const CustomNoRowsOverlayWrapper = styled.div`
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 8px;
	svg {
		width: 100px;
		height: 100px;
	}
	p {
		margin: 0;
		color: #625783;
		font-size: 14px;
		font-weight: 400;
		line-height: 16.8px;
	}
`