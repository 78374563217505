import styled from "styled-components"

export const ReservationMangmentHeaderWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	> div {
		margin-bottom: 16px;
		&:first-child {
			p {
				margin: 0;
				color: #130080;
				font-size: 20px;
				font-weight: 700;
				line-height: 24px;
			}
			span {
				color: #adbac7;
				font-size: 16px;
				font-weight: 400;
				line-height: 19.2px;
			}
		}
		&:last-child {
		}
	}
`
