import CustomButton from "@Common/CustomButton"
import { PopupConfirmationSendMessegWrapper } from "./styles"
import { useTranslation } from "react-i18next"
import { IoClose } from "react-icons/io5"
import { Dispatch, SetStateAction } from "react"
import { APIS_KEYS, useGetSendWhatsupMessagev2 } from "@services"
import {
	loaderState,
	PopupOverlayNewCalender,
	reservationDetails,
} from "@store/index"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { useQueryClient } from "@tanstack/react-query"
interface IProps {
	setIsPopupConfirmationSendMessegOpen: Dispatch<SetStateAction<boolean>>
}
export default function PopupConfirmationSendMesseg({
	setIsPopupConfirmationSendMessegOpen,
}: IProps) {
	const { t } = useTranslation()
	const setLoaderState = useSetRecoilState(loaderState)
	const reservationDetailsValue = useRecoilValue(reservationDetails)
	const setPopupOverlayNewCalender = useSetRecoilState(PopupOverlayNewCalender)
	const { refetch, data: SendWhatsupMessagev2 } = useGetSendWhatsupMessagev2(
		{
			reservationId: reservationDetailsValue?.reservationId!,
		},
		{ enabled: false }
	)
	const queryClient = useQueryClient()

	const sendMessage = async () => {
		setLoaderState(true)
		refetch()
		const response = await SendWhatsupMessagev2
		if (response?.statusCode === 200) {
			setPopupOverlayNewCalender(undefined)
			queryClient.invalidateQueries({ queryKey: [APIS_KEYS.GETCALENDERDATA] })
			setLoaderState(false)
		}
		setLoaderState(false)
	}

	return (
		<PopupConfirmationSendMessegWrapper>
			<div className='containerPopup'>
				<div>
					<p>{t("NEW_LOCKS.SEND_MESSAGE")}</p>
					<div
						className='containerClose'
						onClick={() => setIsPopupConfirmationSendMessegOpen(false)}
					>
						<IoClose />
					</div>
				</div>
				<div>
					<p>
						{t("COMMON.are_you_sure_you_want_to_send_a_message_to_the_guest")}
					</p>
				</div>
				<div>
					<CustomButton
						label={t("NEW_LOCKS.SEND_MESSAGE")}
						withBG
						customPadding='8px 24px'
						borderRadius='8px'
						onClick={() => sendMessage()}
					/>
				</div>
			</div>
		</PopupConfirmationSendMessegWrapper>
	)
}
