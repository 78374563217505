import { Dispatch, MutableRefObject, SetStateAction, useMemo } from "react"
import { CustomHeaderWrapper } from "./styles"
import FullCalendar from "@fullcalendar/react"
import { Grid } from "@mui/material"
import { useTranslation } from "react-i18next"
import ManualReservation from "./actions/manualReservation"
import GoToPrev from "./actions/goToPrev"
import GoToNext from "./actions/goToNext"
import GoToday from "./actions/goToday"
import ControlsMonthlyView from "./ControlsMonthlyView"
import SwitchView from "./actions/switchView"
import SwitchProperties from "./actions/switchProperties"
import SwitchReservations from "./actions/switchReservations"

interface IProps {
	calendarRef: MutableRefObject<FullCalendar | null>
	switchView: "dayGridMonth" | "resourceTimelineWeek"
	setSwitchView: Dispatch<
		SetStateAction<"dayGridMonth" | "resourceTimelineWeek">
	>
	calendarTitle: string
}
export default function CustomHeader({
	calendarRef,
	switchView,
	setSwitchView,
	calendarTitle,
}: IProps) {
	/* ---------------------------------- Hooks --------------------------------- */
	const { t } = useTranslation()

	return (
		<CustomHeaderWrapper>
			<Grid container justifyContent='flex-end'>
				<Grid item xs={12} md={3}>
					<div className='containerLabel'>
						<p>{t("CALENDAR.CALENDAR")}</p>
						<p>{t("CALENDAR.A_SPACE_TO_HELP_YOU_REVIEW_YOUR_RESERVATIONS")}</p>
					</div>
				</Grid>
				<Grid item xs={12} md={3}>
					<ManualReservation />
				</Grid>
				<Grid item xs={12} md={6}>
					<div className='switchActions'>
						{switchView === "dayGridMonth" && <SwitchProperties />}
						<SwitchReservations />
						<GoToday calendarRef={calendarRef} />
						<SwitchView
							switchView={switchView}
							calendarRef={calendarRef}
							setSwitchView={setSwitchView}
						/>
						{switchView === "resourceTimelineWeek" && (
							<div className='switchBtweenWeeks'>
								<GoToPrev calendarRef={calendarRef} switchView={switchView} />
								<p>{calendarTitle}</p>
								<GoToNext calendarRef={calendarRef} switchView={switchView} />
							</div>
						)}
					</div>
				</Grid>
				{switchView === "dayGridMonth" && (
					<Grid item xs={12}>
						<ControlsMonthlyView
							calendarTitle={calendarTitle}
							calendarRef={calendarRef}
							switchView={switchView}
						/>
					</Grid>
				)}
			</Grid>
		</CustomHeaderWrapper>
	)
}
