import { useApi } from './useApi';
import { useQuery } from '@tanstack/react-query';
import {
  AccountingGetExpenseURL,
  AllCategoriesURL,
  AllChannelsURL,
  AllCitiesURL,
  ReasonsDeletionURL,
} from '../utils/constants';
import { ICategory, IChannel } from '@interfaces';

const useCommon = () => {
  const { apiPrivate } = useApi();

  const getAllChannels = async () => {
    const {
      data: { result },
    } = await apiPrivate.get<{ result: IChannel[] }>(AllChannelsURL);
    return result;
  };

  const getAllCategories = async () => {
    const {
      data: { result },
    } = await apiPrivate.get<{ result: ICategory[] }>(AllCategoriesURL);
    return result;
  };
  const getDeleteReasons = async () => {
    const {
      data: { result },
    } = await apiPrivate.get<{ result: { id: number; name: string }[] }>(ReasonsDeletionURL);
    return result;
  };
  const getAllCities = async () => {
    const {
      data: { result },
    } = await apiPrivate.get<{ result: { id: number; name: string }[] }>(AllCitiesURL);
    return result;
  };

  const allChannelsQuery = () =>
    useQuery({
      queryKey: [AllChannelsURL],
      queryFn: getAllChannels,
    });
  const deleteReasonsQuery = () =>
    useQuery({
      queryKey: [ReasonsDeletionURL],
      queryFn: getDeleteReasons,
    });
  const allCategoriesQuery = () =>
    useQuery({
      queryKey: [AllCategoriesURL],
      queryFn: getAllCategories,
    });
  const allCitiesQuery = () =>
    useQuery({
      queryKey: [AllCitiesURL],
      queryFn: getAllCities,
    });

  return { allChannelsQuery, allCategoriesQuery, allCitiesQuery, deleteReasonsQuery };
};
export { useCommon };
