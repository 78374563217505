import { UseMutationOptions, useMutation } from "@tanstack/react-query"
import { AxiosInstance, AxiosResponse } from "axios"
import {
	IPropsAvailabilityInChannels,
	IResponseAvailabilityInChannels,
} from "./types"
import { useApi } from "../../useApi"
import { AllChannelsAvailabilityURL } from "../../../utils/constants"

async function postAvailabilityInChannels(
	data: IPropsAvailabilityInChannels,
	apiPrivate: AxiosInstance
) {
	try {
		const response: AxiosResponse<IResponseAvailabilityInChannels> =
			await apiPrivate.post(`${AllChannelsAvailabilityURL}`, { ...data })

		return response
	} catch (error: any) {
		return error.response
	}
}

export function usePostAvailabilityInChannels(
	options?:
		| Omit<
				UseMutationOptions<
					AxiosResponse<IResponseAvailabilityInChannels>,
					unknown,
					unknown,
					AxiosResponse<IResponseAvailabilityInChannels>
				>,
				"mutationFn"
		  >
		| undefined
) {
	const { apiPrivate } = useApi()
	const mutation = useMutation({
		mutationFn: (data: IPropsAvailabilityInChannels) =>
			postAvailabilityInChannels(data, apiPrivate),
		...options,
	})
	return mutation
}
