import CustomButton from "@Common/CustomButton"
import { ReservationMangmentHeaderWrapper } from "./styles"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

export default function ReservationMangmentHeader() {
	const { t } = useTranslation()
	const navigate = useNavigate()
	return (
		<ReservationMangmentHeaderWrapper>
			<div>
				<p>{t('Reservation.title')}</p>
				<span>{t('Reservation.subTitle')}</span>
			</div>
			<div>
				<CustomButton
					label={t("MANUALRESERVATION.CREATEMANUALRESERVATION")}
					withBG
					customPadding='16px 90px'
					onClick={() => navigate("/manual-reservation")}
				/>
			</div>
		</ReservationMangmentHeaderWrapper>
	)
}