import { Container } from "@mui/material"
import { StepTwoWrapper } from "./styles"
import { FormProvider, useForm } from "react-hook-form"
import Input from "@Common/input"
import { useTranslation } from "react-i18next"
import PorpertesCard from "./porpertesCard"
import { useCategoryMangment } from "@services"
import { useContext } from "react"
import { ToastMessageContext } from "@context/toast-message-cotnext"
import { useNavigate } from "react-router-dom"
export interface IForm {
	propertyId: string[]
	searchInput?: string
}
interface IProps {
	categoryName: string | undefined
}

export default function StepTwo({ categoryName }: IProps) {
	const { postCreateCategoriesMutate } = useCategoryMangment()
	const { showSuccess, showError } = useContext(ToastMessageContext)
	const navigate = useNavigate()
	const {
		t,
		i18n: { language },
	} = useTranslation()
	const { mutateAsync: postCreateCategories } = postCreateCategoriesMutate({
		onError: (e) => {
			try {
				const errObj = e?.response?.data?.errorMessages[0]
				const err = language === "ar" ? errObj?.messageAr : errObj?.messageEn
				showError(err || t('CATEGORYMANAGER.errorOccurred'))
			} catch (e) {
				console.log(e)
			}
		},
	})

	const methods = useForm<IForm>()
	const { watch } = methods
	const searchInputWatch = watch("searchInput")
	const onSubmit = async (value: IForm) => {
		const data = {
			categoryName: categoryName,
			propertyId: !Array.isArray(value?.propertyId)
				? [value.propertyId]
				: value.propertyId,
		}
		const response = await postCreateCategories(data)
		if (response?.data.statusCode >= 200 && response?.data.statusCode < 300) {
			// showSuccess(response?.data?.result)
			showSuccess(t('CATEGORYMANAGER.messSuccess'))
			navigate("/category-management")
		}
	}

	return (
		<StepTwoWrapper>
			<Container maxWidth='md'>
				<FormProvider {...methods}>
					<form onSubmit={methods.handleSubmit(onSubmit)} id='formAddCategory'>
						<div className='containerInfo'>
							<p>{t('CATEGORYMANAGER.AddPropertiesCat')}</p>
						</div>
						<div className='containerSearchInput'>
							<Input
								type='search'
								placeholder={t("CATEGORYMANAGER.SEARCH")}
								name='searchInput'
							/>
						</div>
						<div>
							<PorpertesCard searchInputWatch={searchInputWatch} />
						</div>
					</form>
				</FormProvider>
			</Container>
		</StepTwoWrapper>
	)
}