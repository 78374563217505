import styled from "styled-components"

export const PaginationPropertiesWrapper = styled.div`
	display: flex;
	justify-content: flex-start;
	> div {
		width: 200px;
		background-color: #fff;
		padding: 10px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		button {
			width: 52px;
			height: 44px;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 8px;
			border: 1px solid #eaedf1;
			background-color: #fff;
			color: #5b3fff;
			&:disabled {
				background-color: #eaedf1;
				color: #8e98a1;
			}
		}
		span {
			color: #8e98a1;
			font-size: 14px;
			font-weight: 400;
			line-height: 16.8px;
		}
	}
`
