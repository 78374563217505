import styled from "styled-components"

export const CalendarComponentsWrapper = styled.div<{
	switchView: "dayGridMonth" | "resourceTimelineWeek"
}>`
	position: relative;
	.fc-resource-timeline-divider {
		pointer-events: none;
	}

	.fc-highlight {
		background-color: #5b3fff;
		color: #fff;
		border-radius: 5px;
	}

	.fc-datagrid-body.fc-scrollgrid-sync-table {
		background-color: #fff;
		tbody {
			tr {
				td {
					border: 1px solid #f6f6f7 !important;
				}
			}
		}
	}
	.fc-datagrid-header {
		.fc-datagrid-cell-frame {
			width: 100%;
			display: block;
		}
	}

	.fc-timeline-slot-frame {
		width: 100%;
		display: flex;
		justify-content: center !important;
		align-items: center;
	}
	.fc-theme-standard {
		td,
		th {
			border-color: #e8e8e8;
		}
	}

	.fc {
		.fc-daygrid-day-events {
			margin-top: 2em !important;
		}
		.fc-daygrid-day {
			height: 130px !important;
		}
		.fc-daygrid-day-top {
			background-color: #fff;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			> a {
				padding: 0;
			}
			.fc-daygrid-day-number {
				width: 100%;
				height: 100%;
				> div {
					width: 100%;
					height: 100%;
				}
			}
		}
		.fc-bg-event {
			opacity: 1;
			padding: 1px;
		}
	}
	.fc-event-start {
		margin-right: ${(props) =>
			props.switchView === "dayGridMonth" ? "80px !important" : ""};
	}

	.fc-event-end {
		margin-left: ${(props) =>
			props.switchView === "dayGridMonth" ? "80px !important" : ""};
	}
`
