import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { IProperty, ITemplate } from '@interfaces';
import { Box, Button, Checkbox, Chip, FormControlLabel, MenuItem, Modal, Select, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useProperty } from '@services';
import { Clear } from '@mui/icons-material';
import { modalBoxStyle, modalContainerStyle } from '@utils';
import { useIsMutating } from '@tanstack/react-query';
import { SelectProperty } from '../AddMessageModal/SelectProperty';

const MessageAssignModal: FC<{
  open: boolean;
  data?: ITemplate;
  handleClose: () => void;
  usedProperty?: IProperty[];

  onSuccess: (body: any) => void;
}> = ({ open, data, handleClose, onSuccess, usedProperty }) => {
  const { t } = useTranslation();
  const [properties, setProperties] = useState<IProperty[]>(data?.property ?? []);
  const { getManagementListQuery } = useProperty();
  const mutating = useIsMutating();

  const { data: propertiesList } = getManagementListQuery();

  const handleSend = () => {
    const payload = {
      id: data?.id,
      name: data!.name!,
      welcomeMessageId: data!.welomeMessageId!,
      // farewellMessageId: data!.farewellMessageId!,
      ruleMessageIds: data?.ruleMessages?.map((i) => i.id) ?? [],
      propertyIds: properties.map((p) => p.propertyId) ?? [],
    };
    
    const formData = new FormData();
    
    // Append scalar values directly (convert to string if needed)
    if (payload.id !== undefined) formData.append("id", payload.id.toString());
    formData.append("name", payload.name);
    formData.append("welcomeMessageId", payload.welcomeMessageId.toString());
    
    // If farewellMessageId exists and is needed, do similarly:
    // formData.append("farewellMessageId", payload.farewellMessageId.toString());
    
    // Append arrays by enumerating their values
    payload.ruleMessageIds.forEach((ruleId, index) => {
      formData.append(`ruleMessageIds[${index}]`, ruleId.toString());
    });
    
    payload.propertyIds.forEach((propertyId, index) => {
      formData.append(`propertyIds[${index}]`, propertyId.toString());
    });

    const body= {id:data?.id?.toString(),dataRes:formData}
    onSuccess(body);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        sx={modalContainerStyle}
        aria-labelledby='add-message-modal-title'
        aria-describedby='add-message-modal-description'
      >
        <Box sx={modalBoxStyle}>
          <div className='flex justify-between items-center mb-3'>
            <Typography id='modal-modal-title' className='text-[#27272A]'>
              {t('MESSAGING.UPDATE_FORM')}
            </Typography>
          </div>
          <SelectProperty
            usedProperties={usedProperty}
            property={propertiesList ?? []}
            selected={properties}
            setSelected={setProperties}
          />
          <Button
            size={'large'}
            disabled={!properties?.length || mutating > 0}
            sx={{ mt: 2 }}
            variant={'contained'}
            onClick={handleSend}
            fullWidth
          >
            {t('GENERAL.SEND')}
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default MessageAssignModal;