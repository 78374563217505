import { Dispatch, FC, SetStateAction, useContext, useState } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ToastMessageContext } from '@context';
import { useProperty } from '@services';
import OTPInput from 'react-otp-input';
import { MOBILE_START_WITH_05 } from '../../utils/regex';
import { useIsMutating } from '@tanstack/react-query';

const GathernNewLink: FC<{ onSuccess: () => void; step: number; setStep: Dispatch<SetStateAction<number>> }> = ({
  onSuccess,
  setStep,
  step,
}) => {
  const { t } = useTranslation();
  const mutating = useIsMutating();
  const [phone, setPhone] = useState('');
  const [otp, setOtp] = useState('');
  const { showError } = useContext(ToastMessageContext);
  const handleChange = (event: any) => {
    setPhone(event.target.value);
  };
  const handleOtpChange = (event: any) => {
    setOtp(event.target.value);
  };
  const { gathrenLoginMutate, gathrenVerifyOtpMutate } = useProperty();

  const { mutate: gathrenVerifyOtp } = gathrenVerifyOtpMutate({
    onError: () => {
      showError(t('ERRORS.SOMETHING_WENT_WRONG'));
    },
    onSuccess: onSuccess,
  });
  const { mutate: gathrenLogin } = gathrenLoginMutate({
    onError: () => {
      showError(t('ERRORS.SOMETHING_WENT_WRONG'));
    },
    onSuccess: () => {
      setStep(3);
    },
  });
  const sendOtp = () => {
    if (new RegExp(MOBILE_START_WITH_05).test(phone)) {
      const payload: any = {
        phone: phone,
      };
      gathrenLogin(payload);
    } else {
      showError(t('ERRORS.INVALID_MOBILE'));
    }
  };

  const onOtpSubmit = () => {
    const payload: any = {
      phone: phone,
      otp: otp,
    };

    gathrenVerifyOtp(payload);
  };

  if (step == 3) {
    return (
      <Box sx={{ flex: 1, pt: 4, pb: 2, display: 'flex', flexDirection: 'column' }}>
        <Typography color={'#0F172A'} fontSize={'20px'}>
          {t('ADD_PROPERTY.OTP_STEP')}
        </Typography>
        <Typography color={'#8E98A1'} fontSize={'16px'}>
          {t('ADD_PROPERTY.OTP_STEP_DESCRIPTION')}
        </Typography>

        <Box sx={{ width: '200px', div: { direction: 'ltr' } }}>
          <OTPInput
            value={otp}
            containerStyle={'gap-4 mt-2 rtl:-ms-2'}
            inputStyle={'rounded border border-[#9CA3AF] w-10 h-12 flex text-center text-xl '}
            shouldAutoFocus={true}
            inputType={'tel'}
            skipDefaultStyles={true}
            onChange={setOtp}
            numInputs={4}
            renderInput={(props) => <input {...props} />}
          />
        </Box>
        <Box sx={{ mt: 'auto', display: 'flex', gap: 8, alignItems: 'center' }}>
          <Button
            size={'small'}
            variant={'contained'}
            onClick={onOtpSubmit}
            disabled={otp?.length != 4 || mutating > 0}
          >
            {t('ADD_PROPERTY.CONFIRM')}
          </Button>
        </Box>
      </Box>
    );
  }
  return (
    <Box sx={{ flex: 1, pt: 4, pb: 2, display: 'flex', flexDirection: 'column' }}>
      <Typography color={'#0F172A'} fontSize={'20px'}>
        {t('ADD_PROPERTY.PHONE_STEP_TITLE')}
      </Typography>
      <Typography color={'#8E98A1'} fontSize={'16px'}>
        {t('ADD_PROPERTY.PHONE_STEP_DESCRIPTION')}
      </Typography>
      <TextField
        id='phone'
        className='w-full rounded-3xl'
        InputProps={{ sx: { borderRadius: 24 } }}
        style={{ borderRadius: '24px !important', color: '#9CA3AF', borderColor: '#9CA3AF' }}
        placeholder={`0500000000`}
        value={phone}
        variant='outlined'
        onChange={handleChange}
      />
      <Box sx={{ mt: 'auto', display: 'flex', gap: 8, alignItems: 'center' }}>
        <Button size={'small'} variant={'contained'} onClick={sendOtp} disabled={phone?.length != 10 || mutating > 0}>
          {t('ADD_PROPERTY.SEND_OTP')}
        </Button>
      </Box>
    </Box>
  );
};

export default GathernNewLink;
