import React, { Dispatch, FC, SetStateAction } from 'react';
import { IMessage } from '@interfaces';
import { Box, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
const customPluralMessages: Record<number, string> = {
  1: '_two',
  2: '_three',
};
const SelectWelcomeMessage: FC<{
  messages: IMessage[];
  selected: number | undefined;
  setSelected: Dispatch<SetStateAction<number | undefined>>;
}> = ({ setSelected, messages, selected }) => {
  const { t } = useTranslation();
  return (
    <Box sx={{ pt: 3, px: 1, overflowY: 'auto', maxHeight: '100%' }}>
      <Typography mb={2} color={'primary'}>
        {t('MESSAGING.CHOOSE_WELCOME')}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          // '.MuiFormGroup-root': { gap: 2 },
          '.MuiFormControlLabel-root': {
            marginInline: 0,
          },
          flexDirection: 'column',
          gap: 1,
        }}
      >
        <RadioGroup
          aria-labelledby='demo-controlled-radio-buttons-group'
          name='controlled-radio-buttons-group'
          value={selected}
          onChange={(event, value) => setSelected(Number(value))}
        >
          {messages.map((c, index) => {
            const label = t(`MESSAGING.MESSAGE${customPluralMessages[index] ?? ''}`, { count: Number(index + 1) });

            return (
              <Box key={c.id}>
                <FormControlLabel key={c.id} value={c.id} control={<Radio />} label={label} />
                <Box
                  sx={{
                    borderRadius: 1,
                    border: '1px solid #F4F4F5',
                    marginInlineStart: '42px',
                    color: '#52525B',
                    p: 1,
                    mb: 3,
                    backgroundColor: '#FAFAFA',
                  }}
                  dangerouslySetInnerHTML={{ __html: c.content.replaceAll('\\n', '<br/>').replaceAll('\\r', '') }}
                />
              </Box>
            );
          })}
        </RadioGroup>
      </Box>
    </Box>
  );
};
export { SelectWelcomeMessage };
