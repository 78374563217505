import { FC, useContext } from 'react';
import { Box, Button, IconButton, Modal, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';

import { IModalProps } from '@interfaces';
import { DatePicker, MobileTimePicker } from '@mui/x-date-pickers';
import { useLock } from '@services';
import { ToastMessageContext } from '@context';

const GenerateLockNumberModal: FC<IModalProps> = ({ handleClose, open, data }) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 570,
    bgcolor: 'background.paper',
    color: '#261B6A',
    boxShadow: 24,
    p: 3,
    borderRadius: 5,
  };

  const {
    i18n: { language },
    t,
  } = useTranslation();
  const align = language === 'ar' ? 'right' : 'left';
  const { showSuccess, showError } = useContext(ToastMessageContext);

  const { control, handleSubmit, reset } = useForm<any>({
    mode: 'onChange',
  });

  const closeModal = () => {
    handleClose();
    reset();
  };

  const { generatePassCodeForLockMutate } = useLock();

  const { mutate: generatePassCodeForLock } = generatePassCodeForLockMutate({
    onSuccess: () => {
      showSuccess(t('NEW_LOCKS.GENERATE_PASS_CODE_SUCCESS'));
      closeModal();
    },
    onError: () => {
      showError(t('ERRORS.SOMETHING_WENT_WRONG'));
    },
  });

  const onSubmit = (body: any) => {
    const payload: any = {
      ...data,
      endDate: body?.endDate?.format('YYYY-MM-DD'),
      startDate: body?.startDate?.format('YYYY-MM-DD'),
      startTime: body?.startTime?.locale('en')?.format('hh:mm A'),
      endTime: body?.endTime?.locale('en')?.format('hh:mm A'),
    };
    generatePassCodeForLock(payload);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={closeModal}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <div className='flex justify-between items-center mb-3'>
            <Typography id='modal-modal-title' className='text-[#27272A]'>
              {t('LOCKS.GENERATE_LOCK_NUMBER')}
            </Typography>

            <IconButton aria-label='close' onClick={closeModal} size='small'>
              <CloseIcon className='text-[#27272A] ' />
            </IconButton>
          </div>
          <form className='mt-10' onSubmit={handleSubmit(onSubmit)}>
            <div className=' text-[#261B6A]'>{t('LOCKS.START_ACTIVATING_LOCK')}</div>
            <div className='flex items-center justify-between pt-8'>
              <div>
                <Controller
                  name='startDate'
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <>
                      <div className='text-[#261B6A] font-normal'>
                        <div>{t('DATE')}</div>
                        <div className='pt-4'>
                          <DatePicker value={value} onChange={onChange} format='ddd, MMM D' />
                        </div>
                      </div>
                    </>
                  )}
                />
              </div>
              <div>
                <Controller
                  name='startTime'
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <>
                      <div className='text-[#261B6A] font-normal'>
                        <div>{t('TIME')}</div>
                        <div className='pt-4'>
                          <MobileTimePicker value={value} onChange={onChange} format='LT' ampm />
                        </div>
                      </div>
                    </>
                  )}
                />
              </div>
            </div>
            <div className=' text-[#261B6A] pt-7'>{t('LOCKS.END_OF_LOCK_ACTIVATION')}</div>
            <div className='flex items-center justify-between pt-8'>
              <div>
                <Controller
                  name='endDate'
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <>
                      <div className='text-[#261B6A] font-normal'>
                        <div>{t('DATE')}</div>
                        <div className='pt-4'>
                          <DatePicker value={value} onChange={onChange} format='ddd, MMM D' />
                        </div>
                      </div>
                    </>
                  )}
                />
              </div>
              <div>
                <Controller
                  name='endTime'
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <>
                      <div className='text-[#261B6A] font-normal'>
                        <div>{t('TIME')}</div>
                        <div className='pt-4'>
                          <MobileTimePicker value={value} onChange={onChange} format='LT' ampm />
                        </div>
                      </div>
                    </>
                  )}
                />
              </div>
            </div>

            <div className='mt-10 mx-4'>
              <Button className='w-full' variant={'contained'} size={'large'} type='submit'>
                {t('LOCKS.GENERATE_LOCK_NUMBER')}
              </Button>
            </div>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default GenerateLockNumberModal;
