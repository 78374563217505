import { Box, Chip, IconButton, MenuItem, Select, TableCell, TableRow, TextField } from '@mui/material';
import { Cancel, Check } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { FieldErrors, useForm } from 'react-hook-form';
import { ICategory, IExpense, IIncome } from '@interfaces';
import { zodResolver } from '@hookform/resolvers/zod';
import { FC, KeyboardEvent, useMemo, useRef, useState } from 'react';
import { z } from 'zod';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { useOnClickOutside } from 'usehooks-ts';

const schema = z.object({
  transactionName: z.string().min(1),
  amount: z.number(),
  transactionCreateDate: z.any(),
  categoryId: z.number().min(1),
});
const UpdateExpenseRow: FC<{
  data?: IExpense;
  onSubmit: (body: IExpense) => void;
  categories?: ICategory[];
  onCancel: () => void;
}> = ({ data, onCancel, categories, onSubmit }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const {
    handleSubmit,
    register,
    setValue,
    watch,
    formState: { errors },
  } = useForm<IExpense>({
    defaultValues: {
      ...data,
      categoryId: data?.categoryId ?? data?.category?.id,
    },
    resolver: zodResolver(schema),
    mode: 'all',
  });
  const [dirtyFields, setDirtyFields] = useState(false);
  const ref = useRef(null);

  const align = language === 'ar' ? 'right' : 'left';

  const updating = useMemo(() => Boolean(watch('transactionId')), [watch()]);

  const onErrorForm = (e?: FieldErrors<IIncome>) => {
    // if (!updating) {
    //   showWarning(t('GENERAL.NOT_COMPLETE_DATA'));
    // }
  };
  const beforeSubmit = () => {
    if (dirtyFields) {
      handleSubmit(onSubmit, (errors) => onErrorForm(errors))();
    } else {
      onCancel();
    }
  };

  useOnClickOutside(ref, (e) => {
    const target = e.target as any;
    const parentElement = target?.parentElement;
    const parentId: string | undefined = parentElement?.id;
    const id: string | undefined = parentId?.includes('select-dropdown') ? parentId : target?.id;
    // workaround to avoid clicking outside date and dropdown
    const isDropDown = id?.includes('select-dropdown');
    const targetIsDate =
      target?.classList?.value?.includes('MuiDayCalendar') || target?.classList?.value?.includes('MuiPickers');
    const parentIsDate =
      parentElement?.classList?.value?.includes('MuiDayCalendar') ||
      parentElement?.classList?.value?.includes('MuiPickers');
    const isDate = targetIsDate || parentIsDate;
    if (!isDropDown && !isDate) {
      beforeSubmit();
    }
  });
  return (
    <>
      <TableRow sx={{ display: 'none' }}>
        <TableCell colSpan={5}>
          <form>
            <input {...register('transactionId')} />
            <input {...register('transactionName')} />
            <input {...register('categoryId')} />
            <input {...register('amount')} />
            <input {...register('transactionCreateDate')} />
          </form>
        </TableCell>
      </TableRow>
      <TableRow ref={ref}>
        <TableCell align={align}></TableCell>
        <TableCell align={align}>
          <TextField
            size={'small'}
            value={watch('transactionName')}
            onChange={(e) => {
              setDirtyFields(true);
              setValue('transactionName', e.target.value);
            }}
            error={Boolean(errors.transactionName?.message)}
            onKeyDown={({ key }: KeyboardEvent) => {
              if (key === 'Enter') {
                beforeSubmit();
              }
            }}
          />
        </TableCell>
        <TableCell align={align}>
          <Select
            size={'small'}
            id={'select-dropdown-category'}
            value={watch('categoryId')}
            defaultValue={0}
            error={Boolean(errors.categoryId?.message)}
            onChange={(e) => {
              setDirtyFields(true);
              setValue('categoryId', Number(e.target.value));
            }}
            sx={{
              boxShadow: 'none',
              '.MuiOutlinedInput-notchedOutline': {
                border: errors.categoryId?.message ? 1 : 0,
              },
              minWidth: 100,
            }}
          >
            <MenuItem id={'select-dropdown-category-choose'} value={0}>
              {t('FINANCE.EXPENSE.CHOOSE_CLASSIFICATION')}
            </MenuItem>
            {categories?.map((i) => {
              return (
                <MenuItem id={`select-dropdown-category-${i.id}`} value={i.id} key={i.id}>
                  <Chip
                    id={`select-dropdown-category-${i.id}-chip`}
                    color={'primary'}
                    sx={{ backgroundColor: '#1D4ED8' }}
                    label={i.name}
                  />
                </MenuItem>
              );
            })}
          </Select>
        </TableCell>
        <TableCell align={align}>
          <TextField
            size={'small'}
            value={watch('amount')}
            onChange={(e) => {
              const val: number = Number(e?.target?.value);
              if (!isNaN(val)) {
                setDirtyFields(true);
                setValue('amount', val);
              }
            }}
            error={Boolean(errors.amount?.message)}
            onKeyDown={({ key }: KeyboardEvent) => {
              if (key === 'Enter') {
                beforeSubmit();
              }
            }}
          />
        </TableCell>
        <TableCell align={align}>
          <Box sx={{ display: 'flex', gap: 2, alignItems: align }}>
            <DatePicker
              slotProps={{
                textField: {
                  size: 'small',
                  dir: language == 'ar' ? 'rtl' : 'ltr',
                },
              }}
              value={dayjs(watch('transactionCreateDate'))}
              format={'YYYY-MM-DD'}
              onChange={(value) => {
                setDirtyFields(true);
                setValue('transactionCreateDate', value);
              }}
            />
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
};
export default UpdateExpenseRow;
