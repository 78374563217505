import styled from "styled-components"

export const CustomViewPropertiesWrapper = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 8px;
	.containerImage {
		width: 33px;
		height: 33px;
		background-color: #94a3b8;
		border-radius: 8px;
		overflow: hidden;
		img {
			object-fit: cover;
			width: 100%;
			height: 100%;
		}
		svg {
			width: 100%;
			height: 100%;
		}
	}
	.containerData {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		flex-direction: column;
		p {
			margin: 0;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			&:first-child {
				color: #435363;
				font-size: 14px;
				font-weight: 500;
			}
			&:last-child {
				font-size: 12px;
				font-weight: 500;
				color: #94a3b8;
			}
		}
	}
`
