import { DayHeaderContentArg } from "@fullcalendar/core"
import { DayHeaderContentWrapper } from "./styles"
interface IProps {
	info: DayHeaderContentArg
}
export default function DayHeaderContent({ info }: IProps) {
	return (
		<DayHeaderContentWrapper isWeekEnd={info.dow === 6 || info.dow === 5}>
			{info.text}
		</DayHeaderContentWrapper>
	)
}
