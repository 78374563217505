import { useApi } from "./useApi"
import {WelcomeBookApi} from "../utils/constants"
import { useQuery } from "@tanstack/react-query"


const useWelcomeBook = () => {
	const { apiPrivate } = useApi()
	/* ------------------------------ getOwners ----------------------------- */
	const getWelcomeBook = async (reservationId:string) => {
		const response: { data: any } = await apiPrivate.get(
			WelcomeBookApi + `?reservationId=${reservationId}`)
		return response
	}

	const GetWelcomeBookQuery = (reservationId:string) =>
		useQuery({
			queryKey: [WelcomeBookApi, reservationId],
			queryFn: () => getWelcomeBook(reservationId),
		})
	

	return {
		GetWelcomeBookQuery,
		
	}
}
export { useWelcomeBook }
