import { SyntheticEvent } from "react"
import { Controller, RegisterOptions, useFormContext } from "react-hook-form"
import { ErrorMessage } from "@hookform/error-message"
import { UploadImageWrapper } from "./styles"
import { MdInsertDriveFile } from "react-icons/md"
import { HiDownload } from "react-icons/hi"
import { LuUploadCloud } from "react-icons/lu"
import { IoTrashOutline } from "react-icons/io5"

interface IProps {
	name: string
	registerOptions?: RegisterOptions
	Buttonlabel?: string | JSX.Element
	accept?: string
	maxFilesUpload: number
	deleteImageAPI?: (docsId: string) => Promise<void>
	withErrorMessage?: boolean
	enableUploadFile?: boolean
	withActionDelete?: boolean
	withActionsView?: boolean
	styleViewList?: "list" | "card"
	withLabel?: string
}
export default function CustomUploadFiles({
	name,
	registerOptions,
	Buttonlabel,
	accept,
	maxFilesUpload,
	deleteImageAPI,
	withErrorMessage = false,
	enableUploadFile = true,
	withActionDelete = true,
	withActionsView = true,
	styleViewList = "list",
	withLabel,
}: IProps) {
	// const { formatMessage } = useIntl()
	/* ---------------------------------- Hooks --------------------------------- */
	const {
		getValues,
		setValue,
		watch,
		control,
		formState: { errors },
	} = useFormContext()
	const dataList: File[] | any[] = watch(name)
	/* -------------------------------- Functions ------------------------------- */
	const handleChange = (e: any) => {
		const filelist = Array.from(e.currentTarget.files)
		if (!filelist) return
		const prev = getValues(name) ?? []
		const files = [...prev, ...filelist]
		if (files.length > maxFilesUpload) {
			const spliceFiles = files.splice(0, maxFilesUpload)
			// toast.error(
			// 	// formatMessage(
			// 		{ id: "the_maximum_number_of_files_is_{maxfileUploaded}" },
			// 		{ maxfileUploaded: maxFilesUpload }
			// 	),
			// 	{
			// 		toastId: "warningId",
			// 	}
			// )
			setValue(name, spliceFiles)
		} else {
			setValue(name, files)
		}
	}

	const DeleteFormLocalState = (file: File) => {
		const filterFile = dataList.filter((item) => item !== file)
		setValue(name, filterFile)
	}

	return (
		<UploadImageWrapper
			$disabled={dataList?.length === maxFilesUpload}
			$isTheViewStyleCard={styleViewList === "card"}
		>
			<div className='containerAllElements'>
				<div className='containerButtonAndLabel'>
					{styleViewList === "card" && withLabel && (
						<label className='labelSection'>{withLabel}</label>
					)}

					{enableUploadFile && (
						<div className='ContainerWrapper'>
							<Controller
								name={name}
								control={control}
								rules={registerOptions}
								render={() => (
									<label>
										<input
											type='file'
											onChange={(e: SyntheticEvent<any>) => {
												handleChange(e)
											}}
											style={{ display: "none" }}
											accept={accept}
											multiple
											disabled={dataList?.length === maxFilesUpload}
										/>
										<div className='labelSection'>
											<div>
												<div>
													<LuUploadCloud />
												</div>
											</div>
											<div>
												<p>{Buttonlabel}</p>
											</div>
										</div>
									</label>
								)}
							/>
						</div>
					)}
				</div>

				<div
					className={styleViewList === "list" ? "containerFiles" : "cardView"}
				>
					{dataList &&
						dataList.map((item, index) => {
							return (
								<div className='fileItem' key={item.id ?? index}>
									<div className='iconFile'>
										<div>
											{styleViewList === "list" ? (
												<MdInsertDriveFile />
											) : (
												"FileIcon"
											)}
										</div>
									</div>
									<p>
										{item.name
											? item.name
											: item.url
												? item.url.split("/")[item.url.split("/").length - 1]
												: ""}
									</p>
									<div className='containerControlbuttons'>
										{withActionDelete && (
											<div
												onClick={
													item.name
														? () => DeleteFormLocalState(item)
														: deleteImageAPI
															? () => deleteImageAPI(item.id.toString())
															: undefined
												}
											>
												<IoTrashOutline />
											</div>
										)}
										{item.url && withActionsView && (
											<div
												onClick={() =>
													window.open(item.url, "_blank", "noopener,noreferrer")
												}
											>
												<HiDownload />
											</div>
										)}
									</div>
								</div>
							)
						})}
				</div>
			</div>
			{withErrorMessage && (
				<div className='ErrorMessageStyle'>
					<ErrorMessage
						errors={errors}
						name={name}
						render={({ message }) => <p>{message}</p>}
					/>
				</div>
			)}
		</UploadImageWrapper>
	)
}
