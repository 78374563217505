const compactNumber = (number: number, language: string): string => {
  return formatArToEn(
    Intl.NumberFormat(language, {
      notation: 'compact',
      maximumFractionDigits: 1,
    }).format(number),
  );
};
const sarFormat = (number: number, language?: string): string => {
  return formatArToEn(
    Intl.NumberFormat(language ?? 'en', {
      style: 'currency',
      currency: 'SAR',
      maximumFractionDigits: 2,
    }).format(number),
  );
};
const formatArToEn = (s: string) => {
  return String(s).replace(/[٠-٩]/g, (d) => String('٠١٢٣٤٥٦٧٨٩'.indexOf(d)));
};
const formatEnToAr = (s: string) => {
  return String(s).replace(/[0-9]/g, (d) => String('٠١٢٣٤٥٦٧٨٩'.charAt(Number(d))));
};
const isNumber = (value?: number) => {
  return typeof value === 'number';
};

export { compactNumber, sarFormat, formatArToEn, formatEnToAr, isNumber };
