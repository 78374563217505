import { useTranslation } from 'react-i18next';
import { modalBoxStyle, modalContainerStyle } from '@utils';
import { Box, Button, Modal, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import InActiveIcon from '@assets/svg/inactive-modal-icon.svg?react';
import { InActiveBg } from '../../utils/image-consts';

const InActiveUserModal = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Modal
      open={true}
      sx={{ ...modalContainerStyle }}
      aria-labelledby='add-message-modal-title'
      aria-describedby='add-message-modal-description'
    >
      <Box sx={{ ...modalBoxStyle, p: 0, width: '800px', maxWidth: '80vw', height: '500px', maxHeight: '80vh' }}>
        <Box
          sx={{
            bgcolor: (theme) => theme.palette.primary.main,
            width: '100%',
            flex: 1,
            p: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundImage: `url(${InActiveBg})`,
            backgroundSize: 'cover',
          }}
        >
          <Box
            sx={{
              flex: 1,
              aspectRatio: 1,
              svg: {
                width: '100%',
                height: '100%',
              },
            }}
          >
            <InActiveIcon />
          </Box>
        </Box>
        <Box
          sx={{
            p: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1,
            gap: 2,
          }}
        >
          <Typography textAlign={'center'} fontWeight={'bold'} fontSize={'32px'}>
            <Typography
              sx={{
                display: 'inline-block',
                textWrap: 'nowrap',
              }}
              fontWeight={'bold'}
              color={'primary'}
              fontSize={'32px'}
            >
              {t('INACTIVE_MODAL.HELLO')}{' '}
            </Typography>{' '}
            {t('INACTIVE_MODAL.YOURE_NOT_ACTIVE')}
          </Typography>
          <Typography fontSize={'24px'}>{t('INACTIVE_MODAL.SUBSCRIBE_FOR_FEATURES')}</Typography>
          <Button size={'large'} variant={'contained'} onClick={() => navigate('/properties-management')}>
            {t('SUBSCRIBE_NOW')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
export default InActiveUserModal;
