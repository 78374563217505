import styled from "styled-components"

export const SectionUserDetailsWrapper = styled.div`
	border: 1px solid #0000001a;
	border-radius: 16px;
	display: grid;
	grid-template-rows: auto 1fr;
	grid-template-columns: 1fr;
	width: 100%;
	height: 100%;
	margin-inline: 4px;
	overflow: hidden;
	.containerHeader {
		padding: 16px;
		border-bottom: 1px solid #0000001a;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		p {
			margin: 0;
			height: 35px;
			color: #435363;
			font-size: 20px;
			font-weight: 500;
			line-height: 24px;
			display: flex;
			justify-content: flex-start;
			align-items: center;
		}
	}
	.containerInfoData {
		overflow-x: scroll;
	}
	.containerStatus {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		.accept,
		.reject,
		.pendding,
		.known {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			margin: 0;
			width: auto;
			height: 25px;
			border-radius: 30px;
			padding-inline: 10px;
			gap: 5px;
			span {
				width: 5px;
				height: 5px;
				border-radius: 50px;
			}
		}
		.accept {
			background-color: #f3fbf4;
			color: #16ae26;
			span {
				background-color: #16ae26;
			}
		}
		.reject {
			background-color: #ffe0e3;
			color: #d70015;
			span {
				background-color: #d70015;
			}
		}
		.pendding {
			background-color: #fff2d2;
			color: #fdbf21;
			span {
				background-color: #fdbf21;
			}
		}
		.known {
			background-color: #e9e9e9;
			color: #435363;
			span {
				background-color: #435363;
			}
		}
	}
`
