import { Box, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import BookingOnboardingFirst from '@assets/svg/BookingOnboardingFirst.svg?react';
import BookingOnboardingSecond from '@assets/svg/BookingOnboardingSecond.svg?react';
import BookingOnboardingThird from '@assets/svg/BookingOnboardingThird.svg?react';
import BookinOnboardingFourth from '@assets/svg/BookingOnboardinFourth.svg?react';
import { useState } from 'react';
const imageStyle = { width: '468px', height: '284.13px' }



const BookingOnboarding = ({ onNext = () => { } }) => {
    const { t,i18n:{language} } = useTranslation(); 
    const isRtl = language  === 'ar'

    const [step, setStep] = useState(0)
    const SliderTitle = [
       t('BOOKING.ONBOARDING_TITLE'),
       t('BOOKING.ONBOARDING_SUB_TITLE'),
    ]
    
    const SLider = [
        {
            image: <BookingOnboardingFirst style={imageStyle} />,
            footer: [
                isRtl?  'الخطوة الاولى':'The first step',
                isRtl?   'الذهاب الى مزود الاتصال ':'Go to your service provider'
            ]
        },
        { 
            image: <BookingOnboardingSecond style={imageStyle} />,
    
            footer: [
                isRtl?   'الخطوة الثانية':'The second step',
                isRtl? 'شاشة اختيار المزود':'Provider Selection Screen'
            ]
        },
        {
            image: <BookingOnboardingThird style={imageStyle} />,
    
            footer: [
                isRtl?    'الخطوة الثالثة':'The third step',
                isRtl?   'الموافقة على اتفاقية خدمة XML':'Accept XML Service Agreement'
            ]
        },
        {
            image: <BookinOnboardingFourth style={imageStyle} />,
    
            footer: [
                isRtl?      'الخطوة الرابعة ':'The fourth step ',
                isRtl?  'استيراد العقار عن طريق هوية الفندق في منصة مسؤول  ':'Importing the property via the hotel ID in the Masool platform'
            ]
        },
    ]

    return (
        <Box>
            <Typography sx={{
                color: 'rgba(15, 23, 42, 1)', 
                fontWeight: 400,
                fontSize: '20px',
                lineHeight: '24px',
                mt:4
            }} >
                {SliderTitle[0]}
            </Typography>
            <Typography sx={{
                color: 'rgba(142, 152, 161, 1)',
                fontWeight: 400,
                fontSize: '16px',
                lineHeight: '19px'
            }} >
                {SliderTitle[1]}
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center',mt:3 }} >

                {SLider[step].image}
            </Box>
            <Typography sx={{
                color: 'rgba(15, 23, 42, 1)',
                fontWeight: 400,
                fontSize: '18px',
                lineHeight: '21px'
            }} >
                {SLider[step].footer[0]}
            </Typography>
            <Typography sx={{
                color: 'rgba(142, 152, 161, 1)',
                fontWeight: 400,
                fontSize: '16px',
                lineHeight: '19px'
            }} >
                {SLider[step].footer[1]}
            </Typography>
            <Box sx={{display:'flex',alignItems:'center',justifyContent:'center',mt:3}} >
                {
                    Array.from({length:4}).map((x:any,i:number)=>{
                        return <Box sx={{
                            height: step== i ? '12px':'8px'
                            ,width: step== i ? '12px':'8px',
                            borderRadius: step== i ? '6px':'4px',
                            backgroundColor:step==i?'rgba(91, 63, 255, 1)':'rgba(217, 217, 217, 1)',mx:0.2}} />
                    })
                }
            </Box>
            <Box sx={{ display: 'flex', gap: 8, alignItems: 'center', my: 2 }}>
                <Button size={'small'} variant={'contained'} onClick={() => {
                    if (step == 3) {

                        return onNext()
                    }
                    setStep(step + 1)
                }} disabled={false}>
                  {t('BOOKING.NEXT')}
                </Button>
            </Box>
        </Box>
    )
}

export default BookingOnboarding