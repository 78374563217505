import useBreakPoinst from '@hooks/useBreakPoinst';
import { Box, Paper, SxProps, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FC, ReactNode } from 'react';

const WhiteContainer: FC<{
  children: ReactNode;
  sx?: SxProps;
  title?: string;
  description?: string;
  suffix?: ReactNode;
  extraCards?: ReactNode;
  secondary?: boolean;
  calandarView?:boolean;
}> = ({ children, sx, title, suffix, description, extraCards, secondary = false, calandarView=false}) => {
  const theme = useTheme();
  const { isPhone } = useBreakPoinst();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <>
      {title && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: isPhone ? 'column' : 'row',
            justifyContent: 'space-between',
            alignItems: isPhone ? 'flex-start' : 'center',
          }}
          mb={2}
        >
          <Box
            sx={{ display: matches ? 'flex' : null, alignItems: 'center', flexDirection: !matches ? 'column' : 'row' }}
          >
            <Box>
              <Typography color={'#261B6A'} fontWeight={300} fontSize={'28px'}>
                {title}
              </Typography>
              {Boolean(description?.length) && (
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '19px',
                    color: 'rgba(173, 186, 199, 1)',
                  }}
                >
                  {description}
                </Typography>
              )}
            </Box>
          </Box>
          {suffix}
        </Box>
      )}
      {extraCards}
      <Paper
        elevation={ calandarView ? 0 : secondary ? 0 : 2}
        sx={{
          backgroundColor: calandarView ? 'rgba(246, 246, 248, 1)': secondary ? 'rgba(246, 246, 248, 1)' : theme.palette.primary.light,
          width: {
            xs: 'calc(100vw - 48px)',
            sm: 'calc(100vw - 288px)', 
          },
          overflowX: 'auto',
          flex: 1,
          p:calandarView?0 : { xs: 2, md: 4 },
          ...sx,
        }}
      >
        <Box sx={{backgroundColor:calandarView?'white':undefined,pt:calandarView?3:undefined,px:calandarView?3:undefined}} >
        {children}

        </Box>
      </Paper>
    </>
  );
};
export default WhiteContainer;
