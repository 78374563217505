import { Grid } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { FC, ReactNode, Suspense, useState } from 'react';
import { MenuLinkI } from '@interfaces';
import { PublicFooter, NavMenu } from '@components';

const PublicLayout: FC<{ children: ReactNode }> = ({ children }) => {
  const [mobileOpen, setMobileOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  const { pathname } = useLocation();

  const handleClick: (item: MenuLinkI) => void = ({ link, onClickKey, type }) => {
    if (type === 'route' && pathname != link) {
      navigate(link);
    } else {
      if (pathname == '/') {
        const el = document.getElementById(link);
        if (el) {
          el.scrollIntoView({ behavior: 'smooth' });
        }
      } else {
        navigate('/');
        setTimeout(() => {
          const el = document.getElementById(link);
          if (el) {
            el.scrollIntoView({ behavior: 'smooth' });
          }
        }, 200);
      }
    }
  };

  return (
    <Suspense fallback={<></>}>
      <Grid container direction={'column'} minHeight={'100vh'}>
        <NavMenu handleClick={handleClick} mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} />
        {children}
        <PublicFooter handleClick={handleClick} />
      </Grid>
    </Suspense>
  );
};
export default PublicLayout;
