import { MenuLinkI } from '@interfaces';
import { ReactNode } from 'react';
import XIcon from '@assets/svg/x.svg?react';
import InstaIcon from '@assets/svg/instagram.svg?react';
import LinkedInIcon from '@assets/svg/linkedIn.svg?react';
import { disabledFeatures } from './features';

export const MenuItems: MenuLinkI[] = disabledFeatures.sideMenu
  ? []
  : [
      {
        link: '/',
        name: 'MENU.ABOUT',
        title: 'MENU.ABOUT',
      },
      {
        link: 'features',
        name: 'MENU.FEATURES',
        title: 'MENU.FEATURES',
      },
      {
        link: 'blog',
        onClickKey: 'blog',
        name: 'MENU.BLOG',
        title: 'MENU.BLOG',
      },
    ];
export const socialMediaItems: { link: string; icon: ReactNode }[] = [
  {
    link: 'https://x.com/usemsool',
    icon: <XIcon />,
  },
  {
    link: 'https://www.linkedin.com/company/msool',
    icon: <LinkedInIcon />,
  },
];
