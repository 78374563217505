/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from 'react';
import { Box, Button, Modal } from '@mui/material';
import { useTranslation } from 'react-i18next';
import OnboardingRocket from '@assets/svg/OnboardingRocket.svg?react';
import useBreakPoinst from '@hooks/useBreakPoinst';


type IOnboardingModalProps = {
    handleClose:()=>void;
    onStartJourney: () => void;
    open:boolean;
}

const UpdateNameModal = ({ handleClose, open, onStartJourney }:IOnboardingModalProps) => {
    const { isPhone } = useBreakPoinst()
    const style = {
        position: 'absolute',
        top: '50%',
        left: isPhone ? '4%' : '50%',
        transform: isPhone ? 'translate(0, -70%)' : 'translate(-50%, -50%)',
        width: isPhone ? '90%' : 430,
        bgcolor: 'background.paper',
        color: '#261B6A',
        boxShadow: 24,
        p: 3,
        borderRadius: 5,
    };

    const {
        t,
    } = useTranslation();

    const closeModal = () => {
        handleClose();
    };
    return (
        <>
            <Modal
                open={open}
                onClose={closeModal}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
            >
                <Box sx={style}>
                    <Box textAlign={"center"} >
                        <OnboardingRocket style={{ width: '194px', height: '200.06px' }} />
                        <Box sx={{ color: '#435363', fontWeight: 700, fontSize: '16px' }} >
                            {t('PROPERTIES_MANAGEMENT.AUTO_ACCESS')}
                        </Box>
                        <Box mt={2} sx={{ color: '#435363', fontWeight: 400, fontSize: '16px', textAlign: 'center' }} >
                            {t('PROPERTIES_MANAGEMENT.MODAL_TITLE')}
                        </Box>
                        <Box sx={{ display: 'flex' }} className='mt-6 mx-4'>
                            <Button className='w-full' variant={'contained'} size={'large'} onClick={onStartJourney}>
                                {t('PROPERTIES_MANAGEMENT.NEXT')}
                            </Button>

                            <Box sx={{ marginX: 3 }} />
                            <Button className='w-full' variant={'outlined'} size={'large'} onClick={handleClose}>
                                {t('PROPERTIES_MANAGEMENT.PASS')}
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </>
    );
};

export default UpdateNameModal;
