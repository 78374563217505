import { PriceDataWrapper } from "./styles"
import { Grid } from "@mui/material"
import { CustomDivider, HeaderSection } from "../reservationInfo/styles"
import { useTranslation } from "react-i18next"
import { FORM_REGEX_VALIDATORS } from "@utils"
import Input from "@Common/input"
import MoneysIcon from "@assets/svg/MoneysIcon.svg?react"
import { useEffect } from "react"
import { useFormContext } from "react-hook-form"
import dayjs from "dayjs"
interface IProps {
	valueOfPriceData: {
		totalPrice: number
		numberOfNights: number | undefined
		checkIn: string | undefined
		checkOut: string | undefined
	}
}

interface IForm {
	nightPrice: number
	totalPrice: number
}
export default function PriceData({ valueOfPriceData }: IProps) {
	const { t } = useTranslation()
	const { setValue, watch } = useFormContext<IForm>()
	const watchNightPrice = watch("nightPrice")

	useEffect(() => {
		if (valueOfPriceData) {
			setValue("totalPrice", valueOfPriceData.totalPrice)
		}
	}, [valueOfPriceData, setValue])

	useEffect(() => {
		const totalDays = dayjs(valueOfPriceData?.checkOut)?.diff(
			dayjs(valueOfPriceData?.checkIn).format("YYYY-MM-DD"),
			"days"
		)
		const thePrice =
			Number(watchNightPrice) * totalDays > 0
				? Number(watchNightPrice) * totalDays
				: 0
		setValue("totalPrice", Number(thePrice))
	}, [valueOfPriceData, dayjs, setValue, watchNightPrice])
	return (
		<PriceDataWrapper>
			<div>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<HeaderSection>
							<MoneysIcon />
							<p>{t("COMMON.BOOKING_PRICE_DATA")}</p>
						</HeaderSection>
					</Grid>
					<Grid item xs={12} md={6}>
						<Input
							name='nightPrice'
							label={t("MANUALRESERVATION.NIGHTPRICE")}
							placeholder={t("MANUALRESERVATION.NIGHTPRICE")}
							registerOptions={{
								required: {
									value: true,
									message: t("MANUALRESERVATION.PLEASEENTERTHENIGHTRATE"),
								},
								pattern: {
									value: FORM_REGEX_VALIDATORS.digitsDecimal,
									message: t(
										"MANUALRESERVATION.ONLYNUMBERSAREALLOWEDINTHISFIELD"
									),
								},
							}}
						/>
					</Grid>
					<Grid item xs={12}></Grid>
					<Grid item xs={12} md={6}>
						<Input
							name='totalPrice'
							label={t("MANUALRESERVATION.TOTALPRICEOFRESERVATION")}
							placeholder={t("MANUALRESERVATION.TOTALPRICEOFRESERVATION")}
							readonly
							registerOptions={{
								required: {
									value: true,
									message: t("MANUALRESERVATION.PLEASEENTERTHETOTALPRICE"),
								},
								pattern: {
									value: FORM_REGEX_VALIDATORS.digitsDecimal,
									message: t(
										"MANUALRESERVATION.ONLYNUMBERSAREALLOWEDINTHISFIELD"
									),
								},
							}}
						/>
					</Grid>
					<CustomDivider />
				</Grid>
			</div>
		</PriceDataWrapper>
	)
}
