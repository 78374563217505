import React, { FC } from 'react';
import { Box, Button, Modal, SxProps, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ITemplate } from '@interfaces';
import { modalBoxStyle, modalContainerStyle } from '@utils';

const DeleteTemplateConfirm: FC<{
  open: boolean;
  handleClose: () => void;
  onSuccess: () => void;
  data: ITemplate;
}> = ({ handleClose, onSuccess, open, data }) => {
  const { t } = useTranslation();
  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={modalContainerStyle}
      aria-labelledby='add-message-modal-title'
      aria-describedby='add-message-modal-description'
    >
      <Box sx={modalBoxStyle}>
        <div className='flex justify-between items-center mb-3'>
          <Typography id='modal-modal-title' className='text-[#27272A]'>
            {t('MESSAGING.DELETE')}
          </Typography>
        </div>
        <Typography>
          {t('MESSAGING.ARE_YOU_SURE_DELETE')}{' '}
          <Typography sx={{ display: 'inline' }} color={'primary'}>
            {data.name}
          </Typography>
        </Typography>

        <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
          <Button variant={'contained'} fullWidth size={'large'} color={'primary'} onClick={handleClose}>
            {t('GENERAL.CANCEL_ALT')}
          </Button>
          <Button variant={'contained'} fullWidth size={'large'} color={'error'} onClick={onSuccess}>
            {t('MESSAGING.DELETE')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
export default DeleteTemplateConfirm;
