import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import LinkIcon from '@assets/svg/link.svg?react';
import LinkingIcon from '@assets/svg/linking.svg?react';
import MsoolIcon from '@assets/svg/msool-icon.svg?react';
const LinkingComponent: FC<{ channelIcon: any }> = ({ channelIcon }) => {
  const { t } = useTranslation();
  return (
    <Box sx={{ flex: 1, pt: 4, pb: 2, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
      <Typography color={'#0F172A'} fontSize={'20px'}>
        {t('ADD_PROPERTY.LINK_FINAL_STEP')}
      </Typography>
      <Typography color={'#8E98A1'} fontSize={'16px'} textAlign={'center'}>
        {t('ADD_PROPERTY.LINK_FINAL_STEP_DESCRIPTION')}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          mt: 3,
          svg: {
            width: '50px',
            height: '50px',
          },
        }}
      >
        <MsoolIcon />
        <LinkIcon />
        {channelIcon}
      </Box>
      <Box sx={{ mt: 6 }}>
        <Box
          sx={{
            textAlign: 'center',
            svg: {
              width: '60px',
              height: '60px',
            },
          }}
        >
          <LinkingIcon />
        </Box>
        <Typography>
          {t('ADD_PROPERTY.ALL_ONE_CLICK')}{' '}
          <Typography display={'inline'} color={'primary'}>
            {t('ADD_PROPERTY.MSOOL')}
          </Typography>
        </Typography>
      </Box>
    </Box>
  );
};
export default LinkingComponent;
