import styled from "styled-components"

export const CustomHeaderWrapper = styled.div`
	border: 1px solid #e8e8e8;
	background-color: #fff;
	padding: 20px 30px;
	border-bottom: none;
	.containerLabel {
		p {
			margin: 0;
			&:first-child {
				color: #130080;
				font-size: 20px;
				font-weight: 700;
				line-height: 24px;
				margin-bottom: 5px;
			}
			&:last-child {
				color: #adbac7;
				font-size: 16px;
				font-weight: 400;
				line-height: 19.2px;
			}
		}
	}

	.switchActions {
		display: flex;
		justify-content: flex-end;
		gap: 10px;
		flex-wrap: wrap;
		.switchBtweenWeeks {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			gap: 10px;
			p {
				margin: 0;
				color: #8e98a1;
				font-size: 14px;
				font-weight: 400;
				line-height: 16.8px;
				text-align: center;
			}
		}
	}

	.containerCustomSelectOption {
		> div {
			border-radius: 12px !important;
			> div {
				color: #261b6a;
				font-size: 14px;
				font-weight: 500;
				padding-block: 12px !important;
			}
			.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
				border: 1px solid #eaeef4;
				border-radius: 12px !important;
			}
		}
		form {
			> div {
				border-radius: 12px !important;
				> div {
					color: #261b6a;
					font-size: 14px;
					font-weight: 500;
					padding-block: 11px !important;
				}
				.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
					border: 1px solid #eaeef4;
					border-radius: 12px !important;
				}
			}
		}
	}
`

export const ContainerOptionInSelectOptions = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 8px;
`
export const ContainerChannals = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: row;
`

export const Channals = styled.div`
	width: 25px;
	height: 25px;
	&:not(:first-child) {
		margin-right: -10px;
	}
	svg {
		width: 25px;
		height: 25px;
	}
`
