import { Chip, IconButton, MenuItem, Select, TableCell, TableRow, TextField, Typography } from '@mui/material';
import { Cancel, Check } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { FieldErrors, useForm } from 'react-hook-form';
import { IChannel, IIncome } from '@interfaces';
import { zodResolver } from '@hookform/resolvers/zod';
import { FC, KeyboardEvent, useContext, useMemo, useRef, useState } from 'react';
import { z } from 'zod';
import { sarFormat } from '@utils';
import { Toggle } from '@components';
import { useOnClickOutside } from 'usehooks-ts';
import { ToastMessageContext } from '@context';

const schema = z.object({
  numberOfNights: z.number().min(1),
  reservationAmount: z.number().min(1),
  compensationAmount: z.number(),
  isThereCompensation: z.boolean().default(false),
  channelId: z.number().min(1),
});
const UpdateIncomeRow: FC<{
  data?: IIncome;
  onSubmit: (body: IIncome) => void;
  channels?: IChannel[];
  onCancel: () => void;
}> = ({ data, onCancel, channels, onSubmit }) => {
  const ref = useRef(null);

  const {
    t,
    i18n: { language },
  } = useTranslation();

  const {
    handleSubmit,
    register,
    setValue,
    watch,
    formState: { errors },
  } = useForm<IIncome>({
    defaultValues: {
      isThereCompensation: false,
      compensationAmount: 0,
      ...data,
      channelId: data?.channelId ?? data?.channels?.channelId,
    },
    resolver: zodResolver(schema),
    mode: 'all',
  });

  const [dirtyFields, setDirtyFields] = useState(false);

  const { showWarning } = useContext(ToastMessageContext);
  const updating = useMemo(() => Boolean(watch('revenueId')), [watch()]);
  const align = language === 'ar' ? 'right' : 'left';

  const onErrorForm = (e?: FieldErrors<IIncome>) => {
    // if (!updating) {
    //   showWarning(t('GENERAL.NOT_COMPLETE_DATA'));
    // }
  };
  const beforeSubmit = () => {
    if (dirtyFields) {
      handleSubmit(onSubmit, (errors) => onErrorForm(errors))();
    } else {
      onCancel();
    }
  };
  useOnClickOutside(ref, (e) => {
    const target = e.target as any;
    const parentElement = target?.parentElement;
    const parentId: string | undefined = parentElement?.id;
    const id: string | undefined = parentId?.includes('channel-select') ? parentId : target?.id;
    if (!id?.includes('channel-select')) {
      beforeSubmit();
    }
  });
  return (
    <>
      <TableRow sx={{ display: 'none' }}>
        <TableCell colSpan={6}>
          <form>
            <input {...register('propertyId')} />
            <input {...register('revenueId')} />
            <input {...register('reservationNumber')} />
            <input {...register('channels.commission')} />
            <input {...register('reservationAmount')} />
            <input {...register('compensationAmount')} />
            <input {...register('isThereCompensation')} />
            <input {...register('channelId')} />
            <input {...register('numberOfNights')} />
          </form>
        </TableCell>
      </TableRow>
      <TableRow ref={ref}>
        <TableCell align={align}></TableCell>
        <TableCell align={align}>{watch('reservationNumber')}</TableCell>
        <TableCell align={align}>
          <TextField
            size={'small'}
            value={watch('numberOfNights')}
            onKeyDown={({ key }: KeyboardEvent) => {
              if (key === 'Enter') {
                beforeSubmit();
              }
            }}
            onChange={(e) => {
              const val: number = Number(e?.target?.value);
              if (!isNaN(val)) {
                setDirtyFields(true);
                setValue('numberOfNights', val);
              }
            }}
            error={Boolean(errors.numberOfNights?.message)}
          />
        </TableCell>
        <TableCell align={align}>
          <Select
            size={'small'}
            value={watch('channelId')}
            id={'channel-select'}
            defaultValue={0}
            error={Boolean(errors.channelId?.message)}
            onChange={(e) => {
              setDirtyFields(true);
              setValue('channelId', Number(e.target.value));
            }}
            sx={{
              boxShadow: 'none',
              '.MuiOutlinedInput-notchedOutline': {
                border: errors.channelId?.message ? 1 : 0,
              },
              minWidth: 100,
            }}
          >
            <MenuItem id={'channel-select-choose'} value={0}>
              {t('FINANCE.INCOME.CHOOSE_RESOURCE')}
            </MenuItem>
            {channels?.map((i) => {
              return (
                <MenuItem id={`channel-select-${i.channelId}`} value={i.channelId} key={i.channelId}>
                  <Chip
                    id={`channel-select-${i.channelId}-chip`}
                    color={'primary'}
                    sx={{ backgroundColor: i?.color }}
                    label={i.channelName}
                  />
                </MenuItem>
              );
            })}
          </Select>
        </TableCell>
        <TableCell align={align}>
          {watch('channels.commission')}
          {watch('channels.commission') && '%'}
        </TableCell>
        <TableCell align={align}>
          <TextField
            size={'small'}
            value={watch('reservationAmount')}
            onKeyDown={({ key }: KeyboardEvent) => {
              if (key === 'Enter') {
                beforeSubmit();
              }
            }}
            onChange={(e) => {
              const val: number = Number(e?.target?.value);
              if (!isNaN(val)) {
                setDirtyFields(true);
                setValue('reservationAmount', val);
              }
            }}
            error={Boolean(errors.reservationAmount?.message)}
          />
        </TableCell>

        <TableCell align={align}>
          <Typography fontSize={'14px'} sx={{ color: '#2F7C31' }}>
            {updating && sarFormat(watch('netRevenue') ?? 0)}
          </Typography>
        </TableCell>
        <TableCell align={align}>
          <Toggle
            checked={watch('isThereCompensation')}
            onKeyDown={({ key }: KeyboardEvent) => {
              if (key === 'Enter') {
                beforeSubmit();
              }
            }}
            onChange={(e) => {
              setDirtyFields(true);
              setValue('isThereCompensation', e.target.checked);
            }}
          />
        </TableCell>
        <TableCell align={align} width={120}>
          {watch('isThereCompensation') && (
            <TextField
              size={'small'}
              value={watch('compensationAmount')}
              onChange={(e) => {
                const val: number = Number(e?.target?.value);
                if (!isNaN(val)) {
                  setDirtyFields(true);
                  setValue('compensationAmount', val);
                }
              }}
              error={Boolean(errors.compensationAmount?.message)}
            />
          )}
        </TableCell>
      </TableRow>
    </>
  );
};
export default UpdateIncomeRow;
