import { useTranslation } from "react-i18next"
import { CustomNoRowsOverlayWrapper } from "./stryle"
import BuildingInfrastructure from "@assets/svg/BuildingInfrastructure.svg?react"

export function CustomNoRowsOverlay() {
	const { t } = useTranslation()
	return (
		<CustomNoRowsOverlayWrapper>
			<BuildingInfrastructure />
			<p>{t('GuestManagement.noGuests')}</p>
		</CustomNoRowsOverlayWrapper>
	)
}