import styled from "styled-components"

export const CreateCategoryManagementWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr auto;
	height: 100%;
	gap: 24px;
	> div {
		&.containerFooter {
			padding: 24px 32px;
			display: flex;
			justify-self: flex-start;
			align-items: center;
			gap: 24px;
			flex-direction: column;
			width: 100%;
			.containerStepsLine {
				display: flex;
				flex-direction: row;
				gap: 16px;
				width: 100%;
				span {
					background-color: #d9d9d9;
					flex: 1;
					height: 8px;
					border-radius: 8px;
					&.active {
						background-color: #5b3fff;
					}
				}
			}
			.containerActions {
				display: flex;
				justify-content: space-between;
				align-items: center;
				width: 100%;
				> button {
					&:first-child {
						color: #261b6a;
						background-color: transparent;
						border: none;
						cursor: pointer;
						font-size: 14px;
						font-weight: 700;
						line-height: 16.8px;
						text-decoration: underline;
					}
					&:last-child {
						width: auto;
						padding: 16px 90px;
					}
				}
			}
		}
	}
`
