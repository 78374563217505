import { useApi } from './useApi';
import { useMutation, useQueries, useQuery } from '@tanstack/react-query';
import {
  afterPaymentURL,
  calculateSubscriptionURL,
  checkoutURL,
  downgradeSubscriptionURL,
  getActiveSubscriptionInfoURL,
  getCustomerSubscriptionsURL,
  getPrintInvoiceURL,
  getSubscriptionFeaturesURL,
  getSubscriptionPlansURL,
  payAndUpgradeSubscriptionURL,
  PayNowURL,
} from '../utils/constants';
import { ICustomerSubscription, IPlan, ISubscriptionPlan } from '@interfaces';

const useSubscription = () => {
  const { apiPrivate, apiPublic } = useApi();

  const getSubscriptionPlans = async (id: string): Promise<ISubscriptionPlan[]> => {
    const {
      data: { result },
    } = await apiPublic.get<{ result: ISubscriptionPlan[] }>(`${getSubscriptionPlansURL}`, {
      params: { subscriptionPlanNameId: id },
    });
    return result;
  };

  const getPrintInvoice = async () => {
    return await apiPrivate.get(`${getPrintInvoiceURL}`, {
      responseType: 'arraybuffer',
    });
  };
  const payNow = async () => {
    return await apiPrivate.get(PayNowURL);
  };

  const calculateSubscription = async (params: { userId: number; numOfHomes: number; subscriptionType: number }) => {
    const {
      data: { result },
    } = await apiPublic.get<{ result: { amount: number; subscriptionPlan: ISubscriptionPlan } }>(
      calculateSubscriptionURL,
      { params },
    );
    return result;
  };
  const checkout = async (params: { userId: number; numberOfProperties: number; subscriptionType: number }) => {
    const {
      data: { result },
    } = await apiPublic.get<{
      result: {
        checkout_url: string;
        checkout_id: string;
      };
    }>(checkoutURL, { params });
    return result;
  };
  const afterPayment = async (params: { checkoutId: string }) => {
    const { data } = await apiPublic.get<{
      result: any;
      statusCode: number;
    }>(afterPaymentURL, { params });
    return data;
  };

  const getSubscriptionFeatures = async (): Promise<string[]> => {
    const {
      data: { result },
    } = await apiPublic.get<{ result: { features: string }[] }>(`${getSubscriptionFeaturesURL}`);
    return result?.map((r) => r.features);
  };

  const downgrade = async (body: { propertyIds: number[]; cancellationReasons: number[] }): Promise<string> => {
    const {
      data: { result },
    } = await apiPrivate.post<{ result: string }>(downgradeSubscriptionURL, body);
    return result;
  };
  const payAndUpgrade = async (numberOfProperties: number, subscriptionType: number) => {
    const { data } = await apiPrivate.get(payAndUpgradeSubscriptionURL, {
      params: { numberOfProperties, subscriptionType },
    });
    return data;
  };
  const getActiveSubscriptionInfo = async (): Promise<IPlan[]> => {
    const {
      data: { result },
    } = await apiPrivate.get<{ result: IPlan[] }>(`${getActiveSubscriptionInfoURL}`);
    return result;
  };
  const getCustomerSubscriptions = async (): Promise<ICustomerSubscription[]> => {
    const {
      data: { result },
    } = await apiPrivate.get<{ result: ICustomerSubscription[] }>(`${getCustomerSubscriptionsURL}`);
    return result;
  };
  const calculateSubscriptionQuery = ({
    userId,
    numOfHomes,
    subscriptionType,
  }: {
    userId?: number;
    numOfHomes?: number;
    subscriptionType?: number;
  }) =>
    useQuery({
      enabled: Boolean(userId && numOfHomes && subscriptionType),
      queryKey: [calculateSubscriptionURL, userId, numOfHomes, subscriptionType],
      queryFn: ({ queryKey }) =>
        calculateSubscription({
          userId: Number(queryKey[1]),
          numOfHomes: Number(queryKey[2]),
          subscriptionType: Number(queryKey[3]),
        }),
    });
  const afterPaymentQuery = ({ checkoutId }: { checkoutId?: string }) =>
    useQuery({
      enabled: Boolean(checkoutId),
      queryKey: [calculateSubscriptionURL, checkoutId],
      queryFn: ({ queryKey }) =>
        afterPayment({
          checkoutId: queryKey[1]!,
        }),
    });
  const getActiveSubscriptionInfoQuery = () =>
    useQuery({
      queryKey: [getActiveSubscriptionInfoURL],
      queryFn: () => getActiveSubscriptionInfo(),
    });
  const getCustomerSubscriptionsQuery = () =>
    useQuery({
      queryKey: [getCustomerSubscriptionsURL],
      queryFn: () => getCustomerSubscriptions(),
    });
  const checkoutQuery = ({
    userId,
    amount,
    numberOfProperty,
    subscriptionType,
    startCheckout,
  }: {
    userId?: number;
    amount?: number;
    numberOfProperty?: number;
    subscriptionType?: number;
    startCheckout?: boolean;
  }) =>
    useQuery({
      enabled: Boolean(userId && amount && numberOfProperty && subscriptionType && startCheckout),
      queryKey: [calculateSubscriptionURL, userId, amount, numberOfProperty, subscriptionType, checkout],
      queryFn: ({ queryKey }) =>
        checkout({
          userId: Number(queryKey[1]),
          numberOfProperties: Number(queryKey[3]),
          subscriptionType: Number(queryKey[4]),
        }),
    });
  const subsciptionPlanQueryById = (id: string) =>
    useQuery({
      enabled: Boolean(id),
      queryKey: [getSubscriptionPlansURL, id],
      queryFn: ({ queryKey }) => getSubscriptionPlans(queryKey[1]),
    });
  const subscriptionFeatures = () =>
    useQuery({
      queryKey: [getSubscriptionFeaturesURL],
      queryFn: () => getSubscriptionFeatures(),
    });

  const allSubscriptionPlans = () =>
    useQueries({
      queries: ['1', '2', '3'].map((id) => {
        return {
          queryKey: [getSubscriptionPlansURL, id],
          queryFn: () => getSubscriptionPlans(id),
        };
      }),
    });

  const downgradeMutate = ({ onSuccess, onError }: { onSuccess?: (data: string) => void; onError?: () => void }) =>
    useMutation({
      mutationFn: (body: { propertyIds: number[]; cancellationReasons: number[] }) => downgrade(body),
      onSuccess: (data) => onSuccess && onSuccess(data),
      onError: (error) => onError && onError(),
    });

  const getPrintInvoiceMutate = ({
    onSuccess,
    onError,
  }: {
    onSuccess?: (data: any) => void;
    onError?: (error: any) => void;
  }) =>
    useMutation({
      mutationFn: () => getPrintInvoice(),
      onSuccess: (data) => onSuccess && onSuccess(data),
      onError: (error) => onError && onError(error),
    });
  const payNowMutate = ({ onSuccess, onError }: { onSuccess?: (data: any) => void; onError?: (error: any) => void }) =>
    useMutation({
      mutationFn: () => payNow(),
      onSuccess: (data) => onSuccess && onSuccess(data),
      onError: (error) => onError && onError(error),
    });

  const payAndUpgradeMutate = ({ onSuccess, onError }: { onSuccess?: () => void; onError?: () => void }) =>
    useMutation({
      mutationFn: ({
        numberOfProperties,
        subscriptionType,
      }: {
        numberOfProperties: number;
        subscriptionType: number;
      }) => payAndUpgrade(numberOfProperties, subscriptionType),
      onSuccess: (data) => onSuccess && onSuccess(),
      onError: (error) => onError && onError(),
    });
  return {
    allSubscriptionPlans,
    subscriptionFeatures,
    calculateSubscriptionQuery,
    checkoutQuery,
    afterPaymentQuery,
    getActiveSubscriptionInfoQuery,
    getCustomerSubscriptionsQuery,
    downgradeMutate,
    payAndUpgradeMutate,
    getPrintInvoiceMutate,
    payNowMutate,
  };
};
export { useSubscription };
