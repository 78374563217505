import React, { FC, MouseEvent, useMemo, useState } from 'react';
import { Box, Chip, Popover, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IMessage, ITemplate } from '@interfaces';

const PreviewTemplate: FC<{
  data: ITemplate;
  welcome: IMessage[];
}> = ({ data, welcome }) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | undefined>();

  const restItems = useMemo(() => {
    if (data?.property?.length && data?.property.length > 1) {
      return data.property?.slice(1, data.property.length);
    }
    return [];
  }, [data.property]);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(undefined);
  };

  return (
    <>
      <Box sx={{ display: 'flex', gap: 2, mb: 3, px: 1, alignItems: 'center' }}>
        {Boolean(data.property?.length) && (
          <Chip color={'primary'} variant={'filled'} label={data!.property![0].propertyName} />
        )}
        {Boolean(restItems?.length) && (
          <Box sx={{ color: '#ADBAC7' }} onClick={handleClick}>
            {t('GENERAL.MORE', { n: restItems?.length })}
          </Box>
        )}
      </Box>
      <Popover id={data?.id?.toString()} open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={handlePopoverClose}>
        <Box
          sx={{
            p: 2,
            minWidth: '300px',
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
          }}
        >
          {restItems?.map((i:any) => (
            <Chip color={'primary'} variant={'filled'} key={i.propertyId} label={i.propertyName} />
          ))}
        </Box>
      </Popover>
      <Typography px={1} mb={2} color={'primary'}>
        {t('MESSAGING.PREVIEW_MESSAGE')}
      </Typography>
      <Box sx={{ px: 1, overflowY: 'auto', maxHeight: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            '.MuiFormControlLabel-root': {
              marginInline: 0,
            },
            flexDirection: 'column',
            gap: 1,
            borderRadius: 1,
            border: '1px solid #F4F4F5',
            color: '#52525B',
            pt: 2,
            pb: 4,
            px: 4,
            backgroundColor: '#FAFAFA',
          }}
        >
          <Typography fontSize={'48px'} mb={6}>
            {t('MESSAGING.PREVIEW_HELLO')}
          </Typography>
          <Typography>
            {t('MESSAGING.DEAR_GUEST')}{' '}
            <Typography sx={{ display: 'inline' }} color={'primary'}>
              {t('MESSAGING.GUEST_NAME_PLACEHOLDER')}
            </Typography>
          </Typography>
          <Box
            sx={{ mb: 4 }}
            dangerouslySetInnerHTML={{
              __html:
                welcome
                  ?.find((i) => i.id === data.welomeMessageId)
                  ?.content?.replaceAll('\\n', '<br/>')
                  .replaceAll('\\r', '') ?? '',
            }}
          />
          <Typography>
            {t('MESSAGING.IN_TIME')}
            {': '}
            <Typography sx={{ display: 'inline' }} color={'primary'}>
              {t('MESSAGING.IN_TIME_PLACEHOLDER')}
            </Typography>
          </Typography>
          <Typography sx={{ mb: 4 }}>
            {t('MESSAGING.OUT_TIME')}
            {': '}
            <Typography sx={{ display: 'inline' }} color={'primary'}>
              {t('MESSAGING.OUT_TIME_PLACEHOLDER')}
            </Typography>
          </Typography>
          <Typography sx={{ mb: 4 }} color={'primary'}>
            {t('MESSAGING.LOCATION_PLACEHOLDER')}
          </Typography>
          <Typography>{t('MESSAGING.RULES_INSTRUCTIONS')}:</Typography>
          {data?.ruleMessages?.map((c) => {
            const content = c.content;
            return <Typography key={c.id}>⁃ {content}</Typography>;
          })}

          <Box
            sx={{
              mt: 4,
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              borderRadius: 3,
              backgroundColor: 'white',
              p: 3,
              fontSize: '24px',
            }}
          >
            <Box sx={{ lineHeight: 0, marginTop: '10px' }}> * * * * * *</Box>
          </Box>
        </Box>
      </Box>
      <Typography sx={{ p: 2 }} color={'primary'}>
        {t('MESSAGING.AUTO_INFO')}
      </Typography>
    </>
  );
};
export default PreviewTemplate;
