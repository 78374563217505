import styled from "styled-components"

export const SectionTableWrapper = styled.div`
	.columnsContent {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		margin: 0;
		font-size: 12px;
		font-weight: 400;
		line-height: 14.4px;
		height: 100%;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 10px;
		svg {
			width: 24px;
			height: 24px;
		}
	}
	.columnsActions {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		flex-direction: row;
		gap: 6px;
		div {
			width: 18px;
			height: 18px;
			cursor: pointer;
			svg {
				width: 18px;
				height: 18px;
			}
		}
	}
`
