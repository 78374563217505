import { useApi } from './useApi';
import {TaskMangementUrl } from '../utils/constants'; 
import { useQuery } from '@tanstack/react-query';

const useTaskManagemnt = () => {
  const { apiPrivate } = useApi();

  const getTaskManagemnt = async (): Promise<any> => {
    const { data } = await apiPrivate.get(`${TaskMangementUrl}?pageSize=100&pageNumber=1`); 
    return data;
  };
 
  const taskMangemntQuery = () =>
    useQuery({
      queryKey: [TaskMangementUrl],
      queryFn: getTaskManagemnt,
    });

  return { taskMangemntQuery };
};

export { useTaskManagemnt };
