import styled from "styled-components"

export const CustomTableWrapper = styled.div`
	background-color: #fff;
	border-radius: 16px;
	padding: 16px;
	.HeaderTheTable {
		margin-bottom: 16px;
		p {
			margin: 0;
			color: #435363;
			font-size: 24px;
			font-weight: 400;
			line-height: 28.8px;
		}
	}
	.MuiDataGrid-root {
		border-radius: 0;
		border: none;
		background-color: transparent;
		.MuiDataGrid-container--top [role="row"] {
			background-color: transparent;
		}
		.MuiDataGrid-iconSeparator {
			display: none;
		}
		.MuiDataGrid-footerContainer {
			display: none;
		}
		.MuiDataGrid-withBorderColor {
			border-color: #e9e9e7;
		}
	}
`
export const CustomPaginationWrapper = styled.div`
	border-top: 1px solid #e9e9e7;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-top: 42px;
	.css-ok954w-MuiButtonBase-root-MuiPaginationItem-root.Mui-disabled {
		box-shadow: 0px 0px 6px 0px #00000005;
		background-color: #e9e9e9;
		opacity: 1;
		border-radius: 5px;
		color: #435363;
	}
	.css-ok954w-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
		background-color: #5b3fff;
		color: #fff;
		border-radius: 5px;
	}
	.css-ok954w-MuiButtonBase-root-MuiPaginationItem-root {
		box-shadow: 0px 0px 6px 0px #00000005;
		background: #fff;
		border-radius: 5px;
		color: #5b3fff;
	}

	.MuiPaginationItem-previousNext {
		background-color: #eeebff;
		color: #5b3fff;
		box-shadow: 0px 0px 6px 0px #00000005;
	}
	.perPageSelect {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		gap: 14px;
		> p {
			color: #8e98a1;
			font-size: 14px;
			font-weight: 400;
			line-height: 16.8px;
		}
	}
`
