import { useTranslation } from "react-i18next"
import { PopupOverlayWrapper } from "./styles"

export default function PopupOverlay({children=()=>{}}:any) {

	const {
		i18n: { language },
	} = useTranslation()
	return (
		<PopupOverlayWrapper lang={language as "ar" | "en"}>
			{children}
		</PopupOverlayWrapper>
	)
}
