import { minusPadding } from '@utils';
import { Box, Button, Grid, Typography } from '@mui/material';
import { registerNow } from '../../utils/image-consts';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const FreeTrail = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const navigate = useNavigate();
  return (
    <Box
      dir={'rtl'}
      sx={{
        ...minusPadding,
        minHeight: '400px',
        display: 'flex',
        alignItems: 'center',
        py: 12,
        background: 'linear-gradient(180deg, #FFFFFF 0%, #F6F4FF 100%)',
      }}
      id='register'
    >
      <Grid container justifyContent={'center'}>
        <Grid item xs={6} display={'flex'} justifyContent={'center'}>
          <Box
            sx={{
              textAlign: 'center',
              justifyContent: 'center',
              maxWidth: '380px',
              display: 'flex',
              flexDirection: 'column',
              gap: 3,
            }}
          >
            <Typography fontSize={{ xs: '20px', md: '28px' }} fontWeight={'bold'} color={'info'}>
              {t('FREE_TRAIL')} {t('FREE_TRAIL_DAYS')}
            </Typography>
            <Button variant={'contained'} fullWidth onClick={() => navigate('/register')} sx={{ borderRadius: '32px' }}>
              {t('MENU.JOIN')}
            </Button>
          </Box>
        </Grid>
        <Grid item xs={6} display={'flex'} justifyContent={'end'} marginInlineEnd={{ xs: -8, md: -10 }}>
          <Box
            component={'img'}
            src={registerNow}
            width={'100%'}
            sx={{
              objectFit: 'contain',
              objectPosition: language == 'ar' ? 'left' : 'right',
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
export default FreeTrail;
