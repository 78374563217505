import CustomButton from "@Common/CustomButton"
import { PopupDisableLockWrapper } from "./styles"
import { useTranslation } from "react-i18next"
import { Dispatch, SetStateAction } from "react"
import { useDeleteDisablePasscode } from "@services"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { LockDetailsStore } from "@store/NewCalenderPage/LockDetailsStore"
import { loaderState, PopupOverlayNewCalender } from "@store/index"
interface IProps {
	setIsPopupDisableLock: Dispatch<SetStateAction<boolean>>
}
export default function PopupDisableLock({ setIsPopupDisableLock }: IProps) {
	const { t } = useTranslation()
	const LockDetailsStoreValue = useRecoilValue(LockDetailsStore)
	const setPopupOverlayNewCalender = useSetRecoilState(PopupOverlayNewCalender)
	const setLoaderState = useSetRecoilState(loaderState)
	const { mutateAsync: mutateAsyncDeleteDisablePasscode } =
		useDeleteDisablePasscode()
	const funDisableLock = async () => {
		if (!LockDetailsStoreValue.passcode || !LockDetailsStoreValue.lockId) return
		setLoaderState(true)
		await mutateAsyncDeleteDisablePasscode({
			passcode: LockDetailsStoreValue.passcode,
			lockId: LockDetailsStoreValue.lockId,
		})
		setIsPopupDisableLock(false)
		setPopupOverlayNewCalender(undefined)
		setLoaderState(false)
	}
	return (
		<PopupDisableLockWrapper>
			<div className='containerPopup'>
				<div>
					<p>{t("LOCKS.DISABLE_LOCK")}</p>
				</div>
				<div>
					<p>{t("COMMON.are_you_sure_you_want_to_disable_the_lock")}</p>
				</div>
				<div className='containerFooter'>
					<CustomButton
						label={t("GENERAL.CANCEL_ALT")}
						withBG
						customPadding='8px 24px'
						borderRadius='8px'
						onClick={() => setIsPopupDisableLock(false)}
					/>
					<CustomButton
						label={t("LOCKS.DISABLE_LOCK")}
						customColor='#fff'
						customBG='#D70015'
						customPadding='8px 24px'
						borderRadius='8px'
						borderColor='#D70015'
						onClick={() => funDisableLock()}
					/>
				</div>
			</div>
		</PopupDisableLockWrapper>
	)
}
