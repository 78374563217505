import styled from "styled-components"

export const HeaderSection = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-direction: column;
	gap: 10px;
	margin-bottom: 28px;
	p {
		margin: 0;
		padding: 0;
		color: #130080;
		font-size: 16px;
		font-weight: 400;
		line-height: 19.2px;
	}
	svg {
		color: #5b3fff;
	}
`
export const CustomDivider = styled.div`
	width: 100%;
	height: 1px;
	background-color: #e3e8ef;
	margin-block: 23px;
`
export const ReservationInfoWrapper = styled.div`
	p {
		margin: 0;
		padding: 0;
	}
	.reservationNum {
		span {
			font-size: 14px;
			font-weight: 400;
			line-height: 16.8px;
			&:first-child {
				color: #0f172a;
			}
			&:last-child {
				color: #5b3fff;
			}
		}
	}
`
