import { MenuItem, Select, SelectChangeEvent } from "@mui/material"
import { SelectClassficationWrapper } from "./styles"
import { useGetGuestManagementClassification } from "@services"
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react"
import { useSetRecoilState } from "recoil"
import { classfication } from "@store/index"
import { useTranslation } from "react-i18next"

interface IProps {
	data: string
	guestName: string
	setClassficationPopup: Dispatch<SetStateAction<boolean>>
}

export default function SelectClassfication({
	data,
	guestName,
	setClassficationPopup,
}: IProps) {
	const {
		i18n: { language },
	} = useTranslation()
	const setClassficationState = useSetRecoilState(classfication)
	const [selectedClassficationValue, setSelectedClassficationValue] =
		useState("")

	const { data: classificationQuery } = useGetGuestManagementClassification({
		lang: language === "ar" ? "A" : "E",
	})

	const onChangeFun = async (data: SelectChangeEvent<string>) => {
		setClassficationPopup(true)
		setClassficationState({
			classficationId: data.target.value,
			guestName: guestName,
			onDone: () => {
				setSelectedClassficationValue(data.target.value.toString())
			},
		})
	}

	useEffect(() => {
		const CurrantData = classificationQuery?.result.filter(
			(item) => item.name === data
		)
		if (CurrantData?.length === 0) {
			setSelectedClassficationValue("UnCategorized")
		} else {
			setSelectedClassficationValue(CurrantData?.[0]?.lookupId.toString()!)
		}
	}, [setSelectedClassficationValue, classificationQuery])

	return (
		<SelectClassficationWrapper>
			<Select
				value={selectedClassficationValue}
				onChange={(data) => onChangeFun(data)}
				sx={{
					height: "25px",
					width: "55%",
					borderRadius: "35px",
					color: "#000",
					".MuiOutlinedInput-notchedOutline": {
						borderColor: "rgba(228, 219, 233, 0.25)",
					},
					"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
						borderColor: "rgba(228, 219, 233, 0.25)",
					},
					"&:hover .MuiOutlinedInput-notchedOutline": {
						borderColor: "rgba(228, 219, 233, 0.25)",
					},
					background:
						selectedClassficationValue === "158"
							? "#e4fce6"
							: selectedClassficationValue === "157"
								? "#e8e8e8"
								: selectedClassficationValue === "159"
									? "#ffe0e3"
									: "#ccc",
				}}
				label=''
			>
				<MenuItem
					key={"UnCategorized"}
					value={"UnCategorized"}
					style={{ color: "#9CA3AF" }}
					disabled
				>
					{/* {t('CONTROL.UnCategorized')} */ "غير مصنف"}
				</MenuItem>
				{classificationQuery?.result.map((item: any) => (
					<MenuItem
						key={item?.lookupId}
						value={item?.lookupId}
						style={{ color: "#9CA3AF" }}
					>
						{item?.name}
					</MenuItem>
				))}
			</Select>
		</SelectClassficationWrapper>
	)
}
