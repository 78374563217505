/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useContext } from 'react';
import { Box, Button, IconButton, Modal, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';

import { IModalProps } from '@interfaces';
import { userSettings } from '@services';
import { ToastMessageContext } from '@context';
import useBreakPoinst from '@hooks/useBreakPoinst';

const UpdateNameModal: FC<IModalProps> = ({ handleClose, open, data }) => {
   const {isPhone} =  useBreakPoinst()
    const style = {
        position: 'absolute',
        top: '50%',
        left:isPhone ? '4%' : '50%',
        transform: isPhone ? 'translate(0, -60%)' : 'translate(-50%, -50%)',
        width:isPhone ? '90%' : 495,
        bgcolor: 'background.paper',
        color: '#261B6A',
        boxShadow: 24,
        p: 3,
        borderRadius: 5,
    };

    const {
        t,
    } = useTranslation();

    const { showSuccess, showError } = useContext(ToastMessageContext);

    const { control, handleSubmit, reset, setValue } = useForm<any>({
        mode: 'onChange',
    });

    const { updateUserSettingMutate } = userSettings();

    const { mutate: updateUser } = updateUserSettingMutate({
        onSuccess: () => {
            closeModal();
            showSuccess(t('SEETING.CHANGES_SAVED'));  
        },
        onError: () => {
            showError(t('ERRORS.SOMETHING_WENT_WRONG'));
        },
    });

    const onSubmit = (body: any) => {
        const payload: any = {
            email: body?.email,
        };
        updateUser(payload);
    };

    const closeModal = () => {
        handleClose();
        reset({});
    };



    return (
        <>
            <Modal
                open={open}
                onClose={closeModal}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
            >
                <Box sx={style}>
                    <div className='flex justify-between items-center mb-3'>
                        <Typography sx={{fontSize:'24px', fontWeight:400}} id='modal-modal-title' className='text-[#435363]'>
                        {t('SEETING.CHANGE_EMAIL')}
                        </Typography>

                        <IconButton aria-label='close' onClick={closeModal} size='small'>
                            <CloseIcon className='text-[#27272A] ' />
                        </IconButton>
                    </div>
                    <form className='mt-10' onSubmit={handleSubmit(onSubmit)}> 
                        <>
                            <Controller
                                name='email'
                                control={control}
                                rules={{required:{value:true,message:t("COMMON.REQUIRED")},pattern:{value:/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,message:t('.EMAIL_ERROR')}}}  
                                render={({ field: { value, onChange },fieldState: { error }  }) => (
                                    <>
                                        <div className='text-[#261B6A] font-normal'>
                                            <Box sx={{fontWeight:'700',color:'#435363',fontSize:'20px',marginBottom:1}} > {t('SEETING.EMAIL')}</Box>
                                            <div>
                                                <TextField
                                                    id='email' 
                                                    error={!!error?.message}
                                                    helperText={t(error?.message ?? '')}
                                                    className='w-full rounded-3xl'
                                                    value={value}
                                                    InputProps={{ sx: { borderRadius: 24 } }} 
                                                    style={{
                                                        borderRadius: '24px !important',
                                                        color: '#9CA3AF',
                                                        borderColor: '#9CA3AF',
                                                    }}
                                                    placeholder={t('SEETING.EMAIL')}
                                                    variant='outlined'
                                                    onChange={onChange} 
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}
                            />
                            <div className='mt-6 mx-4'>
                                <Button className='w-full' variant={'contained'} size={'large'} type='submit'>
                                {t('SEETING.SAVE_CHANGES')}
                                </Button>
                            </div>
                        </>
                    </form>
                </Box>
            </Modal>
        </>
    );
};

export default UpdateNameModal;
