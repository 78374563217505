import { FC, useMemo } from 'react';
import PropertiesList from './PropertiesList';
import { useProperty } from '@services';
import { useIsMutating } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import GathernIcon from '@assets/svg/gathern-icon.svg?react';

const GathernProperties: FC<{ reset: (close: boolean) => void,onNext:()=>void }> = ({ reset,onNext }) => {
  const { addPropertyMutate, gathrenListingQuery } = useProperty();
  const mutating = useIsMutating();
  const { t } = useTranslation();
  const { mutate: addProperty, isError, isSuccess } = addPropertyMutate({});
  const { data: gathrenListing, isLoading, isError: errorFetch } = gathrenListingQuery();

  const onLink = (properties: any[]) => {
    const payload: any = properties?.map((property) => ({
      propertyLocation: property?.location?.address,
      propertyId: 0,
      propertyName: property?.title,
      propertyIsDeteled: false,
      propertyCreateDate: null,
      gathrenUnitId: `${property?.id}`,
      airbnbUnitId: '',
      chaletId: property?.chaletId,
      autoAccess: false,
      status: true,
      templateId: null,
      propertyImage: property?.image,

    }));
    addProperty(payload);
  };
  const list = useMemo(() => {
    return (
      gathrenListing?.chalets
        ?.map((c: any) => {
          return c.units?.map((u: any) => ({ ...u, location: c.location, chaletId: c.id }));
        })
        ?.flat() ?? []
    );
  }, [gathrenListing]);
  return (
    <>
      <PropertiesList
        loading={isLoading}
        channel={t('GATHERIN')}
        mutate={onLink}
        channelIcon={<GathernIcon />}
        propertyList={list}
        success={isSuccess}
        error={isError || errorFetch}
        reset={reset}
        mutating={mutating > 0}
        onNext={onNext}
      />
    </>
  );
};
export default GathernProperties;
