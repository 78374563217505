import { FC } from 'react';
import { Box, Button, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { MenuLinkI } from '@interfaces';
import { useTranslation } from 'react-i18next';
import { Language } from '@components';
import { disabledFeatures, MenuItems } from '@utils';

const NavLinks: FC<{ handleClick: (item: MenuLinkI) => void }> = ({ handleClick }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: {
          xs: 'column',
          md: 'row',
        },
        gap: 3,
        pt: {
          xs: 8,
          md: 0,
        },
        alignItems: 'center',
      }}
    >
      {MenuItems.map((item) => {
        return (
          <div key={item.name} className={'text-black font-medium cursor-pointer'}>
            <Box onClick={(e) => handleClick(item)}>{t(item.name)}</Box>
          </div>
        );
      })}
      <>
        <Box
          marginInlineStart={{
            xs: 0,
            md: 'auto',
          }}
        >
          <Button
            onClick={() =>false ? window.open("https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ0jfgD0WEizdTsurS3cNdfPFIuzm-3wqSogMkuzC9y-Dw99A8a7rYZF5OmwL8jdgfPeI2y1JydD", "_blank"):navigate('/login')}
            variant={'contained'}
            color={'primary'}
            sx={{
              borderRadius: '50px',
              textAlign: 'center',
              justifyContent: 'center',
              px: '10px',
              marginInlineStart: 'auto',
              width: 'auto',
            }}
          >
            <Typography sx={{ fontSize: '14px', color: 'white' }}>{t('MENU.LOGIN')}</Typography>
          </Button>
        </Box>
        {mdDown && <Language />}
        {mdUp && (
          <div>
            <Language />
          </div>
        )}
      </>
    </Box>
  );
};
export default NavLinks;
