import styled from "styled-components"

export const HeaderSectionWrapper = styled.div`
	border-bottom: 1px solid #eaedf1;
	color: #130080;
	font-size: 14px;
	font-weight: 400;
	line-height: 16.8px;
	padding-bottom: 16px;
	margin-bottom: 24px;
`
