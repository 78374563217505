import styled from "styled-components"

export const WhiteCardWrapper = styled.div<{
	$height?: string
	$padding?: string
}>`
	border-radius: 16px;
	padding: ${(props) => (props.$padding ? props.$padding : "16px 22px")};
	height: ${(props) => (props.$height ? props.$height : "100%")};
	background-color: #fff;
`
