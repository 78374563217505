import { createTheme } from '@mui/material';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1400,
      xxl: 1600,
    },
  },
  typography: {
    fontFamily:
      '"Tajawal", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,\n' +
      '  "Open Sans", "Helvetica Neue", sans-serif',
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          color: '#261B6A',
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          '.MuiTableCell-root': {
            padding: '8px',
          },
          '.MuiTableBody-root .MuiTableCell-root': {
            padding: '4px',
          },
          minWidth: 800,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '16px',
        },
        endIcon: {
          marginInlineEnd: '-4px',
          marginInlineStart: '8px',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        avatar: {
          marginInlineStart: '4px',
          marginInlineEnd: '-6px',
          width: '20px',
          height: '20px',
        },
        deleteIcon: {
          marginInlineStart: '4px',
          marginInlineEnd: '-6px',
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          top: '64px !important',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          paddingBlock: '8px',
          paddingInline: '32px',
          width: '800px !important',
          borderRadius: '16px',
          color: 'white',
          fontWeight: 'bold',
        },
        standardSuccess: {
          backgroundColor: '#22C55E',
        },
        standardError: {
          backgroundColor: '#d32f2f',
        },
        standardWarning: {
          backgroundColor: '#F0AD4E',
        },
        action: {
          marginInlineStart: 'auto',
          marginInlineEnd: '0',
          padding: '4px 0 0',
          '.MuiIconButton-root': {
            paddingInline: '0px !important',
          },
        },
        icon: {
          display: 'none',
          marginInlineStart: '0',
          marginInlineEnd: '12px',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          maxWidth: '240px',
          wordBreak: 'break-word',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          minWidth: '200px',
          borderRadius: '0',
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#5B3FFF',
      light: '#FFF',
    },
    info: {
      main: '#261B6A',
    },
    success: {
      main: '#22C55E',
    },
    warning: {
      main: '#F0AD4E',
    },
  },
});
declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xxl: true; // adds the `mobile` breakpoint
  }
}

export { theme };
