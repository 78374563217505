import styled from "styled-components"

export const PropertyInformationWrapper = styled.div`
	padding-top: 16px;
	border-top: 1px solid #0000001a;
	p {
		margin: 0;
		margin-bottom: 16px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		span {
			color: #435363;
			font-size: 14px;
			font-weight: 500;
			line-height: 16.8px;
			&:first-child {
				width: 16px;
				height: 16px;
			}
		}
	}
	.conatainerData {
		.propertyInfo {
			display: flex;
			justify-content: space-between;
			align-items: center;

			.containerPropertyName {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				gap: 8px;
				div {
					width: 20px;
					height: 20px;
					overflow: hidden;
					svg {
						width: 100%;
						height: 100%;
					}
				}
				p {
					font-size: 14px;
					font-weight: 700;
					line-height: 16.8px;
					color: #435363;
					margin: 0;
				}
			}

			.containerStatus {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				.accept,
				.reject,
				.pendding,
				.known {
					display: flex;
					justify-content: flex-start;
					align-items: center;
					margin: 0;
					width: auto;
					height: 25px;
					border-radius: 30px;
					padding-inline: 10px;
					gap: 5px;
					span {
						width: 5px;
						height: 5px;
						border-radius: 50px;
					}
				}
				.accept {
					background-color: #f3fbf4;
					color: #16ae26;
					span {
						background-color: #16ae26;
					}
				}
				.reject {
					background-color: #ffe0e3;
					color: #d70015;
					span {
						background-color: #d70015;
					}
				}
				.pendding {
					background-color: #fff2d2;
					color: #fdbf21;
					span {
						background-color: #fdbf21;
					}
				}
				.known {
					background-color: #e9e9e9;
					color: #435363;
					span {
						background-color: #435363;
					}
				}
			}
		}

		.reservationNum {
			margin-top: 16px;
			p {
				width: 100%;
				display: flex;
				justify-content: flex-start;
				align-items: flex-start;
				flex-direction: column;
				margin: 0;
				span {
					width: 100%;
					&:first-child {
						color: #435363;
						font-size: 14px;
						font-weight: 500;
						line-height: 16.8px;
					}
				}
				.containerIcon {
					display: flex;
					justify-content: flex-start;
					align-items: center;
					flex-direction: row;
					gap: 12px;
					span {
						color: #5b3fff;
						font-size: 14px;
						font-weight: 500;
						line-height: 16.8px;
					}
					svg {
						color: #8e98a1;
						cursor: pointer;
					}
				}
			}
		}
		.reservationPrice {
			margin-top: 16px;
			p {
				margin: 0;
				font-size: 14px;
				font-weight: 500;
				line-height: 16.8px;
				color: #435363;
				margin-bottom: 8px;
				span {
					width: auto;
					&:first-child {
						font-size: 12px;
						font-weight: 500;
						line-height: 14.4px;
					}
					&:last-child {
						margin-right: 4px;
						font-size: 12px;
						font-weight: 500;
						line-height: 14.4px;
						color: #5b3fff;
					}
				}
			}
		}
		.reservationTime {
			margin-top: 16px;
			p {
				margin: 0;
				font-size: 14px;
				font-weight: 500;
				line-height: 16.8px;
				color: #435363;
				margin-bottom: 8px;
				span {
					width: auto;
					&:first-child {
						font-size: 12px;
						font-weight: 500;
						line-height: 14.4px;
					}
					&:last-child {
						margin-right: 4px;
						font-size: 12px;
						font-weight: 500;
						line-height: 14.4px;
						color: #5b3fff;
					}
				}
			}
		}
		.containerActions {
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 8px;
			margin-top: 16px;
			flex-wrap: wrap;
			button {
				flex: 1;
				border-radius: 8px;
				padding: 10px 14px;
				font-size: 14px;
				font-weight: 500;
				line-height: 20px;
				cursor: pointer;
				border: 1px solid #204ce0;
				background-color: #5b3fff;
				color: #fff;
			}
		}
		.containerCancelReservetion {
			margin-block: 16px;
			.ContainerLabel {
				cursor: pointer;
				p {
					font-size: 12px;
					font-weight: 700;
					line-height: 16px;
					color: #5b3fff;
					margin: 0;
				}
			}
		}
		.instructions {
			border: 1px solid #f5e3acb2;
			background-color: #fbf0c8b2;
			padding: 12px 8px;
			border-radius: 4px;
			.HeaderSection {
				position: relative;
				> div {
					p {
						margin: 0;
						color: #435363;
						&:first-child {
							font-size: 12px;
							font-weight: 700;
							line-height: 16px;
						}
						&:last-child {
							font-size: 12px;
							font-weight: 400;
							line-height: 16px;
						}
					}
				}
				.CloseButton {
					position: absolute;
					top: 0px;
					left: 0px;
					cursor: pointer;
					svg {
						color: #1c274c;
					}
				}
			}
			.body {
				* {
					color: #435363;
				}
				ol {
					margin: 0;
					padding-right: 15px;
					font-size: 12px;
					font-weight: 400;
					line-height: 16px;
				}
				b {
					font-size: 12px;
					font-weight: 700;
					line-height: 16px;
					margin: 0;
					&.blue {
						a {
							text-decoration: none;
							color: #5b3fff;
						}
					}
				}
				span {
					font-size: 12px;
					font-weight: 400;
					line-height: 16px;
				}
				p {
					margin: 0;
					font-size: 12px;
					font-weight: 400;
					line-height: 16px;
					span {
						font-size: 12px;
						font-weight: 400;
						line-height: 16px;
					}
				}
			}
		}
	}
`
