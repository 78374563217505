import { Box, Button, Divider, Grid, TextField, Typography } from '@mui/material';
import { minusPadding, mobileRTL } from '@utils';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { SAUDI_MOBILE_NUMBER } from '../../utils/regex';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

interface IContactForm {
  title: string;
  name: string;
  email: string;
  phone: string;
  message: string;
}

const contactSchema = z.object({
  title: z.string({ required_error: 'ERRORS.REQUIRED' }).trim().min(1, 'ERRORS.REQUIRED'),
  name: z.string({ required_error: 'ERRORS.REQUIRED' }).trim().min(1, 'ERRORS.REQUIRED'),
  email: z.string({ required_error: 'ERRORS.REQUIRED' }).email({ message: 'ERRORS.INVALID_EMAIL' }),
  phone: z
    .string({ required_error: 'ERRORS.REQUIRED' })
    .regex(new RegExp(SAUDI_MOBILE_NUMBER), 'ERRORS.INVALID_MOBILE'),
  message: z.string({ required_error: 'ERRORS.REQUIRED' }).trim().min(1, 'ERRORS.REQUIRED'),
});
const ContactForm = () => {
  const { control, reset, handleSubmit } = useForm<IContactForm>({
    resolver: zodResolver(contactSchema),
    mode: 'all',
  });
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const onSubmit = (formBody: IContactForm) => {
    const enEmailBody = `
     ${formBody.message} \n\n\n
    Name: ${formBody.name} \n
    Mobile: ${formBody.phone} \n
    Email: ${formBody.email} \n
    `;
    const arEmailBody = `
     ${formBody.message} \n\n\n
    الأسم: ${formBody.name} \n
    رقم الجوال: ${formBody.phone} \n
    البريد الالكتروني: ${formBody.email} \n
    `;

    const mailToLink = `mailto:sales@msool.io?body=${encodeURIComponent(
      language === 'ar' ? arEmailBody : enEmailBody,
    )}&subject=${formBody.title}`;
    window.location.href = mailToLink;
    reset({
      title: '',
      name: '',
      email: '',
      phone: '',
      message: '',
    });
  };
  return (
    <Box
      sx={{
        ...minusPadding,
        py: 5,
      }}
    >
      <Typography mb={1} fontWeight={'medium'} fontSize={'24px'}>
        {t('MENU.CONTACT')}
      </Typography>
      <Divider sx={{ mb: 6 }} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          sx={{
            '.MuiInputBase-root': {
              borderRadius: '24px',
            },
            form: {
              width: '100%',
            },
            '.MuiFormHelperText-root': {
              textAlign: 'start',
            },
          }}
          spacing={{ xs: 0, md: 4 }}
          rowSpacing={2}
        >
          <Grid item xs={12} md={6}>
            <Controller
              name='title'
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <>
                  <Typography mb={1}>{t('CONTACT.TITLE')}</Typography>
                  <TextField
                    value={value}
                    onChange={onChange}
                    error={Boolean(error?.message)}
                    helperText={t(error?.message ?? '')}
                    placeholder={t('CONTACT.TITLE_PLACEHOLDER')}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                </>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name='name'
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <>
                  <Typography mb={1}>{t('CONTACT.NAME')}</Typography>
                  <TextField
                    value={value}
                    onChange={onChange}
                    error={Boolean(error?.message)}
                    helperText={t(error?.message ?? '')}
                    placeholder={t('CONTACT.NAME_PLACEHOLDER')}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                </>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name='email'
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <>
                  <Typography mb={1}>{t('CONTACT.EMAIL')}</Typography>
                  <TextField
                    value={value}
                    onChange={onChange}
                    error={Boolean(error?.message)}
                    helperText={t(error?.message ?? '')}
                    placeholder={t('CONTACT.EMAIL_PLACEHOLDER')}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                </>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name='phone'
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <>
                  <Typography mb={1}>{t('CONTACT.MOBILE')}</Typography>
                  <TextField
                    value={value}
                    onChange={onChange}
                    error={Boolean(error?.message)}
                    helperText={t(error?.message ?? '')}
                    placeholder={t('CONTACT.MOBILE_PLACEHOLDER')}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                </>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name='message'
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <>
                  <Typography mb={1}>{t('CONTACT.MESSAGE')}</Typography>
                  <TextField
                    value={value}
                    onChange={onChange}
                    error={Boolean(error?.message)}
                    helperText={t(error?.message ?? '')}
                    placeholder={t('CONTACT.MESSAGE_PLACEHOLDER')}
                    multiline
                    rows={3}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                </>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6} mt={2}>
            <Button variant={'contained'} type={'submit'} fullWidth>
              {t('GENERAL.SEND')}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};
export default ContactForm;
