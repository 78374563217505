import styled from "styled-components"

export const CustomSlotLabelCalendarWrapper = styled.div<{
	$isWeekEnd: boolean
	$istoday: boolean
}>`
	width: 100%;
	p {
		margin: 0;
		font-size: 16px;
		font-weight: 700;
		line-height: 19.2px;
		text-align: center;
		color: ${(props) => (!props.$isWeekEnd ? "#969696" : "#5B3FFF")};
		&:last-child {
			display: flex;
			justify-content: center;
			align-items: center;
			span {
				background-color: ${(props) => (!props.$istoday ? "" : "#5B3FFF")};
				color: ${(props) => (!props.$istoday ? "" : "#fff")};
				width: 25px;
				height: 25px;
				display: flex;
				justify-content: center;
				align-items: center;
				line-height: 0;
				border-radius: 50px;
			}
		}
	}
`
