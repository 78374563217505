
import { useTranslation } from 'react-i18next';
import { memo } from 'react';
import PinIcon from '@assets/svg/pin.svg?react'; 
import {
    Box,
    Typography,
    TextField,
} from '@mui/material';
import useBreakPoinst from '@hooks/useBreakPoinst';

type IchangeField = {
    label:string;
    value:string;
    onEditPress:()=>void;

}
const ChangeField = ({ label = '', value = '', onEditPress = () => { } }:IchangeField) => {
    const { isPhone } = useBreakPoinst()
    const {
        t,
    } = useTranslation();
    return (
        <Box>
            <Typography mt={3}
                sx={{ color: '#435363', fontSize: '20px', fontWeight: 700, }}
                mb={1}>{label}</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }} >
                <TextField
                    InputProps={{ sx: { borderRadius: 20, height: '44px', textAlign: 'center', padding: 0 } }}
                    sx={{ width: isPhone ? '60%' : '30%', color: '#B4BAC1', borderRadius: 20, textAlign: 'center' }}
                    error={false}
                    helperText={''}
                    disabled
                    onChange={() => { }}
                    name='email'
                    value={value}
                    placeholder={''}
                    fullWidth
                    InputLabelProps={{ shrink: true }}

                />
                <Box
                    onClick={onEditPress}
                    sx={{
                        cursor: 'pointer',
                        display: 'flex',
                        borderRadius: 20,
                        borderColor: '#5B3FFF',
                        borderWidth: 0.3,
                        borderStyle: 'solid',
                        paddingX: 1,
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        height: 44,
                        width: '90px',
                        marginX: 1
                    }}

                >
                    <Typography sx={{
                        color: '#5B3FFF',
                        fontSize: '11px',
                        fontWeight: 700
                    }} >{t("SEETING.UPDATE")}</Typography>
                    <PinIcon />
                </Box>
            </Box>
        </Box>
    )
}
export default memo(ChangeField)