import { MutableRefObject } from "react"
import GoToNext from "../actions/goToNext"
import GoToPrev from "../actions/goToPrev"
import { ControlsMonthlyViewWrapper } from "./styles"
import FullCalendar from "@fullcalendar/react"
interface IProps {
	calendarTitle: string
	calendarRef: MutableRefObject<FullCalendar | null>
	switchView: "dayGridMonth" | "resourceTimelineWeek"
}
export default function ControlsMonthlyView({
	calendarTitle,
	calendarRef,
	switchView,
}: IProps) {
	return (
		<ControlsMonthlyViewWrapper>
			<GoToPrev calendarRef={calendarRef} switchView={switchView} />
			<p>{calendarTitle}</p>
			<GoToNext calendarRef={calendarRef} switchView={switchView} />
		</ControlsMonthlyViewWrapper>
	)
}
