import styled from "styled-components"

export const AccessInstructionsWrapper = styled.div`
	p {
		margin: 0;
		padding: 0;
	}
	.containerHeaderSection {
		display: grid;
		grid-template-columns: 20px 1fr auto;
		grid-template-rows: 1fr;
		border-top: 1px solid #eaeafd;
		gap: 8px;
		padding-block: 28px 12px;
		> div {
			width: 100%;
			&:first-child {
				p {
					color: #261b6a;
					font-size: 14px;
					font-weight: 500;
					line-height: 16.8px;
				}
			}
			&:last-child {
				display: flex;
				justify-content: flex-end;
				align-items: center;
				gap: 8px;
				div {
					&:first-child {
						color: #5b3fff;
						font-size: 12px;
						font-weight: 700;
						line-height: 14.4px;
						cursor: pointer;
					}
					&:last-child {
						cursor: pointer;
						display: flex;
						justify-content: center;
						align-items: center;
					}
				}
			}
		}
	}
	.formInputs {
		background-color: #fafafa;
		border-radius: 8px;
		padding: 12px;
	}
`
