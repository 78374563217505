import { FC } from 'react';
import { Button, TableCell, TableRow } from '@mui/material';
import { Add } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const CreateNewRow: FC<{
  span: number;
  onAdd: () => void;
  align?: 'center' | 'left' | 'right' | 'justify' | 'inherit';
}> = ({ align, span, onAdd }) => {
  const { t } = useTranslation();

  return (
    <TableRow>
      <TableCell colSpan={span} align={align} sx={{ paddingBlock: 1 }}>
        <Button
          variant={'text'}
          sx={{
            color: '#A1A1AA',

            gap: 1,
            backgroundColor: 'white',
            '&:hover': { backgroundColor: 'white' },
          }}
          onClick={onAdd}
          color={'inherit'}
          startIcon={<Add />}
        >
          {t('GENERAL.NEW')}
        </Button>
      </TableCell>
    </TableRow>
  );
};
export default CreateNewRow;
