import styled from "styled-components"

export const BreadcrumbsWrapper = styled.div`
	font-size: 16px;
	font-weight: 400;
	text-transform: capitalize;
	.prevLink {
		color: #435363;
		&:hover {
			text-decoration: underline;
			cursor: pointer;
		}
	}
	.currantLink {
		color: #8e98a1;
		cursor: default;
	}
`
