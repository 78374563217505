import { WhiteContainer } from '@components';
import { FC } from 'react';
import { CircularProgress } from '@mui/material';

const Loading = () => {
  return (
    <WhiteContainer>
      <div className={'h-full flex items-center justify-center gap-4'}>
        <CircularProgress />
      </div>
    </WhiteContainer>
  );
};
export default Loading;
