import { GuestDataWrapper } from "./styles"
import { Grid } from "@mui/material"
import { CustomDivider, HeaderSection } from "../reservationInfo/styles"
import { useTranslation } from "react-i18next"
import Input from "@Common/input"
import { FORM_REGEX_VALIDATORS } from "@utils"
import UserOctagonIcon from "@assets/svg/UserOctagonIcon.svg?react"

export default function GuestData() {
	const { t } = useTranslation()

	return (
		<GuestDataWrapper>
			<div>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<HeaderSection>
							<UserOctagonIcon />
							<p>{t("MANUALRESERVATION.GUESTDATA")}</p>
						</HeaderSection>
					</Grid>
					<Grid item xs={12} md={6}>
						<Input
							name='guestName'
							label={t("MANUALRESERVATION.FIRSTNameOfTheGuest")}
							placeholder={t("MANUALRESERVATION.FIRSTNameOfTheGuest")}
							registerOptions={{
								required: {
									value: true,
									message: t("MANUALRESERVATION.PLEASEENTERTHEGUESTSFIRSTNAME"),
								},
								pattern: {
									value: FORM_REGEX_VALIDATORS.textOnly,
									message: t("MANUALRESERVATION.ACCEPTTEXTONLY"),
								},
							}}
						/>
					</Grid>
					<Grid item xs={12} />
					<Grid item xs={12} md={6}>
						<Input
							name='guestPhoneNumber'
							label={t("MANUALRESERVATION.GUESTMOBILENUMBER")}
							placeholder={t("MANUALRESERVATION.GUESTMOBILENUMBER")}
							registerOptions={{
								required: {
									value: true,
									message: t(
										"MANUALRESERVATION.PLEASEENTERTHEGUESTSMOBILENUMBER"
									),
								},
								pattern: {
									value: FORM_REGEX_VALIDATORS.phone,
									message: t(
										"MANUALRESERVATION.PLEASEENTERTHEGUESTSMOBILENUMBERCORRECTLY"
									),
								},
							}}
						/>
					</Grid>
					<CustomDivider />
				</Grid>
			</div>
		</GuestDataWrapper>
	)
}
