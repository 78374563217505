import { MenuItem, Select } from "@mui/material"
import { useGetListingManagement } from "@services"
import { useEffect, useMemo } from "react"
import { IoIosArrowDown } from "react-icons/io"
import AirbnbIcon from "@assets/svg/airbnb.svg?react"
import GathernIcon from "@assets/svg/GathernIcon2.svg?react"
import BookingIcon from "@assets/svg/BookingIcon.svg?react"
import ChannelAgoda from "@assets/svg/ChannelAgoda.svg?react"
import MsoolIcon from "@assets/svg/msool-icon.svg?react"
import {
	Channals,
	ContainerChannals,
	ContainerOptionInSelectOptions,
} from "../styles"
import { Controller, useForm } from "react-hook-form"
import { useSetRecoilState } from "recoil"
import { selectedProperty } from "@store/index"

interface IChannals {
	Airbnb: JSX.Element
	GatherIn: JSX.Element
	Booking: JSX.Element
	Agoda: JSX.Element
	Msool: JSX.Element
}
const channals: IChannals = {
	Airbnb: <AirbnbIcon />,
	GatherIn: <GathernIcon />,
	Booking: <BookingIcon />,
	Agoda: <ChannelAgoda />,
	Msool: <MsoolIcon />,
}

interface IForm {
	propertyId: string
}
export default function SwitchProperties() {
	/* ------------------------------- Local State ------------------------------ */
	const setSelectedProperty = useSetRecoilState(selectedProperty)
	/* ---------------------------------- Hooks --------------------------------- */
	const { handleSubmit, control, setValue } = useForm<IForm>()
	const { data: getListingManagement } = useGetListingManagement()
	const dataList = useMemo(() => {
		const data = getListingManagement?.result.map((item) => {
			return {
				value: item.propertyId,
				label: item.propertyName,
				connectedChannels: item.connectedChannels,
			}
		})
		return data ?? []
	}, [getListingManagement])

	/* -------------------------------- Functions ------------------------------- */
	const onSubmit = (values: IForm) => {}
	const setSelectedValueInStote = (propertyId: string) => {
		const data = dataList.filter(
			(item) => item.value.toString() === propertyId.toString()
		)
		setSelectedProperty(data[0])
	}
	useEffect(() => {
		if (dataList.length > 0) {
			const defaultValue = dataList[0]
			setValue("propertyId", defaultValue?.value.toString())
			setSelectedProperty(defaultValue)
		}
	}, [dataList, setValue])

	return (
		<div className='containerCustomSelectOption'>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Controller
					name='propertyId'
					control={control}
					defaultValue=''
					render={({ field: { onChange, value } }) => (
						<Select
							value={value}
							onChange={(event) => {
								setSelectedValueInStote(event.target.value)
								onChange(event.target.value)
							}}
							IconComponent={IoIosArrowDown}
						>
							{dataList.map((item) => {
								return (
									<MenuItem value={item.value} key={item.value}>
										<ContainerOptionInSelectOptions>
											<span>{item.label}</span>
											<ContainerChannals>
												{item.connectedChannels.map((subItem, index) => {
													return (
														<Channals key={index}>
															<span>
																{
																	channals[
																		subItem.channelName as
																			| "Airbnb"
																			| "GatherIn"
																			| "Booking"
																			| "Agoda"
																			| "Msool"
																	]
																}
															</span>
														</Channals>
													)
												})}
											</ContainerChannals>
										</ContainerOptionInSelectOptions>
									</MenuItem>
								)
							})}
						</Select>
					)}
				/>
			</form>
		</div>
	)
}
