import styled from "styled-components"

export const PopupViewInnerCategoryWrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1300;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	.popupContainer {
		width: 60%;
		background-color: #fff;
		border-radius: 16px;
		padding: 32px;
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: auto 1fr auto;
		gap: 12px;
		> div {
			p {
				margin: 0;
			}
			&:nth-child(1) {
				display: flex;
				justify-content: flex-end;
				align-items: center;
				button {
					font-size: 24px;
					display: flex;
					justify-content: flex-end;
					align-items: center;
					background-color: transparent;
					border: none;
					cursor: pointer;
					color: #27272a;
				}
			}
			&:nth-child(2) {
				p {
					color: #261b6a;
					font-family: Tajawal;
					font-size: 34px;
					font-weight: 700;
					line-height: 40.8px;
					text-align: right;
				}
			}
			&:nth-child(3) {
				margin-top: 50px;
				border-bottom: 1px solid #eaeafd;
				padding-bottom: 32px;
				p {
					&:first-child {
						font-size: 12px;
						font-weight: 400;
						line-height: 14.4px;
						color: #000000;
					}
					&:last-child {
						font-size: 18px;
						font-weight: 700;
						line-height: 21.6px;
						color: #261b6a;
						margin-top: 15px;
					}
				}
			}
			&:nth-child(4) {
				padding-top: 24px;
				gap: 16px;
				p {
					font-size: 12px;
					font-weight: 400;
					line-height: 14.4px;
					color: #000000;
				}
				> div {
					width: 100%;
					> div {
						.propertyCard {
							padding: 16px;
							.containerData {
								.containerImage {
									height: 100px;
									overflow: hidden;
									border-radius: 8px;
									img {
										border-radius: 8px;
										width: 100%;
										height: 100%;
										object-fit: fill;
									}
								}
								.containerInfo {
									margin-top: 8px;
									display: flex;
									justify-content: flex-start;
									align-items: flex-start;
									gap: 8px;
									flex-direction: column;
									p {
										&:first-child {
											font-size: 14px;
											font-weight: 700;
											line-height: 16.8px;
											color: #331860;
										}
										&:last-child {
											font-size: 12px;
											font-weight: 400;
											line-height: 14.4px;
											color: #625783;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`
