import { useState } from "react"
import StepOne from "./StepOne"
import StepTwo from "./StepTwo"
import { CreateCategoryManagementWrapper } from "./styles"
import CustomButton from "@Common/CustomButton"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

export default function CreateCategoryManagement() {
	const navigate = useNavigate()
	const [activeStepIs, setActiveStepIs] = useState<1 | 2>(1)
	const { t } = useTranslation()
	const [categoryName, setCategoryName] = useState<string>()

	return (
		<CreateCategoryManagementWrapper>
			<div>
				{activeStepIs === 1 && (
					<StepOne
						setCategoryName={setCategoryName}
						categoryName={categoryName}
						setActiveStepIs={setActiveStepIs}
					/>
				)}
				{activeStepIs === 2 && <StepTwo categoryName={categoryName}/>}
			</div>
			<div className='containerFooter'>
				<div className='containerStepsLine'>
					{Array.from({ length: 2 }).map((_item, index) => (
						<span
							key={index + 1}
							className={`lineSteps ${activeStepIs === index + 1 || activeStepIs > 1 ? "active" : ""}`}
						></span>
					))}
				</div>
				<div className='containerActions'>
					<button
						onClick={() =>
							activeStepIs === 1
								? navigate("/category-management")
								: setActiveStepIs(1)
						}
					>
						{t("COMMON.BACK")}
					</button>
					<CustomButton
						withBG
						label={activeStepIs === 2 ? t("SAVE") : t("COMMON.NEXT")}
						type='submit'
						form='formAddCategory'
					/>
				</div>
			</div>
		</CreateCategoryManagementWrapper>
	)
}
